import DataGrid, {
    Column,
    ColumnChooser,
    DataGridRef,
    Export,
    FilterRow, HeaderFilter,
    Pager, Paging,
    Search,
    SearchPanel,
} from 'devextreme-react/data-grid';
import { Popup } from 'devextreme-react/popup';
import TextBox from 'devextreme-react/text-box';
import notify from "devextreme/ui/notify";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../contexts/auth";
import { AuthApiUrl } from "../../environment/routeSettings";
import { VMFindUser } from "../../types/identity/dto/identityDTO";
import { OnExporting } from "../../utils/dataGridTools";
import { TesGet } from '../../utils/rest';

interface IProps {
    label: string;
    customerId: string;
    name: string;
    userId: string;
    title: string;
    onChangeFields: (name: string, value: any) => void;
    isLocked: boolean;
}


const UserPicker = (props: IProps) => {
    const [initData, setInitData] = useState({});
    const [username, setUsername] = useState("");
    const [showStreetPicker, setShowApproachPicker] = useState(false);
    const dataGridRef = useRef<DataGridRef<any, any>>(null);
    const { t } = useTranslation();
    const { activeLoading } = useAuth();

    useEffect(() => {
        async function fetchMyAPI() {
            try {
                if (activeLoading) activeLoading(true);
                await getInitData();
                if (activeLoading) activeLoading(false);
            } catch (ex) {
                if (activeLoading) activeLoading(false);
                notify(t("someErrorOccurred") + ex, "error", 5000);
            }
        }
        if (showStreetPicker) {
            fetchMyAPI()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showStreetPicker]);


    useEffect(() => {
        async function fetchMyAPI() {
            try {
                if (activeLoading) activeLoading(true);
                await getUserName();
                if (activeLoading) activeLoading(false);
            } catch (ex) {
                if (activeLoading) activeLoading(false);
                notify(t("someErrorOccurred") + ex, "error", 5000);
            }
        }
        fetchMyAPI()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function getInitData() {
        try {
            var res = (await TesGet(AuthApiUrl() + "/api/user/findUser", true)) as VMFindUser[];
            setInitData(res.filter(x => x.defaultCustomerId === props.customerId));
        } catch (ex) {
            if (activeLoading) activeLoading(false);
            notify(t("someErrorOccurred"), "error", 5000);
        }
    }

    async function getUserName() {
        try {
            if (props.userId == null || props.userId === "")
                return;
            if (activeLoading) activeLoading(true);
            var res = (await TesGet(AuthApiUrl() + "/api/user/getUsername/" + props.userId, true)) as string;
            setUsername(res)
            if (activeLoading) activeLoading(false);
        } catch (ex) {
            if (activeLoading) activeLoading(false);
            notify(t("someErrorOccurred" + ex), "error", 5000);
        }

    }

    async function onOpenPopUp() {
        if (!props.isLocked) {
            setShowApproachPicker(true);
        }
    }
    function onRowClick(selectedRowData: any) {
        props.onChangeFields(props.name, selectedRowData.data.id)
        setUsername(selectedRowData.data.userName)
        setShowApproachPicker(false);
    }

    return (
        <React.Fragment>
            <label onClick={() => onOpenPopUp()}>
                <TextBox
                    label={props.label}
                    labelMode='static'
                    value={username}
                    disabled={props.isLocked}
                />
            </label>
            <Popup
                width={"60%"}
                visible={showStreetPicker}
                resizeEnabled={true}
                showTitle={true}
                title={props.title}
                hideOnOutsideClick={false}
                showCloseButton={true}
                onHiding={() => setShowApproachPicker(false)}
            >
                <div style={{ height: "100%" }}>
                    {showStreetPicker === true &&
                        <DataGrid
                            ref={dataGridRef}
                            dataSource={initData}
                            style={{ height: '100%' }}
                            rowAlternationEnabled={true}
                            showBorders={true}
                            selection={{ mode: 'single' }}
                            onRowClick={onRowClick}
                            hoverStateEnabled={true}
                            remoteOperations={true}
                            allowColumnReordering={true}
                            allowColumnResizing={true}
                            columnAutoWidth={true}
                            onExporting={OnExporting}
                        >
                            <Export enabled={true} allowExportSelectedData={true} />
                            <Paging enabled={true} defaultPageSize={100} />
                            <Pager
                                showPageSizeSelector={true}
                                allowedPageSizes={[100, 200, 300, 400, 500]}
                                showNavigationButtons={true}
                                showInfo={true}
                                visible={true}
                            />
                            <FilterRow visible={true}
                                applyFilter="auto" />

                            <HeaderFilter visible={true} />

                            <SearchPanel visible={true}
                                width={285}
                                placeholder={t("search...")} />

                            <ColumnChooser width={350} height={400}
                                enabled={true}
                                mode="select"
                                sortOrder="asc"
                            >
                                <Search enabled />
                            </ColumnChooser>

                            <Column dataField="userName"
                                caption={t('username')}
                                defaultSortOrder="asc"
                            >
                                <HeaderFilter>
                                    <Search enabled />
                                </HeaderFilter>
                            </Column>
                            <Column dataField="email"
                                caption={t('email')}
                            >
                                <HeaderFilter>
                                    <Search enabled />
                                </HeaderFilter>
                            </Column>
                            <Column dataField="firstName"
                                caption={t('firstName')}
                            >
                                <HeaderFilter>
                                    <Search enabled />
                                </HeaderFilter>
                            </Column>
                            <Column dataField="lastName"
                                caption={t('lastName')}
                            >
                                <HeaderFilter>
                                    <Search enabled />
                                </HeaderFilter>
                            </Column>
                            <Column dataField="defaultCustomerName"
                                caption={t('client')}
                            >
                                <HeaderFilter>
                                    <Search enabled />
                                </HeaderFilter>
                            </Column>

                            <Column dataType="boolean" dataField="isActive"
                                caption={t('isActive')}>
                                <HeaderFilter>
                                    <Search enabled />
                                </HeaderFilter>
                            </Column>
                        </DataGrid>
                    }
                </div>
            </Popup>

        </React.Fragment>
    );
}
export default UserPicker;