import { DataGrid, Form, NumberBox, Popup, ScrollView, SelectBox, Switch, TextBox, ValidationGroup } from "devextreme-react";
import { Button } from "devextreme-react/button";
import { ValidationGroupRef } from "devextreme-react/cjs/validation-group";
import { Column, ColumnChooser, DataGridRef, Button as DevExpressButton, Export, FilterBuilderPopup, FilterPanel, FilterRow, GroupPanel, Grouping, HeaderFilter, Lookup, Pager, Paging, Search, SearchPanel } from "devextreme-react/data-grid";
import { SimpleItem } from "devextreme-react/form";
import { custom } from "devextreme/ui/dialog";
import notify from "devextreme/ui/notify";
import { t } from "i18next";
import React, { useEffect, useRef, useState } from "react";
import { useAuth } from "../../../../../contexts/auth";
import { CollisionApiUrl, FieldApiUrl } from "../../../../../environment/routeSettings";
import { DashboardSettings, EmphasisAreaSetting, GeneralSettings, UnknownBoolTreatment } from "../../../../../types/collision/collisionTypes";
import { CoordinationSourceType } from "../../../../../types/collision/enums/collisionEnums";
import { FieldCategoryType, FieldServiceType, FieldType } from "../../../../../types/field/enums/fieldEnums";
import { TesField } from "../../../../../types/field/fieldType";
import { ModalMode } from "../../../../../types/general/enums/generalEnums";
import { NameValue } from "../../../../../types/general/generalTypes";
import { SortObjectByPropName } from "../../../../../utils/arrayTools";
import { Enum2Array } from "../../../../../utils/enumTools";
import { TesGet, TesPost } from "../../../../../utils/rest";

interface IProps {
  clientId: string | undefined
}
const Collision = (props: IProps) => {
  const { activeLoading } = useAuth();
  const [initData, setInitData] = useState<GeneralSettings>(new GeneralSettings());
  const [currentPopupData, setCurrentPopupData] = useState<EmphasisAreaSetting>(new EmphasisAreaSetting());
  const [initDataFields, setInitDataFields] = useState<TesField[]>([]);
  const [, setLstCoordinationSourceType] = useState<NameValue[]>([]);
  const dataGridRef = useRef<DataGridRef<any, any>>(null);
  const [showModal, setShowModal] = useState(false);
  const [modalMode, setModalMode] = useState<ModalMode>(ModalMode.Add);
  const validationRef = useRef<ValidationGroupRef>(null);
  const [lstUnknownTreatment, setLstUnknownTreatment] = useState<NameValue[]>([]);

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        getInitData();
        getInitDataFields()
        setLstCoordinationSourceType(Enum2Array(CoordinationSourceType))
        setLstUnknownTreatment(Enum2Array(UnknownBoolTreatment));
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function onSave() {
    try {
      const postObj = {
        ...initData,
        customerId: props.clientId
      }
      if (activeLoading) activeLoading(true);
      await TesPost(
        CollisionApiUrl() + "/api/settings/UpdateSetting",
        postObj,
        true
      );
      await getInitData();
      if (activeLoading) activeLoading(false);
      notify(t("dataSuccessfullyAdded"), "success", 5000);
    } catch {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred"), "error", 5000);
    }
  }

  async function getInitData() {
    setInitData(
      await TesGet(
        CollisionApiUrl() +
        "/api/Settings/GetCustomerSettings/" +
        props.clientId,
        true
      )
    );
  }

  async function getInitDataFields() {
    setInitDataFields(
      await TesGet(
        FieldApiUrl() +
        "/api/TesFields/GetAllFields/" +
        props.clientId +
        "/" +
        FieldServiceType.Collision,
        true
      )
    );
  }

  function onValueChange(key: string, value: any) {
    setCurrentPopupData({ ...currentPopupData, [key]: value })
    setInitData({ ...initData, [key]: value });
  }

  function onPopupValueChange(key: string, value: any) {
    setCurrentPopupData({ ...currentPopupData, [key]: value });
  }

  function onCloseModal() {
    setShowModal(false);
    setCurrentPopupData(new EmphasisAreaSetting);
  }

  function onAdd() {
    try {
      const validationRes = validationRef.current?.instance().validate();
      if (validationRes?.isValid) {

        if (
          initData.dashboardSettings?.emphasisAreaSettings && initData.dashboardSettings?.emphasisAreaSettings?.some(x => x.order === currentPopupData.order)
        ) {
          notify(t("thisOrderAlreadyExist"), "error", 5000);
          return;
        }
        var fieldName = initDataFields.find(x => x.id === currentPopupData.fieldId)?.name ?? ""
        var emSettings: EmphasisAreaSetting[];
        if (initData.dashboardSettings?.emphasisAreaSettings === undefined) {
          emSettings = [{ ...currentPopupData, fieldName: fieldName }]
        } else {
          emSettings = [...initData.dashboardSettings?.emphasisAreaSettings, { ...currentPopupData, fieldName: fieldName }]

        }


        setInitData({ ...initData, dashboardSettings: { ...initData.dashboardSettings ?? new DashboardSettings(), emphasisAreaSettings: emSettings } })
        onCloseModal();
        dataGridRef.current?.instance().refresh()
      }
    } catch (ex) {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred") + ex, "error", 5000);
    }
  }

  async function onUpdate() {
    try {
      if (activeLoading) activeLoading(true);

      const validationRes = validationRef.current?.instance().validate();
      if (validationRes?.isValid) {

        const newData: GeneralSettings = {
          ...initData,
          dashboardSettings: {
            ...initData.dashboardSettings,
            emphasisAreaSettings: [...initData.dashboardSettings?.emphasisAreaSettings?.filter(x => x.fieldId !== currentPopupData.fieldId)!, currentPopupData]!,
            useOnlyGeoCoded: initData.dashboardSettings?.useOnlyGeoCoded!,
            minimumCollisionLevel: initData.dashboardSettings?.minimumCollisionLevel!
          },
        };
        setInitData(newData)

        dataGridRef.current?.instance().refresh();

        onCloseModal();
        notify(t("dataSuccessfullyUpdated"), "success", 5000);
      }

      if (activeLoading) activeLoading(false);
    } catch (ex) {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred") + ex, "error", 5000);
    }
  }

  function onNew() {
    setModalMode(ModalMode.Add);
    setCurrentPopupData({
      ...currentPopupData,
      title: "",
      description: "",
      unknownTreatment: UnknownBoolTreatment.Ignore
    });

    // Show the popup modal
    setShowModal(true);
  }

  function onDelete(d: any) {

    let myDialog = custom({
      title: t("warning"),
      messageHtml: t("deleteWarningMessage"),
      buttons: [{
        text: t("yes"),
        onClick: async (e) => {
          setInitData({ ...initData, dashboardSettings: { ...initData?.dashboardSettings!, emphasisAreaSettings: [...initData.dashboardSettings?.emphasisAreaSettings?.splice(d.row.dataIndex, 1)!] } })
          setInitData(initData)
          return { buttonText: e.component.option("text") }
        }
      },
      {
        text: t("no"),
        onClick: (e) => {
          return { buttonText: e.component.option("text") }
        }
      },]
    });
    myDialog.show();

  }

  function onEdit(d: any) {
    let newData = new EmphasisAreaSetting;
    Object.assign(newData, d.row.data);
    setModalMode(ModalMode.Edit);
    setCurrentPopupData(newData)
    setShowModal(true);
  }

  return (
    <React.Fragment>
      <div className="row" style={{ marginTop: 20, padding: 10 }}>
        <Form colCount={2}>
          <SimpleItem colSpan={1}>
            <TextBox
              placeholder=""
              label={t('geoCodeCityName')}
              labelMode='static'
              value={initData?.geoCodeCityName}
              onValueChange={(e) => onValueChange("geoCodeCityName", e)}
            />
          </SimpleItem>
          <SimpleItem colSpan={1}>
            <TextBox
              placeholder=""
              label={t('arcGISToken')}
              labelMode='static'
              value={initData?.arcGISToken}
              onValueChange={(e) => onValueChange("arcGISToken", e)}
            />
          </SimpleItem>
          <SimpleItem colSpan={1}>
            <TextBox
              placeholder=""
              label={t('arcGISGeoCodeServiceAddress')}
              labelMode='static'
              value={initData?.arcGISGeoCodeServiceAddress}
              onValueChange={(e) => onValueChange("arcGISGeoCodeServiceAddress", e)}
            />
          </SimpleItem>
          <SimpleItem colSpan={1}>
            <TextBox
              placeholder=""
              label={t('googleAPIKey')}
              labelMode='static'
              value={initData?.googleAPIKey}
              onValueChange={(e) => onValueChange("googleAPIKey", e)}
            />
          </SimpleItem>
          <SimpleItem colSpan={2}>
            <h2>{t('dashboardSetting')}</h2>
          </SimpleItem>
          <SimpleItem colSpan={1}>
            <NumberBox
              label={t('minimumCollisionLevel')}
              labelMode='static'
              value={initData.dashboardSettings?.minimumCollisionLevel}
              onValueChange={e => setInitData({ ...initData, dashboardSettings: { ...initData.dashboardSettings!, minimumCollisionLevel: e } })}
            />
          </SimpleItem>
          <SimpleItem colSpan={2}>
            <div>{t("UseOnlyGeoCoded")}</div>
            <Switch
              style={{ marginTop: 5 }}
              value={initData.dashboardSettings?.useOnlyGeoCoded}
              onValueChange={e => setInitData({ ...initData, dashboardSettings: { ...initData.dashboardSettings!, useOnlyGeoCoded: e } })}
            />
          </SimpleItem>
          <SimpleItem colSpan={2}>
            <div className="rightColumn">
              <Button
                style={{ margin: "-3rem 0" }}
                onClick={onNew}
                icon="fa-solid fa-circle-plus"
                text={t("add")}
              />
            </div>
          </SimpleItem>
          <SimpleItem colSpan={2}>
            <DataGrid
              id="gridContainer"
              ref={dataGridRef}
              dataSource={initData.dashboardSettings?.emphasisAreaSettings ?? []}
              rowAlternationEnabled={true}
              showBorders={true}
              hoverStateEnabled={true}
              remoteOperations={true}
              allowColumnReordering={true}
              allowColumnResizing={true}
              columnAutoWidth={true}
              style={{ margin: "0 1rem" }}
            // onExporting={OnExporting}
            >
              <Export enabled={true} allowExportSelectedData={true} />
              <Grouping contextMenuEnabled={true} autoExpandAll={false} />
              <GroupPanel visible={true} /> {/* or "auto" */}
              <FilterPanel visible={true} />
              <FilterBuilderPopup position={"top"} />
              <Paging enabled={true} defaultPageSize={100} />
              <Pager
                showPageSizeSelector={true}
                allowedPageSizes={[100, 200, 300, 400, 500]}
                showNavigationButtons={true}
                showInfo={true}
                visible={true}
              />
              <FilterRow visible={true} applyFilter="auto" />
              <HeaderFilter visible={true} />
              <SearchPanel visible={true} width={285} placeholder={t("search...")} />
              <ColumnChooser width={350} height={400} enabled={true} mode="select" sortOrder="asc">
                <Search enabled />
              </ColumnChooser>
              <Column dataField="fieldName" caption={t("fieldName")} visible={true}>
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column dataField="title" caption={t("title")} visible={true}>
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column dataField="description" caption={t("description")} visible={true}>
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column dataField="unknownTreatment"
                caption={t('unknownTreatment')}
                alignment='left'
              >
                <Lookup dataSource={SortObjectByPropName(lstUnknownTreatment, "name")} valueExpr="value" displayExpr="name" />
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column dataField="order" caption={t("order")} visible={true}>
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column dataField="minPercentage" caption={t("minPercentage")} visible={true}>
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column dataField="maxPercentage" caption={t("maxPercentage")} visible={true}>
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column dataField="goal" caption={t("goal")} visible={true}>
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column dataField="goalMinPercentage" caption={t("goalMinPercentage")} visible={true}>
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column dataField="goalMaxPercentage" caption={t("goalMaxPercentage")} visible={true}>
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column dataField="goalTracking" caption={t("goalTracking")} visible={true}>
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column dataField="enabled" caption={t("enabled")} visible={true}>
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column dataField="totalCollisions" caption={t("total")} visible={true}>
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column type="buttons" caption={t("actions")} width={110} fixed={true} fixedPosition="right">
                <DevExpressButton
                  hint={t("delete")}
                  onClick={onDelete}
                  icon="fa-solid fa-trash-can"
                />
                <DevExpressButton
                  hint={t("edit")}
                  onClick={onEdit}
                  icon="fa-solid fa-pencil"
                />
              </Column>
            </DataGrid>
            {/* </ValidationGroup> */}
          </SimpleItem>
        </Form>
        <div className="rightColumn">
          <Button
            className="tes-modal-btn-add saveButton"
            onClick={onSave}
            text={t("save")}
          />
        </div>
      </div>

      <Popup
        width={"50%"}
        height={"auto"}
        visible={showModal}
        resizeEnabled={true}
        showTitle={true}
        title={modalMode === ModalMode.Add ? t("add") : t("update")}
        showCloseButton={true}
        onHiding={() => onCloseModal()}
      >
        <ScrollView width="100%" height="100%">
          <ValidationGroup ref={validationRef}>
            <Form colCount={2}>
              <SimpleItem colSpan={1}>
                <SelectBox
                  placeholder=""
                  label={t("fieldName")}
                  labelMode="static"
                  value={currentPopupData.fieldId}
                  onValueChange={(e) => onPopupValueChange("fieldId", e)}
                  className="modalInput"
                  valueExpr="id"
                  displayExpr="name"
                  items={SortObjectByPropName(initDataFields, "name").filter(x => x.fieldCategoryType === FieldCategoryType.CollisionGeneral).filter(x => x.fieldType === FieldType.Boolean)}
                  showClearButton={true}
                  searchEnabled={true}
                // disabled={modalMode === ModalMode.Edit}
                >
                </SelectBox>
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <TextBox
                  label={t("title")}
                  labelMode='static'
                  onValueChange={(e) => onPopupValueChange("title", e)}
                  value={currentPopupData.title}
                />
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <TextBox
                  label={t("description")}
                  labelMode='static'
                  onValueChange={(e) => onPopupValueChange("description", e)}
                  value={currentPopupData.description}
                />
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <SelectBox
                  placeholder=""
                  label={t("unknownTreatment")}
                  labelMode="static"
                  value={currentPopupData.unknownTreatment}
                  onValueChange={(e) => onPopupValueChange("unknownTreatment", e)}
                  className="modalInput"
                  valueExpr="value"
                  displayExpr="name"
                  items={SortObjectByPropName(lstUnknownTreatment, "name")}
                  showClearButton={true}
                  searchEnabled={true}
                >
                </SelectBox>
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <NumberBox
                  step={1}
                  label={t('order')}
                  labelMode='static'
                  value={currentPopupData.order}
                  onValueChange={e => onPopupValueChange("order", e)}
                />
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <NumberBox
                  step={1}
                  label={t('minPercentage')}
                  labelMode='static'
                  value={currentPopupData.minPercentage}
                  onValueChange={e => onPopupValueChange("minPercentage", e)}
                />
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <NumberBox
                  step={1}
                  label={t('maxPercentage')}
                  labelMode='static'
                  value={currentPopupData.maxPercentage}
                  onValueChange={e => onPopupValueChange("maxPercentage", e)}
                />
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <NumberBox
                  step={1}
                  label={t('goal')}
                  labelMode='static'
                  value={currentPopupData.goal}
                  onValueChange={e => onPopupValueChange("goal", e)}
                />
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <NumberBox
                  step={1}
                  label={t('goalMinPercentage')}
                  labelMode='static'
                  value={currentPopupData.goalMinPercentage}
                  onValueChange={e => onPopupValueChange("goalMinPercentage", e)}
                />
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <NumberBox
                  step={1}
                  label={t('goalMaxPercentage')}
                  labelMode='static'
                  value={currentPopupData.goalMaxPercentage}
                  onValueChange={e => onPopupValueChange("goalMaxPercentage", e)}
                />
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <div>{t("goalTracking")}</div>
                <Switch
                  style={{ marginTop: 5 }}
                  value={currentPopupData.goalTracking}
                  onValueChange={e => onPopupValueChange("goalTracking", e)}
                />
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <div>{t("enabled")}</div>
                <Switch
                  style={{ marginTop: 5 }}
                  value={currentPopupData.enabled}
                  onValueChange={e => onPopupValueChange("enabled", e)}
                />
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <div>{t("total")}</div>
                <Switch
                  style={{ marginTop: 5 }}
                  value={currentPopupData.totalCollisions}
                  onValueChange={e => onPopupValueChange("totalCollisions", e)}
                />
              </SimpleItem>
            </Form>
          </ValidationGroup>
          <div>
            <div className="rightColumn">
              <Button
                className="tes-modal-btn-cancel"
                style={{ marginRight: 20 }}
                onClick={() => onCloseModal()}
                text={t("cancel")}
              />
              {modalMode === ModalMode.Add ?
                <Button
                  className='tes-modal-btn-add'
                  onClick={onAdd}
                  text={t('add')}
                />
                :
                <Button
                  className='tes-modal-btn-add'
                  onClick={onUpdate}
                  text={t('update')}
                />
              }
            </div>
          </div>
        </ScrollView>
      </Popup>
    </React.Fragment>
  );
};
export default Collision;
