//imports
import { ContextMenu, Switch } from 'devextreme-react';
import { Button } from 'devextreme-react/button';
import DataGrid, {
    Column,
    ColumnChooser,
    DataGridRef,
    Export,
    FilterBuilderPopup,
    FilterPanel,
    FilterRow,
    Grouping,
    GroupItem,
    GroupPanel,
    HeaderFilter,
    Lookup,
    Pager, Paging,
    Search,
    SearchPanel,
    StateStoring,
    Summary,
    TotalItem,
} from 'devextreme-react/data-grid';
import { ValidationGroupRef } from 'devextreme-react/validation-group';
import notify from 'devextreme/ui/notify';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import Permission from '../../../components/permission/permision';
import { CustomerPermissions, UserPermissions } from '../../../constants/Permissions';
import { useAuth } from '../../../contexts/auth';
import { useClientSetting } from '../../../contexts/clientSetting';
import { AuthApiUrl } from '../../../environment/routeSettings';
import { GridType, ModalMode, ResponseCode } from '../../../types/general/enums/generalEnums';
import { INameId, NameValue, RequestResponseResult } from '../../../types/general/generalTypes';
import { VMAddUser, VMAddUserData } from '../../../types/identity/dto/identityDTO';
import { DivisionsPermissionTypes, PasswordMode } from '../../../types/identity/enums/identityEnums';
import { IdentityGeneralSetting, SignOnMethod } from '../../../types/identity/identityTypes';
import { OnExporting } from '../../../utils/dataGridTools';
import { Enum2Array } from '../../../utils/enumTools';
import { RequestErrorHandling, TesGet, TesPost } from '../../../utils/rest';
import { RightClickMenu } from '../../../utils/rightClickMenu';
import { MakeId, MakeInt, MakeSpecialChar } from '../../../utils/stringTools';

const Users = () => {
    //const [gridProfileId, setGridProfileId] = useState(null);

    const [initData, setInitData] = useState<{ defaultCustomerName: string }[]>([]);
    const [initDataClient, setInitDataClient] = useState<INameId[]>();
    const [initDataDivisionRole, setInitDataDivisionRole] = useState<INameId[]>();
    const [lstUserDivisionRole, setLstUserDivisionRole] = useState<VMAddUserData>();
    const [initDataIdentityGeneralSetting, setInitDataIdentityGeneralSetting] = useState<IdentityGeneralSetting>();
    const dataGridRef = useRef<DataGridRef<any, any>>(null);
    const [showModal, setShowModal] = useState(false);
    const [allUsers, setAllUsers] = useState<boolean>((localStorage.getItem("showAllUsers") === "true" ? true : false) || false);
    const [modalMode, setModalMode] = useState<ModalMode>(ModalMode.Add);
    const [selectedData, setSelectedData] = useState<Partial<VMAddUser>>(new VMAddUser());
    const validationGroupRef = useRef<ValidationGroupRef>(null);
    const dataGridRoleRef = useRef<DataGridRef<any, any>>(null);
    const dataGridDivisionRef = useRef<DataGridRef<any, any>>(null);
    const [clientSetting, setClientSetting] = useState<IdentityGeneralSetting>(new IdentityGeneralSetting());
    const [lstSignOnMethod, setLstSignOnMethod] = useState<NameValue[]>([]);
    const history = useNavigate();
    const { activeLoading, selectedClineNameId } = useAuth();
    const { t } = useTranslation();
    const [selectedRowData, setSelectedRowData] = useState<any>(null);
    const items = [
        { text: t('openInNewTab'), icon: "fa-solid fa-up-right-from-square" },
        //   { text: t('openLocation') , icon: "fa-solid fa-location-dot"}
    ];
    const compactViewModel: boolean = (JSON.parse(localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}') || {}).viewMode === "compact";
    const { customerDivisions } = useClientSetting();

    useEffect(() => {
        async function fetchMyAPI() {
            try {
                if (activeLoading) activeLoading(true);
                await getInitData();
                await getInitDataClient();
                await getInitDataIdentityGeneralSetting();
                await getInitDataDivisionRole();
                setLstSignOnMethod(Enum2Array(SignOnMethod));
                if (activeLoading) activeLoading(false);
            } catch (ex) {
                if (activeLoading) activeLoading(false);
                notify(t("someErrorOccurred") + ex, "error", 2500);
            }
        }
        fetchMyAPI()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //Get InitData

    async function getInitData() {
        setInitData(await TesGet(AuthApiUrl() + "/api/user/finduser", true));
    }

    async function getInitDataClient() {
        const res = await TesGet(AuthApiUrl() + "/api/CustomerXUsers/ClientsOfUser", true);
        setInitDataClient(res);

    }

    async function getInitDataIdentityGeneralSetting() {
        setInitDataIdentityGeneralSetting(await TesGet(AuthApiUrl() + "/api/GeneralSettings/" + localStorage.getItem('selectedCustomerId'), true));
    }
    async function getInitDataDivisionRole() {
        let res: INameId[] = new Array<INameId>();
        for (var n in DivisionsPermissionTypes) {
            if (typeof DivisionsPermissionTypes[n] === 'number') {
                res.push({ name: n, id: DivisionsPermissionTypes[n] });
            }
        }
        setInitDataDivisionRole(res);

    }
    async function getUserRolesDivisions(customerId: string) {
        try {
            if (activeLoading) activeLoading(true);
            setLstUserDivisionRole(await TesGet(AuthApiUrl() + "/api/User/GetAddUserData/" + customerId, true));
            if (activeLoading) activeLoading(false);
        } catch (ex) {
            if (activeLoading) activeLoading(false);
            notify(t("someErrorOccurred") + ex, "error", 2500);
        }
    }
    function getDivisionRoles(customerId: string) {
        getUserRolesDivisions(customerId);
    }

    function onNew() {
        if (initDataClient?.length === 1) {
            onValueChanged("defaultCustomerId", initDataClient[0].id)
        }
        getClientSetting(localStorage.getItem("selectedCustomerId") as string);
        setSelectedData({ ...selectedData, passwordMode: PasswordMode.SetPasswordManually, signOnMethod: initDataIdentityGeneralSetting?.defaultSignOnMethod })
        setModalMode(ModalMode.Add);
        setShowModal(true);
    }

    const navigateToAddUser = useCallback(() => {
        history("/users/AddUser");
    }, [history]);

    function onValueChanged(key: string, value: any) {
        if (key === "defaultCustomerId") {
            getDivisionRoles(value);
        }
        setSelectedData({ ...selectedData, [key]: value });
    }

    function onCloseModal() {
        if (modalMode === ModalMode.Add) {
            setShowModal(false);
            setSelectedData(new VMAddUser())
            setSelectedData({ signOnMethod: SignOnMethod.AzureSingleSignOn })
            setLstUserDivisionRole(new VMAddUserData())
        } else {
            setSelectedData(new VMAddUser())
            setSelectedData({ signOnMethod: SignOnMethod.AzureSingleSignOn })
            setShowModal(false);
        }
    }

    async function onAdd() {
        var validationRes = validationGroupRef.current?.instance().validate();
        selectedData.userName = (selectedData.setUsernameManually) ? selectedData.userName : selectedData.email;
        selectedData.password = (selectedData.passwordMode === PasswordMode.SendUsernamePasswordByEmail || selectedData.passwordMode === PasswordMode.SetPasswordManually)
            ? selectedData.password
            : MakeId(10) + MakeSpecialChar(1) + MakeInt(5)
        selectedData.listGroupIds = dataGridRoleRef.current?.instance().getSelectedRowKeys() ?? []
        selectedData.emailUsernamePassword = selectedData.passwordMode === PasswordMode.SendUsernamePasswordByEmail;
        selectedData.emailSetPassword = selectedData.passwordMode === PasswordMode.SendSetPasswordByEmail;
        if (selectedData.signOnMethod === SignOnMethod.TesIdentity) {
            if (selectedData.passwordMode === undefined) {
                notify(t('choosePass'), 'warning', 5000)
                return
            }
        }
        //TODO: Change for new Auth system
        selectedData.listDivisionIds = dataGridDivisionRef.current?.instance().getSelectedRowKeys().map((x) => x.id);
        const regex = new RegExp((initDataIdentityGeneralSetting && initDataIdentityGeneralSetting?.passwordExpression)
            ? initDataIdentityGeneralSetting?.passwordExpression
            : '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$'
        )
        if (selectedData.signOnMethod === SignOnMethod.TesIdentity) {
            if (!(regex.test(selectedData?.password ?? ""))) {
                notify(t("passwordIsNotComplex"), "error", 2500);
                return;
            }
        }

        if (validationRes?.isValid) {
            try {
                if (activeLoading) activeLoading(true);
                var res = await TesPost(AuthApiUrl() + "/api/User/RegisterNew", selectedData, true) as RequestResponseResult<Location>;
                if (res.responseCode === ResponseCode.OK) {
                    onCloseModal();
                    await getInitData();
                    if (activeLoading) activeLoading(false);
                    notify(t("dataSuccessfullyAdded"), "success", 2500);
                } else if (res.responseCode === ResponseCode.PasswordIsNotComplex) {
                    notify(t("passwordIsNotComplex"), "error", 2500);
                } else if (res.responseCode === ResponseCode.UserNameExist) {
                    notify(t("userNameExist"), "error", 2500);

                } else if (res.responseCode === ResponseCode.EmailExist) {
                    notify(t("emailExist"), "error", 2500);
                }
                else {
                    await RequestErrorHandling(res);;
                }
                if (activeLoading) activeLoading(false);

            } catch (ex) {
                if (activeLoading) activeLoading(false);
                notify(t("someErrorOccurred"), "error", 2500);
            }
        }
    }

    function onRowClicked(e: any) {
        const isCtrlKeyPressed = e.event.ctrlKey || e.event.metaKey;
        if (e.data.id !== undefined) {

            if (isCtrlKeyPressed) {
                window.open(`/users/userDetails/${e.data.id}`, "_blank");
            } else {
                history(`/users/userDetails/${e.data.id}`);
            }
        }
    }

    async function getClientSetting(customerId: string) {
        try {
            if (activeLoading) activeLoading(true);
            setClientSetting(await TesGet(AuthApiUrl() + "/api/GeneralSettings/" + customerId, true));
            if (clientSetting !== undefined) {
                if (clientSetting?.defaultSignOnMethod === undefined) {
                    setSelectedData({ ...selectedData, signOnMethod: SignOnMethod.TesIdentity });
                } else {
                    setSelectedData({ ...selectedData, signOnMethod: clientSetting.defaultSignOnMethod });
                }
            }
            if (activeLoading) activeLoading(false);
        } catch (ex) {
            if (activeLoading) activeLoading(false);
            notify(t("someErrorOccurred") + ex, "error", 2500);
        }
    }

    console.log(initData)

    return (
        <Permission
            allowed={[
                UserPermissions.User_D,
                UserPermissions.User_E,
                CustomerPermissions.Customer_D_User,
                CustomerPermissions.Customer_E_User,
                CustomerPermissions.Customer_D_Group,
                CustomerPermissions.Customer_A_Group,
                CustomerPermissions.Customer_D_Division,
            ]}
            hasFeedBackElement={true}
        >
            <div className={compactViewModel ? "compactStyle" : ""}>
                <React.Fragment>
                    <ContextMenu
                        dataSource={items}
                        width={100}
                        target=".dx-data-row"
                        onItemClick={e => RightClickMenu(e, selectedRowData.row.data.id, "/users/userDetails/")}
                    />
                    <h2 className={'content-block'}>{t('users')}</h2>
                    <div className={'content-block'}>
                        <div className={'dx-card responsive-paddings'}>
                            <Permission
                                allowed={[
                                    UserPermissions.User_D,
                                    CustomerPermissions.Customer_D_User,
                                ]}
                                hasFeedBackElement={true}
                            >
                                <div className="row">
                                    <div className='rightColumn' style={{ display: "flex" }}>
                                        <div style={{ margin: "0.5rem 2.5rem 0 0", display: "flex" }}>
                                            <span style={{ marginRight: "0.5rem" }}>{t('showAllUsers')}:</span>
                                            <Switch
                                                value={allUsers}
                                                onValueChange={e => { setAllUsers(e); localStorage.setItem("showAllUsers", e ? "true" : "false") }}
                                            />
                                        </div>
                                        <Button
                                            onClick={navigateToAddUser}
                                            icon="fa-solid fa-circle-plus"
                                            text={t('add')}
                                        />
                                    </div>
                                </div>
                            </Permission>
                            <DataGrid id="gridContainer"
                                ref={dataGridRef}
                                dataSource={allUsers ? initData : initData.filter(x => x.defaultCustomerName === selectedClineNameId?.name)}
                                rowAlternationEnabled={true}
                                showBorders={true}
                                hoverStateEnabled={true}
                                remoteOperations={true}
                                allowColumnReordering={true}
                                allowColumnResizing={true}
                                columnAutoWidth={true}
                                onRowClick={onRowClicked}
                                style={{ margin: "0 1rem" }}
                                onExporting={OnExporting}
                                onContextMenuPreparing={e => { setSelectedRowData(e) }}
                            >
                                <Export enabled={true}
                                    allowExportSelectedData={true}
                                />
                                <Grouping contextMenuEnabled={true} autoExpandAll={false} />
                                <GroupPanel visible={true} /> {/* or "auto" */}
                                <FilterPanel visible={true} />
                                <FilterBuilderPopup position={'top'} />

                                <StateStoring enabled={true} type="localStorage" storageKey={GridType.Users.toString()} savingTimeout={500} />

                                <Summary>
                                    <TotalItem
                                        column="GeoId"
                                        summaryType="count"
                                    />
                                    <GroupItem
                                        summaryType="count"
                                        alignByColumn
                                        name="Total Count"
                                    />
                                </Summary>


                                <Paging enabled={true} defaultPageSize={100} />
                                <Pager
                                    showPageSizeSelector={true}
                                    allowedPageSizes={[100, 200, 300, 400, 500]}
                                    showNavigationButtons={true}
                                    showInfo={true}
                                    visible={true}
                                />

                                <FilterRow visible={true}
                                    applyFilter="auto" />

                                <HeaderFilter visible={true} />


                                <SearchPanel visible={true}
                                    width={285}
                                    placeholder={t("search...")} />
                                <ColumnChooser width={350} height={400}
                                    enabled={true}
                                    mode="select"
                                    sortOrder="asc"
                                >
                                    <Search enabled />
                                </ColumnChooser>

                                <Column dataField="userName"
                                    caption={t('username')}
                                    defaultSortOrder="asc"
                                >
                                    <HeaderFilter>
                                        <Search enabled />
                                    </HeaderFilter>
                                </Column>
                                <Column dataField="email"
                                    caption={t('email')}
                                >
                                    <HeaderFilter>
                                        <Search enabled />
                                    </HeaderFilter>
                                </Column>
                                <Column dataField="firstName"
                                    caption={t('firstName')}
                                >
                                    <HeaderFilter>
                                        <Search enabled />
                                    </HeaderFilter>
                                </Column>
                                <Column dataField="lastName"
                                    caption={t('lastName')}
                                >
                                    <HeaderFilter>
                                        <Search enabled />
                                    </HeaderFilter>
                                </Column>
                                <Column dataField="defaultCustomerName"
                                    caption={t('client')}
                                >
                                    <HeaderFilter>
                                        <Search enabled />
                                    </HeaderFilter>
                                </Column>
                                <Column dataField="signOnMethod"
                                    caption={t('signOnMethod')}
                                >
                                    <HeaderFilter>
                                        <Search enabled />
                                    </HeaderFilter>
                                    <Lookup dataSource={lstSignOnMethod} valueExpr="value" displayExpr="name" />
                                </Column>


                                <Column dataType="boolean" dataField="isActive"
                                    caption={t('isActive')}>
                                    <HeaderFilter>
                                        <Search enabled />
                                    </HeaderFilter>
                                </Column>

                                <Column
                                    dataField="singleDivisionName"
                                    allowSorting={true}
                                    visible={false}
                                    caption={t("division")}
                                >
                                    <HeaderFilter>
                                        <Search enabled />
                                    </HeaderFilter>

                                </Column>
                            </DataGrid>
                        </div>
                    </div>
                </React.Fragment >
            </div>
        </Permission >
    );
}
export default Users;

// {/* Add Modal */}
// <Popup
//     width={"50%"}
//     height={popupHeight}
//     visible={showModal}
//     resizeEnabled={true}
//     showTitle={true}
//     title={t('add')}
//     hideOnOutsideClick={false}
//     showCloseButton={true}
//     onHiding={() => onCloseModal()}
// >
//     <div style={{ height: "90%" }}>
//         <ScrollView width="100%" height="100%">
//             <div className='row'>
//                 <RadioGroup
//                     layout="horizontal"
//                     id="radio-group-with-selection"
//                     items={[
//                         { id: SignOnMethod.AzureSingleSignOn, text: t('microAzureSSO') },
//                         { id: SignOnMethod.TesIdentity, text: t('tesLocalUser') }
//                     ]}
//                     value={selectedData.signOnMethod}
//                     valueExpr="id" displayExpr="text" onValueChange={e => onValueChanged("signOnMethod", e)} />
//             </div>

//             <ValidationGroup
//                 ref={validationGroupRef}
//             >
//                 {selectedData.signOnMethod === SignOnMethod.AzureSingleSignOn &&
//                     <Form
//                         colCount={12}>
//                         <SimpleItem colSpan={10} >
//                             <TextBox
//                                 style={{marginLeft:"-1rem"}}
//                                 label={t('email')}
//                                 labelMode='static'
//                                 value={selectedData.email}
//                                 onValueChange={e => onValueChanged("email", e)}
//                             >
//                                 <Validator>
//                                     <RequiredRule message={t('emailIsRequired')} />
//                                 </Validator>

//                             </TextBox>
//                         </SimpleItem>



//                         <SimpleItem colSpan={2} >
//                             <div >{t('sendNotificationEmail')}</div>
//                             <Switch
//                                 style={{ marginTop: 5 }}
//                                 hint={t("sendNotificationEmail")}
//                                 value={selectedData.sendNotificationEmail}
//                                 onValueChange={e => onValueChanged("sendNotificationEmail", e)}
//                             />
//                         </SimpleItem>

//                         {selectedData.setUsernameManually &&
//                             <SimpleItem colSpan={6} >
//                                 <TextBox
//                                     label={t('username')}
//                                     labelMode='static'
//                                     value={selectedData.userName}
//                                     onValueChange={e => onValueChanged("userName", e)}
//                                 >
//                                     <Validator>
//                                         <RequiredRule message={t('userNameIsRequired')} />
//                                     </Validator>
//                                 </TextBox>
//                             </SimpleItem>

//                         }

//                         {selectedData.setUsernameManually &&
//                             <SimpleItem colSpan={6} >
//                                 <TextBox
//                                     label={t('firstName')}
//                                     labelMode='static'
//                                     value={selectedData.firstName}
//                                     onValueChange={e => onValueChanged("firstName", e)}
//                                 >
//                                     {/* <Validator>
//                                     <RequiredRule message={t('firstNameIsRequired')} />
//                                 </Validator> */}
//                                 </TextBox>
//                             </SimpleItem>

//                         }

//                         {selectedData.setUsernameManually &&
//                             <SimpleItem colSpan={6} >
//                                 <TextBox
//                                     label={t('lastName')}
//                                     labelMode='static'
//                                     value={selectedData.lastName}
//                                     onValueChange={e => onValueChanged("lastName", e)}
//                                 >
//                                     {/* <Validator>
//                                     <RequiredRule message={t('lastNameIsRequired')} />
//                                 </Validator> */}
//                                 </TextBox>
//                             </SimpleItem>

//                         }

//                         <SimpleItem colSpan={6}>
//                             <SelectBox
//                                 style={{marginLeft:"-1rem"}}
//                                 placeholder=""
//                                 label={t('client')}
//                                 valueExpr="id"
//                                 displayExpr="name"
//                                 labelMode='static'
//                                 value={selectedData.defaultCustomerId}
//                                 onValueChange={e => onValueChanged("defaultCustomerId", e)}
//                                 items={initDataClient}
//                                 showClearButton={true}
//                                 searchEnabled={true}
//                             >
//                                 <Validator>
//                                     <RequiredRule message={t('clientIsRequired')} />
//                                 </Validator>
//                             </SelectBox>
//                         </SimpleItem>

//                         {!selectedData.setUsernameManually && <SimpleItem colSpan={6} />}

//                         {/* roles */}
//                         <SimpleItem colSpan={12}>
//                             <p style={{ fontSize: "22px", margin: "1rem 0 0 0" }}>{t("roles")}</p>
//                             <hr style={{ height: "2px", borderWidth: "0px", color: "#9E9E9E", backgroundColor: "#9E9E9E" }}></hr>
//                         </SimpleItem>
//                         <SimpleItem colSpan={12} >
//                             <DataGrid id="gridContainer"
//                                 ref={dataGridRoleRef}
//                                 dataSource={lstUserDivisionRole?.groups}
//                                 rowAlternationEnabled={true}
//                                 showBorders={true}
//                                 keyExpr="id"
//                                 hoverStateEnabled={true}
//                                 onExporting={OnExporting}
//                             >
//                                 <Export enabled={true} allowExportSelectedData={true} />
//                                 <Paging enabled={true} defaultPageSize={20} />
//                                 <Pager
//                                     showPageSizeSelector={true}
//                                     allowedPageSizes={[10, 15, 20, 25, 50]}
//                                     showNavigationButtons={true}
//                                 />
//                                 <Selection
//                                     mode="multiple"
//                                     selectAllMode={'allPages'}
//                                     showCheckBoxesMode={'always'}
//                                 />
//                                 <FilterRow visible={true}
//                                     applyFilter="auto" />

//                                 <HeaderFilter visible={true} />

//                                 <SearchPanel visible={true}
//                                     width={285}
//                                     placeholder={t("search...")} />

//                                 <ColumnChooser width={350} height={400}
//                                     enabled={true}
//                                     mode="select"
//                                     sortOrder="asc"
//                                 >
//                                     <Search enabled />
//                                 </ColumnChooser>
//                                 <Column dataField="name"
//                                     caption={t("name")}>
//                                     <HeaderFilter>
//                                         <Search enabled />
//                                     </HeaderFilter>
//                                 </Column>

//                             </DataGrid>
//                         </SimpleItem>

//                         {/* divisions */}
//                         <SimpleItem colSpan={12}>
//                             <p style={{ fontSize: "22px", margin: "1rem 0 0 0" }}>{t("divisions")}</p>
//                             <hr style={{ height: "2px", borderWidth: "0px", color: "#9E9E9E", backgroundColor: "#9E9E9E" }}></hr>
//                         </SimpleItem>
//                         <SimpleItem colSpan={12} >
//                             <DataGrid id="gridContainer"
//                                 ref={dataGridDivisionRef}
//                                 dataSource={lstUserDivisionRole?.divisions}
//                                 rowAlternationEnabled={true}
//                                 showBorders={true}
//                                 hoverStateEnabled={true}
//                                 allowColumnReordering={true}
//                                 allowColumnResizing={true}
//                                 columnAutoWidth={true}
//                                 onExporting={OnExporting}
//                                 onEditorPreparing={e => {
//                                     if ((e.parentType === "dataRow" || e.parentType === "filterRow") && e.dataField === "roles") {
//                                         e.editorName = "dxTagBox"
//                                         e.editorOptions.dataSource = initDataDivisionRole;
//                                         e.editorOptions.showSelectionControls = true;
//                                         e.editorOptions.displayExpr = "name";
//                                         e.editorOptions.valueExpr = "id";
//                                         e.editorOptions.value = e.value || [];
//                                         e.editorOptions.onValueChanged = function (args: any) {
//                                             e.setValue(args.value);
//                                         }
//                                     }
//                                 }}
//                             >
//                                 <Export enabled={true} allowExportSelectedData={true} />
//                                 <SearchPanel visible={true}
//                                     width={285}
//                                     placeholder={t("search...")} />

//                                 <ColumnChooser width={350} height={400}
//                                     enabled={true}
//                                     mode="select"
//                                     sortOrder="asc"
//                                 >
//                                     <Search enabled />
//                                 </ColumnChooser>
//                                 <Paging enabled={true} defaultPageSize={20} />
//                                 <Pager
//                                     showPageSizeSelector={true}
//                                     allowedPageSizes={[10, 20, 50, 100]}
//                                     showNavigationButtons={true}

//                                 />
//                                 <Editing
//                                     mode="cell"
//                                     allowUpdating={true}
//                                     allowAdding={false}
//                                     allowDeleting={false} />
//                                 <Selection
//                                     mode="multiple"
//                                     selectAllMode={'allPages'}
//                                     showCheckBoxesMode={'always'}
//                                 />

//                                 <FilterRow visible={true}
//                                     applyFilter="auto" />

//                                 <HeaderFilter visible={true} />


//                                 <Column dataField="name"
//                                     width={150}
//                                     caption={t('name')}
//                                 >
//                                     <HeaderFilter>
//                                         <Search enabled />
//                                     </HeaderFilter>
//                                 </Column>

//                                 <Column dataField="city"
//                                     width={100}
//                                     caption={t('city')}>
//                                     <HeaderFilter>
//                                         <Search enabled />
//                                     </HeaderFilter>
//                                 </Column>
//                                 <Column
//                                     dataField="roles" caption={t('permissions')}
//                                     calculateDisplayValue={function (rowData: any) {
//                                         var values = rowData.roles;
//                                         var res = [];
//                                         if (values !== undefined) {
//                                             for (let i = 0; i < values.length; i++) {
//                                                 res.push(DivisionsPermissionTypes[values[i]]);
//                                             }
//                                         }
//                                         return res.join(",");
//                                     }}
//                                 ></Column>
//                             </DataGrid>
//                         </SimpleItem>
//                     </Form>
//                 }

//                 {selectedData.signOnMethod === SignOnMethod.TesIdentity &&
//                     <Form
//                         colCount={12}>
//                         <SimpleItem colSpan={12} >
//                             <TextBox
//                                 label={t('email')}
//                                 labelMode='static'
//                                 value={selectedData.email}
//                                 onValueChange={e => onValueChanged("email", e)}
//                             >
//                                 <Validator>
//                                     <RequiredRule message={t('emailIsRequired')} />
//                                 </Validator>
//                             </TextBox>
//                         </SimpleItem>


//                         <SimpleItem colSpan={6} >
//                             <TextBox
//                             style={{marginLeft:"-1rem"}}
//                                 label={t('firstName')}
//                                 labelMode='static'
//                                 value={selectedData.firstName}
//                                 onValueChange={e => onValueChanged("firstName", e)}
//                             >
//                                 <Validator>
//                                     <RequiredRule message={t('firstNameIsRequired')} />
//                                 </Validator>
//                             </TextBox>
//                         </SimpleItem>
//                         <SimpleItem colSpan={6} >
//                             <TextBox
//                                 label={t('lastName')}
//                                 labelMode='static'
//                                 value={selectedData.lastName}
//                                 onValueChange={e => onValueChanged("lastName", e)}
//                             >
//                                 <Validator>
//                                     <RequiredRule message={t('lastNameIsRequired')} />
//                                 </Validator>
//                             </TextBox>
//                         </SimpleItem>

//                         <SimpleItem colSpan={12}>
//                             <RadioGroup
//                                 layout="horizontal"
//                                 id="radio-group-with-selection"
//                                 items={[
//                                     { id: PasswordMode.SendSetPasswordByEmail, text: t('sendSetPasswordByEmail') },
//                                     { id: PasswordMode.SendUsernamePasswordByEmail, text: t('sendUsernamePasswordByEmail') },
//                                     { id: PasswordMode.SetPasswordManually, text: t('setPasswordManually') }
//                                 ]}
//                                 defaultValue={PasswordMode.SetPasswordManually}
//                                 value={selectedData.passwordMode}
//                                 valueExpr="id" displayExpr="text" onValueChange={e => onValueChanged("passwordMode", e)} />
//                         </SimpleItem>

//                         {(selectedData.passwordMode === PasswordMode.SendUsernamePasswordByEmail || selectedData.passwordMode === PasswordMode.SetPasswordManually) &&
//                             <SimpleItem colSpan={6} >
//                                 <TextBox
//                                     label={t('password')}
//                                     labelMode='static'
//                                     value={selectedData.password}
//                                     onValueChange={e => onValueChanged("password", e)}
//                                     className={design.passwordField}
//                                     mode={passMode}
//                                 >
//                                     <i
//                                         className={passMode === "text" ? `${"fa-regular fa-eye-slash fa-xl"} ${design.eyeIcon}` : `${"fa-regular fa-eye fa-xl"} ${design.eyeIcon}`}
//                                         onClick={() => passMode === "text" ? setPassMode("password") : setPassMode("text")}
//                                     />
//                                     <Validator>
//                                         <RequiredRule message={t('passwordIsRequired')} />
//                                     </Validator>
//                                 </TextBox>
//                             </SimpleItem>
//                         }
//                         {(selectedData.passwordMode === PasswordMode.SendUsernamePasswordByEmail || selectedData.passwordMode === PasswordMode.SetPasswordManually) &&
//                             <SimpleItem colSpan={1} >
//                                 <i
//                                     className={`${"fa-solid fa-key fa-2xl"} ${design.keyIcon} ${design.tooltip}`}
//                                     onClick={e => onValueChanged("password", MakeId(9) + MakeSpecialChar(1) + MakeInt(3))}
//                                 >
//                                     <label className={design.tooltipText}>{t("generatePassword")}</label>
//                                 </i>
//                             </SimpleItem>
//                         }
//                         <SimpleItem colSpan={!(selectedData.passwordMode === PasswordMode.SendUsernamePasswordByEmail || selectedData.passwordMode === PasswordMode.SetPasswordManually) ? 6 : 5}>
//                             <SelectBox
//                                 style={{marginLeft:"-1rem"}}
//                                 placeholder=""
//                                 label={t('client')}
//                                 valueExpr="id"
//                                 displayExpr="name"
//                                 labelMode='static'
//                                 value={selectedData.defaultCustomerId}
//                                 onValueChange={e => onValueChanged("defaultCustomerId", e)}
//                                 items={initDataClient}
//                                 showClearButton={true}
//                                 searchEnabled={true}
//                             >
//                                 <Validator>
//                                     <RequiredRule message={t('clientIsRequired')} />
//                                 </Validator>
//                             </SelectBox>
//                         </SimpleItem>
//                         {!(selectedData.passwordMode === PasswordMode.SendUsernamePasswordByEmail || selectedData.passwordMode === PasswordMode.SetPasswordManually) && <SimpleItem colSpan={6}></SimpleItem>}

//                         <SimpleItem colSpan={12}>
//                             <p style={{ fontSize: "22px", margin: "1rem 0 0 0" }}>{t("roles")}</p>
//                             <hr style={{ height: "2px", borderWidth: "0px", color: "#9E9E9E", backgroundColor: "#9E9E9E" }}></hr>
//                         </SimpleItem>

//                         <SimpleItem colSpan={12} >

//                             <DataGrid id="gridContainer"
//                                 ref={dataGridRoleRef}
//                                 dataSource={lstUserDivisionRole?.groups}
//                                 rowAlternationEnabled={true}
//                                 showBorders={true}
//                                 keyExpr="id"
//                                 hoverStateEnabled={true}
//                                 onExporting={OnExporting}
//                             >
//                                 <Export enabled={true} allowExportSelectedData={true} />
//                                 <Paging enabled={true} defaultPageSize={20} />
//                                 <Pager
//                                     showPageSizeSelector={true}
//                                     allowedPageSizes={[10, 15, 20, 25, 50]}
//                                     showNavigationButtons={true}
//                                 />
//                                 <Selection
//                                     mode="multiple"
//                                     selectAllMode={'allPages'}
//                                     showCheckBoxesMode={'always'}
//                                 />
//                                 <FilterRow visible={true}
//                                     applyFilter="auto" />

//                                 <HeaderFilter visible={true} />

//                                 <SearchPanel visible={true}
//                                     width={285}
//                                     placeholder={t("search...")} />

//                                 <ColumnChooser width={350} height={400}
//                                     enabled={true}
//                                     mode="select"
//                                     sortOrder="asc"
//                                 >
//                                     <Search enabled />
//                                 </ColumnChooser>
//                                 <Column dataField="name"
//                                     caption={t("name")}>
//                                     <HeaderFilter>
//                                         <Search enabled />
//                                     </HeaderFilter>
//                                 </Column>

//                             </DataGrid>
//                         </SimpleItem>
//                         {/* </GroupItemForm> */}

//                         <SimpleItem colSpan={12}>
//                             <p style={{ fontSize: "22px", margin: "1rem 0 0 0" }}>{t("divisions")}</p>
//                             <hr style={{ height: "2px", borderWidth: "0px", color: "#9E9E9E", backgroundColor: "#9E9E9E" }}></hr>
//                         </SimpleItem>

//                         <SimpleItem colSpan={12} >

//                             <DataGrid id="gridContainer"
//                                 ref={dataGridDivisionRef}
//                                 dataSource={lstUserDivisionRole?.divisions}
//                                 rowAlternationEnabled={true}
//                                 showBorders={true}
//                                 hoverStateEnabled={true}
//                                 allowColumnReordering={true}
//                                 allowColumnResizing={true}
//                                 columnAutoWidth={true}
//                                 onExporting={OnExporting}
//                                 onEditorPreparing={e => {
//                                     if ((e.parentType === "dataRow" || e.parentType === "filterRow") && e.dataField === "roles") {
//                                         e.editorName = "dxTagBox"
//                                         e.editorOptions.dataSource = initDataDivisionRole;
//                                         e.editorOptions.showSelectionControls = true;
//                                         e.editorOptions.displayExpr = "name";
//                                         e.editorOptions.valueExpr = "id";
//                                         e.editorOptions.value = e.value || [];
//                                         e.editorOptions.onValueChanged = function (args: any) {
//                                             e.setValue(args.value);
//                                         }
//                                     }
//                                 }}
//                             >
//                                 <Export enabled={true} allowExportSelectedData={true} />
//                                 <SearchPanel visible={true}
//                                     width={285}
//                                     placeholder={t("search...")} />

//                                 <ColumnChooser width={350} height={400}
//                                     enabled={true}
//                                     mode="select"
//                                     sortOrder="asc"
//                                 >
//                                     <Search enabled />
//                                 </ColumnChooser>
//                                 <Paging enabled={true} defaultPageSize={20} />
//                                 <Pager
//                                     showPageSizeSelector={true}
//                                     allowedPageSizes={[10, 20, 50, 100]}
//                                     showNavigationButtons={true}

//                                 />
//                                 <Editing
//                                     mode="cell"
//                                     allowUpdating={true}
//                                     allowAdding={false}
//                                     allowDeleting={false} />
//                                 <Selection
//                                     mode="multiple"
//                                     selectAllMode={'allPages'}
//                                     showCheckBoxesMode={'always'}
//                                 />

//                                 <FilterRow visible={true}
//                                     applyFilter="auto" />

//                                 <HeaderFilter visible={true} />


//                                 <Column dataField="name"
//                                     width={150}
//                                     caption={t('name')}
//                                 >
//                                     <HeaderFilter>
//                                         <Search enabled />
//                                     </HeaderFilter>
//                                 </Column>

//                                 <Column dataField="city"
//                                     width={100}
//                                     caption={t('city')}>
//                                     <HeaderFilter>
//                                         <Search enabled />
//                                     </HeaderFilter>
//                                 </Column>
//                                 <Column
//                                     dataField="roles" caption={t('permissions')}
//                                     calculateDisplayValue={function (rowData: any) {
//                                         var values = rowData.roles;
//                                         var res = [];
//                                         if (values !== undefined) {
//                                             for (let i = 0; i < values.length; i++) {
//                                                 res.push(DivisionsPermissionTypes[values[i]]);
//                                             }
//                                         }
//                                         return res.join(",");
//                                     }}
//                                 ></Column>
//                             </DataGrid>
//                         </SimpleItem>
//                     </Form>
//                 }
//             </ValidationGroup>
//         </ScrollView>
//     </div>
//     <div className='rightColumn' style={{ marginBottom: "0.5rem", marginTop: "0.5rem" }}>
//         <Button
//             className='tes-modal-btn-cancel'
//             style={{ marginRight: 20 }}
//             onClick={() => onCloseModal()}
//             text={t('cancel')}
//         />
//         <Button
//             className='tes-modal-btn-add'
//             onClick={onAdd}
//             text={t('add')}
//         />
//     </div>
// </Popup>
