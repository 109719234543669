//#region imports
import { ContextMenu, SelectBox } from "devextreme-react";
import { Button } from "devextreme-react/button";
import DataGrid, {
  Column,
  ColumnChooser,
  DataGridRef,
  Export,
  FilterBuilderPopup,
  FilterPanel,
  FilterRow,
  Grouping,
  GroupItem,
  GroupPanel,
  HeaderFilter,
  Lookup,
  Pager,
  Paging,
  Search,
  SearchPanel,
  Selection,
  SortByGroupSummaryInfo,
  StateStoring,
  Summary,
} from "devextreme-react/data-grid";
import DropDownButton from "devextreme-react/drop-down-button";
import { Form, SimpleItem } from "devextreme-react/form";
import { Popup } from "devextreme-react/popup";
import ScrollView from "devextreme-react/scroll-view";
import CustomStore from "devextreme/data/custom_store";
import notify from "devextreme/ui/notify";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ExportCollision from "../../../components/exportCollision/exportCollision";
import GeneralGisMapManager from "../../../components/gisMap/generalGisMapManager";
import GridProfilePicker from "../../../components/gridProfilePicker/gridProfilePicker";
import Permission from "../../../components/permission/permision";
import ReportViewer from "../../../components/reportViewer/ReportViewer";
import getTitle from "../../../components/title/getTitle";
import ValidationReport from "../../../components/validationReport/validationReport";
import { CollisionPermissions, TMSPermissions } from "../../../constants/Permissions";
import reportSettings from "../../../constants/report-settings";
import reportLocations from "../../../constants/reportLocations";
import { useAuth } from "../../../contexts/auth";
import { useClientSetting } from "../../../contexts/clientSetting";
import { AuthApiUrl, CollisionApiUrl, FieldApiUrl, ReportApiUrl } from "../../../environment/routeSettings";
import { ApprovalLevel } from "../../../types/collision/collisionTypes";
import { UpdateGeoCodeStatusDTO, VMCollisionStatusUpdate } from "../../../types/collision/dto/collisionDtos";
import {
  CollisionDataSourceType,
  CollisionStatus,
  CollisionValidationStatus,
  GeoCodeStatus,
} from "../../../types/collision/enums/collisionEnums";
import { TableFieldRequestDTO } from "../../../types/field/dto/fieldDTO";
import { FieldCategoryType, FieldLocation2Show, FieldType } from "../../../types/field/enums/fieldEnums";
import { TesField } from "../../../types/field/fieldType";
import { GridType, ResponseCode, TesMapModule } from "../../../types/general/enums/generalEnums";
import { GridSetting, INameId, LazyLoadingRequest, NameValue, RequestResponseResult } from "../../../types/general/generalTypes";
import { ClientReport, ReportLocation, UiReportRequest } from "../../../types/report/reportTypes";
import { SortObjectByPropName } from "../../../utils/arrayTools";
import { OnExporting } from "../../../utils/dataGridTools";
import { GridActualDateCalculator } from "../../../utils/dateTimeTools";
import MaxDropdownItemWidthCalculator from "../../../utils/dropDownWidthCalculator";
import { Enum2Array, EnumFlag2Array } from "../../../utils/enumTools";
import { useScreenSize } from "../../../utils/media-query";
import { RequestErrorHandling, TesGet, TesPost } from "../../../utils/rest";
import { RightClickMenu } from "../../../utils/rightClickMenu";
import "./drafts.scss";
//#endregion imports

const dataSource = new CustomStore({
  key: "_id",
  load: async (loadOption) => {
    return await TesPost(
      `${CollisionApiUrl()
      }/api/Collisions/GetDraftCollisions/`,
      {
        customerId: localStorage.getItem("selectedCustomerId"),
        divisionId: localStorage.getItem("selectedDivisionId"),
        loadOptions: loadOption
      } as LazyLoadingRequest,
      true
    );
  },
});



const Drafts = () => {
  //#region consts
  const { activeLoading } = useAuth();
  const [initDataFields, setInitDataFields] = useState<TesField[]>([]);
  const [showMap, setShowMap] = useState(false);
  const [initDataDivisions, setInitDataDivisions] = useState<INameId[]>([]);
  const [showReportModal, setShowReportModal] = useState<boolean>(false);
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [lstApprovalLevel, setLstApprovalLevel] = useState<NameValue[]>([]);
  const [lstCollisionDataSourceType, setLstCollisionDataSourceType] = useState<NameValue[]>([]);
  const [lstValidationStatus, setLstValidationStatus] = useState<NameValue[]>([]);
  const [initDataReports, setInitDataReports] = useState<ClientReport[]>([]);
  const [showValidationReport, setShowValidationReport] = useState<boolean>(false);
  const [lstSelectedIds, setLstSelectedIds] = useState<string[]>([]);
  const [selectedReport, setSelectedReport] = useState<ClientReport>(new ClientReport());
  const [geoCodeStatus, setGeoCodeStatus] = useState<any>();
  const [lstGeoCodeStatus, setLstGeoCodeStatus] = useState<NameValue[]>([]);
  const [showGeoCodeStatusUpdater, setShowGeoCodeStatusUpdater] = useState<boolean>(false);
  const [showDeleteWarning, setShowDeleteWarning] = useState<boolean>(false);
  const [ids2Delete, setIds2Delete] = useState<string[]>([]);
  const history = useNavigate();
  const dataGridRef = useRef<DataGridRef<any, any>>(null);
  const { t } = useTranslation();
  const dropDownOptions = { width: 215, };
  const { is2xLarge } = useScreenSize();
  const [reportPopupHeight, setReportPopupHeight] = useState<string>("80%")
  const [selectedRowData, setSelectedRowData] = useState<any>(null);
  const [initDataConfirmationLevels, setInitDataConfirmationLevels] = useState<ApprovalLevel[]>([new ApprovalLevel()]);
  const items = [
    { text: t('openInNewTab'), icon: "fa-solid fa-up-right-from-square" },
    { text: t('openLocation'), icon: "fa-solid fa-location-dot" }
  ];
  const [appLevel, setAppLevel] = useState<number>(0);
  const [gridSetting, setGridSetting] = useState<GridSetting>(JSON.parse((localStorage.getItem("GridSetting")!)) || new GridSetting)
  const compactViewModel: boolean = (JSON.parse(localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}') || {}).viewMode === "compact";
  const { generalSetting, customerDivisions } = useClientSetting();
  //#endregion consts


  //#region functions

  const title = getTitle('/collision/drafts', '');
  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(() => {
    if (is2xLarge) { setReportPopupHeight("55%") }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        await getInitialDataFields();
        await getInitDataDivisions();
        await getInitialDataReports();
        await getInitialDataConfirmationLevels();
        setLstApprovalLevel(Enum2Array(CollisionStatus));
        setLstGeoCodeStatus(Enum2Array(GeoCodeStatus));
        setLstValidationStatus(Enum2Array(CollisionValidationStatus));
        setLstCollisionDataSourceType(Enum2Array(CollisionDataSourceType));
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const navigateToCollisionDetails = useCallback(() => {
    history("/collision/collisionDetails/AddNew");
  }, [history]);

  async function getInitialDataConfirmationLevels() {
    setInitDataConfirmationLevels(
      await TesGet(CollisionApiUrl() + "/api/approvalLevels/" + localStorage.getItem("selectedCustomerId"), true));
  }

  async function getInitialDataFields() {
    var postOb: TableFieldRequestDTO = {
      customerId: localStorage.getItem('selectedCustomerId')!,
      categoryTypes: [FieldCategoryType.CollisionGeneral]
    }
    var res = await TesPost(FieldApiUrl() + "/api/TesFields/GetWebTesFieldsByPermissionByCategory", postOb, true) as TesField[];
    res = res.sort(x => x.gridViewIndex);
    setInitDataFields(res);

  }

  async function getInitDataDivisions() {
    setInitDataDivisions(
      await TesGet(
        AuthApiUrl() +
        "/api/divisions/" +
        localStorage.getItem("selectedCustomerId"),
        true
      )
    );
  }

  async function getInitialDataReports() {
    try {
      var postObj: UiReportRequest = {
        customerId: localStorage.getItem("selectedCustomerId")!,
        lstLocations: [
          reportLocations.Collision_Collisions,
        ]
      }

      var res: ClientReport[] = await TesPost(
        ReportApiUrl() +
        "/api/ClientReports/GetPageReports",
        postObj,
        true
      );
      var validationReport = new ClientReport()
      validationReport.id = 'validationReport'
      validationReport.name = t('validationReport')
      var reportLoc = new ReportLocation()
      reportLoc.name = reportLocations.Collision_Collisions
      validationReport.reportLocations = [reportLoc]
      res.push(validationReport)
      setInitDataReports(res);
    } catch (ex) {
      notify(t("errorInFetchReports") + ex, "error", 5000);
    }
  }

  async function onOpenReport(d: any) {
    var gridData = await dataGridRef.current?.instance().getSelectedRowKeys();
    if (gridData?.length === 0 ?? true) {
      notify(t('pleaseSelectSomeData'), 'warning', 5000);
      return;
    }
    if ((gridData?.length ?? 0) > reportSettings.noMaxItems) {
      notify(t('selectCorrectNumberOfGrid'), 'warning', 5000);
      return;
    }
    if (d !== null) {
      setSelectedReport(d.itemData);
    }
    if (d.itemData.id === "validationReport") {
      setShowValidationReport(true)
    } else {
      setShowReportModal(true)
      setLstSelectedIds(gridData!.map(x => x) as string[])
    }
  }

  function onCloseReportModal() {
    setShowReportModal(false);
    dataGridRef.current?.instance().deselectAll();
  }

  function onRowClicked(e: any) {

    const isCtrlKeyPressed = e.event.ctrlKey || e.event.metaKey;
    if (e.data._id !== undefined) {

      if (isCtrlKeyPressed) {
        window.open(`/collision/collisionDetails/${e.data._id}`, "_blank");
      } else {
        history(`/collision/collisionDetails/${e.data._id}`);
      }
    }
  }

  function onCloseConfirmationModal() {
    setAppLevel(0);
    setShowConfirmation(false);
  }

  async function onOpenConfirmation() {
    var gridData = await dataGridRef.current?.instance().getSelectedRowKeys();

    if (gridData?.length === 0) {
      notify(t("noSelectedRows"), "warning", 5000);
      return;
    }
    setShowConfirmation(true);
  }

  async function onConfirmation(status: CollisionStatus, appLevel: number) {
    try {
      var gridData = dataGridRef.current?.instance().getSelectedRowKeys();

      var postObj: VMCollisionStatusUpdate = {
        status: status,
        level: appLevel,
        collisionIds: gridData?.map((x: any) => x) as string[],
      };

      var res = await TesPost(
        CollisionApiUrl() + "/api/Collisions/UpdateApprovalLevel",
        postObj,
        true
      ) as RequestResponseResult<object>;;
      if (res.responseCode === ResponseCode.OK) {
        notify(t("dataSuccessfullyUpdated"), "success", 5000);
      } else if (res.responseCode === ResponseCode.UserNotPermitted) {
        notify(t("userNotPermitted"), "error", 5000);
      } else if (res.responseCode === ResponseCode.CollisionValidationError) {
        notify(t("collisionValidationError"), "error", 5000);
      }
      else {
        await RequestErrorHandling(res);;
      }
      onCloseConfirmationModal();
    } catch (error) {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred" + error), "error", 5000);
    }
  }

  function calculateApprovalLevel(d: any) {
    if (d.data.ApprovalStatus === 4) {
      return (
        <Button
          height={40}
          text={t("info")}
          className="tes-modal-btn-add"
        // stylingMode="outlined"
        // type="success"
        />
      );
    }
    if (d.data.ApprovalStatus === 3) {
      return (
        <Button
          height={40}
          text={t("error")}
          className="tes-modal-btn-cancel"
        // stylingMode="outlined"
        // type="danger"
        />
      );
    }
    if (d.data.ApprovalStatus === 2) {
      return (
        <Button
          height={40}
          text={t("warning")}
          className="tes-modal-btn-cancel"
        // stylingMode="outlined"
        // type="default"
        />
      );
    }
    if (d.data.ApprovalStatus === 1) {
      return (
        <Button
          height={40}
          text={t("info")}
          className="tes-modal-btn-add"
        // stylingMode="outlined"
        // type="normal"
        />
      );
    }
  }

  function calculateValidationStatus(d: any) {
    if (d.data.ValidationStatus === 0) {
      return (
        <Button
          height={40}
          text={t("info")}
          className="tes-modal-btn-add"
        // stylingMode="outlined"
        // type="success"
        />
      );
    }
    if (d.data.ValidationStatus === 3) {
      return (
        <Button
          height={40}
          text={t("error")}
          className="tes-modal-btn-cancel"
        // stylingMode="outlined"
        // type="danger"
        />
      );
    }
    if (d.data.ValidationStatus === 2) {
      return (
        <Button
          height={40}
          text={t("warning")}
          className="tes-modal-btn-cancel"
        // stylingMode="outlined"
        // type="default"
        />
      );
    }
    if (d.data.ValidationStatus === 1) {
      return (
        <Button
          height={40}
          text={t("info")}
          className="tes-modal-btn-add"
        // stylingMode="outlined"
        // type="normal"
        />
      );
    }
  }


  function onChangeGeoCodeStatus(value: any) {
    setGeoCodeStatus(value);
  }



  async function UpdateGeoCodeStatus() {
    try {
      if (activeLoading) activeLoading(true);
      var gridData = await dataGridRef.current?.instance().getSelectedRowKeys();
      if (gridData?.length === 0 ?? true) {
        notify(t('pleaseSelectSomeData'), 'warning', 5000);
        return;
      }
      const postOb: UpdateGeoCodeStatusDTO = {
        collisionIds: gridData as string[],
        geoCodeStatus: geoCodeStatus
      }
      const res = await TesPost(CollisionApiUrl() + "/api/Collisions/UpdateGeoCodeStatus", postOb, true);
      dataGridRef.current?.instance().deselectAll();
      dataGridRef.current?.instance().refresh();
      if (res.responseCode === ResponseCode.OK) {
        notify(t("dataSuccessfullyUpdated"), "success", 5000);
        onCloseGeoCodeStatus();
      } else {
        await RequestErrorHandling(res);;
        onCloseGeoCodeStatus();
      }
      if (activeLoading) activeLoading(false);
    } catch (ex) {
      if (activeLoading) activeLoading(false);
      notify(t("errorInFetchReports") + ex, "error", 5000);
      onCloseGeoCodeStatus();
    }
  }


  function onCloseGeoCodeStatus() {
    setShowGeoCodeStatusUpdater(false)
    setGeoCodeStatus({})
  }

  async function onChangeAppLevel(value: any) {
    setAppLevel(value)
  }


  async function onOpenDeleteWarning() {
    var gridData = dataGridRef.current?.instance().getSelectedRowKeys();
    if (gridData?.length === 0 ?? true) {
      notify(t('pleaseSelectSomeData'), 'warning', 5000);
      return;
    } else {
      setShowDeleteWarning(true)
      setIds2Delete(gridData as string[])
    }
  }

  function onCloseDeleteWarning() {
    setShowDeleteWarning(false)
    setIds2Delete([])
  }

  function onDeleteIds() {
    if (activeLoading) activeLoading(true);
    TesPost(CollisionApiUrl() + "/api/Collisions/DeleteCollisions", ids2Delete, true);
    if (activeLoading) activeLoading(false);
  }

  //#endregion functions
  return (
    <Permission
      allowed={[
        CollisionPermissions.Collision_V_MyCollision,
        CollisionPermissions.Collision_D,
        CollisionPermissions.Collision_Approve,
      ]}
      hasFeedBackElement={true}
    >
      <div className={compactViewModel ? "compactStyle" : ""}>
        <React.Fragment>
          <ContextMenu
            style={{ backgroundColor: "red !important" }}
            dataSource={items}
            width={100}
            target=".dx-data-row"
            onItemClick={e => RightClickMenu(e, selectedRowData.row.data._id, "/collision/collisionDetails/", selectedRowData.row.data.LocationType, selectedRowData.row.data.LocationId)}
          />
          <h2 className={"content-block"}>{t("drafts")}</h2>
          <div className={"content-block"}>
            <div className={"dx-card responsive-paddings"}>
              <div className="row">
                <div className="leftColumn" style={{ display: "flex" }}>
                  <Permission
                    allowed={[CollisionPermissions.Collision_Approve]}
                    hasFeedBackElement={false}
                  >
                    <Button
                      onClick={() => setShowMap(true)}
                      icon="fa-solid fa-map-location-dot"
                      style={{ marginRight: 10 }}
                      hint={t("showMap")}
                    />
                    <Button
                      icon="fa-solid fa-check-double"
                      hint={t("confirmation")}
                      onClick={onOpenConfirmation}
                    />
                  </Permission>

                  <Permission allowed={[CollisionPermissions.Collision_GeoCode]}
                    hasFeedBackElement={false}>
                    <Button
                      style={{ marginLeft: 10 }}
                      onClick={() => setShowGeoCodeStatusUpdater(true)}
                      icon="fa-solid fa-location"
                      hint={t("geoCodeStatusUpdater")}
                    />
                  </Permission>
                  <Permission allowed={[TMSPermissions.TMS_Developer]}
                    hasFeedBackElement={false}>
                    <Button
                      style={{ marginLeft: 10 }}
                      onClick={() => onOpenDeleteWarning()}
                      icon="fa-solid fa-trash"
                      hint={t("delete")}
                    />
                  </Permission>
                  <GridProfilePicker
                    customerId={localStorage.getItem("selectedCustomerId")!}
                    gridType={GridType.CollisionDraft}
                    dataGridRef={dataGridRef}
                    setGridSetting={setGridSetting}
                  />
                  <DropDownButton
                    style={{ marginLeft: 10 }}
                    icon="fa-solid fa-chart-line"
                    hint={t('report')}
                    items={SortObjectByPropName(initDataReports?.filter((x: ClientReport) => [reportLocations.Collision_Collisions].some(a => x.reportLocations?.map(x => x.name).indexOf(a) >= 0)), "name")}
                    dropDownOptions={{ width: MaxDropdownItemWidthCalculator(SortObjectByPropName(initDataReports?.filter((x: ClientReport) => [reportLocations.Collision_Collisions].some(a => x.reportLocations?.map(x => x.name).indexOf(a) >= 0)), "name")) }}
                    displayExpr="name"
                    onItemClick={(d: any) => onOpenReport(d)}
                  />

                  <ExportCollision
                    dataGridRef={dataGridRef}
                  />

                </div>
                <div className="rightColumn">
                  <Permission
                    allowed={[CollisionPermissions.Collision_Approve]}
                    hasFeedBackElement={false}
                  >
                    <Button
                      onClick={navigateToCollisionDetails}
                      icon="fa-solid fa-circle-plus"
                      text={t("collision")}
                    />
                  </Permission>
                </div>
              </div>
              {initDataFields && initDataFields.length > 0 && (
                <DataGrid
                  id="gridContainer"
                  ref={dataGridRef}
                  dataSource={dataSource}
                  rowAlternationEnabled={true}
                  showBorders={true}
                  onRowClick={onRowClicked}
                  hoverStateEnabled={true}
                  remoteOperations={true}
                  allowColumnReordering={true}
                  allowColumnResizing={true}
                  style={{ margin: "0 1rem" }}
                  onExporting={OnExporting}
                  onContextMenuPreparing={e => { setSelectedRowData(e) }}
                  columnAutoWidth={gridSetting.horizontalScrolling}
                //onContentReady={e => {e.component.deselectAll()}}
                //onFilterValueChange={e => {dataGridRef?.current?.instance().deselectAll()}}
                >
                  <Export enabled={true} allowExportSelectedData={true} />
                  <Grouping contextMenuEnabled={true} autoExpandAll={false} />
                  <GroupPanel visible={true} /> {/* or "auto" */}
                  <FilterPanel visible={true} />
                  <FilterBuilderPopup position={"top"} />
                  <StateStoring
                    enabled={true}
                    type="localStorage"
                    storageKey={GridType.CollisionDraft.toString()}
                    savingTimeout={500}
                  />

                  <SortByGroupSummaryInfo
                    summaryItem="Total Count"
                    sortOrder="desc"
                  />
                  <Summary>
                    <GroupItem
                      summaryType="count"
                      alignByColumn
                      name="Total Count"
                    />
                  </Summary>
                  <Paging enabled={true} defaultPageSize={100} />
                  <Pager
                    showPageSizeSelector={true}
                    allowedPageSizes={[100, 200, 300, 400, 500]}
                    showNavigationButtons={true}
                    showInfo={true}
                    visible={true}
                  />
                  <Selection
                    mode="multiple"
                    selectAllMode={'allPages'}
                    showCheckBoxesMode={'always'}
                  />
                  <FilterRow visible={true} applyFilter="auto" />
                  <HeaderFilter visible={true} />
                  <SearchPanel
                    visible={true}
                    width={285}
                    placeholder={t("search...")}
                  />
                  <ColumnChooser width={350} height={400} enabled={true} mode="select" sortOrder="asc">
                    <Search enabled />
                  </ColumnChooser>
                  <Column dataField="Id" caption={t("id")} visible={false}>
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    dataField="ApprovalStatus"
                    caption={t("status")}
                    width={150}
                    cellRender={calculateApprovalLevel}
                  >
                    <Lookup
                      dataSource={SortObjectByPropName(lstApprovalLevel, "name")}
                      valueExpr="value"
                      displayExpr="name"
                    />

                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  {/* <Column
                   // dataField="ValidationStatus"
                    caption={t("validationStatus")}
                    width={180}
                    cellRender={calculateValidationStatus}
                  >
                    <Lookup
                      dataSource={SortObjectByPropName(lstValidationStatus, "name")}
                      valueExpr="value"
                      displayExpr="name"
                    />

                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column> */}
                  <Column dataField="GeoId" caption={t("geoId")}
                    allowSorting={true}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    dataField="LocationDescription"
                    allowSorting={true}
                    width={300}
                    caption={t("locationDescription")}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    dataField="MapLocation.Latitude"
                    allowSorting={true}
                    allowHeaderFiltering={false}
                    caption={t("latitude")}
                    format={{ type: 'fixedPoint', precision: 4 }}
                    visible={false}
                  >
                    <Search enabled />
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    dataField="MapLocation.Longitude"
                    allowSorting={true}
                    allowHeaderFiltering={false}
                    caption={t("longitude")}
                    format={{ type: 'fixedPoint', precision: 4 }}
                    visible={false}
                  >
                    <Search enabled />
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column dataField="GeoCodeStatus" caption={t("geoCodeStatus")} visible={false}>
                    <Lookup
                      dataSource={SortObjectByPropName(lstGeoCodeStatus, "name")}
                      valueExpr="value"
                      displayExpr="name"
                    />
                  </Column>
                  <Column dataField="EditedSubmissionDT" caption={t("lastEditDT")} visible={false}
                    dataType="datetime"
                    format={generalSetting?.dateFormat}
                  >
                  </Column>

                  <Column
                    dataField="SubmissionDT"
                    caption={t('submissionDT')}
                    dataType="datetime"
                    visible={false}
                    format={generalSetting?.dateTimeFormat}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>

                  <Column dataField="DataSourceType" caption={t("dataSourceType")} visible={false}>
                    <Lookup
                      dataSource={SortObjectByPropName(lstCollisionDataSourceType, "name")}
                      valueExpr="value"
                      displayExpr="name"
                    />
                  </Column>
                  <Column dataField="ApproveLevel" caption={t("confirmationLevel")} visible={false}>
                    <Lookup
                      dataSource={SortObjectByPropName(initDataConfirmationLevels, "name")}
                      valueExpr="level"
                      displayExpr="name"
                    />
                  </Column>
                  {initDataFields.map((d) => {
                    if (d.fieldType === FieldType.List) {
                      return (
                        <Column
                          dataField={d.name}
                          caption={d.labelText}
                          allowFiltering={true}
                          visible={(EnumFlag2Array(FieldLocation2Show, d.fieldLocation2Show).some(x => x === FieldLocation2Show.GridView)) ? true : false}
                        >
                          <Search enabled />
                          <Lookup
                            dataSource={SortObjectByPropName(d.fieldValues, "name")}
                            valueExpr="id"
                            displayExpr="name"
                          />
                          <HeaderFilter>
                            <Search enabled />
                          </HeaderFilter>
                        </Column>
                      );
                    }
                    else if (d.fieldType === FieldType.String) {
                      return (
                        <Column
                          key={d.name}
                          dataField={d.name}
                          caption={d.labelText}
                          dataType="string"
                          alignment="left"
                          visible={(EnumFlag2Array(FieldLocation2Show, d.fieldLocation2Show).some(x => x === FieldLocation2Show.GridView)) ? true : false}                      >
                          <HeaderFilter>
                            <Search enabled />
                          </HeaderFilter>
                        </Column>
                      );
                    }
                    else if (d.fieldType === FieldType.Number) {
                      return (
                        <Column
                          dataField={d.name}
                          caption={d.labelText}
                          dataType="number"
                          alignment="left"
                          visible={(EnumFlag2Array(FieldLocation2Show, d.fieldLocation2Show).some(x => x === FieldLocation2Show.GridView)) ? true : false}
                        >
                          <HeaderFilter>
                            <Search enabled />
                          </HeaderFilter>
                        </Column>
                      );
                    } else if (d.fieldType === FieldType.Date) {
                      return (
                        <Column
                          dataField={d.name}
                          caption={d.labelText}
                          dataType="date"
                          calculateCellValue={(e: any) => GridActualDateCalculator(e, d, generalSetting)}
                          format={generalSetting?.dateFormat}
                          visible={(EnumFlag2Array(FieldLocation2Show, d.fieldLocation2Show).some(x => x === FieldLocation2Show.GridView)) ? true : false}
                        >
                          <HeaderFilter>
                            <Search enabled />
                          </HeaderFilter>
                        </Column>
                      );
                    } else if (d.fieldType === FieldType.Time) {
                      return (
                        <Column
                          dataField={d.name}
                          caption={d.labelText}
                          dataType="datetime"
                          calculateCellValue={(e: any) => GridActualDateCalculator(e, d, generalSetting)}
                          format={generalSetting?.timeFormat}
                          visible={(EnumFlag2Array(FieldLocation2Show, d.fieldLocation2Show).some(x => x === FieldLocation2Show.GridView)) ? true : false}
                        >
                          <HeaderFilter>
                            <Search enabled />
                          </HeaderFilter>
                        </Column>
                      );
                    } else if (d.fieldType === FieldType.DateTime) {
                      return (
                        <Column
                          dataField={d.name}
                          caption={d.labelText}
                          dataType="datetime"
                          calculateCellValue={(e: any) => GridActualDateCalculator(e, d, generalSetting)}
                          format={generalSetting?.dateTimeFormat}
                          visible={(EnumFlag2Array(FieldLocation2Show, d.fieldLocation2Show).some(x => x === FieldLocation2Show.GridView)) ? true : false}
                        >
                          <HeaderFilter>
                            <Search enabled />
                          </HeaderFilter>
                        </Column>
                      );
                    } else if (d.fieldType === FieldType.Boolean) {
                      return (
                        <Column
                          dataField={d.name}
                          caption={d.labelText}
                          dataType="boolean"
                          visible={(EnumFlag2Array(FieldLocation2Show, d.fieldLocation2Show).some(x => x === FieldLocation2Show.GridView)) ? true : false}
                        >
                          <HeaderFilter>
                            <Search enabled />
                          </HeaderFilter>
                        </Column>
                      );
                    } else {
                      return (
                        <Column
                          dataField={d.name}
                          caption={d.labelText}
                          allowHeaderFiltering={false}
                          visible={(EnumFlag2Array(FieldLocation2Show, d.fieldLocation2Show).some(x => x === FieldLocation2Show.GridView)) ? true : false}
                        >
                          <Search enabled />
                          <HeaderFilter>
                            <Search enabled />
                          </HeaderFilter>

                        </Column>
                      );
                    }
                  })}
                  <Column
                    dataField="DivisionId"
                    allowSorting={true}
                    visible={false}
                    caption={t("division")}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                    <Lookup
                      dataSource={SortObjectByPropName(customerDivisions!, "name")}
                      valueExpr="id"
                      displayExpr="name"
                    />
                  </Column>
                </DataGrid>
              )}
            </div>
          </div>


          {/* Map Popup */}
          <Popup
            width={"50%"}
            height={"auto"}
            // maxHeight={"90%"}
            visible={showMap}
            resizeEnabled={true}
            showTitle={true}
            title={t("map")}
            hideOnOutsideClick={false}
            showCloseButton={true}
            onHiding={() => setShowMap(false)}
          >
            <GeneralGisMapManager
              tesModule={TesMapModule.Collision}
              dataGridRef={dataGridRef}
              showGis={showMap}
              setShowMap={setShowMap}
              locId="LocationId"
            />
          </Popup>

          {/* Confirmation Popup */}
          <Popup
            width={"40%"}
            height={"auto"}
            visible={showConfirmation}
            resizeEnabled={true}
            showTitle={true}
            title={t("confirmation")}
            hideOnOutsideClick={false}
            showCloseButton={true}
            onHiding={onCloseConfirmationModal}
          >
            <ScrollView width="100%" height="100%">
              {TMSPermissions.TMS_Admin ?
                <div>
                  <Form colCount={2}>
                    <SimpleItem colSpan={1}>
                      <SelectBox
                        placeholder=""
                        label={t("confirmationLevel")}
                        labelMode="static"
                        onValueChange={(e) => onChangeAppLevel(e)}
                        items={initDataConfirmationLevels}
                        valueExpr="level"
                        displayExpr="name"
                        showClearButton={true}
                        searchEnabled={true}
                      />
                    </SimpleItem>
                  </Form>
                  <div className="rightColumn">
                    <Button
                      className="tes-modal-btn-cancel"
                      style={{ marginRight: 20 }}
                      onClick={onCloseConfirmationModal}
                      text={t("cancel")}
                    />

                    <Button
                      className="tes-modal-btn-add"
                      onClick={() => onConfirmation(CollisionStatus.Approved, appLevel)}
                      text={t("confirm")}
                    />
                  </div>
                </div>
                :
                <div>
                  <Form colCount={2}>
                    <SimpleItem colSpan={2}>
                      <p className="title">{t("areYouSureYouWanToConfirmRejectTheSelectedCollisionsThisActionCanNotBeUndone")}</p>
                    </SimpleItem>
                  </Form>
                  <div>
                    <div className="rightColumn">
                      <Button
                        className="tes-modal-btn-cancel"
                        style={{ marginRight: 20 }}
                        onClick={() => onConfirmation(CollisionStatus.Rejected, 0)}
                        text={t("reject")}
                      />

                      <Button
                        className="tes-modal-btn-add"
                        onClick={() => onConfirmation(CollisionStatus.Approved, 0)}
                        text={t("confirm")}
                      />
                    </div>
                  </div>
                </div>
              }

            </ScrollView>
          </Popup>

          {/* Report Popup */}
          <Popup
            visible={showReportModal}
            width={"60%"}
            height={reportPopupHeight}
            resizeEnabled={true}
            showTitle={true}
            title={t("reportViewer")}
            hideOnOutsideClick={false}
            showCloseButton={true}
            onHiding={() => onCloseReportModal()}
          >
            <ReportViewer
              selectedReport={selectedReport}
              lstSelectedIds={lstSelectedIds}
              isOpen={showReportModal}
            />
          </Popup>


          {/* Geo code status updater */}
          <Popup
            visible={showGeoCodeStatusUpdater}
            width={"30%"}
            height={"60%"}
            resizeEnabled={true}
            showTitle={true}
            title={t("geoCodeStatusUpdater")}
            hideOnOutsideClick={false}
            showCloseButton={true}
            onHiding={() => onCloseGeoCodeStatus()}
          >
            <Form>
              <SimpleItem>
                <SelectBox
                  placeholder=""
                  label={t("geoCodeStatus")}
                  labelMode="static"
                  onValueChange={(e) => onChangeGeoCodeStatus(e)}
                  items={lstGeoCodeStatus}
                  displayExpr="name"
                  valueExpr="value"
                  showClearButton={true}
                  searchEnabled={true}
                />
              </SimpleItem>
            </Form>
            <div>
              <div className="rightColumn" style={{ marginTop: 30 }}>
                <Button
                  className="tes-modal-btn-add"
                  onClick={() => UpdateGeoCodeStatus()}
                  text={t("update")}
                />
              </div>
            </div>
          </Popup>

          {/* validation report */}
          <ValidationReport
            collisionIds={lstSelectedIds}
            showValidationReport={showValidationReport}
            setShowValidationReport={setShowValidationReport}
          />

          <Popup
            visible={showDeleteWarning}
            width={"30%"}
            height={"60%"}
            resizeEnabled={true}
            showTitle={true}
            title={t("deleteWarning")}
            hideOnOutsideClick={false}
            showCloseButton={true}
            onHiding={() => onCloseDeleteWarning()}
          >
            <div>
              <Form colCount={2}>
                <SimpleItem colSpan={2}>
                  <p className="title">{t("deleteWarningMessage")}</p>
                </SimpleItem>
              </Form>
              <div>
                <div className="rightColumn">
                  <Button
                    className="tes-modal-btn-cancel"
                    style={{ marginRight: 20 }}
                    onClick={() => onCloseDeleteWarning()}
                    text={t("cancel")}
                  />

                  <Button
                    className="tes-modal-btn-add"
                    onClick={() => onDeleteIds()}
                    text={t("confirm")}
                  />
                </div>
              </div>
            </div>
          </Popup>


        </React.Fragment>
      </div>
    </Permission>
  );
};
export default Drafts;
