//imports
import DataGrid, {
  Column,
  ColumnChooser,
  DataGridRef,
  Button as DevExpressButton,
  Export,
  FilterBuilderPopup,
  FilterPanel,
  FilterRow,
  Grouping,
  GroupItem,
  GroupPanel,
  HeaderFilter,
  Lookup,
  Pager,
  Paging,
  Search,
  SearchPanel,
  SortByGroupSummaryInfo,
  StateStoring,
  Summary
} from "devextreme-react/data-grid";
import React, { useEffect, useRef, useState } from "react";

import { NumberBox, SelectBox, TagBox, TextArea } from "devextreme-react";
import { Button } from "devextreme-react/button";
import { Form, SimpleItem } from "devextreme-react/form";
import { Popup } from "devextreme-react/popup";
import ScrollView from "devextreme-react/scroll-view";
import { Switch } from "devextreme-react/switch";
import TextBox from "devextreme-react/text-box";
import { custom } from "devextreme/ui/dialog";
import notify from "devextreme/ui/notify";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../../contexts/auth";
import {
  FieldCategoryType,
  FieldLocation2Show,
  FieldServiceType,
  FieldType,
} from "../../../../types/field/enums/fieldEnums";
import { CodeGroup, CodeValue, SimplifiedField, TesField } from "../../../../types/field/fieldType";
import {
  GridType,
  ModalMode,
} from "../../../../types/general/enums/generalEnums";
import { TesDelete, TesGet, TesPost, TesPut } from "../../../../utils/rest";
import "./fields.scss";

import Permission from "../../../../components/permission/permision";
import Rule from "../../../../components/rule/Rule";
import getTitle from "../../../../components/title/getTitle";
import { CollisionPermissions } from "../../../../constants/Permissions";
import { FieldApiUrl } from "../../../../environment/routeSettings";
import { VMField } from "../../../../types/field/dto/fieldDTO";
import { NameValue } from "../../../../types/general/generalTypes";
import { SortObjectByPropName, SumIntArray } from "../../../../utils/arrayTools";
import { OnExporting } from "../../../../utils/dataGridTools";
import { Enum2Array, EnumFlag2Array } from "../../../../utils/enumTools";
import { useScreenSize } from "../../../../utils/media-query";
import { Rule2View } from "../../../../utils/simplifiedFieldTools";

const CollisionFields = () => {
  const [initData, setInitData] = useState<TesField[]>([]);
  const [initDataCodeGroups, setInitDataCodeGroups] = useState<CodeGroup[]>([]);
  const dataGridRef = useRef<DataGridRef<any, any>>(null);
  const dataGridListRef = useRef<DataGridRef<any, any>>(null);
  const [showModal, setShowModal] = useState(false);
  const [modalMode, setModalMode] = useState<ModalMode>(ModalMode.Add);
  const [showListModal, setShowListModal] = useState<boolean>(false);
  const [selectedData, setSelectedData] = useState<TesField>(new TesField());
  const [selectedDataSecondModal, setSelectedDataSecondModal] = useState<SimplifiedField[]>([]);
  const [secondModalMode, setSecondModalMode] = useState<ModalMode>(ModalMode.Add);
  const [showAddUpdateModal, setShowAddUpdateModal] = useState(false);
  const [codeValues, setLstCodeValues] = useState<CodeValue[]>([]);
  const [lstFiledType, setLstFiledType] = useState<NameValue[]>([]);
  const [lstFieldLocation, setLstFieldLocation] = useState<NameValue[]>([]);
  const [lstFiledCategoryType, setLstFiledCategoryType] = useState<NameValue[]>([]);
  const [addObj, setAddObj] = useState<SimplifiedField>(new SimplifiedField());
  const [updateObj, setUpdateObj] = useState<SimplifiedField>(new SimplifiedField());
  const { t } = useTranslation();
  const { activeLoading } = useAuth();
  const { isXLarge, is2xLarge } = useScreenSize();
  const [formHeight, setFormHeight] = useState<string>("350px")
  const [codeValuePopHeight, setCodeValuePopHeight] = useState<number | string>(600)
  const [codeValueGridHeight, setGridValueHeight] = useState<number>(400)
  const compactViewModel: boolean = (JSON.parse(localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}') || {}).viewMode === "compact";

  const title = getTitle('/collision/settings/fields', '');
  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(() => {
    if (isXLarge) { setFormHeight("610px"); setCodeValuePopHeight("auto"); setGridValueHeight(520) }
    else if (is2xLarge) { setFormHeight("700px"); setCodeValuePopHeight("auto"); setGridValueHeight(900) }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        setLstFiledType(Enum2Array(FieldType));
        setLstFieldLocation(Enum2Array(FieldLocation2Show));
        setLstFiledCategoryType(Enum2Array(FieldCategoryType));
        await getInitData();
        await getInitDataCodeGroups();
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Get InitData
  async function getInitData() {
    setInitData(
      await TesGet(
        FieldApiUrl() +
        "/api/TesFields/GetAllFields/" +
        localStorage.getItem("selectedCustomerId") +
        "/" +
        FieldServiceType.Collision,
        true
      )
    );
  }

  async function getInitDataCodeGroups() {
    setInitDataCodeGroups(
      await TesGet(
        FieldApiUrl() +
        "/api/codeGroups/GetCodeGroups/" +
        localStorage.getItem("selectedCustomerId") +
        "/" +
        FieldServiceType.Collision,
        true
      )
    );
    const obj = {} as CodeGroup;
    initDataCodeGroups.push(obj);
  }

  function onEdit(d: any) {
    let newData = new TesField();
    Object.assign(newData, d.row.data);
    setModalMode(ModalMode.Edit);
    newData.fieldServiceType = FieldServiceType.Collision;
    setSelectedData(newData);
    setShowModal(true);
  }

  function onNew() {
    setModalMode(ModalMode.Add);
    setShowModal(true);
  }

  const onValueChanged = (key: string, value: any) => {
    setSelectedData({ ...selectedData, [key]: value });
  };


  function onCloseModal() {
    setSelectedData(new TesField());
    setShowModal(false);

  }

  async function onAdd() {
    try {
      if (activeLoading) activeLoading(true);
      selectedData.customerId =
        localStorage.getItem("selectedCustomerId") ?? "";
      selectedData.fieldServiceType = FieldServiceType.Collision;
      await TesPost(FieldApiUrl() + "/api/TesFields", selectedData, true);
      await getInitData();
      onCloseModal();
      if (activeLoading) activeLoading(false);
      notify(t("dataSuccessfullyAdded"), "success", 5000);
    } catch (ex) {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred") + ex, "error", 5000);
    }
  }

  async function onUpdate() {
    try {
      if (activeLoading) activeLoading(true);
      await TesPut(
        FieldApiUrl() + "/api/TesFields/" + selectedData.id,
        selectedData,
        true
      );
      await getInitData();
      onCloseModal();
      notify(t("dataSuccessfullyUpdated"), "success", 5000);
      if (activeLoading) activeLoading(false);
    } catch (ex) {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred" + ex), "error", 5000);
    }
  }




  async function getCodeValues() {
    try {
      if (activeLoading) activeLoading(true);
      setLstCodeValues(await TesGet(
        FieldApiUrl() + "/api/CodeValues/" + selectedData.codeGroupId, true));
      if (activeLoading) activeLoading(false);
    } catch (ex) {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred" + ex), "error", 5000);
    }
  }


  async function onDelete(d: any) {
    let myDialog = custom({
      title: t("deleteData"),
      messageHtml: t("areYouSureWantToDelete?"),
      buttons: [
        {
          text: t("yes"),
          onClick: async (e) => {
            try {
              await TesDelete(
                FieldApiUrl() + "/api/TesFields/" + d.row.data.id,
                true
              );
              await getInitData();
              notify(t("dataSuccessfullyDeleted"), "success", 5000);
            } catch {
              notify(t("someErrorOccurred"), "error", 5000);
            }

            return { buttonText: e.component.option("text") };
          },
        },
        {
          text: t("no"),
          onClick: (e) => {
            return { buttonText: e.component.option("text") };
          },
        },
      ],
    });
    myDialog.show();
  }


  async function onOpenListModal() {
    setShowListModal(true)
    await getCodeValues();
  }

  function onSecondModalEdit(e: any) {
    setUpdateObj(e.row.data)
    setSecondModalMode(ModalMode.Edit)
    setShowAddUpdateModal(true)
  }

  function onSecondModalUpdate() {
    var index = codeValues.findIndex(x => x.id === updateObj.id);
    codeValues[index].rule = updateObj.rule;
    setUpdateObj(new SimplifiedField())
    setShowAddUpdateModal(false)
  }


  const onValueChangedAddObj = (name: string, value: any) => {
    secondModalMode === ModalMode.Add ? setAddObj({ ...addObj, rule: value }) : setUpdateObj({ ...updateObj, rule: value })
  }

  function convertTesField2VMField(tesField: TesField) {
    var vmField: VMField = {
      customerId: tesField.customerId,
      description: tesField.description,
      exactDT: tesField.exactDT,
      fieldCategoryType: tesField.fieldCategoryType,
      fieldType: tesField.fieldType,
      fieldValues: tesField.fieldValues,
      id: tesField.id,
      integrationAddress: tesField.integrationAddress,
      isRequired: tesField.isRequired,
      labelText: tesField.labelText,
      isUpperCase: tesField.isUpperCase,
      name: tesField.name,
      validationRule: tesField.validationRule,
      readOnlyField: tesField.readOnlyField,
      geocodingIndex: tesField.geocodingIndex,
      isShowInDriver: tesField.isShowInDriver,
      isShowInPassenger: tesField.isShowInPassenger,
      isShowInPedestrian: tesField.isShowInPedestrian,
    }
    return vmField;
  }

  function onAddSecondModal() {
    setAddObj({
      ...addObj,
      customerId: localStorage.getItem("selectedCustomerId")!,
      simplifiedTesField: selectedData?.simplifiedTesField,
      fieldType: selectedData?.fieldType,
      simplifiedFieldCategory: selectedData?.simplifiedFieldCategory
    })
    setSelectedDataSecondModal([...selectedDataSecondModal, addObj])
    setAddObj(new SimplifiedField())
    setShowAddUpdateModal(false)
  }


  async function onSaveRule() {
    try {
      if (activeLoading) activeLoading(true);
      await TesPost(
        FieldApiUrl() + "/api/CodeValues/UpdateRules",
        codeValues,
        true
      );
      await getInitData();
      setShowListModal(false);
      notify(t("dataSuccessfullyUpdated"), "success", 5000);
      if (activeLoading) activeLoading(false);
    } catch (ex) {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred" + ex), "error", 5000);
    }
  }

  return (
    <Permission
      allowed={[CollisionPermissions.Collision_D_Field]}
      hasFeedBackElement={true}
    >
      <div className={compactViewModel ? "compactStyle" : ""}>
        <React.Fragment>
          <h2 className={"content-block"}>{t("fields")}</h2>
          <div className={"content-block"}>
            <div className={"dx-card responsive-paddings"}>
              <div className="row">
                <div className="rightColumn">
                  <Button onClick={onNew} icon="fa-solid fa-circle-plus" text={t("add")} />
                </div>
              </div>
              <DataGrid
                id="gridContainer"
                ref={dataGridRef}
                dataSource={initData}
                rowAlternationEnabled={true}
                showBorders={true}
                hoverStateEnabled={true}
                remoteOperations={true}
                allowColumnReordering={true}
                allowColumnResizing={false}
                columnAutoWidth={true}
                style={{ margin: "0 1rem" }}
                onExporting={OnExporting}
              >
                <Export enabled={true} allowExportSelectedData={true} />
                <Grouping contextMenuEnabled={true} autoExpandAll={false} />
                <GroupPanel visible={true} /> {/* or "auto" */}
                <FilterPanel visible={true} />
                <FilterBuilderPopup position={"top"} />
                <StateStoring
                  enabled={true}
                  type="localStorage"
                  storageKey={GridType.CollisionField.toString()}
                  savingTimeout={500}
                />
                <Paging enabled={true} defaultPageSize={100} />
                <Pager
                  showPageSizeSelector={true}
                  allowedPageSizes={[100, 200, 300, 400, 500]}
                  showNavigationButtons={true}
                  showInfo={true}
                  visible={true}
                />
                <FilterRow visible={true} applyFilter="auto" />
                <HeaderFilter visible={true} />
                <SearchPanel
                  visible={true}
                  width={285}
                  placeholder={t("search")}
                />
                <ColumnChooser width={350} height={400} enabled={true} mode="select" sortOrder="asc">
                  <Search enabled />
                </ColumnChooser>
                <SortByGroupSummaryInfo
                  summaryItem="Total Count"
                  sortOrder="desc"
                />
                <Summary>
                  <GroupItem
                    summaryType="count"
                    alignByColumn
                    name="Total Count"
                  />
                </Summary>
                <Column dataField="name" caption={t("name")}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="fieldCategoryType"
                  alignment="left"
                  caption={t("categoryType")}
                >
                  <Lookup
                    dataSource={SortObjectByPropName(lstFiledCategoryType, "name")}
                    valueExpr="value"
                    displayExpr="name"
                  />
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="fieldType"
                  alignment="left"
                  caption={t("fieldType")}
                >
                  <Lookup
                    dataSource={SortObjectByPropName(lstFiledType, "name")}
                    valueExpr="value"
                    displayExpr="name"
                  />
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column dataField="labelText" caption={t("labelText")}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="Description"
                  allowSorting={false}
                  caption={t("description")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="detailViewIndex"
                  allowSorting={true}
                  caption={t("detailViewIndex")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="gridViewIndex"
                  allowSorting={true}
                  caption={t("gridViewIndex")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="mobileAppIndex"
                  allowSorting={true}
                  caption={t("mobileAppIndex")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="geocodingIndex"
                  allowSorting={true}
                  caption={t("geocodingIndex")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column dataField="fieldLocation2Show" caption={t("fieldLocation2Show")}
                  calculateDisplayValue={function (rowData: any) {
                    var values = EnumFlag2Array(FieldLocation2Show, rowData.fieldLocation2Show);
                    var res = [];
                    if (values !== undefined) {
                      for (let i = 0; i < values.length; i++) {
                        res.push(FieldLocation2Show[values[i]]);
                      }
                    }
                    return res.join(", ");
                  }}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="isCalculatedField"
                  caption={t("isCalculatedField")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="isRequiredInApp"
                  caption={t("isRequiredInApp")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="isRequiredInWeb"
                  caption={t("isRequiredInWeb")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>

                <Column
                  dataField="readOnlyField"
                  caption={t("readOnlyField")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column dataField="isUpperCase" caption={t("isUpperCase")}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="integrationAddress"
                  caption={t("integrationAddress")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column dataField="validationRule" caption={t("validationRule")}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column dataField="codeGroupId" caption={t("codeGroup")}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                  <Lookup displayExpr="name" valueExpr="id" dataSource={SortObjectByPropName(initDataCodeGroups, "name")} />
                </Column>
                <Column
                  dataField="isShowInDriver"
                  visible={false}
                  caption={t("isShowInDriver")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="isShowInPassenger"
                  visible={false}
                  caption={t("isShowInPassenger")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="isShowInPedestrian"
                  visible={false}
                  caption={t("isShowInPedestrian")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>

                <Column
                  dataField="exactDT"
                  visible={false}
                  caption={t("exactDT")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  type="buttons"
                  caption={t("actions")}
                  width={110}
                  fixed={true}
                  fixedPosition="right"
                >
                  <DevExpressButton
                    hint={t("edit")}
                    onClick={onEdit}
                    icon="fa-solid fa-pencil"
                  ></DevExpressButton>
                  <DevExpressButton
                    hint={t("delete")}
                    onClick={onDelete}
                    icon="fa-solid fa-trash-can"
                  ></DevExpressButton>
                </Column>
              </DataGrid>
            </div>
          </div>


          {/* Add, Update Field */}
          <Popup
            width={"50%"}
            height={"auto"}
            visible={showModal}
            resizeEnabled={true}
            showTitle={true}
            title={modalMode === ModalMode.Add ? t("add") : t("update")}
            hideOnOutsideClick={false}
            showCloseButton={true}
            onHiding={() => onCloseModal()}
          >
            <div style={{ height: formHeight }}>
              <ScrollView width="100%" height="100%">
                <Form colCount={6} className="popupFields">
                  <SimpleItem colSpan={3}>
                    <SelectBox
                      placeholder=""
                      label={t("categoryType")}
                      labelMode="static"
                      value={selectedData.fieldCategoryType}
                      onValueChange={(e) => onValueChanged("fieldCategoryType", e)}
                      className="modalInput"
                      valueExpr="value"
                      displayExpr="name"
                      items={SortObjectByPropName(lstFiledCategoryType.filter(x => x.name.includes("Collision")), "name")}
                      showClearButton={true}
                      searchEnabled={true}
                    />
                  </SimpleItem>

                  <SimpleItem colSpan={3}>
                    <SelectBox
                      placeholder=""
                      label={t("fieldType")}
                      labelMode="static"
                      value={selectedData.fieldType}
                      onValueChange={(e) => onValueChanged("fieldType", e)}
                      className="modalInput"
                      valueExpr="value"
                      displayExpr="name"
                      items={SortObjectByPropName(lstFiledType, "name")}
                      showClearButton={true}
                      searchEnabled={true}
                    />
                  </SimpleItem>

                  {(selectedData.fieldType?.toString() ===
                    FieldType.DateTime.toString() || selectedData.fieldType?.toString() ===
                    FieldType.Date.toString() || selectedData.fieldType?.toString() ===
                    FieldType.Time.toString()) && (
                      <SimpleItem colSpan={1}>
                        <div>{t("exactDT")}</div>
                        <Switch
                          style={{ marginTop: 5 }}
                          hint={t("exactDT")}
                          value={selectedData.exactDT}
                          onValueChange={(e) => onValueChanged("exactDT", e)}
                        />
                      </SimpleItem>
                    )}

                  <SimpleItem colSpan={3}>
                    <TextBox
                      label={t("name")}
                      labelMode="static"
                      value={selectedData.name}
                      onValueChange={(e) => onValueChanged("name", e)}
                      className="modalInput"
                    />
                  </SimpleItem>

                  <SimpleItem colSpan={3}>
                    <TextBox
                      label={t("labelText")}
                      labelMode="static"
                      value={selectedData.labelText}
                      onValueChange={(e) => onValueChanged("labelText", e)}
                      className="modalInput"
                    />
                  </SimpleItem>

                  <SimpleItem colSpan={3}>
                    <TextBox
                      label={t("description")}
                      labelMode="static"
                      value={selectedData.description}
                      onValueChange={(e) => onValueChanged("description", e)}
                      className="modalInput"
                    />
                  </SimpleItem>

                  <SimpleItem colSpan={3}>
                    <TextBox
                      label={t("integrationAddress")}
                      labelMode="static"
                      value={selectedData.integrationAddress}
                      onValueChange={(e) => onValueChanged("integrationAddress", e)}
                      className="modalInput"
                    />
                  </SimpleItem>

                  {selectedData.fieldType?.toString() ===
                    FieldType.List.toString() && (
                      <SimpleItem colSpan={3}>
                        <SelectBox
                          placeholder=""
                          label={t("codeGroup")}
                          labelMode="static"
                          value={selectedData.codeGroupId}
                          onValueChange={(e) => onValueChanged("codeGroupId", e)}
                          className="modalInput"
                          valueExpr="id"
                          displayExpr="name"
                          items={SortObjectByPropName(initDataCodeGroups, "name")}
                          showClearButton={true}
                          searchEnabled={true}
                        />
                      </SimpleItem>
                    )}


                  <SimpleItem colSpan={3}>
                    <NumberBox
                      label={t("detailViewIndex")}
                      labelMode="static"
                      value={selectedData.detailViewIndex}
                      onValueChange={(e) => onValueChanged("detailViewIndex", e)}
                      className="modalInput"
                    />
                  </SimpleItem>

                  <SimpleItem colSpan={3}>
                    <NumberBox
                      label={t("gridViewIndex")}
                      labelMode="static"
                      value={selectedData.gridViewIndex}
                      onValueChange={(e) => onValueChanged("gridViewIndex", e)}
                      className="modalInput"
                    />
                  </SimpleItem>

                  <SimpleItem colSpan={3}>
                    <NumberBox
                      label={t("mobileAppIndex")}
                      labelMode="static"
                      value={selectedData.mobileAppIndex}
                      onValueChange={(e) => onValueChanged("mobileAppIndex", e)}
                      className="modalInput"
                    />
                  </SimpleItem>
                  <SimpleItem colSpan={3}>
                    <NumberBox
                      label={t("geocodingIndex")}
                      labelMode="static"
                      value={selectedData.geocodingIndex}
                      onValueChange={(e) => onValueChanged("geocodingIndex", e)}
                      className="modalInput"
                    />
                  </SimpleItem>
                  <SimpleItem colSpan={3}>
                    <TagBox
                      label={t("fieldLocation2Show")}
                      labelMode="static"
                      className="modalInput"
                      dataSource={SortObjectByPropName(lstFieldLocation, "name")}
                      valueExpr="value"
                      displayExpr="name"
                      value={EnumFlag2Array(FieldLocation2Show, selectedData.fieldLocation2Show)}
                      onValueChange={(e) => onValueChanged("fieldLocation2Show", SumIntArray(e))}
                    />
                  </SimpleItem>

                  <SimpleItem colSpan={2}>
                    <div>{t("isRequiredInApp")}</div>
                    <Switch
                      style={{ marginTop: 5 }}
                      hint={t("isRequiredInApp")}
                      value={selectedData.isRequiredInApp}
                      onValueChange={(e) => onValueChanged("isRequiredInApp", e)}
                    />
                  </SimpleItem>

                  <SimpleItem colSpan={2}>
                    <div>{t("isRequiredInWeb")}</div>
                    <Switch
                      style={{ marginTop: 5 }}
                      hint={t("isRequiredInWeb")}
                      value={selectedData.isRequiredInWeb}
                      onValueChange={(e) => onValueChanged("isRequiredInWeb", e)}
                    />
                  </SimpleItem>


                  <SimpleItem colSpan={2}>
                    <div>{t("isShowInDriver")}</div>
                    <Switch
                      style={{ marginTop: 5 }}
                      hint={t("isShowInDriver")}
                      value={selectedData.isShowInDriver}
                      onValueChange={(e) => onValueChanged("isShowInDriver", e)}
                    />
                  </SimpleItem>

                  <SimpleItem colSpan={2}>
                    <div>{t("isShowOnPassenger")}</div>
                    <Switch
                      style={{ marginTop: 5 }}
                      hint={t("isShowOnPassenger")}
                      value={selectedData.isShowInPassenger}
                      onValueChange={(e) => onValueChanged("isShowInPassenger", e)}
                    />
                  </SimpleItem>

                  <SimpleItem colSpan={2}>
                    <div>{t("isShowOnPedestrian")}</div>
                    <Switch
                      style={{ marginTop: 5 }}
                      hint={t("isShowOnPedestrian")}
                      value={selectedData.isShowInPedestrian}
                      onValueChange={(e) => onValueChanged("isShowInPedestrian", e)}
                    />
                  </SimpleItem>


                  <SimpleItem colSpan={2}>
                    <div>{t("isCalculatedField")}</div>
                    <Switch
                      style={{ marginTop: 5 }}
                      hint={t("isCalculatedField")}
                      value={selectedData.isCalculatedField}
                      onValueChange={(e) => onValueChanged("isCalculatedField", e)}
                    />
                  </SimpleItem>

                  <SimpleItem colSpan={2}>
                    <div>{t("isCalculatedEqualField")}</div>
                    <Switch
                      style={{ marginTop: 5 }}
                      hint={t("isCalculatedEqualField")}
                      value={selectedData.isCalculatedEqualField}
                      onValueChange={(e) => onValueChanged("isCalculatedEqualField", e)}
                    />
                  </SimpleItem>

                  <SimpleItem colSpan={2}>
                    <div>{t("isUpperCase")}</div>
                    <Switch
                      style={{ marginTop: 5 }}
                      hint={t("isUpperCase")}
                      value={selectedData.isUpperCase}
                      onValueChange={(e) => onValueChanged("isUpperCase", e)}
                    />
                  </SimpleItem>

                  <SimpleItem colSpan={2}>
                    <div>{t("readOnlyField")}</div>
                    <Switch
                      style={{ marginTop: 5 }}
                      hint={t("readOnlyField")}
                      value={selectedData.readOnlyField}
                      onValueChange={(e) => onValueChanged("readOnlyField", e)}
                    />
                  </SimpleItem>

                  <SimpleItem colSpan={2}>
                    {(selectedData.fieldType === FieldType.List && selectedData.isCalculatedField && selectedData.codeGroupId != null && selectedData.isCalculatedEqualField === false) ?
                      <Button
                        onClick={() => onOpenListModal()}
                        text={t("addRule")}
                        className="tes-modal-btn-add"
                        style={{ marginTop: "1rem" }}
                      /> :
                      <div style={{ marginTop: "1rem" }}>
                        <Rule
                          name="rule"
                          onValueChange={onValueChanged}
                          defaultRuleValue={selectedData.rule}
                          fieldServiceType={FieldServiceType.Collision}
                        />
                      </div>

                    }
                  </SimpleItem>
                </Form>
              </ScrollView></div>
            <div className="rightColumn" style={{ marginTop: "1.5rem" }}>
              <Button
                className="tes-modal-btn-cancel"
                style={{ marginRight: 20 }}
                onClick={() => onCloseModal()}
                text={t("cancel")}
              />
              {modalMode === ModalMode.Add ? (
                <Button
                  className="tes-modal-btn-add"
                  onClick={onAdd}
                  text={t("add")}
                />
              ) : (
                <Button
                  className="tes-modal-btn-add"
                  onClick={onUpdate}
                  text={t("update")}
                />
              )}
            </div>
          </Popup>


          {/* Rule component for fieldType == List */}
          <Popup
            width={"80%"}
            height={codeValuePopHeight}
            visible={showListModal}
            resizeEnabled={true}
            showTitle={true}
            title={t("codeValues")}
            hideOnOutsideClick={false}
            showCloseButton={true}
            onHiding={() => setShowListModal(false)}
          >
            <ScrollView width="100%" height="100%">
              <div style={{ marginBottom: "1rem" }}>
                <DataGrid
                  dataSource={codeValues}
                  ref={dataGridListRef}
                  rowAlternationEnabled={true}
                  showBorders={true}
                  hoverStateEnabled={true}
                  remoteOperations={true}
                  allowColumnReordering={true}
                  allowColumnResizing={true}
                  columnAutoWidth={true}
                  height={codeValueGridHeight}
                  onExporting={OnExporting}
                >
                  <Export enabled={true} allowExportSelectedData={true} />
                  <Grouping contextMenuEnabled={true} autoExpandAll={false} />
                  <GroupPanel visible={true} /> {/* or "auto" */}
                  <FilterPanel visible={true} />
                  <FilterBuilderPopup position={"top"} />
                  <Paging enabled={true} defaultPageSize={100} />
                  <Pager
                    showPageSizeSelector={true}
                    allowedPageSizes={[10, 20, 50, 100]}
                    showNavigationButtons={true}
                    showInfo={true}
                    visible={true}
                  />
                  <FilterRow visible={true} applyFilter="auto" />
                  <HeaderFilter visible={true} />
                  <SearchPanel
                    visible={true}
                    width={285}
                    placeholder={t("search")}
                  />
                  <ColumnChooser width={350} height={400} enabled={true} mode="select" sortOrder="asc">
                    <Search enabled />
                  </ColumnChooser>
                  <SortByGroupSummaryInfo
                    summaryItem="Total Count"
                    sortOrder="desc"
                  />
                  <Summary>
                    <GroupItem
                      summaryType="count"
                      alignByColumn
                      name="Total Count"
                    />
                  </Summary>
                  <Column caption={t("id")} visible={false} dataField="codeGroupId"
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column caption={t("name")} visible={true} dataField="name"
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column caption={t("rule")} visible={true}
                    calculateCellValue={(x: { rule: string }) => {
                      return Rule2View(x.rule, initData.map((a: TesField) => convertTesField2VMField(a)))
                    }}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    type="buttons"
                    caption={t("actions")}
                    fixed={true}
                    fixedPosition="right"
                    width={110}
                  >
                    <DevExpressButton
                      hint={t("edit")}
                      onClick={onSecondModalEdit}
                      icon="fa-solid fa-pencil"
                    ></DevExpressButton>
                  </Column>
                </DataGrid>
              </div>
              <div className="rightColumn" style={{ marginTop: "0.5rem" }}>
                <Button
                  className="tes-modal-btn-cancel"
                  style={{ marginRight: 20 }}
                  onClick={() => setShowListModal(false)}
                  text={t("cancel")}
                />
                <Button
                  className="tes-modal-btn-add"
                  onClick={onSaveRule}
                  text={t("save")}
                />
              </div>
            </ScrollView>
          </Popup>

          <Popup
            width={"70%"}
            height={"auto"}
            visible={showAddUpdateModal}
            resizeEnabled={true}
            showTitle={true}
            title={secondModalMode === ModalMode.Add ? t("add") : t("update")}
            hideOnOutsideClick={false}
            showCloseButton={true}
            onHiding={() => setShowAddUpdateModal(false)}
          >
            <ScrollView width="100%" height="100%">
              <div>
                <Form colCount={15} style={{ marginBottom: "2rem" }}>
                  <SimpleItem colSpan={13}>
                    <label style={{ fontSize: "14px" }}>{t("rule")}:</label>
                    <TextArea
                      style={{ marginTop: "1rem" }}
                      autoResizeEnabled={true}
                      minHeight={70}
                      readOnly={true}
                      value={secondModalMode === ModalMode.Add ? Rule2View(addObj.rule, initData.map((a: TesField) => convertTesField2VMField(a))) : Rule2View(updateObj.rule, initData.map((a: TesField) => convertTesField2VMField(a)))}
                      onChange={() => secondModalMode === ModalMode.Add ? setAddObj({ ...addObj, rule: Rule2View(addObj.rule, initData.map((a: TesField) => convertTesField2VMField(a))) }) : setAddObj({ ...updateObj, rule: Rule2View(updateObj.rule, initData.map((a: TesField) => convertTesField2VMField(a))) })}
                    />
                  </SimpleItem>
                  <SimpleItem colSpan={1}>
                    <div className="leftColumn " style={{ marginTop: "3.25rem" }}>
                      <Rule
                        name="rule"
                        onValueChange={onValueChangedAddObj}
                        defaultRuleValue={secondModalMode === ModalMode.Add ? addObj.rule : updateObj.rule}
                        fieldServiceType={FieldServiceType.Collision}
                      />
                    </div>
                  </SimpleItem>
                </Form>
                <div className="rightColumn">
                  <Button
                    className="tes-modal-btn-cancel"
                    style={{ marginRight: 20 }}
                    onClick={() => setShowAddUpdateModal(false)}
                    text={t("cancel")}
                  />
                  <Button
                    className="tes-modal-btn-add"
                    onClick={secondModalMode === ModalMode.Add ? onAddSecondModal : onSecondModalUpdate}
                    text={secondModalMode === ModalMode.Add ? t("add") : t("update")}
                  />
                </div>
              </div>
            </ScrollView>
          </Popup>
        </React.Fragment>
      </div>
    </Permission>
  );
};
export default CollisionFields;
