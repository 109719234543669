//#region imports
import { DateBox, Form, NumberBox, ScrollView, SelectBox, TextBox } from 'devextreme-react';
import { Button } from 'devextreme-react/button';
import DataGrid, {
    Column,
    ColumnChooser,
    DataGridRef,
    Button as DevExpressButton, Export,
    FilterBuilderPopup,
    FilterPanel,
    FilterRow,
    Grouping,
    GroupItem,
    GroupPanel,
    HeaderFilter,
    Lookup,
    Pager, Paging,
    Search,
    SearchPanel,
    Summary,
    TotalItem
} from 'devextreme-react/data-grid';
import { SimpleItem } from 'devextreme-react/form';
import { Popup } from 'devextreme-react/popup';
import { ValidationGroup, ValidationGroupRef } from 'devextreme-react/validation-group';
import { RequiredRule, Validator } from 'devextreme-react/validator';
import { custom } from 'devextreme/ui/dialog';
import notify from 'devextreme/ui/notify';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useAuth } from '../../../../contexts/auth';
import { useClientSetting } from '../../../../contexts/clientSetting';
import { SyncApiUrl } from '../../../../environment/routeSettings';
import { ModalMode } from '../../../../types/general/enums/generalEnums';
import { NameValue } from '../../../../types/general/generalTypes';
import { SyncStatus, SyncType } from '../../../../types/sync/enums/tesSyncEnums';
import { TesSync } from '../../../../types/sync/tesSync';
import { SortObjectByPropName } from '../../../../utils/arrayTools';
import { OnExporting } from '../../../../utils/dataGridTools';
import { Enum2Array } from '../../../../utils/enumTools';
import { TesDelete, TesGet, TesPost } from '../../../../utils/rest';
import './tesSync.scss';

//#endregion imports

const TESSync = () => {

    //#region consts
    const dataGridRef = useRef<DataGridRef<any, any>>(null);
    const [initData, setInitData] = useState<TesSync[]>([new TesSync()]);
    const [showModal, setShowModal] = useState(false);
    const [modalMode, setModalMode] = useState<ModalMode>(ModalMode.Add);
    const [selectedData, setSelectedData] = useState<TesSync>(new TesSync());
    const validationRef = useRef<ValidationGroupRef>(null);
    const { activeLoading } = useAuth();
    const { t } = useTranslation();
    const [lstSyncType, setLstSyncType] = useState<NameValue[]>([new NameValue()]);
    const [lstSyncStatus, setLstSyncStatus] = useState<NameValue[]>([new NameValue()]);
    const compactViewModel: boolean = (JSON.parse(localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}') || {}).viewMode === "compact";
    const { generalSetting } = useClientSetting();

    //#endregion


    //#region functions 
    useEffect(() => {
        async function fetchMyAPI() {
            try {
                if (activeLoading) activeLoading(true);
                await getInitData();
                setLstSyncType(Enum2Array(SyncType))
                setLstSyncStatus(Enum2Array(SyncStatus))
                if (activeLoading) activeLoading(false);
            } catch (ex) {
                if (activeLoading) activeLoading(false);
                notify(t("someErrorOccurred") + ex, "error", 5000);
            }
        }
        fetchMyAPI()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function getInitData() {
        setInitData(await TesGet(SyncApiUrl() + "/api/TesSyncs/" + localStorage.getItem('selectedCustomerId'), true));
    }
    function onCloseModal() {
        if (modalMode === ModalMode.Add) {
            setShowModal(false);
            setSelectedData(new TesSync());
        } else {
            setSelectedData(new TesSync());
            setShowModal(false);
        }
        validationRef.current?.instance().reset();
    }
    const onValueChanged = (key: string, value: any) => {
        setSelectedData({ ...selectedData, [key]: value });
    }
    function onEdit(d: any) {
        let newData = new TesSync();
        Object.assign(newData, d.row.data);
        setModalMode(ModalMode.Edit);
        setSelectedData(newData);
        setShowModal(true);
    }
    function onNew() {
        validationRef.current?.instance().reset();
        setModalMode(ModalMode.Add);
        setShowModal(true);
    }
    async function onAdd() {
        try {
            const validationRes = validationRef.current?.instance().validate();
            if (validationRes?.isValid) {
                const postObj = {
                    ...selectedData,
                    customerId: localStorage.getItem('selectedCustomerId')
                }
                if (activeLoading) activeLoading(true);
                await TesPost(SyncApiUrl() + "/api/TesSyncs", postObj, true);
                //refresh the grid
                await getInitData();
                onCloseModal();
                if (activeLoading) activeLoading(false);
                notify(t("dataSuccessfullyAdded"), "success", 5000);
            }
        } catch {
            if (activeLoading) activeLoading(false);
            notify(t("someErrorOccurred"), "error", 5000);
        }
    }
    async function onUpdate() {
        try {
            const validationRes = validationRef.current?.instance().validate();
            if (validationRes?.isValid) {
                if (activeLoading) activeLoading(true);
                await TesPost(SyncApiUrl() + "/api/TesSyncs", selectedData, true);
                await getInitData();
                onCloseModal();
                notify(t("dataSuccessfullyUpdated"), "success", 5000);
                if (activeLoading) activeLoading(false);
            }
        } catch (ex) {
            if (activeLoading) activeLoading(false);
            notify(t("someErrorOccurred" + ex), "error", 5000);
        }
    }
    async function onDelete(d: any) {
        let myDialog = custom({
            title: t("warning"),
            messageHtml: t("deleteWarningMessage"),
            buttons: [{
                text: t("yes"),
                onClick: async (e) => {
                    try {
                        await TesDelete(SyncApiUrl() + "/api/TesSyncs/" + d.row.data.id, true);
                        await getInitData();
                        notify(t("dataSuccessfullyDeleted"), "success", 5000);

                    } catch {
                        notify(t("someErrorOccurred"), "error", 5000);
                    }
                    return { buttonText: e.component.option("text") }
                }
            },
            {
                text: t("no"),
                onClick: (e) => {
                    return { buttonText: e.component.option("text") }
                }
            },]
        });
        myDialog.show();
    }
    //#endregion functions

    return (
        <div className={compactViewModel ? "compactStyle" : ""}>
            <React.Fragment>
                <h2 className={'content-block'}>{t('tesSync')}</h2>
                <div className={'content-block'}>
                    <div className={'dx-card responsive-paddings'}>
                        <div className="row">
                            <div className='rightColumn'>
                                <Button
                                    onClick={onNew}
                                    icon="fa-solid fa-circle-plus"
                                    text={t('tes')}
                                />
                            </div>
                        </div>
                        <DataGrid id="gridContainer"
                            ref={dataGridRef}
                            dataSource={initData}
                            rowAlternationEnabled={true}
                            showBorders={true}
                            hoverStateEnabled={true}
                            remoteOperations={true}
                            allowColumnReordering={true}
                            allowColumnResizing={true}
                            columnAutoWidth={true}
                            style={{ margin: "0 1rem" }}
                            onExporting={OnExporting}
                        >
                            <Export enabled={true}
                                allowExportSelectedData={true}
                            />
                            <Grouping contextMenuEnabled={true} autoExpandAll={false} />
                            <GroupPanel visible={true} /> {/* or "auto" */}
                            <FilterPanel visible={true} />
                            <FilterBuilderPopup position={'top'} />
                            <Summary>
                                <TotalItem
                                    column="GeoId"
                                    summaryType="count"
                                />
                                <GroupItem
                                    summaryType="count"
                                    alignByColumn
                                    name="Total Count"
                                />
                            </Summary>
                            <Paging enabled={true} defaultPageSize={100} />
                            <Pager
                                showPageSizeSelector={true}
                                allowedPageSizes={[100, 200, 300, 400, 500]}
                                showNavigationButtons={true}
                                showInfo={true}
                                visible={true}
                            />
                            <FilterRow visible={true}
                                applyFilter="auto" />
                            <HeaderFilter visible={true} />
                            <SearchPanel visible={true}
                                width={285}
                                placeholder={t("search...")} />
                            <ColumnChooser width={350} height={400}
                                enabled={true}
                                mode="select"
                                sortOrder="asc"
                            >
                                <Search enabled />
                            </ColumnChooser>
                            <Column dataField="id"
                                caption={t("id")} visible={false}>
                                <HeaderFilter>
                                    <Search enabled />
                                </HeaderFilter>
                            </Column>
                            <Column dataField="customerId"
                                caption={t("customerId")} visible={false}>
                                <HeaderFilter>
                                    <Search enabled />
                                </HeaderFilter>
                            </Column>
                            <Column dataField="syncType" alignment="left"
                                caption={t("syncType")}>
                                <Lookup dataSource={SortObjectByPropName(lstSyncType, "name")} valueExpr="value" displayExpr="name" />
                                <HeaderFilter>
                                    <Search enabled />
                                </HeaderFilter>
                            </Column>
                            <Column dataField="fromDate"
                                caption={t('fromDate')}
                                dataType="datetime"
                                format={
                                    generalSetting?.dateFormat
                                        ? generalSetting?.dateFormat +
                                        (generalSetting?.isAmPm === true
                                            ? "hh:mm aa"
                                            : "hh:mm")
                                        : "MM/dd/yyyy hh:mm aa"
                                }
                            >
                                <HeaderFilter>
                                    <Search enabled />
                                </HeaderFilter>
                            </Column>
                            <Column dataField="toDate"
                                caption={t('toDate')}
                                dataType="datetime"
                                format={
                                    generalSetting?.dateFormat
                                        ? generalSetting?.dateFormat +
                                        (generalSetting?.isAmPm === true
                                            ? "hh:mm aa"
                                            : "hh:mm")
                                        : "MM/dd/yyyy hh:mm aa"
                                }
                            >
                                <HeaderFilter>
                                    <Search enabled />
                                </HeaderFilter>
                            </Column>
                            <Column dataField="syncStatus" alignment="left"
                                caption={t("syncStatus")}>
                                <Lookup dataSource={SortObjectByPropName(lstSyncStatus, "name")} valueExpr="value" displayExpr="name" />
                                <HeaderFilter>
                                    <Search enabled />
                                </HeaderFilter>
                            </Column>
                            <Column dataField="batchSize"
                                caption={t("batchSize")} visible={true}>
                                <HeaderFilter>
                                    <Search enabled />
                                </HeaderFilter>
                            </Column>
                            <Column dataField="syncInterval"
                                caption={t("syncInterval")} visible={true}>
                                <HeaderFilter>
                                    <Search enabled />
                                </HeaderFilter>
                            </Column>
                            <Column dataField="timeout"
                                caption={t("timeout")} visible={true}>
                                <HeaderFilter>
                                    <Search enabled />
                                </HeaderFilter>
                            </Column>
                            <Column dataField="retryNo"
                                caption={t("retryNo")} visible={true}>
                                <HeaderFilter>
                                    <Search enabled />
                                </HeaderFilter>
                            </Column>
                            <Column dataField="retryDelay"
                                caption={t("retryDelay")} visible={true}>
                                <HeaderFilter>
                                    <Search enabled />
                                </HeaderFilter>
                            </Column>
                            <Column dataField="syncUrl"
                                caption={t("syncUrl")} visible={true}>
                                <HeaderFilter>
                                    <Search enabled />
                                </HeaderFilter>
                            </Column>
                            <Column type="buttons" caption={t("actions")} width={110} fixed={true} fixedPosition="right">
                                <DevExpressButton hint={t('edit')} onClick={onEdit} icon="fa-solid fa-pencil" />
                                <DevExpressButton hint={t('delete')} onClick={onDelete} icon="fa-solid fa-trash-can" />
                            </Column>
                        </DataGrid>
                    </div>
                </div>

                <Popup
                    width={"50%"}
                    height={"auto"}
                    visible={showModal}
                    resizeEnabled={true}
                    showTitle={true}
                    title={modalMode === ModalMode.Add ? t('add') : t('update')}
                    hideOnOutsideClick={false}
                    showCloseButton={true}
                    onHiding={() => onCloseModal()}
                >
                    <ScrollView width='100%' height='100%'>
                        <ValidationGroup
                            ref={validationRef}
                        >
                            <Form colCount={2} className='popupFields'>
                                <SimpleItem colSpan={1} >
                                    <SelectBox
                                        placeholder=""
                                        label={t('syncType')}
                                        labelMode='static'
                                        value={selectedData.syncType}
                                        onValueChange={e => onValueChanged("syncType", e)}
                                        className="modalInput"
                                        valueExpr={'value'}
                                        displayExpr={'name'}
                                        items={SortObjectByPropName(lstSyncType.filter(x => !initData.map(y => parseInt(y.syncType?.toString())).includes(parseInt(x.value))), "name")}
                                        disabled={modalMode === ModalMode.Add ? false : true}
                                        showClearButton={true}
                                        searchEnabled={true}
                                    >
                                        <Validator>
                                            <RequiredRule message={t('fieldIsRequired')} />
                                        </Validator>
                                    </SelectBox>
                                </SimpleItem>
                                <SimpleItem colSpan={1} >
                                    <DateBox
                                        type="datetime"
                                        label={t('fromDate')}
                                        onValueChange={(e) => onValueChanged("fromDate", e)}
                                        value={selectedData.fromDate}
                                        pickerType="calendar"
                                        placeholder={generalSetting?.dateFormat}
                                        displayFormat={generalSetting?.dateFormat}
                                        useMaskBehavior={true}
                                        openOnFieldClick={true}
                                        showClearButton={true}
                                    >
                                    </DateBox>
                                </SimpleItem>
                                <SimpleItem colSpan={1} >
                                    <DateBox
                                        type="datetime"
                                        label={t('toDate')}
                                        onValueChange={(e) => onValueChanged("toDate", e)}
                                        value={selectedData.toDate}
                                        pickerType="calendar"
                                        placeholder={generalSetting?.dateFormat}
                                        displayFormat={generalSetting?.dateFormat}
                                        useMaskBehavior={true}
                                        openOnFieldClick={true}
                                        showClearButton={true}
                                    >
                                    </DateBox>
                                </SimpleItem>
                                <SimpleItem colSpan={1} >
                                    <SelectBox
                                        placeholder=""
                                        label={t('syncStatus')}
                                        labelMode='static'
                                        value={selectedData.syncStatus}
                                        onValueChange={e => onValueChanged("syncStatus", e)}
                                        className="modalInput"
                                        valueExpr={'value'}
                                        displayExpr={'name'}
                                        items={SortObjectByPropName(lstSyncStatus, "name")}
                                        showClearButton={true}
                                        searchEnabled={true}
                                    >
                                        <Validator>
                                            <RequiredRule message={t('fieldIsRequired')} />
                                        </Validator>
                                    </SelectBox>
                                </SimpleItem>
                                <SimpleItem colSpan={1}>
                                    <NumberBox
                                        step={0}
                                        label={t('batchSize')}
                                        labelMode='static'
                                        value={selectedData.batchSize}
                                        onValueChange={(e) => onValueChanged("batchSize", e)}
                                        style={{ marginBottom: "1rem" }}
                                    />
                                </SimpleItem>
                                <SimpleItem colSpan={1}>
                                    <NumberBox
                                        step={0}
                                        label={t('syncInterval')}
                                        labelMode='static'
                                        value={selectedData.syncInterval}
                                        onValueChange={(e) => onValueChanged("syncInterval", e)}
                                        style={{ marginBottom: "1rem" }}
                                    />
                                </SimpleItem>
                                <SimpleItem colSpan={1}>
                                    <NumberBox
                                        step={0}
                                        label={t('timeout')}
                                        labelMode='static'
                                        value={selectedData.timeout}
                                        onValueChange={(e) => onValueChanged("timeout", e)}
                                        style={{ marginBottom: "1rem" }}
                                    />
                                </SimpleItem>
                                <SimpleItem colSpan={1}>
                                    <NumberBox
                                        step={0}
                                        label={t('retryNo')}
                                        labelMode='static'
                                        value={selectedData.retryNo}
                                        onValueChange={(e) => onValueChanged("retryNo", e)}
                                        style={{ marginBottom: "1rem" }}
                                    />
                                </SimpleItem>
                                <SimpleItem colSpan={1}>
                                    <NumberBox
                                        step={0}
                                        label={t('retryDelay')}
                                        labelMode='static'
                                        value={selectedData.retryDelay}
                                        onValueChange={(e) => onValueChanged("retryDelay", e)}
                                        style={{ marginBottom: "1rem" }}
                                    />
                                </SimpleItem>
                                <SimpleItem colSpan={1} >
                                    <TextBox
                                        label={t("syncUrl")}
                                        labelMode="static"
                                        value={selectedData.syncUrl}
                                        onValueChange={(e) => onValueChanged("syncUrl", e)}
                                    />
                                </SimpleItem>
                            </Form>
                        </ValidationGroup>
                        <div>
                            <div className='rightColumn'>
                                <Button
                                    className='tes-modal-btn-cancel'
                                    style={{ marginRight: 20 }}
                                    onClick={() => onCloseModal()}
                                    text={t('cancel')}
                                />
                                {modalMode === ModalMode.Add ?
                                    <Button
                                        className='tes-modal-btn-add'
                                        onClick={onAdd}
                                        text={t('add')}
                                    />
                                    :
                                    <Button
                                        className='tes-modal-btn-add'
                                        onClick={onUpdate}
                                        text={t('update')}
                                    />
                                }
                            </div>
                        </div>
                    </ScrollView>
                </Popup>
            </React.Fragment>
        </div>
    );
}
export default TESSync;