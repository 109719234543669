//#region imports
import { ContextMenu, Lookup } from "devextreme-react";
import { Button } from "devextreme-react/button";
import DataGrid, {
  Column,
  ColumnChooser,
  DataGridRef,
  Export,
  FilterBuilderPopup,
  FilterPanel,
  FilterRow,
  Grouping,
  GroupItem,
  GroupPanel,
  HeaderFilter,
  Pager,
  Paging,
  Search,
  SearchPanel,
  Selection,
  StateStoring,
  Summary,
  TotalItem,
} from "devextreme-react/data-grid";
import DropDownButton from "devextreme-react/drop-down-button";
import { Popup } from "devextreme-react/popup";
import CustomStore from "devextreme/data/custom_store";
import notify from "devextreme/ui/notify";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import GeneralGisMapManager from "../../../components/gisMap/generalGisMapManager";
import GridProfilePicker from "../../../components/gridProfilePicker/gridProfilePicker";
import Permission from "../../../components/permission/permision";
import ReportViewer from "../../../components/reportViewer/ReportViewer";
import { SignPermissions } from "../../../constants/Permissions";
import reportLocations from "../../../constants/reportLocations";
import { useAuth } from "../../../contexts/auth";
import { useClientSetting } from "../../../contexts/clientSetting";
import { ReportApiUrl, SignApiUrl } from "../../../environment/routeSettings";
import {
  GridType,
  TesMapModule,
} from "../../../types/general/enums/generalEnums";
import { GridSetting } from "../../../types/general/generalTypes";
import { ClientReport, UiReportRequest } from "../../../types/report/reportTypes";
import { SortObjectByPropName } from "../../../utils/arrayTools";
import { OnExporting } from "../../../utils/dataGridTools";
import MaxDropdownItemWidthCalculator from "../../../utils/dropDownWidthCalculator";
import { useScreenSize } from "../../../utils/media-query";
import { TesPost, TesPut } from "../../../utils/rest";
import { RightClickMenu } from "../../../utils/rightClickMenu";
import "./signs.scss";
//#endregion imports

const dataSource = new CustomStore({
  load: async (loadOption) => {
    return await TesPut(
      `${SignApiUrl()}/api/TesSigns/LazyLoading/${localStorage.getItem(
        "selectedCustomerId"
      )}/false`,
      JSON.stringify(loadOption),
      true
    );
  },
});

const Signs = () => {
  //#region consts
  const [showMap, setShowMap] = useState(false);
  const [initDataReports, setInitDataReports] = useState<ClientReport[]>([]);
  const [selectedReport, setSelectedReport] = useState<ClientReport>(new ClientReport());
  const [showReportModal, setShowReportModal] = useState<boolean>(false);
  const [lstSelectedIds, setLstSelectedIds] = useState<string[]>([]);
  const history = useNavigate();
  const dataGridRef = useRef<DataGridRef<any, any>>(null);
  const { t } = useTranslation();
  const dropDownOptions = { width: 215 };
  const { activeLoading } = useAuth();
  const { is2xLarge } = useScreenSize();
  const [popupHeight, setPopupHeight] = useState<string>("80%")
  const [selectedRowData, setSelectedRowData] = useState<any>(null);
  const items = [
    { text: t('openInNewTab'), icon: "fa-solid fa-up-right-from-square" },
    { text: t('openLocation'), icon: "fa-solid fa-location-dot" }
  ];
  const [gridSetting, setGridSetting] = useState<GridSetting>(JSON.parse((localStorage.getItem("GridSetting")!)) || new GridSetting)
  const compactViewModel: boolean = (JSON.parse(localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}') || {}).viewMode === "compact";
  const { generalSetting, customerDivisions } = useClientSetting();
  //#endregion consts

  //#region functions
  useEffect(() => {
    if (is2xLarge) { setPopupHeight("58%") }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        await getInitialDataReports();
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const navigateToSignDetails = useCallback(() => {
    history("/sign/signDetails/AddNew");
  }, [history]);


  function onRowClicked(e: any) {
    const isCtrlKeyPressed = e.event.ctrlKey || e.event.metaKey;
    if (e.data.id !== undefined) {

      if (isCtrlKeyPressed) {
        window.open(`/sign/signDetails/${e.data.id}`, "_blank");
      } else {
        history(`/sign/signDetails/${e.data.id}`);
      }
    }
  }

  function replaceStr(str: string) {
    str = str.replaceAll("/", "");
    str = str.replaceAll(":", "");
    str = str.replaceAll("*", "");
    str = str.replaceAll("?", "");
    str = str.replaceAll(">", "");
    str = str.replaceAll("<", "");
    str = str.replaceAll("|", "");
    str = str.replaceAll("\\", "");
    str = str.replaceAll(" ", "-");
    // console.log(JSON.stringify(str))
    return str;
  }

  function calculateImageTypeCell(d: any) {
    const data = d.data;
    if (data.signCode) {
      return (
        <div>
          {/* eslint-disable-next-line jsx-a11y/alt-text */}
          <img
            src={
              "http://staticfile.tes.ca/sign/" +
              localStorage.getItem("selectedCustomerName") +
              "/" +
              replaceStr(data.signCode.code) +
              ".png"
            }
            width="50"
            height="50"
            alt=""
          />
        </div>
      );
    }
    if (data.signCodeTitle) {
      return (
        <div>
          {/* eslint-disable-next-line jsx-a11y/alt-text */}
          <img
            src={
              "http://staticfile.tes.ca/sign/" +
              localStorage.getItem("selectedCustomerName") +
              "/" +
              replaceStr(data.signCodeTitle) +
              ".png"
            }
            width="50"
            height="50"
          />
        </div>
      );
    }
    return undefined
  }

  async function getInitialDataReports() {
    try {
      var postObj: UiReportRequest = {
        customerId: localStorage.getItem("selectedCustomerId")!,
        lstLocations: [
          reportLocations.Sign_Signs,
        ]
      }
      var res: ClientReport[] = await TesPost(
        ReportApiUrl() +
        "/api/ClientReports/GetPageReports",
        postObj,
        true
      );
      setInitDataReports(res);
    } catch (ex) {
      notify(t("errorInFetchReports") + ex, "error", 5000);
    }
  }

  function onOpenReport(d: any) {
    if (dataGridRef.current?.instance().getSelectedRowKeys().length === 0) {
      notify(t('pleaseSelectSomeData'), 'warning', 5000);
      return;
    }
    setShowReportModal(true);
    setSelectedReport(d.itemData);
    setLstSelectedIds(dataGridRef.current?.instance().getSelectedRowKeys().map(x => x.id) as string[]);
  }

  function onCloseReportModal() {
    setShowReportModal(false);
    dataGridRef.current?.instance().deselectAll();
  }

  //#endregion functions
  return (
    <Permission
      allowed={[
        SignPermissions.Sign_D,
        SignPermissions.Sign_R,
        SignPermissions.Sign_V,
        SignPermissions.Sign_E,
      ]}
      hasFeedBackElement={true}
    >
      <div className={compactViewModel ? "compactStyle" : ""}>
        <React.Fragment>
          <ContextMenu
            dataSource={items}
            width={100}
            target=".dx-data-row"
            onItemClick={e => RightClickMenu(e, selectedRowData.row.key.id, `/sign/signDetails/`, selectedRowData.row.data.locationType, selectedRowData.row.data.locationId)}
          />
          <h2 className={"content-block"}>{t("signs")}</h2>
          <div className={"content-block"}>
            <div className={"dx-card responsive-paddings"}>
              <div className="row">
                <div className="leftColumn" style={{ display: "flex" }}>
                  <Button
                    onClick={() => setShowMap(true)}
                    icon="fa-solid fa-map-location-dot"
                    hint={t("showMap")}
                  />
                  <GridProfilePicker
                    customerId={localStorage.getItem("selectedCustomerId")!}
                    gridType={GridType.Signs}
                    dataGridRef={dataGridRef}
                    setGridSetting={setGridSetting}
                  />
                  <Permission
                    allowed={[SignPermissions.Sign_V_Report]}
                    hasFeedBackElement={false}
                  >
                    <DropDownButton
                      style={{ marginLeft: 10 }}
                      icon="fa-solid fa-chart-line"
                      hint={t('report')}
                      items={SortObjectByPropName(initDataReports?.filter((x: ClientReport) => [reportLocations.Sign_Signs].some(a => x.reportLocations?.map(x => x.name).indexOf(a) >= 0)), "name")}
                      dropDownOptions={{ width: MaxDropdownItemWidthCalculator(SortObjectByPropName(initDataReports?.filter((x: ClientReport) => [reportLocations.Sign_Signs].some(a => x.reportLocations?.map(x => x.name).indexOf(a) >= 0)), "name")) }}
                      displayExpr="name"
                      onItemClick={(d) => onOpenReport(d)}
                    />
                  </Permission>
                </div>
                <div className="rightColumn">
                  <Permission
                    allowed={[SignPermissions.Sign_D]}
                    hasFeedBackElement={false}
                  >
                    <Button
                      onClick={navigateToSignDetails}
                      icon="fa-solid fa-circle-plus"
                      text={t("sign")}
                    />
                  </Permission>
                </div>
              </div>
              <DataGrid
                id="gridContainer"
                ref={dataGridRef}
                dataSource={dataSource}
                rowAlternationEnabled={true}
                showBorders={true}
                onRowClick={onRowClicked}
                hoverStateEnabled={true}
                remoteOperations={true}
                allowColumnReordering={true}
                allowColumnResizing={true}
                style={{ margin: "0 1rem" }}
                onExporting={OnExporting}
                onContextMenuPreparing={e => { setSelectedRowData(e) }}
                columnAutoWidth={gridSetting.horizontalScrolling}
              //onContentReady={e => {e.component.deselectAll()}}
              //onFilterValueChange={e => {dataGridRef?.current?.instance().deselectAll()}}
              >
                <Export enabled={true} allowExportSelectedData={true} />
                <Grouping contextMenuEnabled={true} autoExpandAll={false} />
                <GroupPanel visible={true} /> {/* or "auto" */}
                <FilterPanel visible={true} />
                <FilterBuilderPopup position={"top"} />
                <StateStoring
                  enabled={true}
                  type="localStorage"
                  storageKey={GridType.Signs.toString()}
                  savingTimeout={500}
                />
                <Summary>
                  <TotalItem column="GeoId" summaryType="count" />
                  <GroupItem
                    summaryType="count"
                    alignByColumn
                    name="Total Count"
                  />
                </Summary>
                <Paging enabled={true} defaultPageSize={100} />
                <Pager
                  showPageSizeSelector={true}
                  allowedPageSizes={[100, 200, 300, 400, 500]}
                  showNavigationButtons={true}
                  showInfo={true}
                  visible={true}
                />
                <Selection
                  mode="multiple"
                  selectAllMode={"allPages"}
                  showCheckBoxesMode={"always"}
                />
                <FilterRow visible={true} applyFilter="auto" />
                <HeaderFilter visible={true} />
                <SearchPanel visible={true} width={285} placeholder={t("search...")} />
                <ColumnChooser width={350} height={400} enabled={true} mode="select" sortOrder="asc">
                  <Search enabled />
                </ColumnChooser>
                <Column
                  alignment="left"
                  dataField="ChangeStatus"
                  cellRender={calculateImageTypeCell.bind(this)}
                  caption={t("symbol")}
                  width={100}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  alignment="left"
                  dataField="id"
                  visible={false}
                  caption={t("id")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column alignment="left" dataField="signId" caption={t("signId")}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column dataField="geoId" caption={t("geoId")}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="locationDescription"
                  width={200}
                  caption={t("locationDescription")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="signLocationTypeTitle"
                  caption={t("locationType")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  alignment="left"
                  dataField="supportTitle"
                  caption={t("supportId")}
                  visible={false}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  alignment="left"
                  dataField="signCodeTitle"
                  caption={t("code")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  alignment="left"
                  dataField="signDescriptionTitle"
                  caption={t("description")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  alignment="left"
                  dataField="signTypeTitle"
                  caption={t("type")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  alignment="left"
                  dataField="signDimensionTitle"
                  caption={t("dimension")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column alignment="left" visible={false} dataField="height" caption={t("height")}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  alignment="left"
                  dataField="signFacingDirectionTitle"
                  caption={t("facingDirection")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  alignment="left"
                  dataField="signFaceMaterialTitle"
                  caption={t("faceMaterial")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  alignment="left"
                  dataField="signReflectiveCoatingTitle"
                  caption={t("reflectiveCoating")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  alignment="left"
                  dataField="signReflectiveRatingTitle"
                  visible={false}
                  caption={t("reflectiveRating")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  alignment="left"
                  dataField="signConditionTitle"
                  caption={t("condition")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  alignment="left"
                  dataField="dateInstalled"
                  visible={false}
                  dataType="date"
                  format={generalSetting?.dateFormat}
                  caption={t("installationDate")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  alignment="left"
                  dataField="dateExpired"
                  visible={false}
                  dataType="date"
                  caption={t("expirationDate")}
                  format={generalSetting?.dateFormat}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  alignment="left"
                  dataField="materialCost"
                  visible={false}
                  caption={t("materialCost")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  alignment="left"
                  dataField="labourCost"
                  visible={false}
                  caption={t("labourCost")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  alignment="left"
                  dataField="installationCost"
                  visible={false}
                  caption={t("installationCost")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  alignment="left"
                  dataField="latitude"
                  visible={false}
                  dataType="number"
                  caption={t("latitude")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  alignment="left"
                  dataField="longitude"
                  visible={false}
                  dataType="number"
                  caption={t("longitude")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  alignment="left"
                  dataField="xCoord"
                  visible={false}
                  dataType="number"
                  caption={t("xCoord")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  alignment="left"
                  dataField="yCoord"
                  visible={false}
                  dataType="number"
                  caption={t("yCoord")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="DivisionId"
                  allowSorting={true}
                  visible={false}
                  caption={t("division")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                  <Lookup
                    dataSource={SortObjectByPropName(customerDivisions!, "name")}
                    valueExpr="id"
                    displayExpr="name"
                  />
                </Column>
              </DataGrid>
            </div>
          </div>

          {/* Map Popup */}
          <Popup
            width={"50%"}
            height={"auto"}
            visible={showMap}
            resizeEnabled={true}
            showTitle={true}
            title={t("map")}
            hideOnOutsideClick={false}
            showCloseButton={true}
            onHiding={() => setShowMap(false)}
          >
            <GeneralGisMapManager
              tesModule={TesMapModule.Sign}
              dataGridRef={dataGridRef}
              showGis={showMap}
              setShowMap={setShowMap}
              locId="locationId"
            />
          </Popup>

          {/* Report Popup */}
          <Popup
            visible={showReportModal}
            width={"auto"}
            height={popupHeight}
            resizeEnabled={true}
            showTitle={true}
            title={t("reportViewer")}
            hideOnOutsideClick={false}
            showCloseButton={true}
            onHiding={() => onCloseReportModal()}
          >
            <ReportViewer
              selectedReport={selectedReport}
              lstSelectedIds={lstSelectedIds}
              isOpen={showReportModal}
            />
          </Popup>
        </React.Fragment>
      </div>
    </Permission>
  );
};
export default Signs;
