//imports
import React, { useEffect, useRef, useState } from "react";
import DataGrid, {
  Column,
  FilterRow,
  HeaderFilter,
  SearchPanel,
  ColumnChooser,
  Pager,
  Paging,
  Summary,
  Grouping,
  GroupPanel,
  FilterBuilderPopup,
  FilterPanel,
  StateStoring,
  GroupItem,
  Button as DevExpressButton,
  Export,
  Lookup,
  SortByGroupSummaryInfo,
  Search,
  DataGridRef,
} from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import { Popup } from "devextreme-react/popup";
import { Button } from "devextreme-react/button";
import { TesPut, TesGet, TesPost, RequestErrorHandling } from "../../../utils/rest";
import { GridType, ModalMode, ResponseCode } from "../../../types/general/enums/generalEnums";
import { TesField } from "../../../types/field/fieldType";
import { INameId, LazyLoadingRequest, RequestResponseResult } from "../../../types/general/generalTypes";
import { Street } from "../../../types/infrastructure/infrastructureTypes";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../contexts/auth";
import {
  DateBox,
  Form,
  ScrollView,
  SelectBox,
  TextBox,
} from "devextreme-react";
import { SimpleItem } from "devextreme-react/form";
import notify from "devextreme/ui/notify";
// import { custom } from "devextreme/ui/dialog";
import { FieldCategoryType, FieldLocation2Show, FieldType } from "../../../types/field/enums/fieldEnums";
import { Switch } from "devextreme-react/switch";
import { useClientSetting } from "../../../contexts/clientSetting";
import Permission from "../../../components/permission/permision";
import { InfrastructurePermissions } from "../../../constants/Permissions";
import { FieldApiUrl, InfrastructureApiUrl } from "../../../environment/routeSettings";
import "./streets.scss";
import { TableFieldRequestDTO } from "../../../types/field/dto/fieldDTO";
import { OnExporting } from "../../../utils/dataGridTools";
import { SortObjectByPropName } from "../../../utils/arrayTools";
import { EnumFlag2Array } from "../../../utils/enumTools";
import { GridActualDateCalculator } from "../../../utils/dateTimeTools";
import getTitle from "../../../components/title/getTitle";


const dataSource = new CustomStore({
  key: "_id",
  load: async (loadOption) => {
    return await TesPost(
      `${InfrastructureApiUrl()
      }/api/streets/getStreets`,
      {
        customerId: localStorage.getItem("selectedCustomerId"),
        divisionId: localStorage.getItem("selectedDivisionId"),
        loadOptions: loadOption
      } as LazyLoadingRequest,
      true
    );
  },
});

const Streets = () => {
  const [initDataFields, setInitDataFields] = useState<TesField[]>([]);
  const [initDataFieldsValue, setInitDataFieldsValue] = useState<INameId[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [modalMode, setModalMode] = useState<ModalMode>(ModalMode.Add);
  const [selectedData, setSelectedData] = useState<Street>(new Street());
  const dataGridRef = useRef<DataGridRef<any, any>>(null);
  const { activeLoading } = useAuth();
  const { generalSetting, customerDivisions } = useClientSetting();
  const compactViewModel: boolean = (JSON.parse(localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}') || {}).viewMode === "compact";

  const title = getTitle('/infrastructure/streets', '');
  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        await getInitialDataFields();
        await getInitialDataFielddValue();
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getInitialDataFields() {
    var postOb: TableFieldRequestDTO = {
      customerId: localStorage.getItem('selectedCustomerId')!,
      categoryTypes: [FieldCategoryType.InfrastructureStreetGeneral]
    }
    var res = await TesPost(FieldApiUrl() + "/api/TesFields/GetWebTesFieldsByPermissionByCategory", postOb, true) as TesField[];
    res = res.sort(x => x.detailViewIndex);
    setInitDataFields(res);
  }

  async function getInitialDataFielddValue() {
    setInitDataFieldsValue(
      await TesGet(
        FieldApiUrl() +
        "/api/CodeValues/GetAllCustomerCodeValue/" +
        localStorage.getItem("selectedCustomerId"),
        true
      )
    );
  }





  function onCloseModal() {
    if (modalMode === ModalMode.Add) {
      setShowModal(false);
    } else {
      setSelectedData(new Street());
      setShowModal(false);
    }
  }

  const onValueChanged = (key: string, value: any) => {
    setSelectedData({ ...selectedData, [key]: value });
  };



  function onEdit(d: any) {
    let newData = new Street();
    Object.assign(newData, d.row.data);
    setModalMode(ModalMode.Edit);
    setSelectedData(newData);
    setShowModal(true);
  }

  function onNew() {
    setModalMode(ModalMode.Add);
    setSelectedData(new Street());
    setShowModal(true);
  }

  async function onAdd() {
    try {
      const postObj = {
        ...selectedData,
        customerId: localStorage.getItem("selectedCustomerId"),
        divisionId: localStorage.getItem("defaultDivisionId")!
      };
      if (activeLoading) activeLoading(true);
      const res = await TesPost(
        InfrastructureApiUrl() + "/api/streets/addStreet",
        postObj,
        true
      ) as RequestResponseResult<any>;
      if (res.responseCode === ResponseCode.OK) {
        await getInitialDataFields();
        dataGridRef?.current?.instance().refresh()
        onCloseModal();
        if (activeLoading) activeLoading(false);
        notify(t("dataSuccessfullyAdded"), "success", 5000);
      } else {
        await RequestErrorHandling(res);
      }
    } catch (er) {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred"), "error", 5000);
    }
  }

  async function onUpdate() {
    try {
      if (activeLoading) activeLoading(true);
      const res = await TesPost(
        InfrastructureApiUrl() + "/api/streets/UpdateStreet",
        {
          ...selectedData,
          Id: selectedData._id,
        },
        true) as RequestResponseResult<any>;
      if (activeLoading) activeLoading(false);
      if (res.responseCode === ResponseCode.OK) {
        await getInitialDataFields();
        dataGridRef?.current?.instance().refresh()
        onCloseModal();
        notify(t("dataSuccessfullyUpdated"), "success", 5000);
      } else {
        await RequestErrorHandling(res);
      }
    } catch (ex) {
      notify(t("someErrorOccurred" + ex), "error", 5000);
      if (activeLoading) activeLoading(false);
    }
  }
  const { t } = useTranslation();

  return (
    <Permission
      allowed={[
        InfrastructurePermissions.Infrastructure_D_Street,
        InfrastructurePermissions.Infrastructure_R_Street,
        InfrastructurePermissions.Infrastructure_V_Street,
      ]}
      hasFeedBackElement={true}
    >
      <div className={compactViewModel ? "compactStyle" : ""}>
        <React.Fragment>
          <h2 className={"content-block"}>{t("streets")}</h2>
          <div className={"content-block"}>
            <div className={"dx-card responsive-paddings"}>
              <div className="row">
                <div className="rightColumn">
                  <Permission
                    allowed={[
                      InfrastructurePermissions.Infrastructure_D_Street,
                      InfrastructurePermissions.Infrastructure_R_Street,
                    ]}
                    hasFeedBackElement={false}
                  >
                    <Button onClick={onNew} icon="fa-solid fa-circle-plus" text={t("street")} />
                  </Permission>
                </div>
              </div>
              {initDataFields && (

                <DataGrid
                  id="gridContainer"
                  ref={dataGridRef}
                  dataSource={dataSource}
                  rowAlternationEnabled={true}
                  showBorders={true}
                  hoverStateEnabled={true}
                  remoteOperations={true}
                  allowColumnReordering={true}
                  allowColumnResizing={true}
                  columnAutoWidth={true}
                  style={{ margin: "0 1rem" }}
                  onExporting={OnExporting}
                >
                  <Export enabled={true} allowExportSelectedData={true} />
                  <Grouping contextMenuEnabled={true} autoExpandAll={false} />
                  <GroupPanel visible={true} /> {/* or "auto" */}
                  <FilterPanel visible={true} />
                  <FilterBuilderPopup position={"top"} />
                  <StateStoring
                    enabled={true}
                    type="localStorage"
                    storageKey={GridType.Street.toString()}
                    savingTimeout={500}
                  />
                  <SortByGroupSummaryInfo
                    summaryItem="Total Count"
                    sortOrder="desc"
                  />
                  <Summary>
                    <GroupItem
                      summaryType="count"
                      alignByColumn
                      name="Total Count"
                    />
                  </Summary>
                  <Paging enabled={true} defaultPageSize={100} />
                  <Pager
                    showPageSizeSelector={true}
                    allowedPageSizes={[100, 200, 300, 400, 500]}
                    showNavigationButtons={true}
                    showInfo={true}
                    visible={true}
                  />
                  <FilterRow visible={true} applyFilter="auto" />
                  <HeaderFilter visible={true} />
                  <SearchPanel visible={true} width={285} placeholder={t("search...")} />
                  <ColumnChooser width={350} height={400} enabled={true} mode="select" sortOrder="asc">
                    <Search enabled />
                  </ColumnChooser>
                  <Column dataField="Name" width={'90%'} caption={t("name")}>
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>

                  {initDataFields.map((d) => {
                    if (d.fieldType === FieldType.List) {
                      return (
                        <Column
                          key={d.name}
                          dataField={d.name}
                          caption={d.labelText}
                          allowFiltering={true}
                          visible={(EnumFlag2Array(FieldLocation2Show, d.fieldLocation2Show).some(x => x === FieldLocation2Show.GridView)) ? true : false}
                        >
                          <Search enabled />
                          <Lookup
                            dataSource={SortObjectByPropName(d.fieldValues, "name")}
                            valueExpr="id"
                            displayExpr="name"
                          />
                          <HeaderFilter>
                            <Search enabled />
                          </HeaderFilter>
                        </Column>
                      );
                    } else if (d.fieldType === FieldType.Number) {
                      return (
                        <Column
                          key={d.name}
                          dataField={d.name}
                          caption={d.labelText}
                          dataType="number"
                          alignment="left"
                          visible={(EnumFlag2Array(FieldLocation2Show, d.fieldLocation2Show).some(x => x === FieldLocation2Show.GridView)) ? true : false}
                        >
                          <HeaderFilter>
                            <Search enabled />
                          </HeaderFilter>
                        </Column>
                      );
                    } else if (d.fieldType === FieldType.Date) {
                      return (
                        <Column
                          key={d.name}
                          dataField={d.name}
                          caption={d.labelText}
                          dataType="date"
                          calculateCellValue={(e: any) => GridActualDateCalculator(e, d, generalSetting)}
                          format={generalSetting?.dateFormat}
                          visible={(EnumFlag2Array(FieldLocation2Show, d.fieldLocation2Show).some(x => x === FieldLocation2Show.GridView)) ? true : false}
                        >
                          <HeaderFilter>
                            <Search enabled />
                          </HeaderFilter>
                        </Column>
                      );
                    } else if (d.fieldType === FieldType.Time) {
                      return (
                        <Column
                          key={d.name}
                          dataField={d.name}
                          caption={d.labelText}
                          dataType="datetime"
                          calculateCellValue={(e: any) => GridActualDateCalculator(e, d, generalSetting)}
                          format={generalSetting?.timeFormat}
                          visible={(EnumFlag2Array(FieldLocation2Show, d.fieldLocation2Show).some(x => x === FieldLocation2Show.GridView)) ? true : false}
                        >
                          <HeaderFilter>
                            <Search enabled />
                          </HeaderFilter>
                        </Column>
                      );
                    } else if (d.fieldType === FieldType.DateTime) {
                      return (
                        <Column
                          key={d.name}
                          dataField={d.name}
                          caption={d.labelText}
                          dataType="datetime"
                          calculateCellValue={(e: any) => GridActualDateCalculator(e, d, generalSetting)}
                          format={generalSetting?.dateTimeFormat}
                          visible={(EnumFlag2Array(FieldLocation2Show, d.fieldLocation2Show).some(x => x === FieldLocation2Show.GridView)) ? true : false}
                        >
                          <HeaderFilter>
                            <Search enabled />
                          </HeaderFilter>
                        </Column>
                      );
                    } else if (d.fieldType === FieldType.Boolean) {
                      return (
                        <Column
                          key={d.name}
                          dataField={d.name}
                          caption={d.labelText}
                          dataType="boolean"
                          visible={(EnumFlag2Array(FieldLocation2Show, d.fieldLocation2Show).some(x => x === FieldLocation2Show.GridView)) ? true : false}
                        >
                          <HeaderFilter>
                            <Search enabled />
                          </HeaderFilter>
                        </Column>
                      );
                    } else {
                      return (
                        <Column
                          key={d.name}
                          dataField={d.name}
                          caption={d.labelText}
                          allowHeaderFiltering={false}
                          visible={(EnumFlag2Array(FieldLocation2Show, d.fieldLocation2Show).some(x => x === FieldLocation2Show.GridView)) ? true : false}
                        >
                          <Search enabled />
                          <HeaderFilter>
                            <Search enabled />
                          </HeaderFilter>

                        </Column>
                      );
                    }
                  })}
                  <Column type="buttons" alignment={'right'} caption={t("actions")} width={110} fixed={true} fixedPosition="right">

                    <DevExpressButton
                      hint={t("edit")}
                      onClick={onEdit}
                      icon="fa-solid fa-pencil"
                    ></DevExpressButton>
                    {/* <DevExpressButton
                  hint={t("delete")}
                  onClick={onDelete}
                  icon="fa-solid fa-trash-can"
                ></DevExpressButton> */}
                  </Column>
                  <Column
                    dataField="DivisionId"
                    allowSorting={true}
                    visible={false}
                    caption={t("division")}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                    <Lookup
                      dataSource={SortObjectByPropName(customerDivisions!, "name")}
                      valueExpr="id"
                      displayExpr="name"
                    />
                  </Column>
                </DataGrid>
              )}
            </div>
          </div>

          <Popup
            width={"50%"}
            height={"auto"}
            visible={showModal}
            resizeEnabled={true}
            showTitle={true}
            title={modalMode === ModalMode.Add ? t("add") : t("update")}
            hideOnOutsideClick={false}
            showCloseButton={true}
            onHiding={() => onCloseModal()}
          >
            <ScrollView width="100%" height="100%">
              <Form colCount={12}>
                <SimpleItem colSpan={11}>
                  <div style={{ marginLeft: "-1rem" }}>
                    <TextBox
                      label={t("name")}
                      labelMode="static"
                      value={selectedData?.Name}
                      onValueChange={(e) => onValueChanged("Name", e)}
                      className="modalInput"
                    />
                  </div>
                </SimpleItem>
                <SimpleItem colSpan={1}>
                  <div>{t("inactive")}</div>
                  <Switch
                    style={{ marginTop: 5, fontWeight: "bold" }}
                    value={selectedData?.inactive}
                    onValueChange={(e) => onValueChanged("Inactive", e)}
                    hint={t("inactive")}
                  />
                </SimpleItem>
                {initDataFields &&
                  // eslint-disable-next-line array-callback-return
                  initDataFields.map((f, i) => {
                    if (f.fieldType === FieldType.String) {
                      return (
                        <TextBox
                          style={{ marginBottom: 20 }}
                          label={f.labelText}
                          labelMode="static"
                          value={selectedData[f.name]}
                          onValueChange={(e) => onValueChanged(f.name, e)}
                          key={f.id}
                          name={f.name}
                        />
                      );
                    }
                    if (f.fieldType === FieldType.Number) {
                      return (
                        <TextBox
                          style={{ marginBottom: 20 }}
                          label={f.labelText}
                          labelMode="static"
                          value={selectedData[f.name]}
                          onValueChange={(e) => onValueChanged(f.name, e)}
                          key={f.id}
                          name={f.name}
                        />
                      );
                    }

                    if (f.fieldType === FieldType.Boolean) {
                      return (
                        <div>
                          <div>{f.labelText}</div>
                          <Switch
                            value={selectedData[f.name]}
                            onValueChange={(e) => onValueChanged(f.name, e)}
                            key={f.id}
                            name={f.name}
                          />
                        </div>
                      );
                    }

                    if (f.fieldType === FieldType.Date) {
                      return (
                        <DateBox
                          label={f.labelText}
                          type="date"
                          value={selectedData[f.name]}
                          onValueChange={(e) => onValueChanged(f.name, e)}
                          key={f.id}
                          name={f.name}
                          pickerType="calendar"
                          placeholder={generalSetting?.dateFormat}
                          displayFormat={generalSetting?.dateFormat}
                          useMaskBehavior={true}
                          openOnFieldClick={true}
                          showClearButton={true}
                        />
                      );
                    }

                    if (f.fieldType === FieldType.Time) {
                      return (
                        <DateBox
                          label={f.labelText}
                          type="time"
                          value={selectedData[f.name]}
                          onValueChange={(e) => onValueChanged(f.name, e)}
                          key={f.id}
                          name={f.name}
                          pickerType="rollers"
                          placeholder={generalSetting?.timeFormat}
                          displayFormat={generalSetting?.timeFormat}
                          useMaskBehavior={true}
                          openOnFieldClick={true}
                          showClearButton={true}
                        />
                      );
                    }

                    if (f.fieldType === FieldType.DateTime) {
                      return (
                        <DateBox
                          label={f.labelText}
                          type="datetime"
                          value={selectedData[f.name]}
                          onValueChange={(e) => onValueChanged(f.name, e)}
                          key={f.id}
                          name={f.name}
                          pickerType="calendar"
                          placeholder={generalSetting?.dateTimeFormat}
                          displayFormat={generalSetting?.dateTimeFormat}
                          useMaskBehavior={true}
                          openOnFieldClick={true}
                          showClearButton={true}
                        />
                      );
                    }

                    if (f.fieldType === FieldType.List) {
                      return (
                        <SelectBox
                          label={f.labelText}
                          items={SortObjectByPropName(f.fieldValues, "name")}
                          displayExpr="name"
                          valueExpr="id"
                          placeholder=""
                          value={selectedData[f.name]}
                          onValueChange={(e) => onValueChanged(f.name, e)}
                          key={f.id}
                          name={f.name}
                          showClearButton={true}
                          searchEnabled={true}
                        />
                      );
                    }
                  })}
              </Form>
              <div>
                <div className="rightColumn" style={{ marginTop: "1.5rem" }}>
                  <Button
                    className="tes-modal-btn-cancel"
                    style={{ marginRight: 20 }}
                    onClick={() => onCloseModal()}
                    text={t("cancel")}
                  />
                  {modalMode === ModalMode.Add ? (
                    <Button
                      className="tes-modal-btn-add"
                      onClick={onAdd}
                      text={t("add")}
                    />
                  ) : (
                    <Button
                      className="tes-modal-btn-add"
                      onClick={onUpdate}
                      text={t("update")}
                    />
                  )}
                </div>
              </div>
            </ScrollView>
          </Popup>
        </React.Fragment>
      </div>
    </Permission>
  );
};
export default Streets;
