import { Button, DataGrid, NumberBox, Popup, ScrollView, SelectBox, Switch, ValidationGroup, Validator } from "devextreme-react";
import { ValidationGroupRef } from "devextreme-react/cjs/validation-group";
import { Column, ColumnChooser, DataGridRef, Button as DevExpressButton, Export, FilterBuilderPopup, FilterPanel, FilterRow, Grouping, GroupItem, GroupPanel, HeaderFilter, Lookup, Pager, Paging, Search, SearchPanel, SortByGroupSummaryInfo, Summary } from "devextreme-react/data-grid";
import { Form, RequiredRule, SimpleItem } from "devextreme-react/form";
import notify from "devextreme/ui/notify";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../../../../contexts/auth";
import { ModalMode, Month } from "../../../../../../types/general/enums/generalEnums";
import { NameValue } from "../../../../../../types/general/generalTypes";
import { AADTHourOfYear, AADTPattern } from "../../../../../../types/trafficStudy/trafficStudyTypes";
import { SortObjectByPropName } from "../../../../../../utils/arrayTools";
import { OnExporting } from "../../../../../../utils/dataGridTools";
import { Enum2Array } from "../../../../../../utils/enumTools";

// props
interface IPros {
  initData: AADTPattern,
  setInitData: React.Dispatch<React.SetStateAction<any>>;
  isLocked: boolean;
  setDataChanged: React.Dispatch<React.SetStateAction<any>>;
  validationRef: React.RefObject<ValidationGroupRef>;
}

const MonthFactors = (props: IPros) => {
  const { t } = useTranslation();
  const dataGridRef = useRef<DataGridRef<any, any>>(null);
  const [showModal, setShowModal] = useState(false);
  const [modalMode, setModalMode] = useState<ModalMode>(ModalMode.Add);
  const [selectedData, setSelectedData] = useState<AADTHourOfYear>(new AADTHourOfYear());
  const [lstMonthOfYear, setLstMonthOfYear] = useState<NameValue[]>([])
  const [selectedRowIndex, setSelectedRowIndex] = useState<number>(0)
  const { activeLoading } = useAuth();

  useEffect(() => {
    setLstMonthOfYear(Enum2Array(Month));
  }, []);

  function onValueChange(name: string, value: any) {
    setSelectedData({ ...selectedData, [name]: value })
    props.setDataChanged(true)
  }

  function onNew() {
    setModalMode(ModalMode.Add);
    setShowModal(true);
  }

  function onCloseModal() {
    if (modalMode === ModalMode.Add) {
      setShowModal(false);
      setSelectedData(new AADTHourOfYear());
    } else {
      setSelectedData(new AADTHourOfYear());
      setShowModal(false);
    }
  }

  function onAdd() {
    try {
      const validationRes = props.validationRef.current?.instance().validate();
      if (validationRes?.isValid) {
        if (props.initData.monthFactors.some(x => x?.monthOfYear === selectedData.monthOfYear && x?.isDefault === true && selectedData.isDefault === true)) {
          notify(t("thereIsAnotherDefaultMonthForThisMonth"), "error", 5000); return
        }
        props.setInitData({ ...props.initData, monthFactors: [...props.initData.monthFactors, selectedData] })
        dataGridRef.current?.instance().refresh()
        onCloseModal()
      }
    } catch (ex) {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred") + ex, "error", 5000);
    }

  }

  function onEdit(d: any) {
    setSelectedRowIndex(d.row.dataIndex)
    let newData = new AADTHourOfYear();
    Object.assign(newData, d.row.data);
    setModalMode(ModalMode.Edit);
    setSelectedData(newData);
    setShowModal(true);
  }

  function onUpdate() {
    try {
      const validationRes = props.validationRef.current?.instance().validate();
      if (validationRes?.isValid) {
        if (props.initData.monthFactors.some(x => x?.monthOfYear === selectedData.monthOfYear && x?.isDefault === true && selectedData.isDefault === true)) {
          notify(t("thereIsAnotherDefaultMonthForThisMonth"), "error", 5000); return
        }
        props.setInitData({ ...props.initData, monthFactors: [...props.initData.monthFactors.map((x, index) => { return index === selectedRowIndex ? selectedData : x })] })
        onCloseModal()
      }
    } catch (ex) {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred") + ex, "error", 5000);
    }

  }

  function onDelete(d: any) {
    props.setInitData({ ...props.initData, monthFactors: [...props.initData.monthFactors.splice(d.row.dataIndex, 1)] })
    props.setInitData(props.initData)
  }

  return (
    <React.Fragment>
      <ValidationGroup ref={props.validationRef}>
        <h2 className={"content-block"}>{t("monthFactors")}</h2>
        <div className={"content-block"}>
          <div className={"dx-card responsive-paddings"}>
            <div className="row">
              <div className="rightColumn">
                <Button
                  onClick={onNew}
                  icon="fa-solid fa-circle-plus"
                  text={t("monthFactor")}
                  disabled={props.isLocked}
                />
              </div>
            </div>
            <DataGrid
              id="gridContainer"
              ref={dataGridRef}
              dataSource={props.initData.monthFactors}
              rowAlternationEnabled={true}
              showBorders={true}
              hoverStateEnabled={true}
              remoteOperations={true}
              allowColumnReordering={true}
              allowColumnResizing={true}
              columnAutoWidth={true}
              style={{ margin: "0 1rem" }}
              onExporting={OnExporting}
            >
              <Export enabled={true} allowExportSelectedData={true} />
              <Grouping contextMenuEnabled={true} autoExpandAll={false} />
              <GroupPanel visible={true} /> {/* or "auto" */}
              <FilterPanel visible={true} />
              <FilterBuilderPopup position={"top"} />
              <Paging enabled={true} defaultPageSize={100} />
              <Pager
                showPageSizeSelector={true}
                allowedPageSizes={[100, 200, 300, 400, 500]}
                showNavigationButtons={true}
                showInfo={true}
                visible={true}
              />
              <FilterRow visible={true} applyFilter="auto" />
              <HeaderFilter visible={true} />
              <SearchPanel visible={true} width={285} placeholder={t("search...")} />
              <ColumnChooser width={350} height={400} enabled={true} mode="select" sortOrder="asc">
                <Search enabled />
              </ColumnChooser>
              <SortByGroupSummaryInfo
                summaryItem="Total Count"
                sortOrder="desc"
              />
              <Summary>
                <GroupItem
                  summaryType="count"
                  alignByColumn
                  name="Total Count"
                />
              </Summary>
              <Column dataField="year" caption={t("year")}>
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column dataField="monthOfYear" caption={t("monthOfYear")} visible={true}>
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
                <Lookup dataSource={SortObjectByPropName(lstMonthOfYear, "name")} valueExpr="value" displayExpr="name" />
              </Column>
              <Column dataField="monthFactor" caption={t("monthFactor")} visible={true}>
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column dataField="isDefault" caption={t("isDefault")}>
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column type="buttons" caption={t("actions")} width={110} visible={!props.isLocked} fixed={true} fixedPosition="right">
                <DevExpressButton
                  hint={t("edit")}
                  onClick={onEdit}
                  icon="fa-solid fa-pencil"
                ></DevExpressButton>
                <DevExpressButton
                  hint={t("delete")}
                  onClick={onDelete}
                  icon="fa-solid fa-trash-can"
                ></DevExpressButton>
              </Column>
            </DataGrid>
          </div>
        </div>

        <Popup
          width={"50%"}
          height={"auto"}
          visible={showModal}
          resizeEnabled={true}
          showTitle={true}
          title={modalMode === ModalMode.Add ? t("add") : t("update")}
          showCloseButton={true}
          onHiding={() => onCloseModal()}
        >
          <ScrollView width="100%" height="100%">
            <Form colCount={2} className='popupFields'>
              <SimpleItem colSpan={1}>
                <NumberBox
                  step={0}
                  label={t("year")}
                  labelMode="static"
                  value={selectedData.year}
                  onValueChange={(e) => onValueChange("year", e)}
                  className="modalInput"
                >
                  <Validator>
                    <RequiredRule message={t("year")} />
                  </Validator>
                </NumberBox>
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <NumberBox
                  step={0}
                  label={t("monthFactor")}
                  labelMode="static"
                  value={selectedData.monthFactor}
                  onValueChange={(e) => onValueChange("monthFactor", e)}
                  className="modalInput"
                >
                  <Validator>
                    <RequiredRule message={t("monthFactor")} />
                  </Validator>
                </NumberBox>
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <SelectBox
                  placeholder=""
                  label={t("monthOfYear")}
                  labelMode="static"
                  value={selectedData.monthOfYear}
                  onValueChange={(e) => onValueChange("monthOfYear", e)}
                  className="modalInput"
                  valueExpr="value"
                  displayExpr="name"
                  items={SortObjectByPropName(lstMonthOfYear, "name")}
                  showClearButton={true}
                  searchEnabled={true}
                >
                  <Validator>
                    <RequiredRule message={t("fieldIsRequired")} />
                  </Validator>
                </SelectBox>
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <div style={{ marginBottom: "0.25rem" }}>{t("isDefault")}</div>
                <Switch
                  value={selectedData.isDefault}
                  onValueChange={e => onValueChange("isDefault", e)}
                  hint={t("isDefault")}
                />
              </SimpleItem>
            </Form>
            <div>
              <div className="rightColumn">
                <Button
                  className="tes-modal-btn-cancel"
                  style={{ marginRight: 20 }}
                  onClick={() => onCloseModal()}
                  text={t("cancel")}
                />
                {modalMode === ModalMode.Add ? (
                  <Button
                    className="tes-modal-btn-add"
                    onClick={onAdd}
                    text={t("add")}
                  />
                ) : (
                  <Button
                    className="tes-modal-btn-add"
                    onClick={onUpdate}
                    text={t("update")}
                  />
                )}
              </div>
            </div>
          </ScrollView>
        </Popup>
      </ValidationGroup>
    </React.Fragment>
  );
};
export default MonthFactors;
