//#region imports
import { Button } from "devextreme-react/button";
import DataGrid, {
  Column,
  ColumnChooser,
  DataGridRef,
  Button as DevExpressButton,
  Export,
  FilterBuilderPopup,
  FilterPanel,
  FilterRow,
  Grouping,
  GroupPanel,
  HeaderFilter,
  Lookup,
  Pager,
  Paging,
  Search,
  SearchPanel,
} from "devextreme-react/data-grid";
import { DateBox } from "devextreme-react/date-box";
import { Form, SimpleItem } from "devextreme-react/form";
import {
  NumberBox,
  Button as NumberBoxButton,
} from "devextreme-react/number-box";
import { Popup } from "devextreme-react/popup";
import ScrollView from "devextreme-react/scroll-view";
import TextBox from "devextreme-react/text-box";
import { dxButtonOptions } from "devextreme/ui/button";
import { custom } from "devextreme/ui/dialog";
import notify from "devextreme/ui/notify";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Permission from "../../../components/permission/permision";
import { MaintenancePermissions } from "../../../constants/Permissions";
import { useAuth } from "../../../contexts/auth";
import { useClientSetting } from "../../../contexts/clientSetting";
import { MaintenanceApiUrl } from "../../../environment/routeSettings";
import { ModalMode } from "../../../types/general/enums/generalEnums";
import { GridSetting } from "../../../types/general/generalTypes";
import {
  Cost,
  Equipment,
  Personnel as VMPersonnel,
} from "../../../types/maintenance/maintenanceTypes";
import { SortObjectByPropName } from "../../../utils/arrayTools";
import { OnExporting } from "../../../utils/dataGridTools";
import { useScreenSize } from "../../../utils/media-query";
import { TesDelete, TesGet, TesPost, TesPut } from "../../../utils/rest";
import "./equipments.scss";
//#endregion imports
const Equipments = () => {
  //#region consts
  const [initDataEquipments, setInitDataEquipments] = useState<Equipment[]>([]);
  const [selectedEquipment, setSelectedEquipment] = useState<
    Partial<Equipment>
  >(new Equipment());
  const [initDataCosts, setInitDataCosts] = useState<Cost[]>([new Cost()]);
  const [selectedCost, setSelectedCost] = useState<Cost>(new Cost());
  const dataGridEquipmentRef = useRef<DataGridRef<any, any>>(null);
  const dataGridCostRef = useRef<DataGridRef<any, any>>(null);
  const [showModal, setShowModal] = useState(false);
  const [showModalCosts, setShowModalCosts] = useState(false);
  const [modalMode, setModalMode] = useState<ModalMode>(ModalMode.Add);
  const [modalModeCost, setmodalModeCost] = useState<ModalMode>(ModalMode.Add);
  const [showCosts, setShowCosts] = useState(false);
  const [equipmentId, setEquipmentId] = useState<string>();
  const { activeLoading } = useAuth();
  const { t } = useTranslation();
  const { isXLarge, is2xLarge } = useScreenSize();
  const [costGridHeight, setCostGridHeight] = useState<number>(360)
  const [costPopupHeight, setCostPopupHeight] = useState<string>("80%")
  const [gridSetting, setGridSetting] = useState<GridSetting>(JSON.parse((localStorage.getItem("GridSetting")!)) || new GridSetting)
  const compactViewModel: boolean = (JSON.parse(localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}') || {}).viewMode === "compact";
  const { generalSetting, customerDivisions } = useClientSetting();
  //#endregion consts


  useEffect(() => {
    if (isXLarge) { setCostGridHeight(530) }
    else if (is2xLarge) { setCostGridHeight(870); setCostPopupHeight("50%") }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        await getInitDataEquipments();
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //#region functions
  //Get InitData
  async function getInitDataEquipments() {
    setInitDataEquipments(
      await TesGet(
        MaintenanceApiUrl() +
        "/api/equipments/" +
        localStorage.getItem("selectedCustomerId"),
        true
      )
    );
  }
  // Equipment Functions
  function onEdit(d: any) {
    let selectedData = new VMPersonnel();
    Object.assign(selectedData, d.row.data);
    setModalMode(ModalMode.Edit);
    setSelectedEquipment(selectedData);
    setShowModal(true);
  }
  function onNew() {
    setModalMode(ModalMode.Add);
    setShowModal(true);
  }
  const onValueChanged = (key: string, value: any) => {
    setSelectedEquipment({ ...selectedEquipment, [key]: value });
  };
  function onCloseModal() {
    if (modalMode === ModalMode.Add) {
      setShowModal(false);
    } else {
      setSelectedEquipment(new VMPersonnel());
      setShowModal(false);
    }
  }
  async function onAdd() {
    const postObj = {
      ...selectedEquipment,
      customerId: localStorage.getItem("selectedCustomerId"),
    };
    try {
      if (activeLoading) activeLoading(true);
      await TesPost(MaintenanceApiUrl() + "/api/equipments", postObj, true);
      await getInitDataEquipments();
      onCloseModal();
      if (activeLoading) activeLoading(false);
      notify(t("dataSuccessfullyAdded"), "success", 5000);
    } catch {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred"), "error", 5000);
    }
  }
  async function onUpdate() {
    try {
      if (activeLoading) activeLoading(true);
      await TesPut(
        MaintenanceApiUrl() + "/api/equipments/" + selectedEquipment.id,
        selectedEquipment,
        true
      );
      await getInitDataEquipments();
      onCloseModal();
      notify(t("dataSuccessfullyUpdated"), "success", 5000);
      if (activeLoading) activeLoading(false);
    } catch (ex) {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred" + ex), "error", 5000);
    }
  }
  async function onDelete(d: any) {
    let myDialog = custom({
      title: t("deleteData"),
      messageHtml: t("areYouSureWantToDelete?"),
      buttons: [
        {
          text: t("yes"),
          onClick: async (e) => {
            try {
              await TesDelete(
                MaintenanceApiUrl() + "/api/equipments/" + d.row.data.id,
                true
              );
              await getInitDataEquipments();
              notify(t("dataSuccessfullyDeleted"), "success", 5000);
            } catch {
              notify(t("someErrorOccurred"), "error", 5000);
            }

            return { buttonText: e.component.option("text") };
          },
        },
        {
          text: t("no"),
          onClick: (e) => {
            return { buttonText: e.component.option("text") };
          },
        },
      ],
    });
    myDialog.show();
  }
  //Costs functions
  function onEditCost(d: any) {
    let newData = new Cost();
    Object.assign(newData, d.row.data);
    setmodalModeCost(ModalMode.Edit);
    setSelectedCost(newData);
    setShowModalCosts(true);
  }


  function onNewCost() {
    setmodalModeCost(ModalMode.Add);
    setShowModalCosts(true);
    setSelectedCost(new Cost());
  }
  function onCloseModalCost() {
    if (modalMode === ModalMode.Add) {
      setShowModalCosts(false);
      setSelectedCost(new Cost());
    } else {
      setShowModalCosts(false);
      setSelectedCost(new Cost());
    }
  }

  const onValueChangedCost = (key: string, value: any) => {
    setSelectedCost({ ...selectedCost, [key]: value });
  };

  function dateRangeOverlaps(
    a_start: Date | undefined,
    a_end: Date | undefined,
    b_start: Date | undefined,
    b_end: Date | undefined
  ) {
    return (
      (a_start == null || b_end == null || a_start <= b_end) &&
      (b_start == null || a_end == null || b_start <= a_end) &&
      (a_start == null || a_end == null || a_start <= a_end) &&
      (b_start == null || b_end == null || b_start <= b_end)
    );
  }
  async function onAddCost() {
    try {
      const postObj = {
        ...selectedCost,
        equipmentId: equipmentId,
      };
      if (
        postObj.fromDate &&
        postObj.toDate &&
        postObj.fromDate.getTime() > postObj.toDate.getTime()
      ) {
        notify(t("validDataRange"), "error", 5000);
        return;
      }
      var hasOverlap = false;
      initDataCosts.forEach((c) => {
        if (!hasOverlap) {
          dateRangeOverlaps(
            postObj.fromDate,
            postObj.toDate,
            c.fromDate,
            c.toDate
          );
          notify(t("overLapDateRange"), "error", 5000);
        }
      });
      if (hasOverlap) {
        return;
      }
      if (activeLoading) activeLoading(true);
      const res: Cost[] = await TesPost(MaintenanceApiUrl() + "/api/costs", postObj, true);
      setInitDataCosts(res);
      dataGridCostRef.current?.instance().refresh();
      onCloseModalCost();
      if (activeLoading) activeLoading(false);
      notify(t("dataSuccessfullyAdded"), "success", 5000);
    } catch {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred"), "error", 5000);
    }
  }
  async function onUpdateCost() {
    if (
      selectedCost.fromDate &&
      selectedCost.toDate &&
      new Date(selectedCost.fromDate).getTime() >
      new Date(selectedCost.toDate).getTime()
    ) {
      notify(t("validDataRange"), "error", 5000);
      return;
    }
    var hasOverlap = false;
    initDataCosts.forEach((c) => {
      if (!hasOverlap && c.id !== selectedCost.id) {
        dateRangeOverlaps(
          selectedCost.fromDate,
          selectedCost.toDate,
          c.fromDate,
          c.toDate
        );
        notify(t("overLapDateRange"), "error", 5000);
      }
    });
    if (hasOverlap) {
      return;
    }
    try {
      if (activeLoading) activeLoading(true);
      await TesPut(
        MaintenanceApiUrl() + "/api/costs/" + selectedCost.id,
        selectedCost,
        true
      );
      //update the cost
      var index = initDataCosts.findIndex((x) => x?.id === selectedCost.id);
      let items = [...initDataCosts];
      items[index] = selectedCost;

      var equIndex = initDataEquipments.findIndex((x) => x?.id === equipmentId);
      let equItems = [...initDataEquipments];
      equItems[equIndex].costs = items;


      setInitDataCosts(items);
      onCloseModalCost();
      dataGridCostRef.current?.instance().refresh();
      notify(t("dataSuccessfullyUpdated"), "success", 5000);
      if (activeLoading) activeLoading(false);
    } catch (ex) {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred" + ex), "error", 5000);
    }
  }

  async function onOpenCosts(data: any) {
    setSelectedCost(data);
    setEquipmentId(data.id)
    setInitDataCosts(data.costs)
    //setInitDataCosts(await TesGet(MaintenanceApiUrl() + "/api/costs/equipmentCosts/" + data.id, true));
    dataGridCostRef.current?.instance().refresh();
    setShowCosts(true);
  }

  function calculateCostsListCell(d: any) {
    return (
      <Button
        onClick={() => onOpenCosts(d.data)}
        icon="fa-solid fa-circle-plus"
        text={t("costs")}
      />
    );
  }
  const numberBoxButton: dxButtonOptions = {
    icon: "fa-solid fa-dollar-sign",
    type: "default",
  };

  //#endregion functions

  return (
    <Permission
      allowed={[MaintenancePermissions.Maintenance_D_Equipment]}
      hasFeedBackElement={true}
    >
      <div className={compactViewModel ? "compactStyle" : ""}>
        <React.Fragment>
          <h2 className={"content-block"}>{t("equipments")}</h2>
          <div className={"content-block"}>
            <div className={"dx-card responsive-paddings"}>
              <div className="row">
                {/* <div className="leftColumn" style={{ display: "flex" }}>
                  <GridProfilePicker
                    customerId={localStorage.getItem("selectedCustomerId")!}
                    gridType={GridType.}
                    dataGridRef={dataGridEquipmentRef}
                    setGridSetting={setGridSetting}
                  />
                </div> */}
                <div className="rightColumn">
                  <Button onClick={onNew} icon="fa-solid fa-circle-plus" text={t("add")} />

                </div>
              </div>
              <DataGrid
                id="gridContainer"
                ref={dataGridEquipmentRef}
                dataSource={initDataEquipments}
                rowAlternationEnabled={true}
                showBorders={true}
                hoverStateEnabled={true}
                remoteOperations={true}
                allowColumnReordering={true}
                allowColumnResizing={true}
                style={{ margin: "0 1rem" }}
                onExporting={OnExporting}
                columnAutoWidth={gridSetting.horizontalScrolling}
              >
                <Export enabled={true} allowExportSelectedData={true} />
                <Grouping contextMenuEnabled={true} autoExpandAll={false} />
                <GroupPanel visible={true} /> {/* or "auto" */}
                <FilterPanel visible={true} />
                <FilterBuilderPopup position={"top"} />

                <Paging enabled={true} defaultPageSize={100} />
                <Pager
                  showPageSizeSelector={true}
                  allowedPageSizes={[100, 200, 300, 400, 500]}
                  showNavigationButtons={true}
                  showInfo={true}
                  visible={true}
                />
                <FilterRow visible={true} applyFilter="auto" />
                <HeaderFilter visible={true} />
                <SearchPanel visible={true} width={285} placeholder={t("search...")} />
                <ColumnChooser width={350} height={400} enabled={true} mode="select" sortOrder="asc">
                  <Search enabled />
                </ColumnChooser>
                <Column dataField="name" caption={t("name")}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  alignment="left"
                  cellRender={calculateCostsListCell}
                  caption={t("costs")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  type="buttons"
                  caption={t("actions")}
                  fixed={true}
                  fixedPosition="right"
                //width={150}
                >
                  <DevExpressButton
                    hint={t("edit")}
                    onClick={onEdit}
                    icon="fa-solid fa-pencil"
                  ></DevExpressButton>
                  <DevExpressButton
                    hint={t("delete")}
                    onClick={onDelete}
                    icon="fa-solid fa-trash-can"
                  ></DevExpressButton>
                </Column>
              </DataGrid>
            </div>
          </div>
          {/* Modal for adding/editing Equipment */}
          <Popup
            width={"50%"}
            height={"auto"}
            visible={showModal}
            resizeEnabled={true}
            showTitle={true}
            title={modalMode === ModalMode.Add ? t("add") : t("update")}
            hideOnOutsideClick={false}
            showCloseButton={true}
            onHiding={() => onCloseModal()}
          >
            <ScrollView width="100%" height="100%">
              <Form colCount={2}>
                <SimpleItem colSpan={1}>
                  <TextBox
                    label={t("name")}
                    labelMode="static"
                    value={selectedEquipment.name}
                    onValueChange={(e) => onValueChanged("name", e)}
                    className="modalInput"
                  />
                </SimpleItem>
                <SimpleItem colSpan={1}>
                  <TextBox
                    label={t("note")}
                    labelMode="static"
                    value={selectedEquipment.note}
                    onValueChange={(e) => onValueChanged("note", e)}
                    className="modalInput"
                  />
                </SimpleItem>
              </Form>
              <div>
                <div className="rightColumn">
                  <Button
                    className="tes-modal-btn-cancel"
                    style={{ marginRight: 20 }}
                    onClick={() => onCloseModal()}
                    text={t("cancel")}
                  />
                  {modalMode === ModalMode.Add ? (
                    <Button
                      className="tes-modal-btn-add"
                      onClick={onAdd}
                      text={t("add")}
                    />
                  ) : (
                    <Button
                      className="tes-modal-btn-add"
                      onClick={onUpdate}
                      text={t("update")}
                    />
                  )}
                </div>
              </div>
            </ScrollView>
          </Popup>

          {/* Modal for showing/choosing Costs */}
          <Popup
            width={"65%"}
            height={costPopupHeight}
            visible={showCosts}
            resizeEnabled={true}
            showTitle={true}
            title={t("costs")}
            hideOnOutsideClick={false}
            showCloseButton={true}
            onHiding={() => setShowCosts(false)}
          >
            <div style={{ height: "80%" }}>
              <div className="row">
                <div className="rightColumn">
                  <Button
                    onClick={() => onNewCost()}
                    icon="fa-solid fa-circle-plus"
                    text={t("add")}
                  />
                </div>
              </div>
              <DataGrid
                id="gridContainer"
                ref={dataGridCostRef}
                dataSource={initDataCosts}
                rowAlternationEnabled={true}
                showBorders={true}
                hoverStateEnabled={true}
                remoteOperations={true}
                allowColumnReordering={true}
                allowColumnResizing={true}
                columnAutoWidth={true}
                height={costGridHeight}
                onExporting={OnExporting}
              >
                <Export enabled={true} allowExportSelectedData={true} />
                <Grouping contextMenuEnabled={true} autoExpandAll={false} />
                <GroupPanel visible={true} /> {/* or "auto" */}
                <FilterPanel visible={true} />
                <FilterBuilderPopup position={"top"} />

                <Paging enabled={true} defaultPageSize={100} />
                <Pager
                  showPageSizeSelector={true}
                  allowedPageSizes={[100, 200, 300, 400, 500]}
                  showNavigationButtons={true}
                  showInfo={true}
                  visible={true}
                />
                <FilterRow visible={true} applyFilter="auto" />
                <HeaderFilter visible={true} />
                <SearchPanel visible={true} width={285} placeholder={t("search...")} />
                <ColumnChooser width={350} height={400} enabled={true} mode="select" sortOrder="asc">
                  <Search enabled />
                </ColumnChooser>
                <Column alignment="left" dataField="price" caption={t("cost")}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="fromDate"
                  dataType="date"
                  format={generalSetting?.dateFormat}
                  caption={t("from")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="toDate"
                  dataType="date"
                  format={generalSetting?.dateFormat}
                  caption={t("to")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="DivisionId"
                  allowSorting={true}
                  visible={false}
                  caption={t("division")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                  <Lookup
                    dataSource={SortObjectByPropName(customerDivisions!, "name")}
                    valueExpr="id"
                    displayExpr="name"
                  />
                </Column>
                <Column
                  type="buttons"
                  caption={t("actions")}
                  fixed={true}
                  fixedPosition="right"
                  width={110}
                >
                  <DevExpressButton
                    hint={t("edit")}
                    onClick={onEditCost}
                    icon="fa-solid fa-pencil"
                  ></DevExpressButton>
                  <DevExpressButton
                    hint={t("delete")}
                    onClick={onDelete}
                    icon="fa-solid fa-trash-can"
                  ></DevExpressButton>
                </Column>
              </DataGrid>

              <div>
                <div className="rightColumn" style={{ marginTop: 10 }}>
                  <Button
                    className="tes-modal-btn-cancel"
                    style={{ marginRight: 20 }}
                    onClick={() => setShowCosts(false)}
                    text={t("cancel")}
                  />
                </div>
              </div>
            </div>
          </Popup>

          {/* Modal for adding/editing Costs */}
          <Popup
            width={"50%"}
            height="auto"
            visible={showModalCosts}
            resizeEnabled={true}
            showTitle={true}
            title={modalModeCost === ModalMode.Add ? t("add") : t("update")}
            hideOnOutsideClick={false}
            showCloseButton={true}
            onHiding={() => onCloseModalCost()}
          >
            <ScrollView width="100%" height="100%">
              <Form colCount={2}>
                <SimpleItem colSpan={1}>
                  <NumberBox
                    step={0}
                    label={t("cost")}
                    labelMode="static"
                    value={selectedCost.price}
                    onValueChange={(e) => onValueChangedCost("price", e)}
                    className="modalInput"
                  >
                    <NumberBoxButton
                      name="clock"
                      location="after"
                      options={numberBoxButton}
                    />
                  </NumberBox>
                </SimpleItem>
                <SimpleItem colSpan={1}>
                  <DateBox
                    label={t('from')}
                    value={selectedCost?.fromDate}
                    type="date"
                    onValueChange={(e) => onValueChangedCost("fromDate", e)}
                    pickerType="calendar"
                    placeholder={generalSetting?.dateFormat}
                    displayFormat={generalSetting?.dateFormat}
                    useMaskBehavior={true}
                    openOnFieldClick={true}
                    showClearButton={true}
                  >
                  </DateBox>
                </SimpleItem>
                <SimpleItem colSpan={1}>
                  <DateBox
                    label={t('to')}
                    value={selectedCost?.toDate}
                    type="date"
                    onValueChange={(e) => onValueChangedCost("toDate", e)}
                    pickerType="calendar"
                    placeholder={generalSetting?.dateFormat}
                    displayFormat={generalSetting?.dateFormat}
                    useMaskBehavior={true}
                    openOnFieldClick={true}
                    showClearButton={true}
                  >
                  </DateBox>
                </SimpleItem>
              </Form>
              <div>
                <div className="rightColumn">
                  <Button
                    className="tes-modal-btn-cancel"
                    style={{ marginRight: 20 }}
                    onClick={() => setShowModalCosts(false)}
                    text={t("cancel")}
                  />
                  {modalModeCost === ModalMode.Add ? (
                    <Button
                      className="tes-modal-btn-add"
                      onClick={onAddCost}
                      text={t("add")}
                    />
                  ) : (
                    <Button
                      className="tes-modal-btn-add"
                      onClick={onUpdateCost}
                      text={t("update")}
                    />
                  )}
                </div>
              </div>
            </ScrollView>
          </Popup>
        </React.Fragment>
      </div>
    </Permission>
  );
};
export default Equipments;
