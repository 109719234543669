import { Button } from "devextreme-react";
import Tabs from "devextreme-react/tabs";
import { custom } from "devextreme/ui/dialog";
import notify from "devextreme/ui/notify";
import { t } from "i18next";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ShareURL from "../../../components/shareURL/shareURL";
import { useAuth } from "../../../contexts/auth";
import { AuthApiUrl, InfrastructureApiUrl } from "../../../environment/routeSettings";
import { IClientParams } from "../../../types/setting/settingTypes";
import { TesGet, TesPost } from "../../../utils/rest";
import "./clientDetails.scss";
import Collision from "./components/collision/collision";
import Formatting from "./components/formatting/formatting";
import GISLayers from "./components/gisLayer/gisLayers";
import Identity from "./components/identity/identity";
import Infrastructure from "./components/infrastructure/infrastructure";
import Report from "./components/report/report";
import Sign from "./components/sign/sign";
import TrafficStudy from "./components/trafficStudy/trafficStudy";
import URLs from "./components/urls/urls";
import tabTitles from "./data/index";

interface InitDataItem {
  id: string; // Change the type to match the actual type
  name: string; // Change the type to match the actual type
}

const ClientDetails = () => {
  const history = useNavigate();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [dataChanged, setDataChanged] = useState(false);
  const [backPermission, setBackPermission] = useState(false);
  const params = useParams<IClientParams>();
  const { activeLoading } = useAuth();
  const [initData, setInitData] = useState<InitDataItem[]>([]);
  const headingParagraphRef = useRef<HTMLParagraphElement>(null);
  const location = useLocation();
  const url = `${window.location.origin}${location.pathname}`;


  useEffect(() => {
    async function fetchMyAPI() {
        try {
            if (activeLoading) activeLoading(true);
            await getInitData();
            if (activeLoading) activeLoading(false);
        } catch (ex) {
            if (activeLoading) activeLoading(false);
            notify(t("someErrorOccurred") + ex, "error", 5000);
        }
    }
    fetchMyAPI()
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, []);

  async function getInitData() {
    setInitData(await TesGet(AuthApiUrl() + "/api/Customers", true));
}

  //function for changing the tabs
  function onTabsSelectionChanged(args: any) {
    if (args.name === "selectedIndex") {
      setSelectedIndex(args.value);
    }
  }

  //permission functions
  function goBackPermission() {
    if (dataChanged === true) {
      let myDialog = custom({
        title: t("warning"),
        messageHtml: t("unsavedDataWarningText"),
        buttons: [
          {
            text: t("yes"),
            onClick: (e) => {
              try {
                add();
                notify(t("dataSuccessfullyUpdated"), "success", 5000);
              } catch {
                notify(t("someErrorOccurred"), "error", 5000);
              }
              return { buttonText: e.component.option("text") };
            },
          },
          {
            text: t("no"),
            onClick: (e) => {
              history(-1);
              return { buttonText: e.component.option("text") };
            },
          },
          {
            text: t("cancel"),
            onClick: (e) => {
              setBackPermission(false);
              return { buttonText: e.component.option("text") };
            },
          },
        ],
      });
      myDialog.show();
    } else {
      history(-1);
    }
  }

  //add function
  async function add() {
    const postObj = {};
    await TesPost(
      InfrastructureApiUrl() + "/api/Areas/AddArea",
      postObj,
      true
    );
  }

  const copyTextToClipboard = () => {
    if (headingParagraphRef.current) {
      const textToCopy = headingParagraphRef.current.innerText;
      navigator.clipboard.writeText(textToCopy);
      notify(t("textCopiedToClipboard"), "success", 2000);
    }
  };

  return (
    <React.Fragment>
      <div className={"content-block"}>
        <div className={"dx-card "}>
              {params.intersectionId !== "AddNew" && (
                <div style={{ marginTop: "1rem"}}>
                  <p ref={headingParagraphRef} className={"detailsHeading"} style={{ display: "inline" }}>
                    <span>{t('client')}: {initData.find(x => x.id === params.clientId)?.name}</span>
                  </p>
                  <Button
                    onClick={() => copyTextToClipboard()}
                    icon="fa-solid fa-copy"
                    hint={t("copy")}
                  />
                  <div className='rightColumn'>
                    {params.clientId !== "AddNew" && (
                      <ShareURL
                        url={url}
                      />
                    )}
                  </div>
                  <hr className="line" style={{ display: "block", marginTop: "1rem" }}></hr>
                </div>
              )}
          <Tabs
            width={"100%"}
            dataSource={tabTitles}
            selectedIndex={selectedIndex}
            onOptionChanged={onTabsSelectionChanged}
          />

          {selectedIndex === 0 && <Formatting clientId={params.clientId}/>}
          {selectedIndex === 1 && <URLs clientId={params.clientId}/>}
          {selectedIndex === 2 && <Identity clientId={params.clientId}/>}
          {selectedIndex === 3 && <Sign clientId={params.clientId}/>}
          {selectedIndex === 4 && <Infrastructure clientId={params.clientId}/>}
          {selectedIndex === 5 && <Collision clientId={params.clientId}/>}
          {selectedIndex === 6 && <TrafficStudy clientId={params.clientId}/>}
          {selectedIndex === 7 && <GISLayers clientId={params.clientId}/>}
          {selectedIndex === 8 && <Report clientId={params.clientId}/>}
        </div>
      </div>
    </React.Fragment>
  );
};
export default ClientDetails;
