//imports
import { ContextMenu, Lookup, ScrollView, TextBox } from "devextreme-react";
import { Button } from "devextreme-react/button";
import DataGrid, {
  Column,
  ColumnChooser,
  DataGridRef,
  Button as DevExpressButton,
  Export,
  FilterBuilderPopup,
  FilterPanel,
  FilterRow,
  Grouping,
  GroupPanel,
  HeaderFilter,
  Pager,
  Paging,
  Search,
  SearchPanel,
} from "devextreme-react/data-grid";
import Form, { SimpleItem } from "devextreme-react/form";
import { Popup } from "devextreme-react/popup";
import { custom } from "devextreme/ui/dialog";
import notify from "devextreme/ui/notify";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Permission from "../../../components/permission/permision";
import SafetyPermissions from "../../../constants/Permissions/SafetyPermissions";
import { useAuth } from "../../../contexts/auth";
import { useClientSetting } from "../../../contexts/clientSetting";
import { SafetyApiUrl } from "../../../environment/routeSettings";
import { ModalMode } from "../../../types/general/enums/generalEnums";
import { Workbook } from "../../../types/safety/safetyTypes";
import { SortObjectByPropName } from "../../../utils/arrayTools";
import { OnExporting } from "../../../utils/dataGridTools";
import { TesDelete, TesGet, TesPost } from "../../../utils/rest";
import { RightClickMenu } from "../../../utils/rightClickMenu";
import "./workbooks.scss";

const Workbooks = () => {
  const [initData, setInitData] = useState<Workbook[]>([]);
  const [selectedData, setSelectedData] = useState<Workbook>(new Workbook());
  const [showModal, setShowModal] = useState(false);
  const [modalMode, setModalMode] = useState<ModalMode>(ModalMode.Add);
  const history = useNavigate();
  const dataGridRef = useRef<DataGridRef<any, any>>(null);
  const { t } = useTranslation();
  const { activeLoading } = useAuth();
  const [selectedRowData, setSelectedRowData] = useState<any>(null);
  const items = [
    { text: t('openInNewTab'), icon: "fa-solid fa-up-right-from-square" },
    // { text: t('openLocation') , icon: "fa-solid fa-location-dot"}
  ];
  const compactViewModel: boolean = (JSON.parse(localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}') || {}).viewMode === "compact";
  const { customerDivisions } = useClientSetting();

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        await getInitData();
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getInitData() {
    setInitData(
      await TesGet(
        SafetyApiUrl() +
        "/api/Workbooks/GetCustomerWorkbooks/" +
        localStorage.getItem("selectedCustomerId"),
        true
      )
    );
  }

  function onEdit(d: any) {
    let newData = new Workbook();
    Object.assign(newData, d.row.data);
    setModalMode(ModalMode.Edit);
    setSelectedData(newData);
    setShowModal(true);
  }


  async function onDelete(d: any) {
    let myDialog = custom({
      title: t("warning"),
      messageHtml: t("deleteWarningMessage"),
      buttons: [{
        text: t("yes"),
        onClick: async (e) => {

          await TesDelete(SafetyApiUrl() +
            "/api/Workbooks/DeleteWorkBook/" +
            d.row.data.id,
            true);
          notify(t("dataSuccessfullyDeleted"), "success", 5000);

          await getInitData()
          return { buttonText: e.component.option("text") }
        }
      },
      {
        text: t("no"),
        onClick: (e) => {
          return { buttonText: e.component.option("text") }
        }
      },]
    });
    myDialog.show();
  }



  function onNew() {
    setModalMode(ModalMode.Add);
    setShowModal(true);
  }

  function onCloseModal() {
    if (modalMode === ModalMode.Add) {
      setShowModal(false);
    } else {
      setSelectedData(new Workbook());
      setShowModal(false);
    }
  }

  async function onAdd() {
    try {
      const postObj = {
        ...selectedData,
        customerId: localStorage.getItem("selectedCustomerId"),
      };
      if (activeLoading) activeLoading(true);
      await TesPost(
        SafetyApiUrl() + "/api/Workbooks/AddWorkbook",
        postObj,
        true
      );
      await getInitData();
      onCloseModal();
      if (activeLoading) activeLoading(false);
      notify(t("dataSuccessfullyAdded"), "success", 5000);

    } catch {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred"), "error", 5000);
    }
  }

  async function onUpdate() {
    try {
      if (activeLoading) activeLoading(true);

      await TesPost(
        SafetyApiUrl() + "/api/Workbooks/UpdateWorkbook",
        selectedData,
        true
      );
      await getInitData();
      onCloseModal();
      notify(t("dataSuccessfullyUpdated"), "success", 5000);
      if (activeLoading) activeLoading(false);
    } catch (ex) {
      notify(t("someErrorOccurred" + ex), "error", 5000);
    }
  }

  function onValueChanged(name: string, value: any) {
    setSelectedData({ ...selectedData, [name]: value })
  }

  function onRowClicked(e: any) {
    const isCtrlKeyPressed = e.event.ctrlKey || e.event.metaKey;
    if (e.data.id !== undefined) {

      if (isCtrlKeyPressed) {
        window.open(`/safety/workbookDetails/${e.data.id}`, "_blank");
      } else {
        history(`/safety/workbookDetails/${e.data.id}`);
      }
    }
  }

  return (
    <Permission
      allowed={[SafetyPermissions.Safety_V_Workbook]}
      hasFeedBackElement={true}
    >
      <div className={compactViewModel ? "compactStyle" : ""}>
        <React.Fragment>
          <ContextMenu
            dataSource={items}
            width={100}
            target=".dx-data-row"
            onItemClick={e => RightClickMenu(e, selectedRowData.row.data.id, "/safety/workbookDetails/")}
          />
          <h2 className={"content-block"}>{t("workbooks")}</h2>
          <div className={"content-block"}>
            <div className={"dx-card responsive-paddings"}>
              <div className="row">
                <div className="rightColumn">
                  <Permission
                    allowed={[SafetyPermissions.Safety_D_Workbook]}
                    hasFeedBackElement={false}
                  >
                    <Button
                      onClick={onNew}
                      icon="fa-solid fa-circle-plus"
                      text={t("workBook")}
                    />
                  </Permission>
                </div>
              </div>
              <DataGrid
                id="gridContainer"
                ref={dataGridRef}
                dataSource={initData}
                rowAlternationEnabled={true}
                showBorders={true}
                onRowClick={onRowClicked}
                hoverStateEnabled={true}
                remoteOperations={true}
                allowColumnReordering={true}
                allowColumnResizing={true}
                columnAutoWidth={true}
                style={{ margin: "0 1rem" }}
                onExporting={OnExporting}
                onContextMenuPreparing={e => { setSelectedRowData(e) }}
              >
                <Export enabled={true} allowExportSelectedData={true} />
                <Grouping contextMenuEnabled={true} autoExpandAll={false} />
                <GroupPanel visible={true} />
                <FilterPanel visible={true} />
                <FilterBuilderPopup position={"top"} />
                <Paging enabled={true} defaultPageSize={100} />
                <Pager
                  showPageSizeSelector={true}
                  allowedPageSizes={[100, 200, 300, 400, 500]}
                  showNavigationButtons={true}
                  showInfo={true}
                  visible={true}
                />
                <FilterRow visible={true} applyFilter="auto" />
                <HeaderFilter visible={true} />
                <SearchPanel visible={true} width={285} placeholder={t("search...")} />
                <ColumnChooser width={350} height={400} enabled={true} mode="select" sortOrder="asc">
                  <Search enabled />
                </ColumnChooser>
                <Column
                  alignment="left"
                  dataField="title"
                  allowSorting={false}
                  caption={t("title")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="description"
                  allowSorting={false}
                  caption={t("description")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column type="buttons" caption={t("actions")} width={110} fixed={true} fixedPosition="right">
                  <DevExpressButton
                    hint={t("edit")}
                    onClick={onEdit}
                    icon="fa-solid fa-pencil"
                  ></DevExpressButton>
                  <DevExpressButton
                    hint={t("delete")}
                    onClick={onDelete}
                    icon="fa-solid fa-trash-can"
                  ></DevExpressButton>
                </Column>
                <Column
                  dataField="DivisionId"
                  allowSorting={true}
                  visible={false}
                  caption={t("division")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                  <Lookup
                    dataSource={SortObjectByPropName(customerDivisions!, "name")}
                    valueExpr="id"
                    displayExpr="name"
                  />
                </Column>
              </DataGrid>
            </div>
          </div>

          {showModal && <Popup
            width={"50%"}
            height={"auto"}
            visible={showModal}
            resizeEnabled={true}
            showTitle={true}
            title={modalMode === ModalMode.Add ? t("add") : t("update")}
            hideOnOutsideClick={false}
            showCloseButton={true}
            onHiding={() => onCloseModal()}
          >
            <ScrollView width="100%" height="100%">
              <Form colCount={2}>
                <SimpleItem colSpan={1}>
                  <TextBox
                    label={t("title")}
                    labelMode="static"
                    value={selectedData.title}
                    onValueChange={(e) => onValueChanged("title", e)}
                    className="modalInput"
                  />
                </SimpleItem>
                <SimpleItem colSpan={1}>
                  <TextBox
                    label={t("description")}
                    labelMode="static"
                    value={selectedData.description}
                    onValueChange={(e) => onValueChanged("description", e)}
                    className="modalInput"
                  />
                </SimpleItem>
              </Form>
              <div>
                <div className="rightColumn">
                  <Button
                    className="tes-modal-btn-cancel"
                    style={{ marginRight: 20 }}
                    onClick={() => onCloseModal()}
                    text={t("cancel")}
                  />
                  {modalMode === ModalMode.Add ? (
                    <Button
                      className="tes-modal-btn-add"
                      onClick={onAdd}
                      text={t("add")}
                    />
                  ) : (
                    <Button
                      className="tes-modal-btn-add"
                      onClick={onUpdate}
                      text={t("update")}
                    />
                  )}
                </div>
              </div>
            </ScrollView>
          </Popup>}
        </React.Fragment>
      </div>
    </Permission>
  );
};
export default Workbooks;
