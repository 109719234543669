import axios from "axios";
import {
  DataGrid,
  Popup,
  ScrollView,
  SelectBox,
  TextBox
} from "devextreme-react";
import { Button } from "devextreme-react/button";
import { Column, ColumnChooser, DataGridRef, Button as DevExpressButton, Export, FilterBuilderPopup, FilterPanel, FilterRow, Grouping, GroupItem, GroupPanel, HeaderFilter, Lookup, Pager, Paging, Search, Selection, SortByGroupSummaryInfo, StateStoring, Summary } from "devextreme-react/data-grid";
import { Form, SimpleItem } from "devextreme-react/form";
import { custom } from "devextreme/ui/dialog";
import notify from "devextreme/ui/notify";
import { t } from "i18next";
import React, { useEffect, useRef, useState } from "react";
import { useAuth } from "../../../../../contexts/auth";
import { AuthApiUrl, ReportApiUrl } from "../../../../../environment/routeSettings";
import { GridType, ModalMode, ResponseCode } from "../../../../../types/general/enums/generalEnums";
import { INameId, NameValue, RequestResponseResult } from "../../../../../types/general/generalTypes";
import { ReportGeneralSettings } from "../../../../../types/report/reportTypes";
import { DayDefinitionType, SignalWarrantAlgorithmType } from "../../../../../types/trafficStudy/enums/trafficStudyEnums";
import { SortObjectByPropName } from "../../../../../utils/arrayTools";
import { OnExporting } from "../../../../../utils/dataGridTools";
import { Enum2Array } from '../../../../../utils/enumTools';
import { TesGet, TesPost } from "../../../../../utils/rest";

interface IProps {
  clientId: string | undefined
}

const Report = (props: IProps) => {
  const { activeLoading } = useAuth();
  const [initData, setInitData] = useState<ReportGeneralSettings>(new ReportGeneralSettings());
  const [lstDayDefinition, setLstDayDefinition] = useState<NameValue[]>([]);
  const [lstSignalWarrantAlgorithmType, setLstSignalWarrantAlgorithmType] = useState<NameValue[]>([]);
  const lstPercentile = [{ key: 55, value: 55 }, { key: 60, value: 60 }, { key: 65, value: 65 }, { key: 70, value: 70 }, { key: 75, value: 75 }, { key: 80, value: 80 }, { key: 90, value: 90 }]
  const dataGridRef = useRef<DataGridRef<any, any>>(null);
  const [customerDivisions, setCustomerDivisions] = useState<INameId[] | null>([]);
  const [showModal, setShowModal] = useState(false);
  const [modalMode, setModalMode] = useState<ModalMode>(ModalMode.Add);
  const [selectedData, setSelectedData] = useState<ReportGeneralSettings>(new ReportGeneralSettings());
  const [selectedRowData, setSelectedRowData] = useState<any>(null)

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        await getInitData();
        setLstDayDefinition(Enum2Array(DayDefinitionType))
        setLstSignalWarrantAlgorithmType(Enum2Array(SignalWarrantAlgorithmType))
        getCustomerDivisions(props.clientId!)
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getInitData() {
    setInitData(await TesGet(ReportApiUrl() + "/api/ReportGeneralSettings/" + props.clientId, true));
  }


  async function getCustomerDivisions(customerId: string | null) {
    if (customerId !== null) {
      try {
        const response = await axios.get(
          AuthApiUrl() + "/api/Divisions/GetAll/" + customerId,
          {
            headers: {
              'X-CSRF': '1',
              "Content-Type": "application/json",
            },
          }
        );
        const res = await response;
        if (res.status === 200) {
          var resGeneral = res.data as INameId[];
          setCustomerDivisions(resGeneral);
        }
      } catch {
        setCustomerDivisions([])
      }
    }
  }



  async function onSave() {
    try {
      if (activeLoading) activeLoading(true);
      const postObj = {
        ...initData,
        customerId: props.clientId,
      };
      var res = await TesPost(
        ReportApiUrl() + "/api/ReportGeneralSettings",
        postObj,
        true
      ) as RequestResponseResult<ReportGeneralSettings>
      if (res.responseCode === ResponseCode.OK) {
        await getInitData();
      }
      if (activeLoading) activeLoading(false);
      notify(t("dataSuccessfullyAdded"), "success", 5000);
    } catch {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred"), "error", 5000);
    }
  }

  async function onDelete(d: any) {
    let myDialog = custom({
      title: t("deleteData"),
      messageHtml: t("areYouSureWantToDelete?"),
      buttons: [
        {
          text: t("yes"),
          onClick: async (e) => {
            try {
              const updatedGridData = initData.divisionGeneralSettings.filter(row => row !== d.row.data);
              setInitData({ ...initData, divisionGeneralSettings: updatedGridData });
              dataGridRef.current?.instance().refresh();
              notify(t("dataSuccessfullyDeleted"), "success", 5000);
            } catch {
              notify(t("someErrorOccurred"), "error", 5000);
            }

            return { buttonText: e.component.option("text") };
          },
        },
        {
          text: t("no"),
          onClick: (e) => {
            return { buttonText: e.component.option("text") };
          },
        },
      ],
    });
    myDialog.show();
  }

  function onCloseModal() {
    if (modalMode === ModalMode.Add) {
      setShowModal(false);
    } else {
      setSelectedData(new ReportGeneralSettings());
      setShowModal(false);
      setSelectedRowData(null)
    }
  }

  function onEdit(d: any) {
    setSelectedRowData(d.row.data)
    let newData = new ReportGeneralSettings();
    Object.assign(newData, d.row.data);
    setModalMode(ModalMode.Edit);
    setSelectedData(newData);
    setShowModal(true);
  }

  async function onAdd() {
    try {
      setInitData({ ...initData, divisionGeneralSettings: initData === undefined || null ? [selectedData] : [...initData.divisionGeneralSettings, { ...selectedData, customerId: props.clientId! }] })
      dataGridRef.current?.instance().refresh()
      onCloseModal()
    } catch (er) {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred"), "error", 5000);
    }
  }

  async function onUpdate() {
    try {
      const gridDataWithRemovedRow = initData.divisionGeneralSettings.filter(row => row !== selectedRowData);
      setInitData({ ...initData, divisionGeneralSettings: [...gridDataWithRemovedRow, { ...selectedData, customerId: props.clientId! }] });
      dataGridRef.current?.instance().refresh();
      onCloseModal()
      notify(t("dataSuccessfullyDeleted"), "success", 5000);
    } catch {
      notify(t("someErrorOccurred"), "error", 5000);
    }
  }

  const onValueChanged = (key: string, value: any) => {
    setInitData({ ...initData, [key]: value });
  };

  const onPopupValueChanged = (key: string, value: any) => {
    setSelectedData({ ...selectedData, [key]: value });
  };

  function onNew() {
    setModalMode(ModalMode.Add);
    setSelectedData(new ReportGeneralSettings());
    setShowModal(true);
  }

  return (
    <React.Fragment>
      <div className="row" style={{ marginTop: 20, padding: 10 }}>
        <Form colCount={2} className='popupFields'>
          <SimpleItem colSpan={1}>
            <TextBox
              label={t("clientName")}
              labelMode="static"
              value={initData?.clientName}
              onValueChange={(e) =>
                onValueChanged("clientName", e)
              }
            />
          </SimpleItem>
          <SimpleItem colSpan={1}>
            <TextBox
              label={t("logoURL")}
              labelMode="static"
              value={initData?.logoURL}
              onValueChange={(e) =>
                onValueChanged("logoURL", e)
              }
            />
          </SimpleItem>
          <SimpleItem colSpan={1}>
            <TextBox
              label={t("footerCopyRight")}
              labelMode="static"
              value={initData?.footerCopyRight}
              onValueChange={(e) =>
                onValueChanged("footerCopyRight", e)
              }
            />
          </SimpleItem>
          <SimpleItem colSpan={1}>
            <TextBox
              label={t("headerCopyRight")}
              labelMode="static"
              value={initData?.headerCopyRight}
              onValueChange={(e) =>
                onValueChanged("headerCopyRight", e)
              }
            />
          </SimpleItem>
          <SimpleItem colSpan={1}>
            <TextBox
              label={t("clientContact")}
              labelMode="static"
              value={initData?.clientContact}
              onValueChange={(e) =>
                onValueChanged("clientContact", e)
              }
            />
          </SimpleItem>
        </Form>
        <div className="row">
          <div className="rightColumn" style={{ marginBottom: "-1rem" }}>
            <Button
              onClick={onNew}
              icon="fa-solid fa-circle-plus"
              text={t("add")}
            />
          </div>
        </div>
        <DataGrid
          id="gridContainer"
          ref={dataGridRef}
          dataSource={initData?.divisionGeneralSettings!}
          rowAlternationEnabled={true}
          showBorders={true}
          hoverStateEnabled={true}
          remoteOperations={true}
          allowColumnReordering={true}
          allowColumnResizing={true}
          onExporting={OnExporting}
          style={{ margin: "0 1rem" }}
        >
          <Export enabled={true} allowExportSelectedData={true} />
          <Grouping contextMenuEnabled={true} autoExpandAll={false} />
          <GroupPanel visible={true} />
          <FilterPanel visible={true} />
          <FilterBuilderPopup position={"top"} />
          <StateStoring
            enabled={true}
            type="localStorage"
            storageKey={GridType.ReportSettings.toString()}
            savingTimeout={500}
          />
          <SortByGroupSummaryInfo
            summaryItem="Total Count"
            sortOrder="desc"
          />
          <Summary>
            <GroupItem
              summaryType="count"
              alignByColumn
              name="Total Count"
            />
          </Summary>
          <Paging enabled={true} defaultPageSize={100} />
          <Pager
            showPageSizeSelector={true}
            allowedPageSizes={[100, 200, 300, 400, 500]}
            showNavigationButtons={true}
            showInfo={true}
            visible={true}
          />
          <Selection
            mode="multiple"
            selectAllMode={"allPages"}
            showCheckBoxesMode={"always"}
            deferred={true}
          />
          <FilterRow visible={true} applyFilter="auto" />
          <HeaderFilter visible={true} />
          <ColumnChooser width={350} height={400} enabled={true} mode="select" sortOrder="asc">
            <Search enabled />
          </ColumnChooser>
          <Column dataField="id" caption={t("id")} visible={false}>
            <HeaderFilter>
              <Search enabled />
            </HeaderFilter>
          </Column>
          <Column dataField="clientName" caption={t("clientName")}>
            <HeaderFilter>
              <Search enabled />
            </HeaderFilter>
          </Column>
          {(customerDivisions?.length ?? 0) > 0 &&
            <Column
              dataField="divisionId"
              caption={t("division")}
              allowSorting={true}
            >
              <HeaderFilter>
                <Search enabled />
              </HeaderFilter>
              <Lookup
                dataSource={customerDivisions}
                valueExpr="id"
                displayExpr="name"
              />
            </Column>
          }
          <Column dataField="logoURL" caption={t("logoURL")}>
            <HeaderFilter>
              <Search enabled />
            </HeaderFilter>
          </Column>
          <Column dataField="headerCopyRight" caption={t("headerCopyRight")}>
            <HeaderFilter>
              <Search enabled />
            </HeaderFilter>
          </Column>
          <Column dataField="footerCopyRight" caption={t("footerCopyRight")}>
            <HeaderFilter>
              <Search enabled />
            </HeaderFilter>
          </Column>
          <Column dataField="clientContact" caption={t("clientContact")}>
            <HeaderFilter>
              <Search enabled />
            </HeaderFilter>
          </Column>
          <Column type="buttons" caption={t("actions")} width={110} fixed={true} fixedPosition="right">
            <DevExpressButton hint={t('edit')}
              onClick={e => onEdit(e)}
              icon="fa-solid fa-pen"
            />
            <DevExpressButton hint={t('delete')}
              onClick={e => onDelete(e)}
              icon="fa-solid fa-trash-can"
            />
          </Column>
        </DataGrid>
        <div className="rightColumn">
          <Button
            className="tes-modal-btn-add saveButton"
            onClick={onSave}
            text={t("save")}
          />
        </div>
      </div>

      <Popup
        width={"50%"}
        height={"auto"}
        visible={showModal}
        resizeEnabled={true}
        showTitle={true}
        title={modalMode === ModalMode.Add ? t("add") : t("update")}
        hideOnOutsideClick={false}
        showCloseButton={true}
        onHiding={() => onCloseModal()}
      >
        <ScrollView width="100%" height="100%">
          <Form colCount={2} className='popupFields'>
            <SimpleItem colSpan={1}>
              <TextBox
                label={t("clientName")}
                labelMode="static"
                value={selectedData?.clientName}
                onValueChange={(e) => onPopupValueChanged("clientName", e)}
              />
            </SimpleItem>
            <SimpleItem colSpan={1}>
              <SelectBox
                placeholder=""
                // style={{ marginBottom: 20 }}
                label={t("division")}
                labelMode='static'
                displayExpr="name"
                valueExpr="id"
                value={selectedData.divisionId}
                onValueChange={e => onPopupValueChanged('divisionId', e)}
                items={SortObjectByPropName(customerDivisions!, "name")}
                showClearButton={true}
                searchEnabled={true}
              />
            </SimpleItem>
            <SimpleItem colSpan={1}>
              <TextBox
                label={t("logoURL")}
                labelMode="static"
                value={selectedData?.logoURL}
                onValueChange={(e) => onPopupValueChanged("logoURL", e)}
              />
            </SimpleItem>
            <SimpleItem colSpan={1}>
              <TextBox
                label={t("footerCopyRight")}
                labelMode="static"
                value={selectedData?.footerCopyRight}
                onValueChange={(e) => onPopupValueChanged("footerCopyRight", e)}
              />
            </SimpleItem>
            <SimpleItem colSpan={1}>
              <TextBox
                label={t("headerCopyRight")}
                labelMode="static"
                value={selectedData?.headerCopyRight}
                onValueChange={(e) => onPopupValueChanged("headerCopyRight", e)}
              />
            </SimpleItem>
            <SimpleItem colSpan={1}>
              <TextBox
                label={t("clientContact")}
                labelMode="static"
                value={selectedData?.clientContact}
                onValueChange={(e) => onPopupValueChanged("clientContact", e)}
              />
            </SimpleItem>
          </Form>
          <div>
            <div className="rightColumn" style={{ marginTop: "1.5rem" }}>
              <Button
                className="tes-modal-btn-cancel"
                style={{ marginRight: 20 }}
                onClick={() => onCloseModal()}
                text={t("cancel")}
              />
              {modalMode === ModalMode.Add ? (
                <Button
                  className="tes-modal-btn-add"
                  onClick={onAdd}
                  text={t("add")}
                />
              ) : (
                <Button
                  className="tes-modal-btn-add"
                  onClick={onUpdate}
                  text={t("update")}
                />
              )}
            </div>
          </div>
        </ScrollView>
      </Popup>
    </React.Fragment>
  );
};
export default Report;
