//imports
import DataGrid, {
  Column,
  ColumnChooser,
  DataGridRef,
  Button as DevExpressButton,
  Export,
  FilterBuilderPopup,
  FilterPanel,
  FilterRow,
  Grouping,
  GroupItem,
  GroupPanel,
  HeaderFilter,
  Lookup,
  Pager,
  Paging,
  Search,
  SearchPanel,
  StateStoring,
  Summary,
  TotalItem
} from "devextreme-react/data-grid";
import React, { useEffect, useRef, useState } from "react";

import { NumberBox, SelectBox } from "devextreme-react";
import { Button } from "devextreme-react/button";
import { Form, SimpleItem } from "devextreme-react/form";
import { Popup } from "devextreme-react/popup";
import ScrollView from "devextreme-react/scroll-view";
import { Switch } from "devextreme-react/switch";
import TextBox from "devextreme-react/text-box";
import { custom } from "devextreme/ui/dialog";
import notify from "devextreme/ui/notify";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../../contexts/auth";
import {
  FieldCategoryType,
  FieldServiceType,
  FieldType,
} from "../../../../types/field/enums/fieldEnums";
import { CodeGroup } from "../../../../types/field/fieldType";
import {
  GridType,
  ModalMode,
} from "../../../../types/general/enums/generalEnums";
import { TesDelete, TesGet, TesPost, TesPut } from "../../../../utils/rest";
import "./fields.scss";

import Permission from "../../../../components/permission/permision";
import Rule from "../../../../components/rule/Rule";
import { ModuleOfModulePermissions } from "../../../../constants/Permissions";
import { FieldApiUrl, ModuleOfModuleApiUrl } from "../../../../environment/routeSettings";
import { VMWebField } from "../../../../types/field/dto/fieldDTO";
import { INameId, NameValue } from "../../../../types/general/generalTypes";
import { SortObjectByPropName } from "../../../../utils/arrayTools";
import { OnExporting } from "../../../../utils/dataGridTools";
import { Enum2Array } from "../../../../utils/enumTools";
import { useScreenSize } from "../../../../utils/media-query";

const ModuleFields = () => {
  //const [gridProfileId, setGridProfileId] = useState(null);
  const [initData, setInitData] = useState<VMWebField[]>([]);
  const [initDataCodeGroups, setInitDataCodeGroups] = useState<CodeGroup[]>([]);
  const dataGridRef = useRef<DataGridRef<any, any>>(null);
  const [showModal, setShowModal] = useState(false);
  const [modalMode, setModalMode] = useState<ModalMode>(ModalMode.Add);
  const [selectedData, setSelectedData] = useState<VMWebField>(new VMWebField());
  const [lstFiledType, setLstFiledType] = useState<NameValue[]>([]);
  const [lstFiledCategoryType, setLstFiledCategoryType] = useState<NameValue[]>([]);
  const [lstModuleTab, setLstModuleTab] = useState<INameId[]>();
  const { t } = useTranslation();
  const { activeLoading } = useAuth();
  const { is2xLarge } = useScreenSize();
  const [popupHeight, setPopupHeight] = useState<string>("auto")
  const [formHeight, setFormHeight] = useState<string>("50vh")
  const compactViewModel: boolean = (JSON.parse(localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}') || {}).viewMode === "compact";

  useEffect(() => {
    if (is2xLarge) { setFormHeight("32vh"); setPopupHeight("40%") }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        setLstFiledType(Enum2Array(FieldType));
        setLstFiledCategoryType(Enum2Array(FieldCategoryType));
        await getInitData();
        await getInitDataCodeGroups();
        await getInitDataModuleTabs();
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Get InitData
  async function getInitData() {
    setInitData(
      await TesGet(
        FieldApiUrl() +
        "/api/TesFields/GetAllFields/" +
        localStorage.getItem("selectedCustomerId") +
        "/" +
        FieldServiceType.ModuleOfModule,
        true
      )
    );
  }

  async function getInitDataCodeGroups() {
    setInitDataCodeGroups(
      await TesGet(
        FieldApiUrl() +
        "/api/codeGroups/GetCodeGroups/" +
        localStorage.getItem("selectedCustomerId") +
        "/" +
        FieldServiceType.ModuleOfModule,
        true
      )
    );
  }

  async function getInitDataModuleTabs() {
    setLstModuleTab(await TesGet(ModuleOfModuleApiUrl() + "/api/ClientModule/GetCustomerModuleTabs/" + localStorage.getItem("selectedCustomerId"), true));
  }

  function onEdit(d: any) {
    setModalMode(ModalMode.Edit);
    setSelectedData(d.row.data);
    setShowModal(true);
  }

  function onNew() {
    setSelectedData(new VMWebField());
    setModalMode(ModalMode.Add);
    setShowModal(true);
  }

  const onValueChanged = (key: string, value: any) => {
    setSelectedData({ ...selectedData, [key]: value });
  };

  function onCloseModal() {
    if (modalMode === ModalMode.Add) {
      setShowModal(false);
    } else {
      setSelectedData(new VMWebField());
      setShowModal(false);
    }
  }

  async function onAdd() {
    try {
      const postObj = {
        ...selectedData,
        FieldServiceType: FieldServiceType.ModuleOfModule,
        customerId: localStorage.getItem("selectedCustomerId"),
        FieldCategoryType: 0
      }
      if (activeLoading) activeLoading(true);
      await TesPost(FieldApiUrl() + "/api/TesFields", postObj, true);
      await getInitData();
      onCloseModal();
      if (activeLoading) activeLoading(false);
      notify(t("dataSuccessfullyAdded"), "success", 5000);
    } catch (ex) {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred") + ex, "error", 5000);
    }
  }

  async function onUpdate() {
    try {
      const postObj = {
        ...selectedData,
        FieldServiceType: FieldServiceType.ModuleOfModule
      }
      if (activeLoading) activeLoading(true);
      await TesPut(
        FieldApiUrl() + "/api/TesFields/" + postObj.id,
        postObj,
        true
      );
      await getInitData();
      onCloseModal();
      notify(t("dataSuccessfullyUpdated"), "success", 5000);
      if (activeLoading) activeLoading(false);
    } catch (ex) {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred" + ex), "error", 5000);
    }
  }

  async function onDelete(d: any) {
    let myDialog = custom({
      title: t("deleteData"),
      messageHtml: t("areYouSureWantToDelete?"),
      buttons: [
        {
          text: t("yes"),
          onClick: async (e) => {
            try {
              await TesDelete(
                FieldApiUrl() + "/api/TesFields/" + d.row.data.id,
                true
              );
              await getInitData();
              notify(t("dataSuccessfullyDeleted"), "success", 5000);
            } catch {
              notify(t("someErrorOccurred"), "error", 5000);
            }

            return { buttonText: e.component.option("text") };
          },
        },
        {
          text: t("no"),
          onClick: (e) => {
            return { buttonText: e.component.option("text") };
          },
        },
      ],
    });
    myDialog.show();
  }
  return (
    <Permission
      allowed={[ModuleOfModulePermissions.ModuleOfModule_D_Setups]}
      hasFeedBackElement={true}
    >
      <div className={compactViewModel ? "compactStyle" : ""}>
        <React.Fragment>
          <h2 className={"content-block"}>{t("fields")}</h2>
          <div className={"content-block"}>
            <div className={"dx-card responsive-paddings"}>
              <div className="row">
                <div className="rightColumn">
                  <Button onClick={onNew} icon="fa-solid fa-circle-plus" text={t("add")} />
                </div>
              </div>
              <DataGrid
                id="gridContainer"
                ref={dataGridRef}
                dataSource={initData}
                rowAlternationEnabled={true}
                showBorders={true}
                hoverStateEnabled={true}
                remoteOperations={true}
                allowColumnReordering={true}
                allowColumnResizing={true}
                columnAutoWidth={true}
                style={{ margin: "0 1rem" }}
                onExporting={OnExporting}
              >
                <Export enabled={true} allowExportSelectedData={true} />
                <Grouping contextMenuEnabled={true} autoExpandAll={false} />
                <GroupPanel visible={true} /> {/* or "auto" */}
                <FilterPanel visible={true} />
                <FilterBuilderPopup position={"top"} />
                <StateStoring
                  enabled={true}
                  type="localStorage"
                  storageKey={GridType.CollisionField.toString()}
                  savingTimeout={500}
                />
                <Summary>
                  <TotalItem column="GeoId" summaryType="count" />
                  <GroupItem summaryType="count" alignByColumn name="Total Count" />
                </Summary>
                <Paging enabled={true} defaultPageSize={100} />
                <Pager
                  showPageSizeSelector={true}
                  allowedPageSizes={[100, 200, 300, 400, 500]}
                  showNavigationButtons={true}
                  showInfo={true}
                  visible={true}
                />
                <FilterRow visible={true} applyFilter="auto" />
                <HeaderFilter visible={true} />
                <SearchPanel visible={true} width={285} placeholder={t("search")} />
                <ColumnChooser width={350} height={400} enabled={true} mode="select" sortOrder="asc">
                  <Search enabled />
                </ColumnChooser>
                <Column dataField="name" caption={t("name")}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="fieldCategoryType"
                  alignment="left"
                  caption={t("categoryType")}
                >
                  <Lookup dataSource={SortObjectByPropName(lstFiledCategoryType, "name")} valueExpr="value" displayExpr="name" />

                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="fieldType"
                  alignment="left"
                  caption={t("fieldType")}
                >
                  <Lookup dataSource={SortObjectByPropName(lstFiledType, "name")} valueExpr="value" displayExpr="name" />

                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column dataField="labelText" caption={t("labelText")}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column dataField="index" alignment="left" caption={t("index")}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="Description"
                  allowSorting={false}
                  caption={t("description")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="isCalculatedField"
                  caption={t("isCalculatedField")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column dataField="isRequiredInApp" caption={t("isRequiredInApp")}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column dataField="isRequiredInWeb" caption={t("isRequiredInWeb")}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column dataField="isShowInApp" caption={t("isShowInApp")}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column dataField="isShowInWeb" caption={t("isShowInWeb")}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column dataField="isUpperCase" caption={t("isUpperCase")}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="integrationAddress"
                  caption={t("integrationAddress")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column dataField="validationRule" caption={t("validationRule")}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column dataField="codeGroup" caption={t("codeGroup")}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column dataField="moduleOfModuleTabId" caption={t("moduleTab")}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                  <Lookup dataSource={lstModuleTab} displayExpr={'name'} valueExpr={'id'} />
                </Column>
                <Column
                  dataField="isShowInDriver"
                  visible={false}
                  caption={t("isShowInDriver")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="isShowInPassenger"
                  visible={false}
                  caption={t("isShowInPassenger")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="isShowInPedestrian"
                  visible={false}
                  caption={t("isShowInPedestrian")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  type="buttons"
                  caption={t("actions")}
                  fixed={true}
                  fixedPosition="right"
                  width={110}
                >
                  <DevExpressButton
                    hint={t("edit")}
                    onClick={onEdit}
                    icon="fa-solid fa-pencil"
                  ></DevExpressButton>
                  <DevExpressButton
                    hint={t("delete")}
                    onClick={onDelete}
                    icon="fa-solid fa-trash-can"
                  ></DevExpressButton>
                </Column>
              </DataGrid>
            </div>
          </div>

          <Popup
            width={"50%"}
            height={popupHeight}
            visible={showModal}
            resizeEnabled={true}
            showTitle={true}
            title={modalMode === ModalMode.Add ? t("add") : t("update")}
            hideOnOutsideClick={false}
            showCloseButton={true}
            onHiding={() => onCloseModal()}
          >
            <div style={{ height: formHeight }}>
              <ScrollView width="100%" height="100%">
                <Form colCount={2} className='popupFields'>

                  <SimpleItem colSpan={1}>
                    <SelectBox
                      placeholder=""
                      label={t("fieldType")}
                      labelMode="static"
                      value={selectedData.fieldType}
                      onValueChange={(e) => onValueChanged("fieldType", e)}
                      className="modalInput"
                      valueExpr="value"
                      displayExpr="name"
                      items={SortObjectByPropName(lstFiledType, "name")}
                      showClearButton={true}
                      searchEnabled={true}
                    />
                  </SimpleItem>

                  <SimpleItem colSpan={1}>
                    <TextBox
                      label={t("name")}
                      labelMode="static"
                      value={selectedData.name}
                      onValueChange={(e) => onValueChanged("name", e)}
                      className="modalInput"
                    />
                  </SimpleItem>

                  <SimpleItem colSpan={1}>
                    <TextBox
                      label={t("labelText")}
                      labelMode="static"
                      value={selectedData.labelText}
                      onValueChange={(e) => onValueChanged("labelText", e)}
                      className="modalInput"
                    />
                  </SimpleItem>

                  <SimpleItem colSpan={1}>
                    <NumberBox
                      step={0}
                      label={t("index")}
                      labelMode="static"
                      value={selectedData.index}
                      onValueChange={(e) => onValueChanged("index", e)}
                      className="modalInput"
                    />
                  </SimpleItem>

                  <SimpleItem colSpan={1}>
                    <TextBox
                      label={t("description")}
                      labelMode="static"
                      value={selectedData.description}
                      onValueChange={(e) => onValueChanged("description", e)}
                      className="modalInput"
                    />
                  </SimpleItem>

                  <SimpleItem colSpan={1}>
                    <TextBox
                      label={t("integrationAddress")}
                      labelMode="static"
                      value={selectedData.integrationAddress}
                      onValueChange={(e) => onValueChanged("integrationAddress", e)}
                      className="modalInput"
                    />
                  </SimpleItem>

                  <SimpleItem colSpan={1}>
                    <SelectBox
                      placeholder=""
                      label={t("moduleTab")}
                      labelMode="static"
                      value={selectedData.moduleOfModuleTabId}
                      onValueChange={(e) => onValueChanged("moduleOfModuleTabId", e)}
                      className="modalInput"
                      valueExpr="id"
                      displayExpr="name"
                      items={lstModuleTab}
                      showClearButton={true}
                      searchEnabled={true}
                    />
                  </SimpleItem>

                  {(selectedData.fieldType === FieldType.List) && (
                    <SimpleItem colSpan={1}>
                      <SelectBox
                        placeholder=""
                        label={t("codeGroup")}
                        labelMode="static"
                        value={selectedData.codeGroupId}
                        onValueChange={(e) => onValueChanged("codeGroupId", e)}
                        className="modalInput"
                        valueExpr="id"
                        displayExpr="name"
                        items={SortObjectByPropName(initDataCodeGroups, "name")}
                        showClearButton={true}
                        searchEnabled={true}
                      />
                    </SimpleItem>
                  )}

                  <SimpleItem colSpan={1}>
                    <div>{t("isRequiredInApp")}</div>
                    <Switch
                      style={{ marginTop: 5 }}
                      hint={t("isRequiredInApp")}
                      value={selectedData.isRequiredInApp}
                      onValueChange={(e) => onValueChanged("isRequiredInApp", e)}
                    />
                  </SimpleItem>

                  <SimpleItem colSpan={1}>
                    <div>{t("isRequiredInWeb")}</div>
                    <Switch
                      style={{ marginTop: 15 }}
                      hint={t("isRequiredInWeb")}
                      value={selectedData.isRequiredInWeb}
                      onValueChange={(e) => onValueChanged("isRequiredInWeb", e)}
                    />
                  </SimpleItem>

                  <SimpleItem colSpan={1}>
                    <div>{t("isShowInApp")}</div>
                    <Switch
                      style={{ marginTop: 15 }}
                      hint={t("isShowInApp")}
                      value={selectedData.isShowInApp}
                      onValueChange={(e) => onValueChanged("isShowInApp", e)}
                    />
                  </SimpleItem>

                  <SimpleItem colSpan={1}>
                    <div>{t("isShowInWeb")}</div>
                    <Switch
                      style={{ marginTop: 15 }}
                      hint={t("isShowInWeb")}
                      value={selectedData.isShowInWeb}
                      onValueChange={(e) => onValueChanged("isShowInWeb", e)}
                    />
                  </SimpleItem>

                  <SimpleItem colSpan={1}>
                    <div>{t("isShowInDriver")}</div>
                    <Switch
                      style={{ marginTop: 15 }}
                      hint={t("isShowInDriver")}
                      value={selectedData.isShowInDriver}
                      onValueChange={(e) => onValueChanged("isShowInDriver", e)}
                    />
                  </SimpleItem>

                  <SimpleItem colSpan={1}>
                    <div>{t("isShowOnPassenger")}</div>
                    <Switch
                      style={{ marginTop: 15 }}
                      hint={t("isShowOnPassenger")}
                      value={selectedData.isShowInPassenger}
                      onValueChange={(e) => onValueChanged("isShowOnPassenger", e)}
                    />
                  </SimpleItem>

                  <SimpleItem colSpan={1}>
                    <div>{t("isShowOnPedestrian")}</div>
                    <Switch
                      style={{ marginTop: 15 }}
                      hint={t("isShowOnPedestrian")}
                      value={selectedData.isShowInPedestrian}
                      onValueChange={(e) => onValueChanged("isShowOnPedestrian", e)}
                    />
                  </SimpleItem>

                  <SimpleItem colSpan={1}>
                    <div>{t("isCalculatedField")}</div>
                    <Switch
                      style={{ marginTop: 15 }}
                      hint={t("isCalculatedField")}
                      value={selectedData.isCalculatedField}
                      onValueChange={(e) => onValueChanged("isCalculatedField", e)}
                    />
                  </SimpleItem>

                  <SimpleItem colSpan={1}>
                    <div>{t("isUpperCase")}</div>
                    <Switch
                      style={{ marginTop: 15 }}
                      hint={t("isUpperCase")}
                      value={selectedData.isUpperCase}
                      onValueChange={(e) => onValueChanged("isUpperCase", e)}
                    />
                  </SimpleItem>

                  <SimpleItem>
                    <div style={{ marginTop: "1rem" }}>
                      <Rule
                        name="rule"
                        onValueChange={onValueChanged}
                        defaultRuleValue={selectedData.rule}
                        fieldServiceType={FieldServiceType.ModuleOfModule}
                      />
                    </div>

                  </SimpleItem>

                </Form>
              </ScrollView>
            </div>
            <div className="rightColumn">
              <Button
                className="tes-modal-btn-cancel"
                style={{ marginRight: 20 }}
                onClick={() => onCloseModal()}
                text={t("cancel")}
              />
              {modalMode === ModalMode.Add ? (
                <Button
                  className="tes-modal-btn-add"
                  onClick={onAdd}
                  text={t("add")}
                />
              ) : (
                <Button
                  className="tes-modal-btn-add"
                  onClick={onUpdate}
                  text={t("update")}
                />
              )}
            </div>
          </Popup>
        </React.Fragment>
      </div>
    </Permission>
  );
};
export default ModuleFields;
