//imports
import React, { useEffect, useRef, useState } from "react";
import DataGrid, {
  Column,
  FilterRow,
  HeaderFilter,
  SearchPanel,
  ColumnChooser,
  Grouping,
  GroupPanel,
  FilterBuilderPopup,
  FilterPanel,
  StateStoring,
  Button as DevExpressButton,
  Export,
  Lookup,
  SortByGroupSummaryInfo,
  Summary,
  GroupItem,
  Search,
  Paging,
  Pager,
  DataGridRef,
} from "devextreme-react/data-grid";
import { Popup } from "devextreme-react/popup";
import { Button } from "devextreme-react/button";
import { TesGet, TesPost, RequestErrorHandling } from "../../../../utils/rest";
import {
  GridType,
  ModalMode,
  ResponseCode,
} from "../../../../types/general/enums/generalEnums";
import "./municipalityPopulation.scss";
import { useTranslation } from "react-i18next";
import ScrollView from "devextreme-react/scroll-view";
import notify from "devextreme/ui/notify";
import { useAuth } from "../../../../contexts/auth";
import { Form, SimpleItem } from "devextreme-react/form";
import { NumberBox, SelectBox } from "devextreme-react";
import Permission from "../../../../components/permission/permision";
import { InfrastructurePermissions } from "../../../../constants/Permissions";
import { MunicipalityPopulation, TesCodeValue } from "../../../../types/infrastructure/infrastructureTypes";
import { RequestResponseResult } from "../../../../types/general/generalTypes";
import { Validator, RequiredRule } from "devextreme-react/validator";
import { ValidationGroup, ValidationGroupRef } from "devextreme-react/validation-group"
import { FieldApiUrl, InfrastructureApiUrl } from "../../../../environment/routeSettings";
import { OnExporting } from "../../../../utils/dataGridTools";
import { SortObjectByPropName } from "../../../../utils/arrayTools";
import getTitle from "../../../../components/title/getTitle";

const MunicipalityPopulations = () => {
  //const [gridProfileId, setGridProfileId] = useState(null);
  const [initData, setInitData] = useState<MunicipalityPopulation[]>([]);
  const [initDataMunicipality, setInitDataMunicipalityPopulation] = useState<TesCodeValue>(new TesCodeValue());
  const [selectedData, setSelectedData] = useState<MunicipalityPopulation>(new MunicipalityPopulation());
  const dataGridRef = useRef<DataGridRef<any, any>>(null);
  const [showModal, setShowModal] = useState(false);
  const [modalMode, setModalMode] = useState<ModalMode>(ModalMode.Add);
  const { t } = useTranslation();
  const { activeLoading } = useAuth();
  const validationRef = useRef<ValidationGroupRef>(null);
  const compactViewModel: boolean = (JSON.parse(localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}') || {}).viewMode === "compact";

  const title = getTitle('/infrastructure/settings/municipalityPopulation', '');
  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        await getInitData();
        await getInitDataMunicipalityPopulation();
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getInitData() {
    const postObj = {
      customerId: localStorage.getItem("selectedCustomerId")
    }
    const res = await TesPost(InfrastructureApiUrl() + "/api/MunicipalityPopulation/GetCustomerMunicipalityPopulation", postObj, true) as RequestResponseResult<MunicipalityPopulation[]>
    if (res.responseCode === ResponseCode.OK) {
      setInitData(res.results)
    } else {
      await RequestErrorHandling(res);
    }
  }

  async function getInitDataMunicipalityPopulation() {
    setInitDataMunicipalityPopulation(
      await TesGet(FieldApiUrl() + "/api/codeValues/infrastructureTesCodeValues/"
        + localStorage.getItem('selectedCustomerId'), true)
    );
  }

  function onEdit(d: any) {
    let newData = new MunicipalityPopulation();
    Object.assign(newData, d.row.data);
    setModalMode(ModalMode.Edit);
    setSelectedData(newData);
    setShowModal(true);
  }

  function onNew() {
    setModalMode(ModalMode.Add);
    setShowModal(true);
  }

  const onValueChanged = (key: string, value: any) => {
    setSelectedData({ ...selectedData, [key]: value });
  };

  function onCloseModal() {
    if (modalMode === ModalMode.Add) {
      setShowModal(false);
      setSelectedData(new MunicipalityPopulation());
    } else {
      setSelectedData(new MunicipalityPopulation());
      setShowModal(false);
    }
  }

  async function onAdd() {
    try {
      const validationRes = validationRef.current?.instance().validate();
      if (validationRes?.isValid) {
        const postObj = {
          ...selectedData,
          customerId: localStorage.getItem("selectedCustomerId"),
        };
        if (activeLoading) activeLoading(true);
        let res = (await TesPost(
          InfrastructureApiUrl() + "/api/MunicipalityPopulation/AddMunicipalityPopulation",
          postObj,
          true
        )) as RequestResponseResult<null>;
        if (res.responseCode === ResponseCode.OK) {
          dataGridRef.current?.instance().refresh();
          await getInitData();
          onCloseModal();
          if (activeLoading) activeLoading(false);
          notify(t("dataSuccessfullyAdded"), "success", 5000);
        } else {
          await RequestErrorHandling(res);;
          if (activeLoading) activeLoading(false);
        }
      }
    } catch {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred"), "error", 5000);
    }
  }

  async function onUpdate() {
    try {
      const validationRes = validationRef.current?.instance().validate();
      if (validationRes?.isValid) {
        if (activeLoading) activeLoading(true);
        let res = (await TesPost(
          InfrastructureApiUrl() + "/api/MunicipalityPopulation/UpdateMunicipalityPopulation",
          selectedData,
          true
        )) as RequestResponseResult<null>;
        if (res.responseCode === ResponseCode.OK) {
          await getInitData();
          onCloseModal();
          notify(t("dataSuccessfullyUpdated"), "success", 5000);
          if (activeLoading) activeLoading(false);
        } else {
          await RequestErrorHandling(res);;
          if (activeLoading) activeLoading(false);
        }
      }
    } catch (ex) {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred" + ex), "error", 5000);
    }
  }

  //   async function onDelete(d: any) {
  //     let myDialog = custom({
  //       title: t("deleteData"),
  //       messageHtml: t("areYouSureWantToDelete?"),
  //       buttons: [
  //         {
  //           text: t("yes"),
  //           onClick: async (e) => {
  //             try {
  //               await TesDelete(
  //                 apiAddresses.fieldAPI + "TesFields/" + d.row.data.id,
  //                 true
  //               );
  //               await getInitData();
  //               notify(t("dataSuccessfullyDeleted"), "success", 5000);
  //             } catch {
  //               notify(t("someErrorOccurred"), "error", 5000);
  //             }

  //             return { buttonText: e.component.option("text") };
  //           },
  //         },
  //         {
  //           text: t("no"),
  //           onClick: (e) => {
  //             return { buttonText: e.component.option("text") };
  //           },
  //         },
  //       ],
  //     });
  //     myDialog.show();
  //   }

  return (
    <Permission
      allowed={[InfrastructurePermissions.Infrastructure_D_Setups]}
      hasFeedBackElement={true}
    >
      <div className={compactViewModel ? "compactStyle" : ""}>
        <React.Fragment>
          <h2 className={"content-block"}>{t("municipalityPopulation")}</h2>
          <div className={"content-block"}>
            <div className={"dx-card responsive-paddings"}>
              <div className="row">
                <div className="rightColumn">
                  <Button onClick={onNew} icon="fa-solid fa-circle-plus" text={t("add")} />
                </div>
              </div>
              <DataGrid
                id="gridContainer"
                ref={dataGridRef}
                dataSource={initData}
                rowAlternationEnabled={true}
                showBorders={true}
                hoverStateEnabled={true}
                remoteOperations={true}
                allowColumnReordering={true}
                allowColumnResizing={true}
                columnAutoWidth={true}
                style={{ margin: "0 1rem" }}
                onExporting={OnExporting}
              >
                <Export enabled={true} allowExportSelectedData={true} />
                <Grouping contextMenuEnabled={true} autoExpandAll={false} />
                <GroupPanel visible={true} /> {/* or "auto" */}
                <FilterPanel visible={true} />
                <FilterBuilderPopup position={"top"} />
                <StateStoring
                  enabled={true}
                  type="localStorage"
                  storageKey={GridType.InfrastructureMunicipalityPopulation.toString()}
                  savingTimeout={500}
                />
                <FilterRow visible={true} applyFilter="auto" />
                <HeaderFilter visible={true} />
                <SearchPanel visible={true} width={285} placeholder={t("search...")} />
                <ColumnChooser width={350} height={400} enabled={true} mode="select" sortOrder="asc">
                  <Search enabled />
                </ColumnChooser>
                <SortByGroupSummaryInfo
                  summaryItem="Total Count"
                  sortOrder="desc"
                />
                <Summary>
                  <GroupItem
                    summaryType="count"
                    alignByColumn
                    name="Total Count"
                  />
                </Summary>
                <Paging enabled={true} defaultPageSize={100} />
                <Pager
                  showPageSizeSelector={true}
                  allowedPageSizes={[100, 200, 300, 400, 500]}
                  showNavigationButtons={true}
                  showInfo={true}
                  visible={true}
                />
                <Column dataField="municipalityId" caption={t("municipality")} visible={true}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                  <Lookup dataSource={SortObjectByPropName(initDataMunicipality?.municipalities, "name")} valueExpr="id" displayExpr="name" />
                </Column>
                <Column dataField="population" caption={t("population")} visible={true}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column dataField="id" caption={t("id")} visible={false}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column dataField="customerId" caption={t("customerId")} visible={false}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  type="buttons"
                  caption={t("actions")}
                  fixed={true}
                  fixedPosition="right"
                  width={110}
                >
                  <DevExpressButton
                    hint={t("edit")}
                    onClick={onEdit}
                    icon="fa-solid fa-pencil"
                  ></DevExpressButton>
                  <DevExpressButton
                    hint={t("delete")}
                    //   onClick={onDelete}
                    icon="fa-solid fa-trash-can"
                  ></DevExpressButton>
                </Column>
              </DataGrid>
            </div>
          </div>

          <Popup
            width={"50%"}
            height={"auto"}
            visible={showModal}
            resizeEnabled={true}
            showTitle={true}
            title={modalMode === ModalMode.Add ? t("add") : t("update")}
            hideOnOutsideClick={false}
            showCloseButton={true}
            onHiding={() => onCloseModal()}
          >
            <ScrollView width="100%" height="100%">
              <ValidationGroup ref={validationRef}>
                <Form colCount={2}>
                  {modalMode === ModalMode.Add && <SimpleItem colSpan={1}>
                    <SelectBox
                      placeholder=""
                      label={t("municipality")}
                      labelMode="static"
                      value={selectedData.municipalityId}
                      onValueChange={(e) => onValueChanged("municipalityId", e)}
                      className="modalInput"
                      valueExpr="id"
                      displayExpr="name"
                      items={SortObjectByPropName(initDataMunicipality.municipalities?.filter(x => !initData.map(y => y.municipalityId).includes(x.id)), "name")}
                      showClearButton={true}
                      searchEnabled={true}
                    >
                      <Validator>
                        <RequiredRule message={t("municipality")} />
                      </Validator>
                    </SelectBox>
                  </SimpleItem>}
                  <SimpleItem colSpan={1}>
                    <NumberBox
                      step={0}
                      label={t("population")}
                      labelMode="static"
                      value={selectedData.population}
                      onValueChange={(e) => onValueChanged("population", e)}
                      className="modalInput"
                    >
                      <Validator>
                        <RequiredRule message={t("population")} />
                      </Validator>
                    </NumberBox>
                  </SimpleItem>
                </Form>
              </ValidationGroup>
              <div>
                <div className="rightColumn">
                  <Button
                    className="tes-modal-btn-cancel"
                    style={{ marginRight: 20 }}
                    onClick={() => onCloseModal()}
                    text={t("cancel")}
                  />
                  {modalMode === ModalMode.Add ? (
                    <Button
                      className="tes-modal-btn-add"
                      onClick={onAdd}
                      text={t("add")}
                    />
                  ) : (
                    <Button
                      className="tes-modal-btn-add"
                      onClick={onUpdate}
                      text={t("update")}
                    />
                  )}
                </div>
              </div>
            </ScrollView>
          </Popup>
        </React.Fragment>
      </div>
    </Permission>
  );
};
export default MunicipalityPopulations;