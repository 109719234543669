import { ContextMenu, Lookup } from "devextreme-react";
import { Button } from "devextreme-react/button";
import DataGrid, {
  Column,
  ColumnChooser,
  DataGridRef,
  Export,
  FilterBuilderPopup,
  FilterPanel,
  FilterRow,
  Grouping,
  GroupItem,
  GroupPanel,
  HeaderFilter,
  Pager,
  Paging,
  Search,
  SearchPanel,
  Summary
} from "devextreme-react/data-grid";
import notify from "devextreme/ui/notify";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import getTitle from "../../../components/title/getTitle";
import { useAuth } from "../../../contexts/auth";
import { GeneralSetting, useClientSetting } from "../../../contexts/clientSetting";
import { InfrastructureApiUrl } from "../../../environment/routeSettings";
import { ResponseCode } from "../../../types/general/enums/generalEnums";
import { RequestResponseResult } from "../../../types/general/generalTypes";
import { PermanentCountStation } from "../../../types/infrastructure/infrastructureTypes";
import { SortObjectByPropName } from "../../../utils/arrayTools";
import { OnExporting } from "../../../utils/dataGridTools";
import {
  TesPost
} from "../../../utils/rest";
import { RightClickMenu } from "../../../utils/rightClickMenu";

const PermanentCountStations = () => {
  const [initData, setInitData] = useState<PermanentCountStation[]>([new PermanentCountStation()]);
  const dataGridRef = useRef<DataGridRef<any, any>>(null);
  const { activeLoading } = useAuth();
  const { user } = useAuth();
  const { t } = useTranslation();
  const history = useNavigate();
  const [selectedRowData, setSelectedRowData] = useState<any>(null);
  const items = [
    { text: t('openInNewTab'), icon: "fa-solid fa-up-right-from-square" },
    // { text: t('openLocation') , icon: "fa-solid fa-location-dot"}
  ];
  const [generalSetting] = useState<GeneralSetting>(new GeneralSetting());
  const { customerDivisions } = useClientSetting();

  const title = getTitle('/infrastructure/permanentCountStation', '');
  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        await getInitData();
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getInitData() {
    const postObj = {
      customerId: localStorage.getItem("selectedCustomerId"),
      divisionId: localStorage.getItem("selectedDivisionId"),
    }
    const res = await TesPost(
      InfrastructureApiUrl() + "/api/PermanentCountStations/GetCustomerPermanentCountStations",
      postObj,
      true) as RequestResponseResult<PermanentCountStation[]>;
    if (res.responseCode === ResponseCode.OK) {
      setInitData(res.results)
    } else {
      await RequestErrorHandling(res);
    }
  }

  const navigateToPermanentCountStationDetails = useCallback(() => {
    history("/infrastructure/permanentCountStationDetails/AddNew");
  }, [history]);

  function onRowClicked(e: any) {
    const isCtrlKeyPressed = e.event.ctrlKey || e.event.metaKey;
    if (e.data.id !== undefined) {

      if (isCtrlKeyPressed) {
        window.open(`/infrastructure/permanentCountStationDetails/${e.data.id}`, "_blank");
      } else {
        history(`/infrastructure/permanentCountStationDetails/${e.data.id}`);
      }
    }
  }

  return (
    // <Permission
    //   allowed={[ModuleOfModulePermissions.ModuleOfModule_D_Data, ModuleOfModulePermissions.ModuleOfModule_D_Module, ModuleOfModulePermissions.ModuleOfModule_E_Data, ModuleOfModulePermissions.ModuleOfModule_V_Data]}
    //   hasFeedBackElement={true}
    // >
    <React.Fragment>
      <ContextMenu
        dataSource={items}
        width={100}
        target=".dx-data-row"
        onItemClick={e => RightClickMenu(e, selectedRowData.row.key.id, `/infrastructure/permanentCountStationDetails/`)}
      />
      <h2 className={"content-block"}>{t("permanentCountStation")}</h2>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <div className="row">
            <div className="rightColumn">
              <Button
                onClick={navigateToPermanentCountStationDetails}
                icon="fa-solid fa-circle-plus"
                text={t("add")}
              />
            </div>
          </div>
          <DataGrid
            id="gridContainer"
            ref={dataGridRef}
            dataSource={initData}
            rowAlternationEnabled={true}
            showBorders={true}
            hoverStateEnabled={true}
            remoteOperations={true}
            onRowClick={onRowClicked}
            allowColumnReordering={true}
            allowColumnResizing={true}
            columnAutoWidth={true}
            style={{ margin: "0 1rem" }}
            onExporting={OnExporting}
            onContextMenuPreparing={e => { setSelectedRowData(e) }}
          >
            <Export enabled={true} allowExportSelectedData={true} />
            <Grouping contextMenuEnabled={true} autoExpandAll={false} />
            <GroupPanel visible={true} />
            <FilterPanel visible={true} />
            <FilterBuilderPopup position={"top"} />
            <Summary>
              <GroupItem summaryType="count" alignByColumn name="Total Count" />
            </Summary>
            <Paging enabled={true} defaultPageSize={100} />
            <Pager
              showPageSizeSelector={true}
              allowedPageSizes={[100, 200, 300, 400, 500]}
              showNavigationButtons={true}
              showInfo={true}
              visible={true}
            />
            <FilterRow visible={true} applyFilter="auto" />
            <HeaderFilter visible={true} />
            <SearchPanel visible={true} width={285} placeholder={t("search...")} />
            <ColumnChooser width={350} height={400} enabled={true} mode="select" sortOrder="asc">
              <Search enabled />
            </ColumnChooser>
            <Column dataField="id" caption={t("id")} visible={false}>
              <HeaderFilter>
                <Search enabled />
              </HeaderFilter>
            </Column>
            <Column dataField="name" caption={t("name")}>
              <HeaderFilter>
                <Search enabled />
              </HeaderFilter>
            </Column>
            <Column dataField="description" caption={t("description")}>
              <HeaderFilter>
                <Search enabled />
              </HeaderFilter>
            </Column>
            <Column dataField="locationId" caption={t("locationId")} visible={false}>
              <HeaderFilter>
                <Search enabled />
              </HeaderFilter>
            </Column>
            <Column dataField="installationDate" caption={t("installationDate")} dataType="date" format={generalSetting.dateTimeFormat}>
              <HeaderFilter>
                <Search enabled />
              </HeaderFilter>
            </Column>
            <Column
              dataField="DivisionId"
              allowSorting={true}
              visible={false}
              caption={t("division")}
            >
              <HeaderFilter>
                <Search enabled />
              </HeaderFilter>
              <Lookup
                dataSource={SortObjectByPropName(customerDivisions!, "name")}
                valueExpr="id"
                displayExpr="name"
              />
            </Column>
          </DataGrid>
        </div>
      </div>
    </React.Fragment>
    // </Permission>
  );
};
export default PermanentCountStations;

function RequestErrorHandling(res: RequestResponseResult<PermanentCountStation[]>) {
  throw new Error("Function not implemented.");
}

