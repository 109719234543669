import { SelectBox, TextArea } from "devextreme-react";
import { Button } from 'devextreme-react/button';
import {
    DataGridRef
} from 'devextreme-react/data-grid';
import Form, { SimpleItem } from "devextreme-react/form";
import { Popup } from 'devextreme-react/popup';
import notify from "devextreme/ui/notify";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { useAuth } from "../../contexts/auth";
import { AuthApiUrl, CollisionApiUrl, } from "../../environment/routeSettings";
import { XMLExportTask } from "../../types/collision/collisionTypes";
import { TransferCollisionTask } from "../../types/collision/dto/collisionDtos";
import { CollisionExportType, CollisionTransferAction, CollisionTransferMatchBy } from "../../types/collision/enums/collisionEnums";
import { INameId, NameValue } from "../../types/general/generalTypes";
import { Enum2Array } from "../../utils/enumTools";
import { useScreenSize } from "../../utils/media-query";
import { TesGet, TesPost } from '../../utils/rest';
import './exportCollision.scss';

interface IProps {
    dataGridRef: React.RefObject<DataGridRef<any, any>>
}


const ExportCollision = (props: IProps) => {
    const [showModal, setShowModal] = useState<boolean>(false);
    const { activeLoading } = useAuth();
    const [popupHeight, setPopupHeight] = useState<number | string>(670)
    const [gridHeight, setGridHeight] = useState<number | string>(340)
    const { isXLarge, is2xLarge } = useScreenSize();
    const [initData, setInitData] = useState<TransferCollisionTask>(new TransferCollisionTask());
    const [lstExportType, setLstExportType] = useState<NameValue[]>([]);
    const [clients, setClients] = useState<INameId[]>([]);
    const [lstMatchBy, setLstMatchBy] = useState<NameValue[]>([]);
    const [lstTransferActions, setLstTransferActions] = useState<NameValue[]>([]);
    const [emails, setEmails] = useState<string>("");


    useEffect(() => {
        if (isXLarge) { setPopupHeight(830); setGridHeight(500) }
        if (is2xLarge) { setPopupHeight("auto"); setGridHeight("auto") }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    useEffect(() => {
        async function fetchMyAPI() {
            try {
                // if (activeLoading) activeLoading(true);
                // if (activeLoading) activeLoading(false);
                setLstExportType(Enum2Array(CollisionExportType));
                setLstMatchBy(Enum2Array(CollisionTransferMatchBy));
                setLstTransferActions(Enum2Array(CollisionTransferAction));

                await getClients();
            } catch (ex) {
                if (activeLoading) activeLoading(false);
                notify(t("someErrorOccurred") + ex, "error", 5000);
            }
        }
        if (showModal) {
            fetchMyAPI();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showModal]);



    async function getClients() {
        setClients(
            await TesGet(
                AuthApiUrl() + "/api/CustomerXUsers/ClientsOfUser",
                true
            )
        );
    }

    function onValueChange(key: string, value: any) {
        setInitData({ ...initData, [key]: value });
    }

    async function onExport() {

        var countData = await props.dataGridRef?.current?.instance().getSelectedRowKeys();

        if ((countData?.length ?? 0) <= 0) {
            notify(t('noSelectedRows'), 'warning', 5000);
            setShowModal(false);
            return;
        }

        //check email regex
        const emailRegex = new RegExp(
            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
        );
        var emailArray = emails.split(";") as string[];
        emailArray.forEach((element: string) => {
            if (!(emailRegex.test(element ?? ""))) {
                notify(t("invalidEmailFormat"), "error", 2500);
            }
        });

        if (initData.collisionTransferType === CollisionExportType.Export) {
            const postObj: XMLExportTask = {
                CollisionIds: countData?.map(x => x) ?? [],
                emails: emailArray,
            }
            try {
                if (activeLoading) activeLoading(true);
                //add condition based on the export type
                await TesPost(CollisionApiUrl() + "/api/XMLExport/ExportXML", postObj, true)
                notify(t("exportResMessage"), "success", 5000);
                setShowModal(false)
                setInitData(new TransferCollisionTask());
                if (activeLoading) activeLoading(false);

            } catch (ex) {
                if (activeLoading) activeLoading(false);
                notify(t("someErrorOccurred") + ex, "error", 5000);
            }
        } else if (initData.collisionTransferType === CollisionExportType.Copy || initData.collisionTransferType === CollisionExportType.Move) {
            const postObj: TransferCollisionTask = {
                collisionIds: countData?.map(x => x) as string[] ?? [],
                emails: emailArray,
                collisionTransferType: initData?.collisionTransferType,
                toCustomerId: initData?.toCustomerId,
                matchBy: initData?.matchBy,
                collisionTransferAction: initData?.collisionTransferAction
            }
            try {
                if (activeLoading) activeLoading(true);
                //add condition based on the export type
                await TesPost(CollisionApiUrl() + "/api/XMLExport/TransferCollisions", postObj, true)
                notify(t("exportResMessage"), "success", 5000);
                setShowModal(false)
                setInitData(new TransferCollisionTask());
                if (activeLoading) activeLoading(false);

            } catch (ex) {
                if (activeLoading) activeLoading(false);
                notify(t("someErrorOccurred") + ex, "error", 5000);
            }
        }


    }

    function onCloseModal() {
        setShowModal(false)
        setInitData(new TransferCollisionTask());
    }


    return (
        <React.Fragment>
            <Button
                style={{ marginLeft: 10 }}
                onClick={() => setShowModal(true)}
                icon="fa-solid fa-file-export"
                hint={t('export')}
            />

            {/* Main Grid */}
            <Popup
                width={"65%"}
                height={popupHeight}
                visible={showModal}
                resizeEnabled={true}
                showTitle={true}
                title={t("export")}
                hideOnOutsideClick={false}
                showCloseButton={true}
                onHiding={() => onCloseModal()}
            >
                <div>
                    <Form colCount={2}>
                        <SimpleItem colSpan={1}>
                            <SelectBox
                                labelMode="static"
                                label={t('collisionTransferType')}
                                value={initData?.collisionTransferType}
                                items={lstExportType}
                                showClearButton={true}
                                searchEnabled={true}
                                valueExpr="value"
                                displayExpr="name"
                                onValueChange={(e) => onValueChange("collisionTransferType", e)}
                            />
                        </SimpleItem>
                        {(initData?.collisionTransferType === CollisionExportType.Copy || initData.collisionTransferType === CollisionExportType.Move) &&
                            <SimpleItem colSpan={1}>
                                <SelectBox
                                    labelMode="static"
                                    label={t('toCustomerId')}
                                    value={initData?.toCustomerId}
                                    items={clients}
                                    valueExpr="id"
                                    displayExpr="name"
                                    showClearButton={true}
                                    searchEnabled={true}
                                    onValueChange={(e) => onValueChange("toCustomerId", e)}
                                />
                            </SimpleItem>
                        }
                        {(initData?.collisionTransferType === CollisionExportType.Copy || initData.collisionTransferType === CollisionExportType.Move) &&
                            <SimpleItem colSpan={1}>
                                <SelectBox
                                    labelMode="static"
                                    label={t('matchBy')}
                                    value={initData?.matchBy}
                                    items={lstMatchBy}
                                    showClearButton={true}
                                    searchEnabled={true}
                                    valueExpr="value"
                                    displayExpr="name"
                                    onValueChange={(e) => onValueChange("matchBy", e)}
                                />
                            </SimpleItem>
                        }

                        {(initData?.collisionTransferType === CollisionExportType.Copy || initData.collisionTransferType === CollisionExportType.Move) &&
                            <SimpleItem colSpan={1}>
                                <SelectBox
                                    labelMode="static"
                                    label={t('transferAction')}
                                    value={initData?.collisionTransferAction}
                                    items={lstTransferActions}
                                    showClearButton={true}
                                    searchEnabled={true}
                                    valueExpr="value"
                                    displayExpr="name"
                                    onValueChange={(e) => onValueChange("collisionTransferAction", e)}
                                />
                            </SimpleItem>
                        }

                        <SimpleItem colSpan={1}>
                            <TextArea
                                labelMode="static"
                                label={t('emails')}
                                value={emails}
                                onValueChange={(e) => setEmails(e)}
                            />
                        </SimpleItem>
                    </Form>
                </div>
                <div className='rightColumn' >
                    <Button
                        className='tes-modal-btn-cancel'
                        style={{ marginRight: 20 }}
                        onClick={() => onCloseModal()}
                        text={t('cancel')}
                    />
                    <Button
                        className='tes-modal-btn-add'
                        onClick={onExport}
                        text={t('export')}
                    />

                </div>
            </Popup>

        </React.Fragment >
    );
}
export default ExportCollision;