import Drawer from "devextreme-react/drawer";
import ScrollView, { ScrollViewRef } from "devextreme-react/scroll-view";
import React, { useCallback, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { Template } from "devextreme-react/core/template";
import { Footer, Header, SideNavigationMenu } from "../../components";
import { useScreenSize } from "../../utils/media-query";
import { useMenuPatch } from "../../utils/patches";

import { useAuth } from "../../contexts/auth";
import { MenuStatus, useNavigation } from "../../contexts/navigation";
import "./side-nav-outer-toolbar.scss";

interface IProps {
  title: string;
  children: any;
}

export default function SideNavOuterToolbar({ title, children }: IProps) {
  const scrollViewRef = useRef<ScrollViewRef>(null);
  const history = useNavigate();
  const { selectedClineNameId } = useAuth();

  const { isXSmall, isLarge, isMedium, isXLarge, is2xLarge } = useScreenSize();
  const [patchCssClass, onMenuReady] = useMenuPatch();
  const { menuStatus, setMenuStatus } = useNavigation();

  const toggleMenu = useCallback(({ event }) => {
    if (setMenuStatus) {
      // if (menuStatus === MenuStatus.Closed) { setMenuStatus(MenuStatus.Opened) } else { setMenuStatus(MenuStatus.Closed) }
      setMenuStatus(
        (prevMenuStatus: MenuStatus) => prevMenuStatus === MenuStatus.Closed
          ? MenuStatus.Opened
          : MenuStatus.Closed
      );
      event.stopPropagation();
    }
  }, []);


  const temporaryOpenMenu = useCallback(() => {
    if (setMenuStatus) {
      setMenuStatus(
        (prevMenuStatus: MenuStatus) => prevMenuStatus === MenuStatus.Closed
          ? MenuStatus.TemporaryOpened
          : prevMenuStatus
      );
    }
  }, []);


  const onOutsideClick = useCallback(() => {
    if (setMenuStatus) {
      setMenuStatus((prevMenuStatus: MenuStatus) =>
        (prevMenuStatus !== MenuStatus.Closed && !isMedium && !isLarge && !isXLarge && !is2xLarge)
          ? MenuStatus.Closed
          : prevMenuStatus
      );
    }



    return true;
  }, [isMedium, isLarge, isXLarge, is2xLarge]);

  const onNavigationChanged = useCallback(
    ({ itemData: { path }, event, node }) => {
      const isCtrlKeyPressed = event.ctrlKey || event.metaKey;
      if (menuStatus === MenuStatus.Closed || !path || node.selected) {
        event.preventDefault();
        return;
      }
      if (path.includes("http")) {
        if (isCtrlKeyPressed) {
          window.open(path, "_blank");
        } else {
          window.open(path);
        }
      } else {
        if (isCtrlKeyPressed) {
          window.open(path, "_blank");
        } else {
          history(path);
        }
      }

      scrollViewRef.current?.instance().scrollTo(0);

      if (menuStatus === MenuStatus.TemporaryOpened) {
        if (setMenuStatus) setMenuStatus(MenuStatus.Closed);
        event.stopPropagation();
      }
    },
    [history, menuStatus]
  );



  useEffect(() => {
    if (isMedium || isLarge || isXLarge || is2xLarge) { if (setMenuStatus) setMenuStatus(MenuStatus.Opened) }
    // if (isMedium) { if (setMenuStatus) setMenuStatus(MenuStatus.Opened) }
  }, [isLarge, isMedium, isXLarge, is2xLarge])

  return (
    <div className={"side-nav-outer-toolbar"}>
      <Header
        // className={'layout-header'}
        menuToggleEnabled
        toggleMenu={toggleMenu}
        title={title}
        selectedClineNameId={selectedClineNameId}
      />
      <Drawer
        className={["drawer", patchCssClass].join(" ")}
        position={"before"}
        closeOnOutsideClick={onOutsideClick}
        // openedStateMode={(isMedium || isLarge || isXLarge || is2xLarge) ? "shrink" : "overlap"}
        revealMode={isXSmall ? "slide" : "expand"}
        minSize={isXSmall ? 0 : 60}
        maxSize={250}
        shading={(isMedium || isLarge || isXLarge || is2xLarge) ? false : true}
        opened={menuStatus === MenuStatus.Closed ? false : true}
        template={"menu"}
      >
        <div className={"container"}>
          <ScrollView ref={scrollViewRef} className={"layout-body with-footer"}>
            <div className={"content"}>
              {React.Children.map(children, (item) => {
                return item.type !== Footer && item;
              })}
            </div>
            <div className={"content-block"}>
              {React.Children.map(children, (item) => {
                return item.type === Footer && item;
              })}
            </div>
          </ScrollView>
        </div>
        <Template name={"menu"}>
          <SideNavigationMenu
            compactMode={menuStatus === MenuStatus.Closed}
            selectedItemChanged={onNavigationChanged}
            openMenu={temporaryOpenMenu}
            onMenuReady={onMenuReady}
          ></SideNavigationMenu>
        </Template>
      </Drawer>
    </div>
  );
}

// const MenuStatus = {
//   Closed: 1,
//   Opened: 2,
//   TemporaryOpened: 3,
// };
