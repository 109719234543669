import DateBox from 'devextreme-react/date-box';
import { Form, SimpleItem } from 'devextreme-react/form';
import { SelectBox } from 'devextreme-react/select-box';
import { Switch } from 'devextreme-react/switch';
import TextBox from 'devextreme-react/text-box';
import ValidationGroup, { ValidationGroupRef } from 'devextreme-react/validation-group';
import { t } from 'i18next';
import React from 'react';
import IntersectionValuePicker from '../../../../../components/intersectionValuePicker/intersectionValuePicker';
import StreetValuePicker from '../../../../../components/streetValuePicker/streetValuePicker';
import { FieldCategoryType, FieldPermission, FieldType } from '../../../../../types/field/enums/fieldEnums';
import { TesField } from '../../../../../types/field/fieldType';
import { PageMode } from '../../../../../types/general/enums/generalEnums';
import { Location } from '../../../../../types/infrastructure/infrastructureTypes';
import { GeneralSetting } from '../../../../../types/setting/gridSettingTypes';
import { SortObjectByPropName } from '../../../../../utils/arrayTools';

// props
interface IPros {
    initDataFields: TesField[] | undefined;
    location: Location;
    onChangeDescription: (name: string, value: string) => void;
    setLocation: React.Dispatch<React.SetStateAction<any>>;
    generalSettings: GeneralSetting | null;
    isLocked: boolean;
    setDataChanged: React.Dispatch<React.SetStateAction<any>>;
    pageMode: number
    validationRef: React.RefObject<ValidationGroupRef>
}

const Details = (props: IPros) => {

    function onChangeFields(name: string, value: any, isUpperCase: boolean) {
        if (name === "limit1IntersectionId" || name === "limit2IntersectionId" || name === "streetId" || name === "limit1StreetId" || name === "limit2StreetId") {
            props.setLocation({
                ...props.location,
                [name]: value
            })
            if (props.pageMode === PageMode.Update) {
                props.setDataChanged(true)
            }
        } else {
            props.setLocation({
                ...props.location,
                general: {
                    ...props.location.general,
                    [name]: value
                }
            })
        }
        if (props.pageMode === PageMode.Update) {
            props.setDataChanged(true)
        }
    }

    return (
        <React.Fragment>
            <div className='row' style={{ marginTop: 20, padding: 10 }}>
                <ValidationGroup
                    ref={props.validationRef}
                >
                    <Form colCount={2}>
                        <SimpleItem colSpan={1}>
                            <IntersectionValuePicker
                                label={t('intersectionLimit1')}
                                name={'limit1IntersectionId'}
                                onChangeFields={onChangeFields}
                                value={props.location.limit1IntersectionId}
                                isLocked={props.isLocked}
                            />
                        </SimpleItem>
                        <SimpleItem colSpan={1}>
                            <IntersectionValuePicker
                                label={t('intersectionLimit2')}
                                name={'limit2IntersectionId'}
                                onChangeFields={onChangeFields}
                                value={props.location.limit2IntersectionId}
                                isLocked={props.isLocked}
                            />
                        </SimpleItem>
                        <SimpleItem colSpan={1}>
                            <StreetValuePicker
                                label={t('streetName')}
                                name={'streetId'}
                                value={props.location.streetId}
                                onChangeDescription={props.onChangeDescription}
                                onChangeFields={onChangeFields}
                                isLocked={props.isLocked}
                                isTextBox={true}

                            />
                        </SimpleItem>
                        <SimpleItem colSpan={1}>
                            <StreetValuePicker
                                label={t('limit1StreetId')}
                                name={'limit1StreetId'}
                                value={props.location.limit1StreetId}
                                onChangeFields={onChangeFields}
                                onChangeDescription={props.onChangeDescription}
                                isLocked={props.isLocked}
                                isTextBox={true}
                            />
                        </SimpleItem>
                        <SimpleItem colSpan={1}>
                            <StreetValuePicker
                                label={t('limit2StreetId')}
                                name={'limit2StreetId'}
                                value={props.location.limit2StreetId}
                                onChangeFields={onChangeFields}
                                onChangeDescription={props.onChangeDescription}
                                isLocked={props.isLocked}
                                isTextBox={true}
                            />
                        </SimpleItem>
                        {/* eslint-disable-next-line array-callback-return */}
                        {props.initDataFields && props.initDataFields.filter(x => x.fieldCategoryType === FieldCategoryType.InfrastructureMidBlockDetails).map((f, i) => {
                            if (f.fieldType === FieldType.String) {
                                return (
                                    <SimpleItem colSpan={1}>
                                        <TextBox
                                            // style={{ marginBottom: 20 }}
                                            label={f.labelText}
                                            labelMode='static'
                                            value={props.location.general && props.location.general[f.name]}
                                            onValueChange={e => onChangeFields(f.name, e, true)}
                                            key={i}
                                            disabled={props.isLocked || f.permission === FieldPermission.View || f.readOnlyField}
                                        />
                                    </SimpleItem>
                                );
                            }
                            if (f.fieldType === FieldType.Number || f.fieldType === FieldType.DecimalNumber || f.fieldType === FieldType.DoubleNumber) {
                                return (
                                    <SimpleItem colSpan={1}>
                                        <TextBox
                                            // style={{ marginBottom: 20 }}
                                            label={f.labelText}
                                            labelMode='static'
                                            value={props.location.general && props.location.general[f.name]}
                                            onValueChange={e => onChangeFields(f.name, e, true)}
                                            key={i}
                                            disabled={props.isLocked || f.permission === FieldPermission.View || f.readOnlyField}
                                        />
                                    </SimpleItem>
                                );
                            }

                            if (f.fieldType === FieldType.Boolean) {
                                return (
                                    <SimpleItem colSpan={1}>
                                        <div style={{ fontWeight: "bold" }}>{f.labelText}</div>
                                        <Switch
                                            // style={{ marginBottom: 20 }}
                                            onValueChange={e => onChangeFields(f.name, e, true)}
                                            value={props.location.general && props.location.general[f.name]}
                                            key={i}
                                            disabled={props.isLocked || f.permission === FieldPermission.View || f.readOnlyField}
                                        />
                                    </SimpleItem>
                                );
                            }

                            if (f.fieldType === FieldType.Date) {
                                return (
                                    <SimpleItem colSpan={1}>
                                        <DateBox
                                            // style={{ marginBottom: 20 }}
                                            label={f.labelText}
                                            value={props.location.general && props.location.general[f.name]}
                                            type="date"
                                            onValueChange={e => onChangeFields(f.name, e, true)}
                                            key={i}
                                            pickerType="calendar"
                                            placeholder={props.generalSettings?.dateTimeFormat}
                                            displayFormat={props.generalSettings?.dateTimeFormat}
                                            useMaskBehavior={true}
                                            openOnFieldClick={true}
                                            disabled={props.isLocked || f.permission === FieldPermission.View || f.readOnlyField}
                                            showClearButton={true}
                                        />
                                    </SimpleItem>
                                );
                            }

                            if (f.fieldType === FieldType.Time) {
                                return (
                                    <SimpleItem colSpan={1}>
                                        <DateBox
                                            // style={{ marginBottom: 20 }}
                                            label={f.labelText}
                                            value={props.location.general && props.location.general[f.name]}
                                            type="time"
                                            onValueChange={e => onChangeFields(f.name, e, true)}
                                            key={i}
                                            pickerType="rollers"
                                            placeholder={props.generalSettings?.timeFormat}
                                            displayFormat={props.generalSettings?.timeFormat}
                                            useMaskBehavior={true}
                                            openOnFieldClick={true}
                                            disabled={props.isLocked || f.permission === FieldPermission.View || f.readOnlyField}
                                            showClearButton={true}
                                        />
                                    </SimpleItem>
                                );
                            }

                            if (f.fieldType === FieldType.DateTime) {
                                return (
                                    <SimpleItem colSpan={1}>
                                        <DateBox
                                            // style={{ marginBottom: 20 }}
                                            label={f.labelText}
                                            value={props.location.general && props.location.general[f.name]}
                                            type="datetime"
                                            onValueChange={e => onChangeFields(f.name, e, true)}
                                            key={i}
                                            pickerType="calendar"
                                            placeholder={props.generalSettings?.dateTimeFormat}
                                            displayFormat={props.generalSettings?.dateTimeFormat}
                                            useMaskBehavior={true}
                                            openOnFieldClick={true}
                                            disabled={props.isLocked || f.permission === FieldPermission.View || f.readOnlyField}
                                            showClearButton={true}
                                        />
                                    </SimpleItem>
                                );
                            }


                            if (f.fieldType === FieldType.List) {
                                return (
                                    <SimpleItem colSpan={1}>
                                        <SelectBox
                                            // style={{ marginBottom: 20 }}
                                            items={SortObjectByPropName(f.fieldValues, "name")}
                                            displayExpr="name"
                                            valueExpr="id"
                                            labelMode='static'
                                            label={f.labelText}
                                            placeholder=""
                                            value={props.location.general && props.location.general[f.name]}
                                            onValueChange={e => onChangeFields(f.name, e, true)}
                                            key={i}
                                            disabled={props.isLocked || f.permission === FieldPermission.View || f.readOnlyField}
                                            showClearButton={true}
                                            searchEnabled={true}
                                        />
                                    </SimpleItem>
                                );
                            }

                        })
                        }
                        <div style={{ fontWeight: "bold" }}>{t("inactive")}</div>
                        <Switch
                            style={{ marginTop: 5 }}
                            hint={t("inactive")}
                            value={props.location.inactive}
                            onValueChange={e => onChangeFields("inactive", e, true)}
                            disabled={props.isLocked}
                        />
                    </Form>
                </ValidationGroup>
            </div>
        </React.Fragment >
    );


}
export default Details;