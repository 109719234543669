import { Form, NumberBox, SelectBox, ValidationGroup } from 'devextreme-react';
import { ValidationGroupRef } from 'devextreme-react/cjs/validation-group';
import { SimpleItem } from 'devextreme-react/form';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CollapsibleCard from '../../../../../components/collapsibleCard/collapsibleCard';
import InfrastructureLocationPicker from '../../../../../components/infrastructureLocationPicker/infrastructureLocationPicker';
import { VMMapLocation } from '../../../../../types/collision/dto/collisionDtos';
import { TesMapModule } from '../../../../../types/general/enums/generalEnums';
import { VMLocation } from '../../../../../types/infrastructure/dto/locationdDto';
import { LocationPickerMode } from '../../../../../types/infrastructure/enums/infrastructureEnums';
import { TesSign } from '../../../../../types/sign/signTypes';

// props
interface IPros {
    initDataSign: TesSign,
    initDataLocation: VMLocation
    isLocked: boolean;
    setDataChanged: React.Dispatch<React.SetStateAction<any>>;
    onValueChange: (name: string, value: any) => void;
    validationRef: React.RefObject<ValidationGroupRef>
}

const Location = (props: IPros) => {
    const [mapLocation, setMapLocation] = useState<VMMapLocation>();
    const { t } = useTranslation();
    const compactViewModel: boolean = (JSON.parse(localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}') || {}).viewMode === "compact";

    useEffect(() => {
        var VMLocation: VMMapLocation = {
            latitude: props.initDataSign.support?.latitude ?? 0,
            longitude: props.initDataSign.support?.longitude ?? 0,
            xCoord: props.initDataSign.support?.xCoord ?? 0,
            yCoord: props.initDataSign.support?.yCoord ?? 0
        }
        setMapLocation(VMLocation);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.initDataLocation]);



    return (
        <div className={`signDetails-location ${compactViewModel ? "compactStyle" : ""}`}>
            <React.Fragment>
                <ValidationGroup
                    ref={props.validationRef}
                >
                    <div className='row' style={{ marginTop: 20, padding: 10 }}>
                        {(props.initDataSign?.support) ?
                            <Form colCount={2}>
                                <SimpleItem colSpan={1}>
                                    <CollapsibleCard title={t("mapLocation")} isOpen={true}>
                                        <InfrastructureLocationPicker
                                            didMount={true}
                                            tesModule={TesMapModule.InfrastructureDetails}
                                            componentMode={LocationPickerMode.TextBox}
                                            renderMap={true}
                                            renderCoordinate={false}
                                            tempMapLocation={mapLocation}
                                            disableLocationSelection={true}
                                            showIcons={true}
                                            initLocationData={{
                                                customerId: props.initDataLocation.customerId,
                                                id: props.initDataSign.support.locationId,
                                            }}

                                        //tempMapLocation={VMMapLocation}
                                        />
                                    </CollapsibleCard>
                                </SimpleItem>
                                <SimpleItem colSpan={1}>
                                    <NumberBox
                                        step={0}
                                        style={{ marginBottom: 20 }}
                                        label={t('xCoord')}
                                        labelMode='static'
                                        value={props.initDataSign?.support?.xCoord}
                                        disabled={props.isLocked}
                                        onValueChange={(e) => props.onValueChange("xCoord", e)}
                                    />
                                    <NumberBox
                                        step={0}
                                        style={{ marginBottom: 20 }}
                                        label={t('yCoord')}
                                        labelMode='static'
                                        value={props.initDataSign?.support?.yCoord}
                                        disabled={props.isLocked}
                                        onValueChange={(e) => props.onValueChange("xCoord", e)}
                                    />
                                    <NumberBox
                                        step={0}
                                        style={{ marginBottom: 20 }}
                                        label={t('latitude')}
                                        labelMode='static'
                                        value={props.initDataSign?.support?.latitude}
                                        disabled={props.isLocked}
                                        onValueChange={(e) => props.onValueChange("xCoord", e)}
                                    />
                                    <NumberBox
                                        step={0}
                                        style={{ marginBottom: 20 }}
                                        label={t('longitude')}
                                        labelMode='static'
                                        value={props.initDataSign?.support?.longitude}
                                        disabled={props.isLocked}
                                        onValueChange={(e) => props.onValueChange("xCoord", e)}
                                    />
                                    <SelectBox
                                        placeholder=""
                                        label={t('locationType')}
                                        valueExpr="id"
                                        displayExpr="name"
                                        labelMode='static'
                                        value={props.initDataSign?.support?.supportLocationTypeId}
                                        //onValueChange={(e)=>props.onChangeFields("supportLocationTypeId",e)}
                                        className="modalInput"
                                        //items={props.initDataSetups.supportLocationType}
                                        disabled={props.isLocked}
                                        onValueChange={(e) => props.onValueChange("xCoord", e)}
                                        showClearButton={true}
                                        searchEnabled={true}
                                    />
                                </SimpleItem>

                            </Form>
                            :
                            <div className='row' style={{ marginTop: 20, padding: 10 }}>
                                <div className='halfColumn' >
                                    <p style={{ fontWeight: 'bold' }}>{t("thereIsNoDataInThisTab")}</p>
                                </div>
                            </div>
                        }
                    </div>
                </ValidationGroup>
            </React.Fragment>
        </div>
    );
}
export default Location;