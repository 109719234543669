//#region impoprts
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { TesPut } from "../../../../../utils/rest";
import { Popup } from "devextreme-react/popup";
import DataGrid, {
  Column,
  FilterRow,
  HeaderFilter,
  SearchPanel,
  ColumnChooser,
  Pager,
  Paging,
  Button as DevExpressButton,
  Export,
  Selection,
  GroupPanel,
  Grouping,
  Search,
  DataGridRef,
} from "devextreme-react/data-grid";
import {
  JobStatus,
  SignStatus,
} from "../../../../../types/maintenance/maintenanceTypes";
import { Button } from "devextreme-react/button";
import CustomStore from "devextreme/data/custom_store";
import notify from "devextreme/ui/notify";
import { custom } from "devextreme/ui/dialog";
import { VMWebSign } from "../../../../../types/sign/dto/signDto";
import { SelectBox, TextArea, ValidationGroup } from "devextreme-react";
import ScrollView from "devextreme-react/scroll-view";
import { Form, SimpleItem } from "devextreme-react/form";
import Permission from "../../../../../components/permission/permision";
import { MaintenancePermissions } from "../../../../../constants/Permissions";
import { SignApiUrl } from "../../../../../environment/routeSettings";
import { OnExporting } from "../../../../../utils/dataGridTools";
import { SortObjectByPropName } from "../../../../../utils/arrayTools";
import { ValidationGroupRef } from "devextreme-react/cjs/validation-group";
//#endregion imports

//#region props
interface IPros {
  initDataJobStatuses: JobStatus[];
  initDataSigns: VMWebSign[];
  generalSettings: any;
  signStatus: SignStatus;
  assignSign: (lstSigns: VMWebSign[]) => void;
  onDeleteSign: (sign: VMWebSign) => void;
  assignSignStatus: (signId: string) => void;
  onValueChangeSignStatus: (name: string, value: any) => void;
  addNewSignStatus: (selectedSign: VMWebSign) => void;
  isLocked: boolean;
  setDataChanged: React.Dispatch<React.SetStateAction<any>>;
  validationRef: React.RefObject<ValidationGroupRef>
}
//#endregion props

const Signs = (props: IPros) => {
  //#region  consts
  const [showModalAssignSign, setShowModalAssignSign] = useState(false);
  const [showModalStatus, setShowModalStatus] = useState(false);
  const [selectedSign, setSelectedSign] = useState<VMWebSign>(new VMWebSign());
  const { t } = useTranslation();
  const dataGridRef = useRef<DataGridRef<any, any>>(null);
  const dataGridRefAssignSign = useRef<DataGridRef<any, any>>(null);

  //#endregion costs

  //#region functions
  const dataSourceAssignSign = new CustomStore({
    load: async (loadOption) => {
      return await TesPut(
        `${SignApiUrl()}/api/TesSigns/LazyLoading/${localStorage.getItem(
          "selectedCustomerId"
        )}/false`,
        JSON.stringify(loadOption),
        true
      );
    },
  });
  function onCloseModalAddSign() {
    setShowModalAssignSign(false);
    dataGridRefAssignSign.current?.instance().deselectAll();
  }
  function onDelete(d: any) {
    let myDialog = custom({
      title: t("warning"),
      messageHtml: t("deleteWarningMessage"),
      buttons: [
        {
          text: t("yes"),
          onClick: async (e) => {
            try {
              props.onDeleteSign(d);
              notify(t("dataSuccessfullyDeleted"), "success", 5000);
            } catch {
              notify(t("someErrorOccurred"), "error", 5000);
            }
            return { buttonText: e.component.option("text") };
          },
        },
        {
          text: t("no"),
          onClick: (e) => {
            return { buttonText: e.component.option("text") };
          },
        },
      ],
    });
    myDialog.show();
  }
  function onAssignSign() {
    var lstSigns =
      dataGridRefAssignSign.current?.instance().getSelectedRowKeys() as VMWebSign[];
    props.assignSign(lstSigns);
    setShowModalAssignSign(false);
    props.setDataChanged(true)
  }
  function calculateStatus(d: any) {
    if (props.initDataJobStatuses) {
      const v = props.initDataJobStatuses.find((x) => x.id === d.statusId);
      if (v) {
        return v.name;
      }
    }
  }
  function replaceStr(str: string) {
    str = str.replaceAll("/", "");
    str = str.replaceAll(":", "");
    str = str.replaceAll("*", "");
    str = str.replaceAll("?", "");
    str = str.replaceAll(">", "");
    str = str.replaceAll("<", "");
    str = str.replaceAll("|", "");
    str = str.replaceAll("\\", "");
    str = str.replaceAll(" ", "-");
    return str;
  }
  function calculateImageTypeCell(d: any) {
    const data = d.data;
    if (data.signCode) {
      return (
        <div>
          {/* eslint-disable-next-line jsx-a11y/alt-text */}
          <img
            src={
              "http://staticfile.tes.ca/sign/" +
              localStorage.getItem("selectedCustomerName") +
              "/" +
              replaceStr(data.signCode.code) +
              ".png"
            }
            width="50"
            height="50"
          />
        </div>
      );
    }
    if (data.signCodeTitle) {
      return (
        <div>
          {/* eslint-disable-next-line jsx-a11y/alt-text */}
          <img
            src={
              "http://staticfile.tes.ca/sign/" +
              localStorage.getItem("selectedCustomerName") +
              "/" +
              replaceStr(data.signCodeTitle) +
              ".png"
            }
            width="50"
            height="50"
          />
        </div>
      );
    }
  }
  function calculateValuesListCell(d: any) {
    return (
      <Button
        className="tes-modal-btn-add"
        onClick={() => onOpenStatus(d.data)}
        text={t("status")}
      />
    );
  }
  function onOpenStatus(data: any) {
    props.assignSignStatus(data.id);
    setSelectedSign(data);
    setShowModalStatus(true);
  }
  function onCloseModalSignStatus() {
    setShowModalStatus(false);
  }
  function onSaveStatus() {
    props.addNewSignStatus(selectedSign);
    dataGridRef.current?.instance().refresh();
    setShowModalStatus(false);
  }
  //#endregion functions
  return (
    <React.Fragment>
      <ValidationGroup
        ref={props.validationRef}
      >
        <div className={"content-block"}>
          <div className={" responsive-paddings"}>
            <div className="row">
              <div className="rightColumn">
                <Button
                  style={{ marginLeft: 5 }}
                  onClick={() => setShowModalAssignSign(true)}
                  icon="fa-solid fa-link"
                  text={t("assign")}
                  disabled={props.isLocked}
                />
              </div>
            </div>
            {/* Maintenance datagrid */}
            <DataGrid
              id="gridContainer"
              ref={dataGridRef}
              dataSource={props.initDataSigns}
              rowAlternationEnabled={true}
              showBorders={true}
              hoverStateEnabled={true}
              remoteOperations={true}
              allowColumnReordering={true}
              allowColumnResizing={true}
              columnAutoWidth={true}
              onExporting={OnExporting}
            >
              <Paging enabled={true} defaultPageSize={100} />
              <Pager
                showPageSizeSelector={true}
                allowedPageSizes={[100, 200, 300, 400, 500]}
                showNavigationButtons={true}
                showInfo={true}
                visible={true}
              />

              <Export enabled={true} allowExportSelectedData={true} />

              <FilterRow visible={true} applyFilter="auto" />

              <HeaderFilter visible={true} />

              <SearchPanel visible={true} width={285} placeholder={t("search...")} />
              <ColumnChooser width={350} height={400} enabled={true} mode="select" sortOrder="asc">
                <Search enabled />
              </ColumnChooser>
              <Column
                alignment="left"
                dataField="ChangeStatus"
                cellRender={calculateImageTypeCell.bind(this)}
                caption={t("symbol")}
                width={100}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>

              <Column dataField="geoId" caption={t("geoId")}>
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>

              <Column dataField="signLocationTypeTitle" caption={t("locationType")}>
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>

              <Column alignment="left" dataField="signId" caption={t("signId")}>
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>

              <Column
                alignment="left"
                dataField="supportTitle"
                caption={t("supportId")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>

              <Column alignment="left" dataField="signCodeTitle" caption={t("code")}>
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>

              <Column
                alignment="left"
                dataField="signDimensionTitle"
                caption={t("dimension")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>

              <Column
                alignment="left"
                dataField="height"
                visible={false}
                caption={t("height")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>

              <Column
                alignment="left"
                dataField="signFacingDirectionTitle"
                visible={false}
                caption={t("facingDirection")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>

              <Column
                alignment="left"
                dataField="signFaceMaterialTitle"
                visible={false}
                caption={t("faceMaterial")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>

              <Column
                alignment="left"
                dataField="signReflectiveCoatingTitle"
                visible={false}
                caption={t("reflectiveCoating")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>

              <Column
                alignment="left"
                dataField="signReflectiveRatingTitle"
                visible={false}
                caption={t("reflectiveRating")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>

              <Column
                alignment="left"
                dataField="signConditionTitle"
                visible={false}
                caption={t("condition")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>

              <Column
                alignment="left"
                dataField="dateInstalled"
                dataType="date"
                format={props.generalSettings.dateFormat}
                visible={false}
                caption={t("installationDate")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>

              <Column
                alignment="left"
                dataField="dateExpired"
                dataType="date"
                format={props.generalSettings.dateFormat}
                visible={false}
                caption={t("expirationDate")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>

              <Column
                alignment="left"
                dataField="materialCost"
                visible={false}
                caption={t("materialCost")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>

              <Column
                alignment="left"
                dataField="labourCost"
                visible={false}
                caption={t("labourCost")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>

              <Column
                alignment="left"
                dataField="installationCost"
                visible={false}
                caption={t("installationCost")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column alignment="left" dataField="note" caption={t("comment")}>
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>

              <Column
                alignment="left"
                dataField="statusId"
                calculateDisplayValue={calculateStatus}
                caption={t("status")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>

              <Column
                alignment="left"
                cellRender={calculateValuesListCell}
                //visible={!(this.props.isLocked)}
                caption={t("status")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>

              {/* {!this.props.isLocked && */}
              <Column type="buttons" caption={t("actions")} width={110} visible={!props.isLocked} fixed={true} fixedPosition="right">
                <DevExpressButton
                  hint={t("delete")}
                  onClick={onDelete}
                  icon="fa-solid fa-trash-can"
                ></DevExpressButton>
              </Column>
              {/* } */}
            </DataGrid>
          </div>
        </div>

        {/* Assign Sign Modal */}
        <Popup
          width={"65%"}
          visible={showModalAssignSign}
          resizeEnabled={true}
          showTitle={true}
          title={t("assignSign")}
          hideOnOutsideClick={false}
          showCloseButton={true}
          onHiding={() => onCloseModalAddSign()}
        >
          <div style={{ height: "90%" }}>
            <DataGrid
              id="gridContainer"
              ref={dataGridRefAssignSign}
              dataSource={dataSourceAssignSign}
              rowAlternationEnabled={true}
              showBorders={true}
              style={{ height: "100%" }}
              keyExpr="id"
              hoverStateEnabled={true}
              remoteOperations={true}
              allowColumnReordering={true}
              allowColumnResizing={true}
              columnAutoWidth={true}
              onExporting={OnExporting}
            //onContentReady={e => {e.component.deselectAll()}}
            //onFilterValueChange={e => {dataGridRefAssignSign?.current?.instance().deselectAll()}}
            >
              <Export enabled={true} allowExportSelectedData={true} />
              <Grouping contextMenuEnabled={true} autoExpandAll={false} />
              <GroupPanel visible={true} /> {/* or "auto" */}
              <Paging enabled={true} defaultPageSize={100} />
              <Pager
                showPageSizeSelector={true}
                allowedPageSizes={[100, 200, 300, 400, 500]}
                showNavigationButtons={true}
                showInfo={true}
                visible={true}
              />
              <Selection
                mode="multiple"
                selectAllMode={"allPages"}
                showCheckBoxesMode={"always"}
              />
              <FilterRow visible={true} applyFilter="auto" />
              <HeaderFilter visible={true} />
              <SearchPanel visible={true} width={285} placeholder={t("search...")} />
              <ColumnChooser width={350} height={400} enabled={true} mode="select" sortOrder="asc">
                <Search enabled />
              </ColumnChooser>
              <Column dataField="geoId" caption={t("geoId")}>
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column dataField="signLocationTypeTitle" caption={t("locationType")}>
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column alignment="left" dataField="signId" caption={t("signId")}>
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                alignment="left"
                dataField="supportTitle"
                caption={t("supportId")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column alignment="left" dataField="signCodeTitle" caption={t("code")}>
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                alignment="left"
                dataField="signDimensionTitle"
                visible={false}
                caption={t("dimension")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                alignment="left"
                dataField="height"
                visible={false}
                caption={t("height")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                alignment="left"
                dataField="signFacingDirectionTitle"
                visible={false}
                caption={t("facingDirection")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                alignment="left"
                dataField="signFaceMaterialTitle"
                visible={false}
                caption={t("faceMaterial")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                alignment="left"
                dataField="signReflectiveCoatingTitle"
                visible={false}
                caption={t("reflectiveCoating")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                alignment="left"
                dataField="signReflectiveRatingTitle"
                visible={false}
                caption={t("reflectiveRating")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                alignment="left"
                dataField="signConditionTitle"
                visible={false}
                caption={t("condition")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                alignment="left"
                dataField="dateInstalled"
                dataType="date"
                format={props.generalSettings.dateFormat}
                visible={false}
                caption={t("installationDate")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                alignment="left"
                dataField="dateExpired"
                dataType="date"
                format={props.generalSettings.dateFormat}
                visible={false}
                caption={t("expirationDate")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                alignment="left"
                dataField="materialCost"
                visible={false}
                caption={t("materialCost")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                alignment="left"
                dataField="labourCost"
                visible={false}
                caption={t("labourCost")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                alignment="left"
                dataField="installationCost"
                visible={false}
                caption={t("installationCost")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
            </DataGrid>
          </div>

          <div style={{ marginTop: 20 }}>
            <div className="rightColumn">
              <Button
                className="tes-modal-btn-cancel"
                style={{ marginRight: 20 }}
                onClick={onCloseModalAddSign}
                text={t("cancel")}
              />
              <Permission
                allowed={[
                  MaintenancePermissions.Maintenance_E_Job,
                  MaintenancePermissions.Maintenance_D_Job,
                ]}
                hasFeedBackElement={false}
              >
                <Button
                  className="tes-modal-btn-add"
                  onClick={onAssignSign}
                  text={t("add")}
                />
              </Permission>
            </div>
          </div>
        </Popup>

        {/* Status Modal */}
        <Popup
          width={"40%"}
          height="auto"
          visible={showModalStatus}
          resizeEnabled={true}
          showTitle={true}
          title={t("add")}
          hideOnOutsideClick={false}
          showCloseButton={true}
          onHiding={() => onCloseModalSignStatus()}
        >
          <ScrollView width="100%" height="100%">
            <Form colCount={2}>
              <SimpleItem colSpan={2}>
                <SelectBox
                  placeholder=""
                  style={{ marginBottom: -10 }}
                  label={t("statusId")}
                  labelMode="static"
                  valueExpr="id"
                  displayExpr="name"
                  onValueChange={(e) => props.onValueChangeSignStatus("statusId", e)}
                  value={props.signStatus.statusId}
                  items={SortObjectByPropName(props.initDataJobStatuses, "name")}
                  showClearButton={true}
                  searchEnabled={true}
                />
              </SimpleItem>
              <SimpleItem colSpan={2}>
                <TextArea
                  style={{ marginBottom: 20 }}
                  label={t("note")}
                  height={50}
                  labelMode="static"
                  onValueChange={(e) => props.onValueChangeSignStatus("note", e)}
                  value={props.signStatus.note}
                />
              </SimpleItem>
            </Form>
            <div>
              <div className="rightColumn">
                <Button
                  className="tes-modal-btn-cancel"
                  style={{ marginRight: 20 }}
                  onClick={() => setShowModalStatus(false)}
                  text={t("cancel")}
                />
                <Button
                  className="tes-modal-btn-add"
                  onClick={onSaveStatus}
                  text={t("add")}
                />
              </div>
            </div>
          </ScrollView>
        </Popup>
      </ValidationGroup>
    </React.Fragment>
  );
};

export default Signs;
