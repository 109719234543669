import { Switch, TextBox, ValidationGroup, Validator } from "devextreme-react";
import { ValidationGroupRef } from "devextreme-react/cjs/validation-group";
import { Form, RequiredRule, SimpleItem } from "devextreme-react/form";
import React from "react";
import { useTranslation } from "react-i18next";
import { AADTPattern } from "../../../../../../types/trafficStudy/trafficStudyTypes";

// props
interface IPros {
  initData: AADTPattern,
  setInitData: React.Dispatch<React.SetStateAction<any>>;
  isLocked: boolean;
  setDataChanged: React.Dispatch<React.SetStateAction<any>>;
  validationRef: React.RefObject<ValidationGroupRef>
}

const Details = (props: IPros) => {
  const { t } = useTranslation();

  function onValueChange(name: string, value: any) {
    props.setInitData({ ...props.initData, [name]: value })
    console.log(props.initData.hourFactors)
    props.setDataChanged(true)
  }

  return (
    <React.Fragment>
      <div className="row" style={{ marginTop: 20, padding: 10 }}>
        <ValidationGroup ref={props.validationRef}>
          <Form colCount={2}>
            <SimpleItem colSpan={1}>
              <TextBox
                label={t("name")}
                labelMode="static"
                value={props.initData.name}
                onValueChange={(e) => onValueChange("name", e)}
                disabled={props.isLocked}
              >
                <Validator>
                  <RequiredRule message={t("fieldIsRequired")} />
                </Validator>
              </TextBox>
            </SimpleItem>
            <SimpleItem colSpan={1}>
              <div style={{ marginLeft: "1rem", marginBottom: "0.25rem" }}>{t("isDefault")}</div>
              <Switch
                disabled={props.isLocked}
                style={{ marginLeft: "1rem" }}
                defaultValue={false}
                value={props.initData.isDefault}
                onValueChange={e => onValueChange("isDefault", e)}
                hint={t("isDefault")}
              />
            </SimpleItem>
          </Form>
        </ValidationGroup>
      </div>
    </React.Fragment>
  );
};
export default Details;
