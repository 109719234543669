import { Button, NumberBox, SelectBox, Tabs, TagBox } from 'devextreme-react';
import { Form, SimpleItem } from 'devextreme-react/form';
import notify from 'devextreme/ui/notify';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CollapsibleCard from '../../../../../components/collapsibleCard/collapsibleCard';
import { useAuth } from '../../../../../contexts/auth';
import { NameValue, NameValueNumber } from '../../../../../types/general/generalTypes';
import { SectionDetailType, SectionType } from '../../../../../types/infrastructure/enums/infrastructureEnums';
import { ClientReport } from '../../../../../types/report/reportTypes';
import { SignalWarrantsPredictionResult } from '../../../../../types/systematicScreening/systematicScreeningTypes';
import { ApproachLaneNo, ApproachType, FlowConditionType } from '../../../../../types/trafficStudy/enums/trafficStudyEnums';
import { SortObjectByPropName } from '../../../../../utils/arrayTools';
import { Enum2Array, EnumFlag2Array } from '../../../../../utils/enumTools';
import TabOne from './components/1';
import TabTwo from './components/2';
import TabThree from './components/3';
import TabFour from './components/4';
import TabFive from './components/5';
import TabSix from './components/6';
import tabTitles from './data/data';
import "./signalWarrants.scss";


// props
interface IPros {
  initData: SignalWarrantsPredictionResult,
}



const SignalWarrantsTab = (props: IPros) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [justificationName, setJustificationName] = useState<string>("");
  const dropDownOptions = { width: 215 };
  const { t } = useTranslation();
  const { activeLoading } = useAuth();
  const compactViewModel: boolean = (JSON.parse(localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}') || {}).viewMode === "compact";
  const [lstApproachLaneOnMajor, setApproachLaneOnMajor] = useState<NameValue[]>([]);
  const [lstApproachLaneOnMinor, setLstApproachLaneOnMinor] = useState<NameValue[]>([]);
  const [lstYears, setLstYears] = useState<NameValueNumber[]>([]);
  const [lstSiteType, setLstSiteType] = useState<NameValue[]>([]);
  const [lstFlowCondition, setLstFlowCondition] = useState<NameValue[]>([]);
  const [lstChannelizedRightTurnsApproaches, setLstChannelizedRightTurnsApproaches] = useState<NameValue[]>([]);
  const [showReportModal, setShowReportModal] = useState<boolean>(false);
  const [selectedReport, setSelectedReport] = useState<ClientReport>(new ClientReport());
  const [reportPayLoad, setReportPayLoad] = useState<string>();


  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        setLstApproachLaneOnMinor(Enum2Array(ApproachLaneNo));
        setApproachLaneOnMajor(Enum2Array(ApproachLaneNo));
        setLstSiteType(Enum2Array(SectionType));
        setLstFlowCondition(Enum2Array(FlowConditionType));
        setLstChannelizedRightTurnsApproaches(Enum2Array(ApproachType));
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI()
    setJustificationName(props.initData?.signalWarrantPredictionRes?.justification1[0]?.name ?? "");
    var yearRes: NameValueNumber[] = [];
    for (let year = new Date().getFullYear(); year > 1980; year--) {
      let newYew: NameValueNumber = {
        name: year.toString(),
        value: year
      }
      yearRes.push(newYew)
    }
    setLstYears(yearRes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onJustificationDateTimeChange(value: any) {
    setJustificationName(value);
  }
  function onTabsSelectionChanged(args: any) {
    if (args.name === "selectedIndex") {
      setSelectedIndex(args.value);
    }
  }

  // function onOpenReport(d: any) {
  //   var res: StudyReportRequest = {
  //     ReportTitle: t('signalWarrants'),
  //     StudyId: props.initData.id!,
  //     TesReportTypeName: d.itemData.tesReportType.name
  //   }

  //   setReportPayLoad(JSON.stringify([res]))
  //   setSelectedReport(d.itemData);
  //   // setLstSelectedIds(dataGridRef.current?.instance().getSelectedRowKeys().map(x => x.id) as string[]);
  //   setShowReportModal(true);
  // }

  return (
    <div className={`studyDetails-signalWarrants ${compactViewModel ? "compactStyle" : ""}`}>
      <React.Fragment>


        {props.initData?.notPredicted === false ?
          <div className='row signal' style={{ margin: "1rem" }}>
            <div className={"dx-card"} id="iconsCard" style={{ margin: "0rem 1rem 2rem 1rem" }}>
              <div className="row" style={{ margin: "0.7rem 0 -0.7rem 0" }}>
                <div className='rightColumn'>
                  <p style={{ fontWeight: "bold", fontSize: 16 }}>
                    {t("thisStudyIs")}
                    <span>{props.initData?.signalWarrantPredictionRes.isJustified ? t("justified") : t("notJustifiedLower")} ({props.initData?.predictionJustificationSummary})</span>
                    {/* ({props.initData.signalWarrantPredictionRes.}). */}
                  </p>
                </div>
              </div>
            </div>
            <Form colCount={2}>
              <SimpleItem colSpan={2}>
                <div className='title' style={{ fontWeight: "bolder" }}>{t('period')}</div>
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <SelectBox
                  id="period"
                  placeholder=""
                  label={t('dateTime')}
                  valueExpr="name"
                  displayExpr="name"
                  labelMode='static'
                  value={justificationName}
                  onValueChange={e => onJustificationDateTimeChange(e)}
                  className="modalInput"
                  items={SortObjectByPropName(props.initData?.signalWarrantPredictionRes.justification1, "name")}
                  //disabled={props.isLocked}
                  showClearButton={true}
                  searchEnabled={true}
                />
              </SimpleItem>
              <SimpleItem colSpan={1} />
              <SimpleItem colSpan={2}>
                <div id="configurationsCollapse">
                  <CollapsibleCard title={t('configsRelatedToPeriodSelection')} isOpen={false}>
                    <Form colCount={3} className='popupFields'>
                      <SimpleItem colSpan={1}>
                        <SelectBox
                          placeholder=""
                          label={t('sectionType')}
                          valueExpr="value"
                          displayExpr="name"
                          labelMode='static'
                          value={props.initData?.sectionType === SectionDetailType.FourLegIntersection ? SectionType.FourLegIntersection : SectionType.TIntersection}
                          className="modalInput"
                          items={SortObjectByPropName(lstSiteType, "name")}
                          disabled={true}
                          showClearButton={true}
                          searchEnabled={true}
                        />
                      </SimpleItem>
                      <SimpleItem colSpan={1}>
                        <SelectBox
                          placeholder=""
                          label={t('approachLaneOnMajor')}
                          valueExpr="value"
                          displayExpr="name"
                          labelMode='static'
                          value={props.initData?.signalWarrantConfig?.approachLaneOnMajor}
                          className="modalInput"
                          items={SortObjectByPropName(lstApproachLaneOnMajor, "name")}
                          disabled={true}
                          showClearButton={true}
                          searchEnabled={true}
                        />
                      </SimpleItem>
                      <SimpleItem colSpan={1}>
                        <SelectBox
                          placeholder=""
                          label={t('approachLaneOnMinor')}
                          valueExpr="value"
                          displayExpr="name"
                          labelMode='static'
                          value={props.initData?.signalWarrantConfig?.approachLaneOnMinor}
                          className="modalInput"
                          items={SortObjectByPropName(lstApproachLaneOnMinor, "name")}
                          disabled={true}
                          showClearButton={true}
                          searchEnabled={true}
                        />
                      </SimpleItem>
                      <SimpleItem colSpan={1}>
                        <TagBox
                          label={t("channelizesRightTurnsApproaches")}
                          labelMode="static"
                          className="modalInput"
                          dataSource={SortObjectByPropName(lstChannelizedRightTurnsApproaches.filter(x => x.value !== "16"), "name")}
                          defaultValue={EnumFlag2Array(ApproachType, props.initData?.signalWarrantConfig?.channelizesRightTurnsApproaches)}
                          valueExpr="value"
                          displayExpr="name"
                          disabled={true}
                        />
                      </SimpleItem>
                      <SimpleItem colSpan={1}>
                        <SelectBox
                          placeholder=""
                          label={t('flowCondition')}
                          valueExpr="value"
                          displayExpr="name"
                          labelMode='static'
                          value={props.initData?.signalWarrantConfig?.flowCondition}
                          className="modalInput"
                          items={SortObjectByPropName(lstFlowCondition, "name")}
                          disabled={true}
                          showClearButton={true}
                          searchEnabled={true}
                        />
                      </SimpleItem>
                      <SimpleItem colSpan={1}>
                        <NumberBox
                          step={0}
                          label={t('hoursNeededForJustification')}
                          labelMode='static'
                          value={props.initData?.signalWarrantConfig?.hoursNeededForJustification}
                          className="modalInput"
                          disabled={true}
                        />
                      </SimpleItem>
                      <SimpleItem colSpan={1}>
                        <NumberBox
                          step={0}
                          label={t('pedestrianDelayVolume')}
                          labelMode='static'
                          value={props.initData?.signalWarrantConfig?.pedestrianDelayVolume}
                          className="modalInput"
                          disabled={true}
                        />
                      </SimpleItem>
                      {/* <SimpleItem colSpan={1}>
                    <TagBox
                      label={t("years")}
                      labelMode="static"
                      className="modalInput"
                      dataSource={SortObjectByPropName(lstYears, "name")}
                      value={props.initData?.signalWarrantConfig?.years}
                      valueExpr="value"
                      displayExpr="name"
                      disabled={true}
                    />
                  </SimpleItem> */}
                    </Form>
                  </CollapsibleCard>
                </div>
              </SimpleItem>
            </Form>
            <div className={"dx-card "} style={{ marginTop: "1rem" }}>
              <Tabs
                width={"100%"}
                dataSource={tabTitles}
                selectedIndex={selectedIndex}
                onOptionChanged={onTabsSelectionChanged}
              />
              {selectedIndex === 0 && (
                <TabOne
                  justificationName={justificationName}
                  signalWarrants={props.initData.signalWarrantPredictionRes}
                />
              )}
              {selectedIndex === 1 && (
                <TabTwo
                  justificationName={justificationName}
                  signalWarrants={props.initData.signalWarrantPredictionRes}
                />
              )}
              {selectedIndex === 2 && (
                <TabThree
                  justificationName={justificationName}
                  signalWarrants={props.initData.signalWarrantPredictionRes}
                />
              )}
              {selectedIndex === 3 && (
                <TabFour
                  justificationName={justificationName}
                  signalWarrants={props.initData.signalWarrantPredictionRes}
                />
              )}
              {selectedIndex === 4 && (
                <TabFive
                  justificationName={justificationName}
                  signalWarrants={props.initData.signalWarrantPredictionRes}
                />
              )}
              {selectedIndex === 5 && (
                <TabSix
                  justificationName={justificationName}
                  signalWarrants={props.initData.signalWarrantPredictionRes}
                />
              )}
            </div>



            {/* Report Popup
          <Popup
            visible={showReportModal}
            width={"60%"}
            height={"75%"}
            resizeEnabled={true}
            showTitle={true}
            title={t("reportViewer")}
            hideOnOutsideClick={false}
            showCloseButton={true}
            onHiding={() => setShowReportModal(false)}
          >
            <ReportViewer
              selectedReport={selectedReport}
              lstSelectedIds={[]}
              payload={reportPayLoad}
              isOpen={showReportModal}
            />
          </Popup> */}
          </div>
          :
          <div style={{ margin: "2rem 0rem" }}>
            <span style={{ margin: "0 2rem 0 1rem", fontWeight: "bolder" }}>{t("latestStudyGuide")}</span>
            <Button
              className='tes-modal-btn-add'
              onClick={e => window.open(`/trafficStudy/studies/studyDetails/2/${props.initData.latestStudyId}`, "_blank")?.focus()}
              text={t('openLatestStudy')}
            />
          </div>
        }
      </React.Fragment>
    </div>
  );


}
export default SignalWarrantsTab;