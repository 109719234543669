//#region imports
import {
  Form,
  NumberBox,
  ScrollView,
  SelectBox,
  Switch,
  TextBox,
} from "devextreme-react";
import { Button } from "devextreme-react/button";
import DataGrid, {
  Column,
  ColumnChooser,
  DataGridRef,
  Button as DevExpressButton,
  Export,
  FilterBuilderPopup,
  FilterPanel,
  FilterRow,
  Grouping,
  GroupItem,
  GroupPanel,
  HeaderFilter,
  Lookup,
  Pager,
  Paging,
  Search,
  SearchPanel,
  Summary,
} from "devextreme-react/data-grid";
import { SimpleItem } from "devextreme-react/form";
import { Popup } from "devextreme-react/popup";
import { ValidationGroup, ValidationGroupRef } from "devextreme-react/validation-group";
import { RequiredRule, Validator } from "devextreme-react/validator";
import { custom } from "devextreme/ui/dialog";
import notify from "devextreme/ui/notify";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Permission from "../../../components/permission/permision";
import { TMSPermissions } from "../../../constants/Permissions";
import { useAuth } from "../../../contexts/auth";
import { PhoenixApiUrl } from "../../../environment/routeSettings";
import { ModalMode } from "../../../types/general/enums/generalEnums";
import { INameId, NameValue } from "../../../types/general/generalTypes";
import {
  EnvironmentType,
  OracleConnectionType,
} from "../../../types/phoenix/enums/phoenixEnums";
import { VMCustomerEnvironment } from "../../../types/phoenix/phoenixTypes";
import { SortObjectByPropName } from "../../../utils/arrayTools";
import { OnExporting } from "../../../utils/dataGridTools";
import { Enum2Array } from "../../../utils/enumTools";
import { TesDelete, TesGet, TesPost, TesPut } from "../../../utils/rest";
import "./clientEnvironments.scss";

//#endregion imports
const ClientEnvironments = () => {
  //#region consts
  const [initDataEnvironments, setInitDataEnvironment] = useState<
    VMCustomerEnvironment[]
  >([new VMCustomerEnvironment()]);
  const [initDataAppVersion, setInitDataAppVersion] = useState<INameId[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [modalMode, setModalMode] = useState<ModalMode>(ModalMode.Add);
  const [selectedData, setSelectedData] = useState<VMCustomerEnvironment>(new VMCustomerEnvironment());
  const [lstEnvironmentType, setLstEnvironmentType] = useState<NameValue[]>([new NameValue()]);
  const [lstOracleConnectionType, setLstOracleConnectionType] = useState<NameValue[]>([new NameValue()]);
  const validationRef = useRef<ValidationGroupRef>(null);
  const dataGridRef = useRef<DataGridRef<any, any>>(null);
  const { activeLoading } = useAuth();
  const { t } = useTranslation();
  const compactViewModel: boolean = (JSON.parse(localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}') || {}).viewMode === "compact";
  //#endregion

  //#region functions
  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        await getInitialDataEnvironment();
        await getInitialDataAppVersion();
        setLstEnvironmentType(Enum2Array(EnvironmentType));
        setLstOracleConnectionType(Enum2Array(OracleConnectionType));
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  async function getInitialDataEnvironment() {
    setInitDataEnvironment(
      await TesGet(
        PhoenixApiUrl() +
        "/api/customerEnvironments/" +
        localStorage.getItem("selectedCustomerId"),
        true
      )
    );
  }
  async function getInitialDataAppVersion() {
    setInitDataAppVersion(
      await TesGet(PhoenixApiUrl() + "/api/appVersions/nameId", true)
    );
  }
  function onCloseModal() {
    if (modalMode === ModalMode.Add) {
      setShowModal(false);
      setSelectedData(new VMCustomerEnvironment());
    } else {
      setSelectedData(new VMCustomerEnvironment());
      setShowModal(false);
    }
  }
  const onValueChanged = (key: string, value: any) => {
    setSelectedData({ ...selectedData, [key]: value });
  };
  function onEdit(d: any) {
    let newData = new VMCustomerEnvironment();
    Object.assign(newData, d.row.data);
    setModalMode(ModalMode.Edit);
    setSelectedData(newData);
    setShowModal(true);
  }
  function onNew() {
    setModalMode(ModalMode.Add);
    setShowModal(true);
  }
  async function onAdd() {
    try {
      const validationRes = validationRef.current?.instance().validate();
      if (validationRes?.isValid) {
        const postObj = {
          ...selectedData,
          customerId: localStorage.getItem("selectedCustomerId"),
        };
        if (activeLoading) activeLoading(true);
        await TesPost(
          PhoenixApiUrl() + "/api/customerEnvironments",
          postObj,
          true
        );
        dataGridRef.current?.instance().refresh();
        await getInitialDataEnvironment();
        onCloseModal();
        if (activeLoading) activeLoading(false);
        notify(t("dataSuccessfullyAdded"), "success", 5000);
      }
    } catch {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred"), "error", 5000);
    }
  }
  async function onUpdate() {
    try {
      const validationRes = validationRef.current?.instance().validate();
      if (validationRes?.isValid) {
        if (activeLoading) activeLoading(true);
        await TesPut(
          PhoenixApiUrl() + "/api/customerEnvironments/" + selectedData.id,
          selectedData,
          true
        );
        await getInitialDataEnvironment();
        onCloseModal();
        notify(t("dataSuccessfullyUpdated"), "success", 5000);
        if (activeLoading) activeLoading(false);
      }
    } catch (ex) {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred" + ex), "error", 5000);
    }
  }
  async function onDelete(d: any) {
    let myDialog = custom({
      title: t("warning"),
      messageHtml: t("deleteWarningMessage"),
      buttons: [
        {
          text: t("yes"),
          onClick: async (e) => {
            try {
              await TesDelete(
                PhoenixApiUrl() +
                "/api/customerEnvironments/" +
                d.row.data.id,
                true
              );
              await getInitialDataEnvironment();
              notify(t("dataSuccessfullyDeleted"), "success", 5000);
            } catch {
              notify(t("someErrorOccurred"), "error", 5000);
            }
            return { buttonText: e.component.option("text") };
          },
        },
        {
          text: t("no"),
          onClick: (e) => {
            return { buttonText: e.component.option("text") };
          },
        },
      ],
    });
    myDialog.show();
  }
  //#endregion functions

  return (
    <Permission
      allowed={[TMSPermissions.TMS_D_AppVersion]}
      hasFeedBackElement={true}
    >
      <div className={compactViewModel ? "compactStyle" : ""}>
        <React.Fragment>
          <h2 className={"content-block"}>{t("clientEnvironments")}</h2>
          <div className={"content-block"}>
            <div className={"dx-card responsive-paddings"}>
              <div className="row">
                <div className="rightColumn">
                  <Button
                    onClick={onNew}
                    icon="fa-solid fa-circle-plus"
                    text={t("clientEnvironment")}
                  />
                </div>
              </div>
              <DataGrid
                id="gridContainer"
                ref={dataGridRef}
                dataSource={initDataEnvironments}
                rowAlternationEnabled={true}
                showBorders={true}
                hoverStateEnabled={true}
                remoteOperations={true}
                allowColumnReordering={true}
                allowColumnResizing={true}
                columnAutoWidth={true}
                style={{ margin: "0 1rem" }}
                onExporting={OnExporting}
              >
                <Export enabled={true} allowExportSelectedData={true} />
                <Grouping contextMenuEnabled={true} autoExpandAll={false} />
                <GroupPanel visible={true} /> {/* or "auto" */}
                <FilterPanel visible={true} />
                <FilterBuilderPopup position={"top"} />
                <Summary>
                  <GroupItem
                    summaryType="count"
                    alignByColumn
                    name="Total Count"
                  />
                </Summary>
                <Paging enabled={true} defaultPageSize={100} />
                <Pager
                  showPageSizeSelector={true}
                  allowedPageSizes={[100, 200, 300, 400, 500]}
                  showNavigationButtons={true}
                  showInfo={true}
                  visible={true}
                />
                <FilterRow visible={true} applyFilter="auto" />
                <HeaderFilter visible={true} />
                <SearchPanel visible={true} width={285} placeholder={t("search...")} />
                <ColumnChooser width={350} height={400} enabled={true} mode="select" sortOrder="asc">
                  <Search enabled />
                </ColumnChooser>
                <Column dataField="name" caption={t("name")}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column dataField="appVersionName" caption={t("appVersion")}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column dataField="environmentType" caption={t("environmentType")}>
                  <Lookup
                    dataSource={SortObjectByPropName(lstEnvironmentType, "name")}
                    valueExpr="value"
                    displayExpr="name"
                  />
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column dataField="isStartMap" caption={t("runMapInStartup")}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column dataField="getLastVersion" caption={t("getLastVersion")}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column dataField="isDefault" caption={t("defaultEnvironment")}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column type="buttons" caption={t("actions")} width={110} fixed={true} fixedPosition="right">
                  <DevExpressButton
                    hint={t("edit")}
                    onClick={onEdit}
                    icon="fa-solid fa-pencil"
                  ></DevExpressButton>
                  <DevExpressButton
                    hint={t("delete")}
                    onClick={onDelete}
                    icon="fa-solid fa-trash-can"
                  ></DevExpressButton>
                </Column>
              </DataGrid>
            </div>
          </div>

          {/* Popup for add/edit */}
          <Popup
            width={"60%"}
            height={"auto"}
            visible={showModal}
            resizeEnabled={true}
            showTitle={true}
            title={modalMode === ModalMode.Add ? t("add") : t("update")}
            hideOnOutsideClick={false}
            showCloseButton={true}
            onHiding={() => onCloseModal()}
          >
            <ScrollView width="100%" height="100%">
              <ValidationGroup ref={validationRef}>
                <Form colCount={2}>
                  <SimpleItem colSpan={2}>
                    <SelectBox
                      placeholder=""
                      label={t("environmentType")}
                      labelMode="static"
                      value={selectedData.environmentType}
                      onValueChange={(e) => onValueChanged("environmentType", e)}
                      className="modalInput"
                      valueExpr={"value"}
                      displayExpr={"name"}
                      items={SortObjectByPropName(lstEnvironmentType, "name")}
                      showClearButton={true}
                      searchEnabled={true}
                    >
                      <Validator>
                        <RequiredRule message={t("fieldIsRequired")} />
                      </Validator>
                    </SelectBox>
                  </SimpleItem>
                </Form>
                {selectedData.environmentType === EnvironmentType.Oracle && (
                  <Form colCount={2}>
                    <SimpleItem colSpan={1}>
                      <TextBox
                        label={t("name")}
                        labelMode="static"
                        value={selectedData.name}
                        onValueChange={(e) => onValueChanged("name", e)}
                        className="modalInput"
                      >
                        <Validator>
                          <RequiredRule message={t("fieldIsRequired")} />
                        </Validator>
                      </TextBox>
                    </SimpleItem>
                    <SimpleItem colSpan={1}>
                      <SelectBox
                        placeholder=""
                        label={t("appVersion")}
                        labelMode="static"
                        value={selectedData.appVersionId}
                        onValueChange={(e) => onValueChanged("appVersionId", e)}
                        className="modalInput"
                        valueExpr={"id"}
                        displayExpr={"name"}
                        items={SortObjectByPropName(initDataAppVersion, "name")}
                        showClearButton={true}
                        searchEnabled={true}
                      >
                        <Validator>
                          <RequiredRule message={t("fieldIsRequired")} />
                        </Validator>
                      </SelectBox>
                    </SimpleItem>
                    <SimpleItem colSpan={1}>
                      <TextBox
                        label={t("hostName")}
                        labelMode="static"
                        value={selectedData.serverAddress}
                        onValueChange={(e) => onValueChanged("serverAddress", e)}
                        className="modalInput"
                      >
                        <Validator>
                          <RequiredRule message={t("fieldIsRequired")} />
                        </Validator>
                      </TextBox>
                    </SimpleItem>
                    <SimpleItem colSpan={1}>
                      <NumberBox
                        step={0}
                        label={t("port")}
                        labelMode="static"
                        value={selectedData.port}
                        onValueChange={(e) => onValueChanged("port", e)}
                        className="modalInput"
                      >
                        <Validator>
                          <RequiredRule message={t("fieldIsRequired")} />
                        </Validator>
                      </NumberBox>
                    </SimpleItem>
                    <SimpleItem colSpan={1}>
                      <SelectBox
                        placeholder=""
                        label={t("oracleConnectionType")}
                        labelMode="static"
                        value={selectedData.oracleConnectionType}
                        onValueChange={(e) => onValueChanged("oracleConnectionType", e)}
                        className="modalInput"
                        valueExpr={"value"}
                        displayExpr={"name"}
                        items={SortObjectByPropName(lstOracleConnectionType, "name")}
                        showClearButton={true}
                        searchEnabled={true}
                      >
                        <Validator>
                          <RequiredRule message={t("fieldIsRequired")} />
                        </Validator>
                      </SelectBox>
                    </SimpleItem>
                    {selectedData.oracleConnectionType ===
                      OracleConnectionType.ServiceName && (
                        <SimpleItem colSpan={1}>
                          <TextBox
                            label={t("serviceName")}
                            labelMode="static"
                            value={selectedData.serviceName}
                            onValueChange={(e) => onValueChanged("serviceName", e)}
                            className="modalInput"
                          >
                            <Validator>
                              <RequiredRule message={t("fieldIsRequired")} />
                            </Validator>
                          </TextBox>
                        </SimpleItem>
                      )}
                    {selectedData.oracleConnectionType ===
                      OracleConnectionType.SID && (
                        <SimpleItem colSpan={1}>
                          <TextBox
                            label={t("sid")}
                            labelMode="static"
                            value={selectedData.sid}
                            onValueChange={(e) => onValueChanged("sid", e)}
                            className="modalInput"
                          >
                            <Validator>
                              <RequiredRule message={t("fieldIsRequired")} />
                            </Validator>
                          </TextBox>
                        </SimpleItem>
                      )}
                    <SimpleItem colSpan={1}>
                      <TextBox
                        label={t("username")}
                        labelMode="static"
                        value={selectedData.username}
                        onValueChange={(e) => onValueChanged("username", e)}
                        className="modalInput"
                      >
                        <Validator>
                          <RequiredRule message={t("fieldIsRequired")} />
                        </Validator>
                      </TextBox>
                    </SimpleItem>
                    <SimpleItem colSpan={1}>
                      <TextBox
                        label={t("password")}
                        labelMode="static"
                        value={selectedData.password}
                        onValueChange={(e) => onValueChanged("password", e)}
                        className="modalInput"
                      >
                        <Validator>
                          <RequiredRule message={t("fieldIsRequired")} />
                        </Validator>
                      </TextBox>
                    </SimpleItem>
                    <SimpleItem colSpan={1}>
                      <div>{t("runMapInStartup")}</div>
                      <Switch
                        style={{ marginTop: 5, fontWeight: "bold" }}
                        value={selectedData.isStartMap}
                        onValueChange={(e) => onValueChanged("isStartMap", e)}
                        hint={t("runMapInStartup")}
                      />
                    </SimpleItem>
                    <SimpleItem colSpan={1}>
                      <div>{t("getLastVersion")}</div>
                      <Switch
                        style={{ marginTop: 5, fontWeight: "bold" }}
                        value={selectedData.getLastVersion}
                        onValueChange={(e) => onValueChanged("getLastVersion", e)}
                        hint={t("getLastVersion")}
                      />
                    </SimpleItem>
                    <SimpleItem colSpan={1}>
                      <div>{t("defaultEnvironment")}</div>
                      <Switch
                        style={{ marginTop: 5, fontWeight: "bold" }}
                        value={selectedData.isDefault}
                        onValueChange={(e) => onValueChanged("isDefault", e)}
                        hint={t("defaultEnvironment")}
                      />
                    </SimpleItem>
                  </Form>
                )}
                {selectedData.environmentType === EnvironmentType.OracleTNS && (
                  <Form colCount={2}>
                    <SimpleItem colSpan={1}>
                      <TextBox
                        label={t("name")}
                        labelMode="static"
                        value={selectedData.name}
                        onValueChange={(e) => onValueChanged("name", e)}
                        className="modalInput"
                      >
                        <Validator>
                          <RequiredRule message={t("fieldIsRequired")} />
                        </Validator>
                      </TextBox>
                    </SimpleItem>
                    <SimpleItem colSpan={1}>
                      <SelectBox
                        placeholder=""
                        label={t("appVersion")}
                        labelMode="static"
                        value={selectedData.appVersionId}
                        onValueChange={(e) => onValueChanged("appVersionId", e)}
                        className="modalInput"
                        valueExpr={"id"}
                        displayExpr={"name"}
                        items={SortObjectByPropName(initDataAppVersion, "name")}
                        showClearButton={true}
                        searchEnabled={true}
                      >
                        <Validator>
                          <RequiredRule message={t("fieldIsRequired")} />
                        </Validator>
                      </SelectBox>
                    </SimpleItem>
                    <SimpleItem colSpan={1}>
                      <TextBox
                        label={t("tnsServiceName")}
                        labelMode="static"
                        value={selectedData.serverAddress}
                        onValueChange={(e) => onValueChanged("serverAddress", e)}
                        className="modalInput"
                      >
                        <Validator>
                          <RequiredRule message={t("fieldIsRequired")} />
                        </Validator>
                      </TextBox>
                    </SimpleItem>

                    <SimpleItem colSpan={1}>
                      <SelectBox
                        placeholder=""
                        label={t("oracleConnectionType")}
                        labelMode="static"
                        value={selectedData.oracleConnectionType}
                        onValueChange={(e) =>
                          onValueChanged("oracleConnectionType", e)
                        }
                        className="modalInput"
                        valueExpr={"value"}
                        displayExpr={"name"}
                        items={SortObjectByPropName(lstOracleConnectionType, "name")}
                        showClearButton={true}
                        searchEnabled={true}
                      >
                        <Validator>
                          <RequiredRule message={t("fieldIsRequired")} />
                        </Validator>
                      </SelectBox>
                    </SimpleItem>

                    <SimpleItem colSpan={1}>
                      <TextBox
                        label={t("loginId")}
                        labelMode="static"
                        value={selectedData.username}
                        onValueChange={(e) => onValueChanged("username", e)}
                        className="modalInput"
                      >
                        <Validator>
                          <RequiredRule message={t("fieldIsRequired")} />
                        </Validator>
                      </TextBox>
                    </SimpleItem>
                    <SimpleItem colSpan={1}>
                      <TextBox
                        label={t("password")}
                        labelMode="static"
                        value={selectedData.password}
                        onValueChange={(e) => onValueChanged("password", e)}
                        className="modalInput"
                      >
                        <Validator>
                          <RequiredRule message={t("fieldIsRequired")} />
                        </Validator>
                      </TextBox>
                    </SimpleItem>
                    <SimpleItem colSpan={1}>
                      <div>{t("runMapInStartup")}</div>
                      <Switch
                        style={{ marginTop: 5, fontWeight: "bold" }}
                        value={selectedData.isStartMap}
                        onValueChange={(e) => onValueChanged("isStartMap", e)}
                        hint={t("runMapInStartup")}
                      />
                    </SimpleItem>
                    <SimpleItem colSpan={1}>
                      <div>{t("getLastVersion")}</div>
                      <Switch
                        style={{ marginTop: 5, fontWeight: "bold" }}
                        value={selectedData.getLastVersion}
                        onValueChange={(e) => onValueChanged("getLastVersion", e)}
                        hint={t("getLastVersion")}
                      />
                    </SimpleItem>
                    <SimpleItem colSpan={1}>
                      <div>{t("defaultEnvironment")}</div>
                      <Switch
                        style={{ marginTop: 5, fontWeight: "bold" }}
                        value={selectedData.isDefault}
                        onValueChange={(e) => onValueChanged("isDefault", e)}
                        hint={t("defaultEnvironment")}
                      />
                    </SimpleItem>
                  </Form>
                )}
                {selectedData.environmentType === EnvironmentType.SQL && (
                  <Form colCount={2}>
                    <SimpleItem colSpan={1}>
                      <TextBox
                        label={t("name")}
                        labelMode="static"
                        value={selectedData.name}
                        onValueChange={(e) => onValueChanged("name", e)}
                        className="modalInput"
                      >
                        <Validator>
                          <RequiredRule message={t("fieldIsRequired")} />
                        </Validator>
                      </TextBox>
                    </SimpleItem>
                    <SimpleItem colSpan={1}>
                      <SelectBox
                        placeholder=""
                        label={t("appVersion")}
                        labelMode="static"
                        value={selectedData.appVersionId}
                        onValueChange={(e) => onValueChanged("appVersionId", e)}
                        className="modalInput"
                        valueExpr={"id"}
                        displayExpr={"name"}
                        items={SortObjectByPropName(initDataAppVersion, "name")}
                        showClearButton={true}
                        searchEnabled={true}
                      >
                        <Validator>
                          <RequiredRule message={t("fieldIsRequired")} />
                        </Validator>
                      </SelectBox>
                    </SimpleItem>
                    <SimpleItem colSpan={1}>
                      <TextBox
                        label={t("serverName")}
                        labelMode="static"
                        value={selectedData.serverAddress}
                        onValueChange={(e) => onValueChanged("serverAddress", e)}
                        className="modalInput"
                      >
                        <Validator>
                          <RequiredRule message={t("fieldIsRequired")} />
                        </Validator>
                      </TextBox>
                    </SimpleItem>
                    <SimpleItem colSpan={1}>
                      <TextBox
                        label={t("databaseName")}
                        labelMode="static"
                        value={selectedData.databaseName}
                        onValueChange={(e) => onValueChanged("databaseName", e)}
                        className="modalInput"
                      >
                        <Validator>
                          <RequiredRule message={t("fieldIsRequired")} />
                        </Validator>
                      </TextBox>
                    </SimpleItem>

                    <SimpleItem colSpan={1}>
                      <TextBox
                        label={t("loginId")}
                        labelMode="static"
                        value={selectedData.username}
                        onValueChange={(e) => onValueChanged("username", e)}
                        className="modalInput"
                      >
                        <Validator>
                          <RequiredRule message={t("fieldIsRequired")} />
                        </Validator>
                      </TextBox>
                    </SimpleItem>
                    <SimpleItem colSpan={1}>
                      <TextBox
                        label={t("password")}
                        labelMode="static"
                        value={selectedData.password}
                        onValueChange={(e) => onValueChanged("password", e)}
                        className="modalInput"
                      >
                        <Validator>
                          <RequiredRule message={t("fieldIsRequired")} />
                        </Validator>
                      </TextBox>
                    </SimpleItem>
                    <SimpleItem colSpan={1}>
                      <div>{t("runMapInStartup")}</div>
                      <Switch
                        style={{ marginTop: 5, fontWeight: "bold" }}
                        value={selectedData.isStartMap}
                        onValueChange={(e) => onValueChanged("isStartMap", e)}
                        hint={t("runMapInStartup")}
                      />
                    </SimpleItem>
                    <SimpleItem colSpan={1}>
                      <div>{t("getLastVersion")}</div>
                      <Switch
                        style={{ marginTop: 5, fontWeight: "bold" }}
                        value={selectedData.getLastVersion}
                        onValueChange={(e) => onValueChanged("getLastVersion", e)}
                        hint={t("getLastVersion")}
                      />
                    </SimpleItem>
                    <SimpleItem colSpan={1}>
                      <div>{t("defaultEnvironment")}</div>
                      <Switch
                        style={{ marginTop: 5, fontWeight: "bold" }}
                        value={selectedData.isDefault}
                        onValueChange={(e) => onValueChanged("isDefault", e)}
                        hint={t("defaultEnvironment")}
                      />
                    </SimpleItem>
                  </Form>
                )}
              </ValidationGroup>
              <div>
                <div className="rightColumn">
                  <Button
                    className="tes-modal-btn-cancel"
                    style={{ marginRight: 20 }}
                    onClick={() => onCloseModal()}
                    text={t("cancel")}
                  />
                  {modalMode === ModalMode.Add ? (
                    <Button
                      className="tes-modal-btn-add"
                      onClick={onAdd}
                      text={t("add")}
                    />
                  ) : (
                    <Button
                      className="tes-modal-btn-add"
                      onClick={onUpdate}
                      text={t("update")}
                    />
                  )}
                </div>
              </div>
            </ScrollView>
          </Popup>
        </React.Fragment>
      </div>
    </Permission>
  );
};
export default ClientEnvironments;
