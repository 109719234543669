//imports
import { Button } from "devextreme-react/button";
import DataGrid, {
  Column,
  ColumnChooser,
  DataGridRef,
  Export,
  FilterBuilderPopup,
  FilterPanel,
  FilterRow,
  Grouping,
  GroupItem,
  GroupPanel,
  HeaderFilter,
  Pager,
  Paging,
  Search,
  SearchPanel,
  SortByGroupSummaryInfo,
  StateStoring,
  Summary
} from "devextreme-react/data-grid";
import notify from "devextreme/ui/notify";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Permission from "../../../../components/permission/permision";
import getTitle from "../../../../components/title/getTitle";
import { InfrastructurePermissions } from "../../../../constants/Permissions";
import { useAuth } from "../../../../contexts/auth";
import { TrafficApiUrl } from "../../../../environment/routeSettings";
import { GridType, ResponseCode } from "../../../../types/general/enums/generalEnums";
import { MmsRequest } from "../../../../types/infrastructure/infrastructureTypes";
import { OnExporting } from "../../../../utils/dataGridTools";
import { RequestErrorHandling, TesPost } from "../../../../utils/rest";

const MMS = () => {
  const [initData, setInitData] = useState<MmsRequest[]>([]);
  const dataGridRef = useRef<DataGridRef<any, any>>(null);
  const history = useNavigate();
  const { activeLoading } = useAuth();
  const { t } = useTranslation();
  const compactViewModel: boolean = (JSON.parse(localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}') || {}).viewMode === "compact";

  const title = getTitle('/infrastructure/tools/mms', '');
  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        await getInitData();
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Get InitData
  async function getInitData() {
    const postObj = {
      customerId: localStorage.getItem("selectedCustomerId"),
      divisionId: localStorage.getItem("selectedDivisionId"),
    }
    const res = await TesPost(
      TrafficApiUrl() +
      "/api/Mms/GetMmsRequest",
      postObj,
      true
    )
    if (res.responseCode === ResponseCode.OK) {
      setInitData(res.results);
    } else {
      await RequestErrorHandling(res);
    }
  }

  const navigateToMMSDetails = useCallback(() => {
    history("/infrastructure/tools/mmsDetails/AddNew");
  }, [history]);

  function onRowClick(e: any) {
    const isCtrlKeyPressed = e.event.ctrlKey || e.event.metaKey;

    if (isCtrlKeyPressed) {
      window.open(`/infrastructure/tools/mmsDetails/${e.data.id}`, "_blank");
    } else {
      history(`/infrastructure/tools/mmsDetails/${e.data.id}`);
    }
  }

  return (
    <Permission allowed={[InfrastructurePermissions.Infrastructure_D_Area]} hasFeedBackElement={true}>
      <div className={compactViewModel ? "compactStyle" : ""}>
        <React.Fragment>
          <h2 className={"content-block"}>{t("mms")}</h2>
          <div className={"content-block"}>
            <div className={"dx-card responsive-paddings"}>
              <div className="row">
                <div className="rightColumn">
                  <Button
                    onClick={navigateToMMSDetails}
                    icon="fa-solid fa-circle-plus"
                    text={t("mms")}
                  />
                </div>
              </div>
              <DataGrid
                id="gridContainer"
                ref={dataGridRef}
                dataSource={initData}
                rowAlternationEnabled={true}
                showBorders={true}
                hoverStateEnabled={true}
                remoteOperations={true}
                allowColumnReordering={true}
                allowColumnResizing={true}
                columnAutoWidth={true}
                onRowClick={onRowClick}
                style={{ margin: "0 1rem" }}
                onExporting={OnExporting}
              >
                <Export enabled={true} allowExportSelectedData={true} />
                <Grouping contextMenuEnabled={true} autoExpandAll={false} />
                <GroupPanel visible={true} /> {/* or "auto" */}
                <FilterPanel visible={true} />
                <FilterBuilderPopup position={"top"} />
                <StateStoring
                  enabled={true}
                  type="localStorage"
                  storageKey={GridType.MMS.toString()}
                  savingTimeout={500}
                />
                <SortByGroupSummaryInfo
                  summaryItem="Total Count"
                  sortOrder="desc"
                />
                <Summary>
                  <GroupItem
                    summaryType="count"
                    alignByColumn
                    name="Total Count"
                  />
                </Summary>
                <Paging enabled={true} defaultPageSize={100} />
                <Pager
                  showPageSizeSelector={true}
                  allowedPageSizes={[100, 200, 300, 400, 500]}
                  showNavigationButtons={true}
                  showInfo={true}
                  visible={true}
                />
                <FilterRow visible={true} applyFilter="auto" />
                <HeaderFilter visible={true} />
                <SearchPanel visible={true} width={285} placeholder={t("search...")} />
                <ColumnChooser width={350} height={400} enabled={true} mode="select" sortOrder="asc">
                  <Search enabled />
                </ColumnChooser>
                <Column dataField="name" caption={t("name")}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column dataField="description" caption={t("description")}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column dataField="id" caption={t("id")} visible={false}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column dataField="customerId" caption={t("customerId")} visible={false}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column dataField="divisionId" caption={t("divisionId")} visible={false}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column dataField="year" caption={t("year")}
                  dataType="number"
                  // sortOrder={'desc'}
                  allowHeaderFiltering={true}
                >
                  <Search enabled />
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
              </DataGrid>
            </div>
          </div>
        </React.Fragment>
      </div>
    </Permission>
  );
};
export default MMS;
