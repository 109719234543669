import { Button, DataGrid, Form, Popup, ScrollView, TextBox, ValidationGroup } from "devextreme-react";
import { ValidationGroupRef } from "devextreme-react/cjs/validation-group";
import { Column, ColumnChooser, DataGridRef, Button as DevExpressButton, Export, FilterBuilderPopup, FilterPanel, FilterRow, Grouping, GroupItem, GroupPanel, HeaderFilter, Pager, Paging, Search, SearchPanel, SortByGroupSummaryInfo, StateStoring, Summary } from "devextreme-react/data-grid";
import { SimpleItem } from "devextreme-react/form";
import notify from "devextreme/ui/notify";
import { t } from "i18next";
import React, { useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import { XMLRedaction } from "../../../../../types/collision/collisionTypes";
import { GridType, ModalMode } from "../../../../../types/general/enums/generalEnums";
import { INameId } from "../../../../../types/general/generalTypes";

interface IPros {
  isLocked: boolean;
  initData: XMLRedaction;
  setInitData: React.Dispatch<React.SetStateAction<any>>;
  validationRef: React.RefObject<ValidationGroupRef>;
}

const XMLTags = (props: IPros) => {

  const [dataGridData, setDataGridData] = useState<INameId[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [modalMode, setModalMode] = useState<ModalMode>(ModalMode.Add);
  const [name, setName] = useState<string>("");
  const [selectedRow, setSelectedRow] = useState<INameId>()
  const dataGridRef = useRef<DataGridRef<any, any>>(null);

  useEffect(() => {
    const fields = props.initData.reductionFields || [];
    setDataGridData(fields.map((name) => ({ id: uuidv4(), name })));
  }, [props.initData]);

  function onNew() {
    setModalMode(ModalMode.Add);
    setShowModal(true);
  }

  function onCloseModal() {
    setName("");
    setShowModal(false);
  }

  async function onAdd() {
    if (name !== "") {
      try {
        props.setInitData({
          ...props.initData,
          reductionFields: [
            ...props.initData.reductionFields || [],
            name
          ]
        });

        dataGridRef.current?.instance().refresh();
        setShowModal(false);

      } catch (er) {
        notify(t("someErrorOccurred"), "error", 5000);
      }
    } else {
      notify(t("filedIsEmpty"), "error", 5000);
    }
  }

  async function onUpdate() {
    if (name.trim() === "" || !selectedRow) {
      notify(t("fieldIsEmpty"), "error", 5000);
      return;
    }

    try {
      const updatedReductionFields = props.initData.reductionFields.map(field =>
        field === selectedRow.name ? name : field
      );

      props.setInitData({
        ...props.initData,
        reductionFields: updatedReductionFields
      });

      setDataGridData(dataGridData.map(item =>
        item.id === selectedRow.id ? { ...item, name } : item
      ));

      dataGridRef.current?.instance().refresh();
      onCloseModal();
    } catch (error) {
      notify(t("someErrorOccurred"), "error", 5000);
    }
  }

  function onDelete(e: any): void {
    props.setInitData({ ...props.initData, reductionFields: props.initData.reductionFields.filter(x => x !== e.row.data.name) })
    dataGridRef.current?.instance().refresh();
  }

  return (
    <React.Fragment>
      <ValidationGroup ref={props.validationRef}>
        <div className='row' style={{ marginTop: 20, padding: 10 }}>
          <div className='rightColumn'>
            <Button
              onClick={onNew}
              icon="fa-solid fa-circle-plus"
              text={t('add')}
              disabled={props.isLocked}
              style={{ marginBottom: "0.5rem" }}
            />
          </div>
          <ScrollView width="100%" height="100%">
            <Form colCount={3}>
              <SimpleItem colSpan={3}>
                <DataGrid
                  id="gridContainer"
                  ref={dataGridRef}
                  dataSource={dataGridData}
                  rowAlternationEnabled={true}
                  showBorders={true}
                  hoverStateEnabled={true}
                  remoteOperations={true}
                  allowColumnReordering={true}
                  allowColumnResizing={true}
                  columnAutoWidth={true}
                  disabled={props.isLocked}
                  onRowClick={e => {
                    setSelectedRow(e.data)
                    setModalMode(ModalMode.Edit)
                    setShowModal(true)
                    setName(e.data.name)
                  }
                  }
                >
                  <Export enabled={true} allowExportSelectedData={true} />
                  <Grouping contextMenuEnabled={true} autoExpandAll={false} />
                  <GroupPanel visible={true} />
                  <FilterPanel visible={true} />
                  <FilterBuilderPopup position={"top"} />
                  <StateStoring
                    enabled={true}
                    type="localStorage"
                    storageKey={GridType.CollisionXMLRedactionDetails.toString()}
                    savingTimeout={500}
                  />
                  <SortByGroupSummaryInfo
                    summaryItem="Total Count"
                    sortOrder="desc"
                  />
                  <Summary>
                    <GroupItem
                      summaryType="count"
                      alignByColumn
                      name="Total Count"
                    />
                  </Summary>
                  <Paging enabled={true} defaultPageSize={100} />
                  <Pager
                    showPageSizeSelector={true}
                    allowedPageSizes={[100, 200, 300, 400, 500]}
                    showNavigationButtons={true}
                    showInfo={true}
                    visible={true}
                  />
                  <FilterRow visible={true} applyFilter="auto" />
                  <HeaderFilter visible={true} />
                  <SearchPanel visible={true} width={285} placeholder={t("search...")} />
                  <ColumnChooser width={350} height={400} enabled={true} mode="select" sortOrder="asc">
                    <Search enabled />
                  </ColumnChooser>
                  <Column dataField="name" caption={t("name")}>
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    dataField="id"
                    allowSorting={true}
                    visible={false}
                    caption={t("id")}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column type="buttons" caption={t("actions")} width={110} fixed={true} fixedPosition="right">
                    <DevExpressButton
                      hint={t("delete")}
                      onClick={e => onDelete(e)}
                      icon="fa-solid fa-trash-can"
                    />
                  </Column>
                </DataGrid>
              </SimpleItem>
            </Form>
          </ScrollView>
        </div>

        <Popup
          width={"50%"}
          height={"auto"}
          visible={showModal}
          resizeEnabled={true}
          showTitle={true}
          title={modalMode === ModalMode.Add ? t("add") : t("update")}
          hideOnOutsideClick={false}
          showCloseButton={true}
          onHiding={() => onCloseModal()}
        >
          <ScrollView width="100%" height="100%">
            <Form colCount={2}>
              <SimpleItem colSpan={2}>
                <TextBox
                  label={t("name")}
                  labelMode="static"
                  value={name}
                  onValueChange={(e) => setName(e)}
                  className="modalInput"
                />
              </SimpleItem>
            </Form>
            <div>
              <div className="rightColumn">
                <Button
                  className="tes-modal-btn-cancel"
                  style={{ marginRight: 20 }}
                  onClick={() => onCloseModal()}
                  text={t("cancel")}
                />
                {modalMode === ModalMode.Add ? (
                  <Button
                    className="tes-modal-btn-add"
                    onClick={onAdd}
                    text={t("add")}
                  />
                ) : (
                  <Button
                    className="tes-modal-btn-add"
                    onClick={onUpdate}
                    text={t("update")}
                  />
                )}
              </div>
            </div>
          </ScrollView>
        </Popup>

      </ValidationGroup>
    </React.Fragment>
  );
}

export default XMLTags;

