//#region imports
import {
  DateBox,
  Form,
  ScrollView,
  SelectBox,
  TagBox,
  TextBox,
} from "devextreme-react";
import { Button } from "devextreme-react/button";
import DataGrid, {
  Column,
  ColumnChooser,
  DataGridRef,
  Button as DevExpressButton,
  Export,
  FilterBuilderPopup,
  FilterPanel,
  FilterRow,
  Grouping,
  GroupItem,
  GroupPanel,
  HeaderFilter,
  Lookup,
  Pager,
  Paging,
  Search,
  SearchPanel,
  SortByGroupSummaryInfo,
  Summary
} from "devextreme-react/data-grid";
import { SimpleItem } from "devextreme-react/form";
import { Popup } from "devextreme-react/popup";
import { ValidationGroup, ValidationGroupRef } from "devextreme-react/validation-group";
import { RequiredRule, Validator } from "devextreme-react/validator";
import { custom } from "devextreme/ui/dialog";
import notify from "devextreme/ui/notify";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Permission from "../../../../components/permission/permision";
import getTitle from "../../../../components/title/getTitle";
import { TrafficStudyPermissions } from "../../../../constants/Permissions";
import { useAuth } from "../../../../contexts/auth";
import { useClientSetting } from "../../../../contexts/clientSetting";
import { TrafficApiUrl } from "../../../../environment/routeSettings";
import {
  ModalMode,
  ResponseCode,
} from "../../../../types/general/enums/generalEnums";
import {
  LazyLoadingRequestBase,
  NameValue,
  RequestResponseResult,
} from "../../../../types/general/generalTypes";
import {
  ReportingPeriodType,
  StudyType
} from "../../../../types/trafficStudy/enums/trafficStudyEnums";
import { ReportingPeriod } from "../../../../types/trafficStudy/trafficStudyTypes";
import { SortObjectByPropName, SumIntArray } from "../../../../utils/arrayTools";
import { OnExporting } from "../../../../utils/dataGridTools";
import { CreateDateAsUTC } from "../../../../utils/dateTimeTools";
import { Enum2Array, EnumFlag2Array } from "../../../../utils/enumTools";
import {
  RequestErrorHandling,
  TesDelete,
  TesPost
} from "../../../../utils/rest";
import "./reportingPeriods.scss";

//#endregion imports
const ReportingPeriods = () => {
  //#region consts
  const { generalSetting } = useClientSetting();
  const [initData, setInitData] = useState<ReportingPeriod[]>([new ReportingPeriod()]);
  const [showModal, setShowModal] = useState(false);
  const [modalMode, setModalMode] = useState<ModalMode>(ModalMode.Add);
  const [selectedData, setSelectedData] = useState<ReportingPeriod>(new ReportingPeriod());
  const [lstReportingPeriodType, setLstReportingPeriodType] = useState<NameValue[]>([]);
  const [lstStudyType, setLstStudyType] = useState<NameValue[]>([])
  const validationRef = useRef<ValidationGroupRef>(null);
  const dataGridRef = useRef<DataGridRef<any, any>>(null);
  const { activeLoading } = useAuth();
  const { t } = useTranslation();
  const compactViewModel: boolean = (JSON.parse(localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}') || {}).viewMode === "compact";

  //#endregion
  //#region functions
  const title = getTitle('/trafficStudy/setups/reportingPeriod', '');
  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        await getInitData();
        setLstReportingPeriodType(Enum2Array(ReportingPeriodType));
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    setLstStudyType(Enum2Array(StudyType));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getInitData() {
    try {
      var res = await TesPost(
        TrafficApiUrl() +
        "/api/Setups/GetCustomerReportingPeriod",
        {
          customerId: localStorage.getItem("selectedCustomerId")
        } as LazyLoadingRequestBase,

        true
      ) as RequestResponseResult<ReportingPeriod[]>
      if (activeLoading) activeLoading(false);
      if (res.responseCode === ResponseCode.OK) {
        setInitData(res.results);
      } else {
        await RequestErrorHandling(res);;
      }
    } catch (ex) {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred") + ex, "error", 5000);
    }
  }

  function onCloseModal() {
    if (modalMode === ModalMode.Add) {
      setShowModal(false);
      setSelectedData(new ReportingPeriod());
    } else {
      setSelectedData(new ReportingPeriod());
      setShowModal(false);
    }
  }
  const onValueChanged = (key: string, value: any) => {
    setSelectedData({ ...selectedData, [key]: value });
  };
  function onEdit(d: any) {
    let newData = new ReportingPeriod();
    Object.assign(newData, d.row.data);
    setModalMode(ModalMode.Edit);
    setSelectedData(newData);
    setShowModal(true);
  }
  function onNew() {
    setModalMode(ModalMode.Add);
    setShowModal(true);
  }
  async function onAdd() {
    try {
      const validationRes = validationRef.current?.instance().validate();
      if (validationRes?.isValid) {
        const postObj = {
          ...selectedData,
          customerId: localStorage.getItem("selectedCustomerId"),
        };
        if (activeLoading) activeLoading(true);
        let res = (await TesPost(
          TrafficApiUrl() + "/api/Setups/AddReportingPeriod",
          postObj,
          true
        )) as RequestResponseResult<null>;
        if (res.responseCode === ResponseCode.OK) {
          dataGridRef.current?.instance().refresh();
          await getInitData();
          onCloseModal();
          if (activeLoading) activeLoading(false);
          notify(t("dataSuccessfullyAdded"), "success", 5000);
        } else {
          await RequestErrorHandling(res);;
        }
      }
    } catch {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred"), "error", 5000);
    }
  }
  async function onUpdate() {
    try {
      const validationRes = validationRef.current?.instance().validate();
      if (validationRes?.isValid) {
        if (activeLoading) activeLoading(true);
        let res = (await TesPost(
          TrafficApiUrl() + "/api/Setups/UpdateReportingPeriod",
          {
            ...selectedData,
            startTime: CreateDateAsUTC(selectedData.startTime),
            endTime: CreateDateAsUTC(selectedData.endTime),
          },
          true
        )) as RequestResponseResult<null>;
        if (res.responseCode === ResponseCode.OK) {
          await getInitData();
          onCloseModal();
          notify(t("dataSuccessfullyUpdated"), "success", 5000);
          if (activeLoading) activeLoading(false);
        } else {
          await RequestErrorHandling(res);;
        }
      }
    } catch (ex) {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred" + ex), "error", 5000);
    }
    console.log(selectedData)
  }

  async function onDelete(d: any) {
    let myDialog = custom({
      title: t("warning"),
      messageHtml: t("deleteWarningMessage"),
      buttons: [
        {
          text: t("yes"),
          onClick: async (e: any) => {
            try {

              let res = (await TesDelete(
                TrafficApiUrl() + "/api/Setups/DeleteReportingPeriod/" + d.row.data.id,

                true
              )) as RequestResponseResult<null>;
              if (res.responseCode === ResponseCode.OK) {
                await getInitData();
                onCloseModal();
                notify(t("dataSuccessfullyDeleted"), "success", 5000);
                if (activeLoading) activeLoading(false);
              } else {
                await RequestErrorHandling(res);;
              }
            } catch {
              notify(t("someErrorOccurred"), "error", 5000);
            }
            return { buttonText: e.component.option("text") };
          },
        },
        {
          text: t("no"),
          onClick: (e) => {
            return { buttonText: e.component.option("text") };
          },
        },
      ],
    });
    myDialog.show();
  }

  //#endregion functions

  return (
    <Permission
      allowed={[TrafficStudyPermissions.TrafficStudy_D_Setups]}
      hasFeedBackElement={true}
    >
      <div className={compactViewModel ? "compactStyle" : ""}>
        <React.Fragment>
          <h2 className={"content-block"}>{t("reportingPeriod")}</h2>
          <div className={"content-block"}>
            <div className={"dx-card responsive-paddings"}>
              <div className="row">
                <div className="rightColumn">
                  <Button onClick={onNew} icon="fa-solid fa-circle-plus" text={t("reportingPeriod")} />
                </div>
              </div>
              <DataGrid
                id="gridContainer"
                ref={dataGridRef}
                dataSource={initData}
                rowAlternationEnabled={true}
                showBorders={true}
                hoverStateEnabled={true}
                remoteOperations={true}
                allowColumnReordering={true}
                allowColumnResizing={true}
                columnAutoWidth={true}
                style={{ margin: "0 1rem" }}
                onExporting={OnExporting}
              >
                <Export enabled={true} allowExportSelectedData={true} />
                <Grouping contextMenuEnabled={true} autoExpandAll={false} />
                <GroupPanel visible={true} /> {/* or "auto" */}
                <FilterPanel visible={true} />
                <FilterBuilderPopup position={"top"} />
                <Paging enabled={true} defaultPageSize={100} />
                <Pager
                  showPageSizeSelector={true}
                  allowedPageSizes={[100, 200, 300, 400, 500]}
                  showNavigationButtons={true}
                  showInfo={true}
                  visible={true}
                />
                <FilterRow visible={true} applyFilter="auto" />
                <HeaderFilter visible={true} />
                <SearchPanel visible={true} width={285} placeholder={t("search...")} />
                <ColumnChooser width={350} height={400} enabled={true} mode="select" sortOrder="asc">
                  <Search enabled />
                </ColumnChooser>
                <SortByGroupSummaryInfo
                  summaryItem="Total Count"
                  sortOrder="desc"
                />
                <Summary>
                  <GroupItem
                    summaryType="count"
                    alignByColumn
                    name="Total Count"
                  />
                </Summary>
                <Column dataField="id" caption={t("id")} visible={false}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="customerId"
                  caption={t("customerId")}
                  visible={false}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column dataField="label" caption={t("label")} visible={true}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column dataField="studyType" caption={t("studyType")}
                  calculateDisplayValue={function (rowData: any) {
                    var values = EnumFlag2Array(StudyType, rowData.studyType);
                    var res = [];
                    if (values !== undefined) {
                      for (let i = 0; i < values.length; i++) {
                        res.push(StudyType[values[i]]);
                      }
                    }
                    return res.join(",");
                  }}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="startTime"
                  caption={t("startTime")}
                  dataType="datetime"
                  visible={true}
                  format={generalSetting?.isAmPm ? "shortTime" : "HH:mm"}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="endTime"
                  caption={t("endTime")}
                  dataType="datetime"
                  visible={true}
                  format={generalSetting?.isAmPm ? "shortTime" : "HH:mm"}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column dataField="type" caption={t("type")}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                  <Lookup
                    dataSource={SortObjectByPropName(lstReportingPeriodType, "name")}
                    valueExpr="value"
                    displayExpr="name"
                  />
                </Column>
                <Column type="buttons" caption={t("actions")} width={110} fixed={true} fixedPosition="right">
                  <DevExpressButton
                    hint={t("edit")}
                    onClick={onEdit}
                    icon="fa-solid fa-pencil"
                  ></DevExpressButton>
                  <DevExpressButton
                    hint={t("delete")}
                    onClick={onDelete}
                    icon="fa-solid fa-trash-can"
                  ></DevExpressButton>
                </Column>
              </DataGrid>
            </div>
          </div>

          {/* Popup for add/edit */}
          <Popup
            width={"50%"}
            height={"auto"}
            visible={showModal}
            resizeEnabled={true}
            showTitle={true}
            title={modalMode === ModalMode.Add ? t("add") : t("update")}
            hideOnOutsideClick={false}
            showCloseButton={true}
            onHiding={() => onCloseModal()}
          >
            <ScrollView width="100%" height="100%">
              <ValidationGroup ref={validationRef}>
                <Form colCount={2} className='popupFields'>
                  <SimpleItem colSpan={1}>
                    <TextBox
                      label={t("label")}
                      labelMode="static"
                      value={selectedData.label}
                      onValueChange={(e) => onValueChanged("label", e)}
                      className="modalInput"
                    >
                      <Validator>
                        <RequiredRule message={t("label")} />
                      </Validator>
                    </TextBox>
                  </SimpleItem>
                  <SimpleItem>
                    <DateBox
                      type="time"
                      label={t("startTime")}
                      value={selectedData.startTime}
                      onValueChange={(e) => onValueChanged("startTime", e)}
                      pickerType="rollers"
                      placeholder={generalSetting?.timeFormat}
                      displayFormat={generalSetting?.timeFormat}
                      useMaskBehavior={true}
                      openOnFieldClick={true}
                      showClearButton={true}
                      style={{ marginBottom: "1rem" }}
                    >
                      <Validator>
                        <RequiredRule message={t("fieldIsRequired")} />
                      </Validator>
                    </DateBox>
                  </SimpleItem>
                  <SimpleItem>
                    <DateBox
                      type="time"
                      label={t("endTime")}
                      value={selectedData.endTime}
                      onValueChange={(e) => onValueChanged("endTime", e)}
                      pickerType="rollers"
                      placeholder={generalSetting?.timeFormat}
                      displayFormat={generalSetting?.timeFormat}
                      useMaskBehavior={true}
                      openOnFieldClick={true}
                      showClearButton={true}
                      style={{ marginBottom: "1rem" }}
                    >
                      <Validator>
                        <RequiredRule message={t("fieldIsRequired")} />
                      </Validator>
                    </DateBox>
                  </SimpleItem>
                  <SimpleItem colSpan={1}>
                    <SelectBox
                      placeholder=""
                      label={t("type")}
                      labelMode="static"
                      value={selectedData.type}
                      onValueChange={(e) => onValueChanged("type", e)}
                      items={SortObjectByPropName(lstReportingPeriodType, "name")}
                      valueExpr="value"
                      displayExpr="name"
                      showClearButton={true}
                      searchEnabled={true}
                    />
                  </SimpleItem>
                  <SimpleItem colSpan={1}>
                    <TagBox
                      label={t("vehicleClassificationTypes")}
                      labelMode="static"
                      className="modalInput"
                      dataSource={SortObjectByPropName(lstStudyType, "name")}
                      value={EnumFlag2Array(StudyType, selectedData.studyType)}
                      valueExpr="value"
                      displayExpr="name"
                      onValueChange={(e) => onValueChanged("studyType", SumIntArray(e))}
                    >
                    </TagBox>
                  </SimpleItem>
                </Form>
              </ValidationGroup>
              <div>
                <div className="rightColumn">
                  <Button
                    className="tes-modal-btn-cancel"
                    style={{ marginRight: 20 }}
                    onClick={() => onCloseModal()}
                    text={t("cancel")}
                  />
                  {modalMode === ModalMode.Add ? (
                    <Button
                      className="tes-modal-btn-add"
                      onClick={onAdd}
                      text={t("add")}
                    />
                  ) : (
                    <Button
                      className="tes-modal-btn-add"
                      onClick={onUpdate}
                      text={t("update")}
                    />
                  )}
                </div>
              </div>
            </ScrollView>
          </Popup>
        </React.Fragment>
      </div>
    </Permission>
  );
};
export default ReportingPeriods;
