import { NumberBox, SelectBox } from 'devextreme-react';
import DateBox from 'devextreme-react/date-box';
import Form, { SimpleItem } from 'devextreme-react/form';
import { Switch } from 'devextreme-react/switch';
import TextBox from 'devextreme-react/text-box';
import ValidationGroup, { ValidationGroupRef } from 'devextreme-react/validation-group';
import { t } from 'i18next';
import React from 'react';
import CollapsibleCard from '../../../../../components/collapsibleCard/collapsibleCard';
import MidBlockValuePicker from '../../../../../components/midBlockValuePicker/midBlockValuePicker';
import { GeneralSetting } from '../../../../../contexts/clientSetting';
import { FieldCategoryType, FieldPermission, FieldType } from '../../../../../types/field/enums/fieldEnums';
import { TesField } from '../../../../../types/field/fieldType';
import { PageMode } from '../../../../../types/general/enums/generalEnums';
import { Location } from '../../../../../types/infrastructure/infrastructureTypes';
import { SortObjectByPropName } from '../../../../../utils/arrayTools';
import { DatePickerDateCalculator } from '../../../../../utils/dateTimeTools';

// props
interface IPros {
    initDataFields: TesField[] | undefined;
    location: Location;
    setLocation: React.Dispatch<React.SetStateAction<any>>
    isLocked: boolean;
    setDataChanged: React.Dispatch<React.SetStateAction<any>>;
    pageMode: number
    validationRef: React.RefObject<ValidationGroupRef>
    generalSettings: GeneralSetting | null;

}

const ApproachDetails = (props: IPros) => {

    const compactViewModel: boolean = (JSON.parse(localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}') || {}).viewMode === "compact";

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    function onChangeLocationFields(name: string, value: any) {
        props.setLocation({
            ...props.location,
            [name]: value
        })
        if (props.pageMode === PageMode.Update) {
            props.setDataChanged(true);
        }
    }

    function onChangeLocationSWPFields(name: string, value: any) {
        props.setLocation({
            ...props.location,
            signalWarrantsPredictionSettings: { ...props.location.signalWarrantsPredictionSettings, [name]: value }
        })
        if (props.pageMode === PageMode.Update) {
            props.setDataChanged(true);
        }
    }

    function onChangeFields(name: string, value: any, isUpperCase: boolean) {
        props.setLocation({
            ...props.location,
            general: {
                ...props.location.general,
                [name]: value
            }
        })
        if (props.pageMode === PageMode.Update) {
            props.setDataChanged(true)
        }
    }

    return (
        <div className={`intersectionDetails-approachDetails ${compactViewModel ? "compactStyle" : ""}`}>
            <React.Fragment>
                <div className='row' style={{ marginTop: 20, padding: 10 }}>
                    <ValidationGroup
                        ref={props.validationRef}
                    >

                        <Form colCount={2}>

                            <SimpleItem colSpan={1}>
                                <MidBlockValuePicker
                                    label={t('northApproach')}
                                    name='intersectionNorthApproachId'
                                    value={props.location.intersectionNorthApproachId}
                                    onChangeFields={onChangeLocationFields}
                                    isLocked={props.isLocked}
                                    isTextBox={true}
                                />
                            </SimpleItem>
                            <SimpleItem colSpan={1}>

                                <MidBlockValuePicker
                                    label={t('southApproach')}
                                    name='intersectionSouthApproachId'
                                    value={props.location.intersectionSouthApproachId}
                                    onChangeFields={onChangeLocationFields}
                                    isLocked={props.isLocked}
                                    isTextBox={true}
                                />
                            </SimpleItem>
                            <SimpleItem colSpan={1}>

                                <MidBlockValuePicker
                                    label={t('westApproach')}
                                    name='intersectionWestApproachId'
                                    value={props.location.intersectionWestApproachId}
                                    onChangeFields={onChangeLocationFields}
                                    isLocked={props.isLocked}
                                    isTextBox={true}
                                />
                            </SimpleItem>
                            <SimpleItem colSpan={1}>

                                <MidBlockValuePicker
                                    label={t('eastApproach')}
                                    name='intersectionEastApproachId'
                                    value={props.location.intersectionEastApproachId}
                                    onChangeFields={onChangeLocationFields}
                                    isLocked={props.isLocked}
                                    isTextBox={true}
                                />
                            </SimpleItem>
                            {/* eslint-disable-next-line array-callback-return */}
                            {props.initDataFields && props.initDataFields.filter(x => x.fieldCategoryType === FieldCategoryType.InfrastructureIntersectionApproachDetails).map((f, i) => {
                                if (f.fieldType === FieldType.String) {
                                    return (
                                        <SimpleItem colSpan={1}>
                                            <TextBox
                                                // // style={{ marginBottom: 20 }}
                                                label={f.labelText}
                                                labelMode='static'
                                                value={props.location.general && props.location.general[f.name]}
                                                onValueChange={e => onChangeFields(f.name, e, true)}
                                                key={f.id}
                                                name={f.name}
                                                disabled={props.isLocked || f.permission === FieldPermission.View || f.readOnlyField}
                                            />
                                        </SimpleItem>
                                    );
                                }
                                if (f.fieldType === FieldType.Number || f.fieldType === FieldType.DecimalNumber || f.fieldType === FieldType.DoubleNumber) {
                                    return (
                                        <SimpleItem colSpan={1}>
                                            <TextBox
                                                // // style={{ marginBottom: 20 }}
                                                label={f.labelText}
                                                labelMode='static'
                                                value={props.location.general && props.location.general[f.name]}
                                                onValueChange={e => onChangeFields(f.name, e, true)}
                                                key={f.id}
                                                name={f.name}
                                                disabled={props.isLocked || f.permission === FieldPermission.View || f.readOnlyField}
                                            />
                                        </SimpleItem>
                                    );
                                }

                                if (f.fieldType === FieldType.Boolean) {
                                    return (
                                        <SimpleItem colSpan={1}>
                                            <div style={{ fontWeight: 'bold' }} >{f.labelText}</div>
                                            <Switch
                                                onValueChange={e => onChangeFields(f.name, e, true)}
                                                value={props.location.general && props.location.general[f.name]}
                                                key={f.id}
                                                name={f.name}
                                                disabled={props.isLocked || f.permission === FieldPermission.View || f.readOnlyField}
                                            />
                                        </SimpleItem>
                                    );
                                }

                                if (f.fieldType === FieldType.Date) {
                                    return (
                                        <SimpleItem colSpan={1}>
                                            <DateBox
                                                label={f.labelText}
                                                value={f.exactDT === true ? DatePickerDateCalculator(props.location.general[f.name]) : props.location.general[f.name]}
                                                dateSerializationFormat={f.exactDT === true ? "yyyy-MM-ddTHH:mm:ss" : undefined}
                                                type="date"
                                                onValueChange={e => onChangeFields(f.name, e, true)}
                                                key={f.id}
                                                name={f.name}
                                                pickerType="calendar"
                                                placeholder={props.generalSettings?.dateFormat}
                                                displayFormat={props.generalSettings?.dateFormat}
                                                useMaskBehavior={true}
                                                openOnFieldClick={true}
                                                disabled={props.isLocked || f.permission === FieldPermission.View || f.readOnlyField}
                                                showClearButton={true}
                                            />
                                        </SimpleItem>
                                    );
                                }

                                if (f.fieldType === FieldType.Time) {
                                    return (
                                        <SimpleItem colSpan={1}>
                                            <DateBox
                                                label={f.labelText}
                                                value={f.exactDT === true ? DatePickerDateCalculator(props.location.general[f.name]) : props.location.general[f.name]}
                                                dateSerializationFormat={f.exactDT === true ? "yyyy-MM-ddTHH:mm:ss" : undefined}
                                                type="time"
                                                onValueChange={e => onChangeFields(f.name, e, true)}
                                                key={f.id}
                                                name={f.name}
                                                pickerType="rollers"
                                                placeholder={props.generalSettings?.timeFormat}
                                                displayFormat={props.generalSettings?.timeFormat}
                                                useMaskBehavior={true}
                                                openOnFieldClick={true}
                                                disabled={props.isLocked || f.permission === FieldPermission.View || f.readOnlyField}
                                                showClearButton={true}
                                            />
                                        </SimpleItem>
                                    );
                                }

                                if (f.fieldType === FieldType.DateTime) {
                                    return (
                                        <SimpleItem colSpan={1}>
                                            <DateBox
                                                label={f.labelText}
                                                value={f.exactDT === true ? DatePickerDateCalculator(props.location.general[f.name]) : props.location.general[f.name]}
                                                dateSerializationFormat={f.exactDT === true ? "yyyy-MM-ddTHH:mm:ss" : undefined}
                                                type="datetime"
                                                onValueChange={e => onChangeFields(f.name, e, true)}
                                                key={f.id}
                                                name={f.name}
                                                pickerType="calendar"
                                                placeholder={props.generalSettings?.dateTimeFormat}
                                                displayFormat={props.generalSettings?.dateTimeFormat}
                                                useMaskBehavior={true}
                                                openOnFieldClick={true}
                                                disabled={props.isLocked || f.permission === FieldPermission.View || f.readOnlyField}
                                                showClearButton={true}
                                            />
                                        </SimpleItem>
                                    );
                                }


                                if (f.fieldType === FieldType.List) {
                                    return (
                                        <SimpleItem colSpan={1}>
                                            <SelectBox
                                                placeholder=""
                                                label={f.labelText}
                                                labelMode='static'
                                                items={SortObjectByPropName(f.fieldValues, "name")}
                                                displayExpr="name"
                                                valueExpr="id"
                                                value={props.location.general && props.location.general[f.name]}
                                                onValueChange={e => onChangeFields(f.name, e, true)}
                                                key={f.id}
                                                name={f.name}
                                                disabled={props.isLocked || f.permission === FieldPermission.View || f.readOnlyField}
                                                showClearButton={true}
                                                searchEnabled={true}
                                            />
                                        </SimpleItem>
                                    );
                                }

                            })
                            }
                        </Form>

                        <CollapsibleCard isOpen={true} title={t("signalWarrantPredictionAnalysisSettings")}>

                            <Form colCount={2}>
                                <SimpleItem colSpan={1}>
                                    <NumberBox
                                        // // style={{ marginBottom: 20 }}
                                        label={t("northApproachGrowth")}
                                        labelMode='static'
                                        value={props.location.signalWarrantsPredictionSettings?.northUserDefinedGrowth ?? 0}
                                        onValueChange={e => onChangeLocationSWPFields("northUserDefinedGrowth", e)}
                                        disabled={props.isLocked}
                                    />
                                </SimpleItem>
                                <SimpleItem colSpan={1}>
                                    <NumberBox
                                        // // style={{ marginBottom: 20 }}
                                        label={t("eastApproachGrowth")}
                                        labelMode='static'
                                        value={props.location.signalWarrantsPredictionSettings?.eastUserDefinedGrowth ?? 0}
                                        onValueChange={e => onChangeLocationSWPFields("eastUserDefinedGrowth", e)}
                                        disabled={props.isLocked}
                                    />
                                </SimpleItem>
                                <SimpleItem colSpan={1}>
                                    <NumberBox
                                        // // style={{ marginBottom: 20 }}
                                        label={t("southApproachGrowth")}
                                        labelMode='static'
                                        value={props.location.signalWarrantsPredictionSettings?.southUserDefinedGrowth ?? 0}
                                        onValueChange={e => onChangeLocationSWPFields("southUserDefinedGrowth", e)}
                                        disabled={props.isLocked}
                                    />
                                </SimpleItem>
                                <SimpleItem colSpan={1}>
                                    <NumberBox
                                        // // style={{ marginBottom: 20 }}
                                        label={t("westApproachGrowth")}
                                        labelMode='static'
                                        value={props.location.signalWarrantsPredictionSettings?.westUserDefinedGrowth ?? 0}
                                        onValueChange={e => onChangeLocationSWPFields("westUserDefinedGrowth", e)}
                                        disabled={props.isLocked}
                                    />
                                </SimpleItem>

                            </Form>

                        </CollapsibleCard>

                    </ValidationGroup>
                </div>
            </React.Fragment>
        </div>
    );


}
export default ApproachDetails;