import { Button, DateBox, Form, ScrollView, SelectBox, ValidationGroup } from 'devextreme-react';
import { ValidationGroupRef } from 'devextreme-react/cjs/validation-group';
import { CustomShape, Diagram, DiagramRef, Group, Toolbox } from 'devextreme-react/diagram';
import { SimpleItem } from "devextreme-react/form";
import { Popup } from 'devextreme-react/popup';
import { RequiredRule, Validator } from 'devextreme-react/validator';
import notify from 'devextreme/ui/notify';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../contexts/auth';
import { useClientSetting } from '../../contexts/clientSetting';
import { CollisionApiUrl } from '../../environment/routeSettings';
import { CollisionDiagramRequest } from '../../types/collision/collisionTypes';
import { CollisionDiagramType } from '../../types/collision/enums/collisionEnums';
import { NameValue } from '../../types/general/generalTypes';
import { SortObjectByPropName } from '../../utils/arrayTools';
import { Enum2Array } from '../../utils/enumTools';
import { TesPost } from '../../utils/rest';

interface IPros {
   getCollisionDiagramData: () => Promise<string[]>
   lock: boolean
}


const CollisionDiagram = (props: IPros) => {
   const [diagramRequest, setDiagramRequest] = useState<CollisionDiagramRequest>(new CollisionDiagramRequest());
   const [lstCollisionTypeDiagram, setLstCollisionTypeDiagram] = useState<NameValue[]>([]);
   const [lstCollisionId, setLstCollisionId] = useState<string[]>([]);
   const [reports, setReports] = useState<any>({});
   const [showModal, setShowModal] = useState<boolean>(false);
   const [showDiagramViewer, setShowDataGramViewer] = useState<boolean>(false);
   const { generalSetting } = useClientSetting();
   const validationRef = useRef<ValidationGroupRef>(null);
   const { t } = useTranslation();
   const { activeLoading } = useAuth();
   const diagramRef = useRef<DiagramRef>(null);


   useEffect(() => {
      async function fetchMyAPI() {
         try {
            if (activeLoading) activeLoading(true);
            if (activeLoading) activeLoading(false);
         } catch (ex) {
            if (activeLoading) activeLoading(false);
            notify(t("someErrorOccurred") + ex, "error", 2500);
         }
      }
      fetchMyAPI()
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);


   function onCloseModal() {
      setShowModal(false)
      setDiagramRequest(new CollisionDiagramRequest())
   }

   async function onOpenModal() {
      if ((await props.getCollisionDiagramData()).length === 0) {
         notify(t('noSelectedRows'), 'warning', 5000)
      } else {
         setLstCollisionTypeDiagram(Enum2Array(CollisionDiagramType));
         setLstCollisionId(await props.getCollisionDiagramData());
         setShowModal(true)
      }

   }

   function onValueChange(name: string, value: any) {
      setDiagramRequest({ ...diagramRequest, [name]: value })
   }

   async function onGetReport() {
      try {
         var postObj: CollisionDiagramRequest = {
            type: diagramRequest.type,
            customerId: localStorage.getItem('selectedCustomerId') as string,
            fromDate: diagramRequest.fromDate,
            toDate: diagramRequest.toDate,
            lstCollisionId: lstCollisionId
         }
         const validationRes = validationRef.current?.instance().validate();
         if (validationRes?.isValid) {
            const res = await TesPost(CollisionApiUrl() + "/api/Reports/GetCollisionDiagram", postObj, true);
            if (res !== null) {
               setReports(res);
               setShowDataGramViewer(true);
               diagramRef.current?.instance().import(JSON.stringify(res));
            }
         }
         console.log("reports" + JSON.stringify(reports))
      } catch (ex) {
         notify(ex, 'warning', 3000)
      }
   }



   return (
      <div>
         <Button
            icon="fa-solid fa-chart-pie"
            style={{ marginLeft: 5 }}
            onClick={() => onOpenModal()}
            hint={t('collisionDiagram')}
            disabled={props.lock}
         />
         {/* collision diagram request popup */}
         <Popup
            width={"60%"}
            height={"auto"}
            visible={showModal}
            resizeEnabled={true}
            showTitle={true}
            title={t("collisionDiagram")}
            hideOnOutsideClick={false}
            showCloseButton={true}
            onHiding={() => onCloseModal()}
         >
            <ValidationGroup
               ref={validationRef}
            >
               <ScrollView width="100%" height="100%">
                  <Form colCount={2}>
                     <SimpleItem colSpan={1}>
                        <DateBox
                           label={t("fromDate")}
                           type="date"
                           value={diagramRequest?.fromDate}
                           onValueChange={(e) => onValueChange("fromDate", e)}
                           pickerType="calendar"
                           placeholder={generalSetting?.dateFormat}
                           displayFormat={generalSetting?.dateFormat}
                           useMaskBehavior={true}
                           openOnFieldClick={true}
                           showClearButton={true}
                        />
                     </SimpleItem>

                     <SimpleItem colSpan={1}>
                        <DateBox
                           label={t("toDate")}
                           type="date"
                           value={diagramRequest?.toDate}
                           onValueChange={(e) => onValueChange("toDate", e)}
                           pickerType="calendar"
                           placeholder={generalSetting?.dateFormat}
                           displayFormat={generalSetting?.dateFormat}
                           useMaskBehavior={true}
                           openOnFieldClick={true}
                           showClearButton={true}
                        />
                     </SimpleItem>
                     <SimpleItem colSpan={1}>
                        <SelectBox
                           label={t("collisionDiagramType")}
                           labelMode="static"
                           value={diagramRequest?.type}
                           onValueChange={(e) => onValueChange("type", e)}
                           className="modalInput"
                           valueExpr={"value"}
                           displayExpr={"name"}
                           items={SortObjectByPropName(lstCollisionTypeDiagram.filter(x => parseInt(x.value) < 6), "name")}
                           searchEnabled={true}
                        >
                           <Validator>
                              <RequiredRule message={t('fieldIsRequired')} />
                           </Validator>
                        </SelectBox>
                     </SimpleItem>

                  </Form>
                  <div>
                     <div className="rightColumn">
                        <Button
                           className="tes-modal-btn-cancel"
                           style={{ marginRight: 20 }}
                           onClick={() => onCloseModal()}
                           text={t("cancel")}
                        />
                        <Button
                           className="tes-modal-btn-add"
                           onClick={onGetReport}
                           text={t("create")}
                        />

                     </div>
                  </div>
               </ScrollView>
            </ValidationGroup>
         </Popup>


         {/* reports designer popup */}
         <Popup
            width={"90%"}
            height={"85%"}
            visible={showDiagramViewer}
            resizeEnabled={true}
            showTitle={true}
            title={t("collisionDiagram")}
            hideOnOutsideClick={false}
            showCloseButton={true}
            onHiding={() => setShowDataGramViewer(false)}
         >
            <ScrollView width="100%" height="100%">
               <Diagram id="diagram" ref={diagramRef}>


                  <CustomShape
                     category="TurningMovements"
                     type="TurningMovement_NS"
                     title={t("turningMovements")}
                     backgroundImageUrl="https://staticfile.tes.ca/collisonReport/collisionDiagram/TurningMovement_NS.svg"
                     backgroundImageLeft={0.15}
                     backgroundImageTop={0}
                     backgroundImageWidth={0.7}
                     backgroundImageHeight={0.7}
                     defaultWidth={0.7}
                     defaultHeight={0.7}
                     defaultText="0"
                     allowEditText={false}
                     textLeft={0}
                     textTop={0.7}
                     textWidth={1}
                     textHeight={0.3}>
                  </CustomShape>

                  <CustomShape
                     category="TurningMovements"
                     type="TurningMovement_EW"
                     title={t("turningMovements")}
                     backgroundImageUrl="https://staticfile.tes.ca/collisonReport/collisionDiagram/TurningMovement_EW.svg"
                     backgroundImageLeft={0.15}
                     backgroundImageTop={0}
                     backgroundImageWidth={0.7}
                     backgroundImageHeight={0.7}
                     defaultWidth={0.7}
                     defaultHeight={0.7}
                     defaultText="0"
                     allowEditText={false}
                     textLeft={0}
                     textTop={0.7}
                     textWidth={1}
                     textHeight={0.3}>
                  </CustomShape>


                  <CustomShape
                     category="TurningMovements"
                     type="TurningMovement_SN"
                     title={t("turningMovements")}
                     backgroundImageUrl="https://staticfile.tes.ca/collisonReport/collisionDiagram/TurningMovement_SN.svg"
                     backgroundImageLeft={0.15}
                     backgroundImageTop={0}
                     backgroundImageWidth={0.7}
                     backgroundImageHeight={0.7}
                     defaultWidth={0.7}
                     defaultHeight={0.7}
                     defaultText="0"
                     allowEditText={false}
                     textLeft={0}
                     textTop={0.7}
                     textWidth={1}
                     textHeight={0.3}>
                  </CustomShape>

                  <CustomShape
                     category="TurningMovements"
                     type="TurningMovement_WE"
                     title={t("turningMovements")}
                     backgroundImageUrl="https://staticfile.tes.ca/collisonReport/collisionDiagram/TurningMovement_WE.svg"
                     backgroundImageLeft={0.15}
                     backgroundImageTop={0}
                     backgroundImageWidth={0.7}
                     backgroundImageHeight={0.7}
                     defaultWidth={0.7}
                     defaultHeight={0.7}
                     defaultText="0"
                     allowEditText={false}
                     textLeft={0}
                     textTop={0.7}
                     textWidth={1}
                     textHeight={0.3}>
                  </CustomShape>



                  <CustomShape
                     category="Angles"
                     type="Angle_SW"
                     title={t("angles")}
                     backgroundImageUrl="https://staticfile.tes.ca/collisonReport/collisionDiagram/Angle_SW.svg"
                     backgroundImageLeft={0.15}
                     backgroundImageTop={0}
                     backgroundImageWidth={0.7}
                     backgroundImageHeight={0.7}
                     defaultWidth={0.7}
                     defaultHeight={0.7}
                     defaultText="0"
                     allowEditText={false}
                     textLeft={0}
                     textTop={0.7}
                     textWidth={1}
                     textHeight={0.3}>
                  </CustomShape>

                  <CustomShape
                     category="Angles"
                     type="Angle_SE"
                     title={t("angles")}
                     backgroundImageUrl="https://staticfile.tes.ca/collisonReport/collisionDiagram/Angle_SE.svg"
                     backgroundImageLeft={0.15}
                     backgroundImageTop={0}
                     backgroundImageWidth={0.7}
                     backgroundImageHeight={0.7}
                     defaultWidth={0.7}
                     defaultHeight={0.7}
                     defaultText="0"
                     allowEditText={false}
                     textLeft={0}
                     textTop={0.7}
                     textWidth={1}
                     textHeight={0.3}>
                  </CustomShape>

                  <CustomShape
                     category="Angles"
                     type="Angle_NE"
                     title={t("angles")}
                     backgroundImageUrl="https://staticfile.tes.ca/collisonReport/collisionDiagram/Angle_NE.svg"
                     backgroundImageLeft={0.15}
                     backgroundImageTop={0}
                     backgroundImageWidth={0.7}
                     backgroundImageHeight={0.7}
                     defaultWidth={0.7}
                     defaultHeight={0.7}
                     defaultText="0"
                     allowEditText={false}
                     textLeft={0}
                     textTop={0.7}
                     textWidth={1}
                     textHeight={0.3}>
                  </CustomShape>

                  <CustomShape
                     category="Angles"
                     type="Angle_NW"
                     title={t("angles")}
                     backgroundImageUrl="https://staticfile.tes.ca/collisonReport/collisionDiagram/Angle_NW.svg"
                     backgroundImageLeft={0.15}
                     backgroundImageTop={0}
                     backgroundImageWidth={0.7}
                     backgroundImageHeight={0.7}
                     defaultWidth={0.7}
                     defaultHeight={0.7}
                     defaultText="0"
                     allowEditText={false}
                     textLeft={0}
                     textTop={0.7}
                     textWidth={1}
                     textHeight={0.3}>
                  </CustomShape>

                  <CustomShape
                     category="AngleTurningLeft"
                     type="AngleTurningLeft_EN"
                     title={t("angleTurningLeft")}
                     backgroundImageUrl="https://staticfile.tes.ca/collisonReport/collisionDiagram/AngleTurningLeft_EN.svg"
                     backgroundImageLeft={0.15}
                     backgroundImageTop={0}
                     backgroundImageWidth={0.7}
                     backgroundImageHeight={0.7}
                     defaultWidth={0.7}
                     defaultHeight={0.7}
                     defaultText="0"
                     allowEditText={false}
                     textLeft={0}
                     textTop={0.7}
                     textWidth={1}
                     textHeight={0.3}>
                  </CustomShape>


                  <CustomShape
                     category="AngleTurningLeft"
                     type="AngleTurningLeft_SE"
                     title={t("angleTurningLeft")}
                     backgroundImageUrl="https://staticfile.tes.ca/collisonReport/collisionDiagram/AngleTurningLeft_SE.svg"
                     backgroundImageLeft={0.15}
                     backgroundImageTop={0}
                     backgroundImageWidth={0.7}
                     backgroundImageHeight={0.7}
                     defaultWidth={0.7}
                     defaultHeight={0.7}
                     defaultText="0"
                     allowEditText={false}
                     textLeft={0}
                     textTop={0.7}
                     textWidth={1}
                     textHeight={0.3}>
                  </CustomShape>



                  <CustomShape
                     category="AngleTurningLeft"
                     type="AngleTurningLeft_WS"
                     title={t("angleTurningLeft")}
                     backgroundImageUrl="https://staticfile.tes.ca/collisonReport/collisionDiagram/AngleTurningLeft_WS.svg"
                     backgroundImageLeft={0.15}
                     backgroundImageTop={0}
                     backgroundImageWidth={0.7}
                     backgroundImageHeight={0.7}
                     defaultWidth={0.7}
                     defaultHeight={0.7}
                     defaultText="0"
                     allowEditText={false}
                     textLeft={0}
                     textTop={0.7}
                     textWidth={1}
                     textHeight={0.3}>
                  </CustomShape>

                  <CustomShape
                     category="AngleTurningLeft"
                     type="AngleTurningLeft_NW"
                     title={t("angleTurningLeft")}
                     backgroundImageUrl="https://staticfile.tes.ca/collisonReport/collisionDiagram/AngleTurningLeft_NW.svg"
                     backgroundImageLeft={0.15}
                     backgroundImageTop={0}
                     backgroundImageWidth={0.7}
                     backgroundImageHeight={0.7}
                     defaultWidth={0.7}
                     defaultHeight={0.7}
                     defaultText="0"
                     allowEditText={false}
                     textLeft={0}
                     textTop={0.7}
                     textWidth={1}
                     textHeight={0.3}>
                  </CustomShape>

                  <CustomShape
                     category="AngleTurningRight"
                     type="AngleTurningRight_EN"
                     title={t("angleTurningLeft")}
                     backgroundImageUrl="https://staticfile.tes.ca/collisonReport/collisionDiagram/AngleTurningRight_EN.svg"
                     backgroundImageLeft={0.15}
                     backgroundImageTop={0}
                     backgroundImageWidth={0.7}
                     backgroundImageHeight={0.7}
                     defaultWidth={0.7}
                     defaultHeight={0.7}
                     defaultText="0"
                     allowEditText={false}
                     textLeft={0}
                     textTop={0.7}
                     textWidth={1}
                     textHeight={0.3}>
                  </CustomShape>

                  <CustomShape
                     category="AngleTurningRight"
                     type="AngleTurningRight_SE"
                     title={t("angleTurningLeft")}
                     backgroundImageUrl="https://staticfile.tes.ca/collisonReport/collisionDiagram/AngleTurningRight_SE.svg"
                     backgroundImageLeft={0.15}
                     backgroundImageTop={0}
                     backgroundImageWidth={0.7}
                     backgroundImageHeight={0.7}
                     defaultWidth={0.7}
                     defaultHeight={0.7}
                     defaultText="0"
                     allowEditText={false}
                     textLeft={0}
                     textTop={0.7}
                     textWidth={1}
                     textHeight={0.3}>
                  </CustomShape>

                  <CustomShape
                     category="AngleTurningRight"
                     type="AngleTurningRight_WS"
                     title={t("angleTurningLeft")}
                     backgroundImageUrl="https://staticfile.tes.ca/collisonReport/collisionDiagram/AngleTurningRight_WS.svg"
                     backgroundImageLeft={0.15}
                     backgroundImageTop={0}
                     backgroundImageWidth={0.7}
                     backgroundImageHeight={0.7}
                     defaultWidth={0.7}
                     defaultHeight={0.7}
                     defaultText="0"
                     allowEditText={false}
                     textLeft={0}
                     textTop={0.7}
                     textWidth={1}
                     textHeight={0.3}>
                  </CustomShape>

                  <CustomShape
                     category="AngleTurningRight"
                     type="AngleTurningRight_NW"
                     title={t("angleTurningLeft")}
                     backgroundImageUrl="https://staticfile.tes.ca/collisonReport/collisionDiagram/AngleTurningRight_NW.svg"
                     backgroundImageLeft={0.15}
                     backgroundImageTop={0}
                     backgroundImageWidth={0.7}
                     backgroundImageHeight={0.7}
                     defaultWidth={0.7}
                     defaultHeight={0.7}
                     defaultText="0"
                     allowEditText={false}
                     textLeft={0}
                     textTop={0.7}
                     textWidth={1}
                     textHeight={0.3}>
                  </CustomShape>

                  <CustomShape
                     category="Approaching"
                     type="Approaching_NS"
                     title={t("approaching")}
                     backgroundImageUrl="https://staticfile.tes.ca/collisonReport/collisionDiagram/Approaching_NS.svg"
                     backgroundImageLeft={0.15}
                     backgroundImageTop={0}
                     backgroundImageWidth={0.7}
                     backgroundImageHeight={0.7}
                     defaultWidth={0.7}
                     defaultHeight={0.7}
                     defaultText="0"
                     allowEditText={false}
                     textLeft={0}
                     textTop={0.7}
                     textWidth={1}
                     textHeight={0.3}>
                  </CustomShape>

                  <CustomShape
                     category="Approaching"
                     type="Approaching_WE"
                     title={t("approaching")}
                     backgroundImageUrl="https://staticfile.tes.ca/collisonReport/collisionDiagram/Approaching_WE.svg"
                     backgroundImageLeft={0.15}
                     backgroundImageTop={0}
                     backgroundImageWidth={0.7}
                     backgroundImageHeight={0.7}
                     defaultWidth={0.7}
                     defaultHeight={0.7}
                     defaultText="0"
                     allowEditText={false}
                     textLeft={0}
                     textTop={0.7}
                     textWidth={1}
                     textHeight={0.3}>
                  </CustomShape>

                  <CustomShape
                     category="RearEnd"
                     type="RearEnd_NN"
                     title={t("rearEnd")}
                     backgroundImageUrl="https://staticfile.tes.ca/collisonReport/collisionDiagram/RearEnd_NN.svg"
                     backgroundImageLeft={0.15}
                     backgroundImageTop={0}
                     backgroundImageWidth={0.7}
                     backgroundImageHeight={0.7}
                     defaultWidth={0.7}
                     defaultHeight={0.7}
                     defaultText="0"
                     allowEditText={false}
                     textLeft={0}
                     textTop={0.7}
                     textWidth={1}
                     textHeight={0.3}>
                  </CustomShape>

                  <CustomShape
                     category="RearEnd"
                     type="RearEnd_EE"
                     title={t("rearEnd")}
                     backgroundImageUrl="https://staticfile.tes.ca/collisonReport/collisionDiagram/RearEnd_EE.svg"
                     backgroundImageLeft={0.15}
                     backgroundImageTop={0}
                     backgroundImageWidth={0.7}
                     backgroundImageHeight={0.7}
                     defaultWidth={0.7}
                     defaultHeight={0.7}
                     defaultText="0"
                     allowEditText={false}
                     textLeft={0}
                     textTop={0.7}
                     textWidth={1}
                     textHeight={0.3}>
                  </CustomShape>

                  <CustomShape
                     category="RearEnd"
                     type="RearEnd_SS"
                     title={t("rearEnd")}
                     backgroundImageUrl="https://staticfile.tes.ca/collisonReport/collisionDiagram/RearEnd_SS.svg"
                     backgroundImageLeft={0.15}
                     backgroundImageTop={0}
                     backgroundImageWidth={0.7}
                     backgroundImageHeight={0.7}
                     defaultWidth={0.7}
                     defaultHeight={0.7}
                     defaultText="0"
                     allowEditText={false}
                     textLeft={0}
                     textTop={0.7}
                     textWidth={1}
                     textHeight={0.3}>
                  </CustomShape>

                  <CustomShape
                     category="RearEnd"
                     type="RearEnd_WW"
                     title={t("rearEnd")}
                     backgroundImageUrl="https://staticfile.tes.ca/collisonReport/collisionDiagram/RearEnd_WW.svg"
                     backgroundImageLeft={0.15}
                     backgroundImageTop={0}
                     backgroundImageWidth={0.7}
                     backgroundImageHeight={0.7}
                     defaultWidth={0.7}
                     defaultHeight={0.7}
                     defaultText="0"
                     allowEditText={false}
                     textLeft={0}
                     textTop={0.7}
                     textWidth={1}
                     textHeight={0.3}>
                  </CustomShape>


                  <CustomShape
                     category="Sideswipe"
                     type="Sideswipe_NN"
                     title={t("sideswipe")}
                     backgroundImageUrl="https://staticfile.tes.ca/collisonReport/collisionDiagram/Sideswipe_NN.svg"
                     backgroundImageLeft={0.15}
                     backgroundImageTop={0}
                     backgroundImageWidth={0.7}
                     backgroundImageHeight={0.7}
                     defaultWidth={0.7}
                     defaultHeight={0.7}
                     defaultText="0"
                     allowEditText={false}
                     textLeft={0}
                     textTop={0.7}
                     textWidth={1}
                     textHeight={0.3}>
                  </CustomShape>


                  <CustomShape
                     category="Sideswipe"
                     type="Sideswipe_EE"
                     title={t("sideswipe")}
                     backgroundImageUrl="https://staticfile.tes.ca/collisonReport/collisionDiagram/Sideswipe_EE.svg"
                     backgroundImageLeft={0.15}
                     backgroundImageTop={0}
                     backgroundImageWidth={0.7}
                     backgroundImageHeight={0.7}
                     defaultWidth={0.7}
                     defaultHeight={0.7}
                     defaultText="0"
                     allowEditText={false}
                     textLeft={0}
                     textTop={0.7}
                     textWidth={1}
                     textHeight={0.3}>
                  </CustomShape>


                  <CustomShape
                     category="Sideswipe"
                     type="Sideswipe_SS"
                     title={t("sideswipe")}
                     backgroundImageUrl="https://staticfile.tes.ca/collisonReport/collisionDiagram/Sideswipe_SS.svg"
                     backgroundImageLeft={0.15}
                     backgroundImageTop={0}
                     backgroundImageWidth={0.7}
                     backgroundImageHeight={0.7}
                     defaultWidth={0.7}
                     defaultHeight={0.7}
                     defaultText="0"
                     allowEditText={false}
                     textLeft={0}
                     textTop={0.7}
                     textWidth={1}
                     textHeight={0.3}>
                  </CustomShape>

                  <CustomShape
                     category="Sideswipe"
                     type="Sideswipe_WW"
                     title={t("sideswipe")}
                     backgroundImageUrl="https://staticfile.tes.ca/collisonReport/collisionDiagram/Sideswipe_WW.svg"
                     backgroundImageLeft={0.15}
                     backgroundImageTop={0}
                     backgroundImageWidth={0.7}
                     backgroundImageHeight={0.7}
                     defaultWidth={0.7}
                     defaultHeight={0.7}
                     defaultText="0"
                     allowEditText={false}
                     textLeft={0}
                     textTop={0.7}
                     textWidth={1}
                     textHeight={0.3}>
                  </CustomShape>

                  <CustomShape
                     category="SMVOther"
                     type="SMVOther_N"
                     title={t("SMVOther")}
                     backgroundImageUrl="https://staticfile.tes.ca/collisonReport/collisionDiagram/SMVOther_N.svg"
                     backgroundImageLeft={0.15}
                     backgroundImageTop={0}
                     backgroundImageWidth={0.7}
                     backgroundImageHeight={0.7}
                     defaultWidth={0.7}
                     defaultHeight={0.7}
                     defaultText="0"
                     allowEditText={false}
                     textLeft={0}
                     textTop={0.7}
                     textWidth={1}
                     textHeight={0.3}>
                  </CustomShape>

                  <CustomShape
                     category="SMVOther"
                     type="SMVOther_E"
                     title={t("SMVOther")}
                     backgroundImageUrl="https://staticfile.tes.ca/collisonReport/collisionDiagram/SMVOther_E.svg"
                     backgroundImageLeft={0.15}
                     backgroundImageTop={0}
                     backgroundImageWidth={0.7}
                     backgroundImageHeight={0.7}
                     defaultWidth={0.7}
                     defaultHeight={0.7}
                     defaultText="0"
                     allowEditText={false}
                     textLeft={0}
                     textTop={0.7}
                     textWidth={1}
                     textHeight={0.3}>
                  </CustomShape>

                  <CustomShape
                     category="SMVOther"
                     type="SMVOther_S"
                     title={t("SMVOther")}
                     backgroundImageUrl="https://staticfile.tes.ca/collisonReport/collisionDiagram/SMVOther_S.svg"
                     backgroundImageLeft={0.15}
                     backgroundImageTop={0}
                     backgroundImageWidth={0.7}
                     backgroundImageHeight={0.7}
                     defaultWidth={0.7}
                     defaultHeight={0.7}
                     defaultText="0"
                     allowEditText={false}
                     textLeft={0}
                     textTop={0.7}
                     textWidth={1}
                     textHeight={0.3}>
                  </CustomShape>

                  <CustomShape
                     category="SMVOther"
                     type="SMVOther_W"
                     title={t("SMVOther")}
                     backgroundImageUrl="https://staticfile.tes.ca/collisonReport/collisionDiagram/SMVOther_W.svg"
                     backgroundImageLeft={0.15}
                     backgroundImageTop={0}
                     backgroundImageWidth={0.7}
                     backgroundImageHeight={0.7}
                     defaultWidth={0.7}
                     defaultHeight={0.7}
                     defaultText="0"
                     allowEditText={false}
                     textLeft={0}
                     textTop={0.7}
                     textWidth={1}
                     textHeight={0.3}>
                  </CustomShape>

                  <CustomShape
                     category="SMVUnattended"
                     type="SMVUnattended_N"
                     title={t("SMVOther")}
                     backgroundImageUrl="https://staticfile.tes.ca/collisonReport/collisionDiagram/SMVUnattended_N.svg"
                     backgroundImageLeft={0.15}
                     backgroundImageTop={0}
                     backgroundImageWidth={0.7}
                     backgroundImageHeight={0.7}
                     defaultWidth={0.7}
                     defaultHeight={0.7}
                     defaultText="0"
                     allowEditText={false}
                     textLeft={0}
                     textTop={0.7}
                     textWidth={1}
                     textHeight={0.3}>
                  </CustomShape>
                  <CustomShape
                     category="SMVUnattended"
                     type="SMVUnattended_E"
                     title={t("SMVOther")}
                     backgroundImageUrl="https://staticfile.tes.ca/collisonReport/collisionDiagram/SMVUnattended_E.svg"
                     backgroundImageLeft={0.15}
                     backgroundImageTop={0}
                     backgroundImageWidth={0.7}
                     backgroundImageHeight={0.7}
                     defaultWidth={0.7}
                     defaultHeight={0.7}
                     defaultText="0"
                     allowEditText={false}
                     textLeft={0}
                     textTop={0.7}
                     textWidth={1}
                     textHeight={0.3}>
                  </CustomShape>
                  <CustomShape
                     category="SMVUnattended"
                     type="SMVUnattended_S"
                     title={t("SMVOther")}
                     backgroundImageUrl="https://staticfile.tes.ca/collisonReport/collisionDiagram/SMVUnattended_S.svg"
                     backgroundImageLeft={0.15}
                     backgroundImageTop={0}
                     backgroundImageWidth={0.7}
                     backgroundImageHeight={0.7}
                     defaultWidth={0.7}
                     defaultHeight={0.7}
                     defaultText="0"
                     allowEditText={false}
                     textLeft={0}
                     textTop={0.7}
                     textWidth={1}
                     textHeight={0.3}>
                  </CustomShape>
                  <CustomShape
                     category="SMVUnattended"
                     type="SMVUnattended_W"
                     title={t("SMVOther")}
                     backgroundImageUrl="https://staticfile.tes.ca/collisonReport/collisionDiagram/SMVUnattended_W.svg"
                     backgroundImageLeft={0.15}
                     backgroundImageTop={0}
                     backgroundImageWidth={0.7}
                     backgroundImageHeight={0.7}
                     defaultWidth={0.7}
                     defaultHeight={0.7}
                     defaultText="0"
                     allowEditText={false}
                     textLeft={0}
                     textTop={0.7}
                     textWidth={1}
                     textHeight={0.3}>
                  </CustomShape>


                  <CustomShape
                     category="tes"
                     type="Unknown"
                     title={t("unknown")}
                     backgroundImageUrl="https://staticfile.tes.ca/collisonReport/collisionDiagram/Unknown.svg"
                     backgroundImageLeft={0.15}
                     backgroundImageTop={0}
                     backgroundImageWidth={0.7}
                     backgroundImageHeight={0.7}
                     defaultWidth={0.7}
                     defaultHeight={0.7}
                     defaultText="0"
                     allowEditText={false}
                     textLeft={0}
                     textTop={0.7}
                     textWidth={1}
                     textHeight={0.3}>
                  </CustomShape>

                  <CustomShape
                     category="tes"
                     type="Description"
                     title={t("description")}
                     backgroundImageUrl="https://staticfile.tes.ca/collisonReport/collisionDiagram/Description.svg"
                     backgroundImageLeft={0.15}
                     backgroundImageTop={0}
                     backgroundImageWidth={0.7}
                     backgroundImageHeight={0.7}
                     defaultWidth={0.7}
                     defaultHeight={0.7}
                     defaultText="0"
                     allowEditText={false}
                     textLeft={0}
                     textTop={0.7}
                     textWidth={1}
                     textHeight={0.3}>
                  </CustomShape>

                  <CustomShape
                     category="tes"
                     type="rectangleTL"
                     title={t("logo")}
                     backgroundImageUrl="https://staticfile.tes.ca/collisonReport/collisionDiagram/paging/rectangleTL.svg"
                     backgroundImageTop={0}
                     backgroundImageWidth={1}
                     backgroundImageHeight={1}
                     defaultWidth={1}
                     defaultHeight={1}
                     allowEditText={false}
                  >
                  </CustomShape>

                  <CustomShape
                     category="tes"
                     type="rectangleTR"
                     title={t("logo")}
                     backgroundImageUrl="https://staticfile.tes.ca/collisonReport/collisionDiagram/paging/rectangleTR.svg"
                     backgroundImageTop={0}
                     backgroundImageWidth={1}
                     backgroundImageHeight={1}
                     defaultWidth={1}
                     defaultHeight={1}
                     allowEditText={false}
                  >
                  </CustomShape>
                  <CustomShape
                     category="tes"
                     type="rectangleBL"
                     title={t("logo")}
                     backgroundImageUrl="https://staticfile.tes.ca/collisonReport/collisionDiagram/paging/rectangleBL.svg"
                     backgroundImageTop={0}
                     backgroundImageWidth={1}
                     backgroundImageHeight={1}
                     defaultWidth={1}
                     defaultHeight={1}
                     allowEditText={false}
                  >
                  </CustomShape>
                  <CustomShape
                     category="tes"
                     type="rectangleBR"
                     title={t("logo")}
                     backgroundImageUrl="https://staticfile.tes.ca/collisonReport/collisionDiagram/paging/rectangleBR.svg"
                     backgroundImageTop={0}
                     backgroundImageWidth={1}
                     backgroundImageHeight={1}
                     defaultWidth={1}
                     defaultHeight={1}
                     allowEditText={false}
                  >
                  </CustomShape>



                  <CustomShape
                     category="tes"
                     type="logo"
                     title={t("logo")}
                     backgroundImageUrl="https://staticfile.tes.ca/collisonReport/collisionDiagram/paging/Logo_colour.svg"
                     backgroundImageLeft={0}
                     backgroundImageTop={0}
                     backgroundImageWidth={0}
                     backgroundImageHeight={0}
                     defaultWidth={2.08}
                     defaultHeight={0.58}
                     defaultText=""
                     allowEditText={false}
                     textLeft={0}
                     textTop={0}
                     textWidth={1}
                     textHeight={0}>
                  </CustomShape>

                  <CustomShape
                     category="tes"
                     type="compass"
                     title={t("compass")}
                     backgroundImageUrl="https://staticfile.tes.ca/collisonReport/collisionDiagram/paging/direction.svg"
                     backgroundImageLeft={0}
                     backgroundImageTop={0}
                     backgroundImageWidth={0}
                     backgroundImageHeight={0}
                     defaultWidth={1.63}
                     defaultHeight={1.63}
                     allowEditText={false}
                     textLeft={0}
                     textTop={0}
                     textWidth={1}
                     textHeight={0}>
                  </CustomShape>






                  <Toolbox>
                     <Group category="general" title="General" />
                     <Group category="custom" title="TES" />
                     <Group category="custom" title="TurningMovements" />
                     <Group category="custom" title="Angles" />
                     <Group category="custom" title="Angle Turning Left" />
                     <Group category="custom" title="Angle Turning Right" />
                     <Group category="custom" title="Approaching" />
                     <Group category="custom" title="RearEnd" />
                     <Group category="custom" title="Sideswipe" />
                     <Group category="custom" title="SMV Other" />
                     <Group category="custom" title="SMV Unattended" />





                  </Toolbox>

               </Diagram>

            </ScrollView>
         </Popup>
      </div>


   )
}

export default CollisionDiagram;